import React from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { Stack, TextField, Box, Grid, Button, MenuItem } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Country } from "country-state-city";
import axios from "axios";
import {  useQueryClient } from "@tanstack/react-query";

const CustomTextField = styled(TextField)(({ theme }) => ({
  width: "60%",

  "& .MuiInputBase-input": {
    height: "32px",
    borderRadius: "5px",
    padding: "3px",
    "&:focus": {
      border: "1px solid #d2cece",
    },
    "&:hover + fieldset": {
      border: "1px solid #d2cece",
    },
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #d2cece",
  },
}));

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  phone_number: Yup.string().required("Phone Number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  gstin: Yup.string().required("GST Number is required"),
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
  pincode: Yup.string().required("Pin Code is required")
});

const EditProfileDetails = ({ data, setSnackbarInfo, clientid }) => {
    const navigate = useNavigate();  const queryClient = useQueryClient();

    function navigateTo(){
        navigate("/myaccount/profile");
    }
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, dirtyFields },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      first_name: data.first_name,
      last_name: data.last_name,
      phone_number: data.phone_number,
      email: data.email,
      gstin: data.gstin,
      address: data.address,
      country: data.country,
      state: data.state,
      city: data.city,
      pincode: data.pincode,
    },
  });
  const onSubmit = async (data) => {
    const modified = {};
    Object.keys(dirtyFields).forEach((key) => {
      modified[key] = data[key];
    });
    
    axios
      .put(`${process.env.REACT_APP_WEBSERVICES_PRODUCTION_URL}/updateClient/${clientid}`, modified)
      .then(function (response) {
        setSnackbarInfo({
          open: true,
          severity: "success",
          message: response.data.message,
        });
        queryClient.invalidateQueries({ queryKey: ["getclientDetailsbyid"] });

        navigateTo()
      })

      .catch(function (errorr) {
        if (errorr.response) {
          setSnackbarInfo({
            open: true,
            severity: "error",

            message: errorr.response.data.error.message,
          });
        }
      });
  };


  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box
          className="my-account-left-info-wrapper"
          style={{ backgroundColor: "#fff", margin: 17, borderRadius: 15 }}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"100%"}
          >
            <Box>
              {" "}
              <h6>User Information</h6>
            </Box>
          </Box>
          <Grid container spacing={2}>
            {[
              { label: "First Name", name: "first_name" },
              { label: "Last Name", name: "last_name" },
              { label: "Phone Number", name: "phone_number" },
              { label: "Email", name: "email" },
              { label: "GST Number", name: "gstin" },
            ].map(({ label, name }) => (
              <Grid item md={6} xs={12} key={name}>
                <Box className="my-account-left-info">
                  <Box className="my-account-left-info-key">{label} :</Box>
                  <CustomTextField
                    required
                    {...register(name)}
                    error={errors && errors[name] && errors[name].message}
                    helperText={errors && errors[name] && errors[name].message}
                  />{" "}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box
          className="my-account-left-info-wrapper"
          style={{ backgroundColor: "#fff", margin: 20, borderRadius: 15 }}
        >
          <h6>Address</h6>
          <Grid container spacing={2}>
            {[
              { label: "Address ", name: "address", isSelect: false },
              { label: "City", name: "city", isSelect: false },
              { label: "State", name: "state", isSelect: false },
              {
                label: "Country",
                name: "country",
                isSelect: true,
                array: Country.getAllCountries(),
              },
              { label: "Postal Code", name: "pincode", isSelect: false },
            ].map(({ label, name, array, isSelect }) => (
              <Grid item md={6} xs={12} key={name}>
                <Box className="my-account-left-info">
                  <Box className="my-account-left-info-key">{label} :</Box>
                  <Box className="my-account-left-info-value">
                    {isSelect && name === "country" ? (
                      <CustomTextField
                        select
                        required
                        {...register(name)}
                        error={!!errors[name]}
                        helperText={errors[name]?.message}
                        SelectProps={{
                          MenuProps: {
                            style: {
                              maxHeight: 300,
                            },
                          },
                        }}
                        defaultValue={data.country}
                      >
                        {array.map((item) => (
                          <MenuItem key={item.phonecode} value={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </CustomTextField>
                    ) : (
                      <CustomTextField
                        required
                        {...register(name)}
                        error={!!errors[name]}
                        helperText={errors[name]?.message}
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Grid item xs={12}>
          <Stack gap={2} direction={"row"} justifyContent={"center"}>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isSubmitting}
            >
              Update
            </LoadingButton>

            <Button
              variant="outlined"
              onClick={navigateTo}
            >
              Cancel
            </Button>
          </Stack>
        </Grid>
      </form>
    </>
  );
};

export default EditProfileDetails;