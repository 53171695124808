import { Switch as MuiSwitch } from '@mui/material';
import { styled } from '@mui/material';

const IOSSwitch = styled(MuiSwitch)(({ theme }) => ({
  width: 41, // Adjusted width
  height: 19, // Adjusted height
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '100ms',
    '&.Mui-checked': {
      transform: 'translateX(22px) !important', 
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: 'rgba(87, 168, 119, 1)', // Background color when checked
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 15, // Adjust thumb width
    height: 15, // Adjust thumb height
    left: 'calc(100% - 20px)', // Position thumb to the right
  },
  '& .MuiSwitch-track': {
    borderRadius: 9, // Adjust track border radius
    backgroundColor: theme.palette.mode === 'light' ? 'rgba(128,128,128)' : '#1E1E1E',  // Adjusted colors
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    width: 41, // Adjust track width
    height: 19, // Adjust track height
// border: '1px solid red '
    
  },
}));

function CustomSwitch(props) {
  return <IOSSwitch {...props} />;
}

export default CustomSwitch;
