import { Button } from "@mui/material";
import React from "react";
import Style from "../../../Style/ChatNow/ChatNow.module.css";
const LeaderBoardHeader = () => {
  const buttonStyle = {
    background: "rgba(0, 10, 255, 1)",
    borderRadius: "30px",
    color: "white",
    height: "27px",
    fontSize: "12px",
    textTransform: "capitalize",
  };
  return (
    <div className={Style.LeaderBoardHeader_Wrapper}>
      <span className={Style.leaderBoadrText}>LeaderBoard</span>
      <span className={Style.leaderBoadrButton_padding}>
        <Button style={buttonStyle} variant="contained">
          Day
        </Button>
      </span>
    </div>
  );
};

export default LeaderBoardHeader;
