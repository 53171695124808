import { Box, IconButton } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { ImageFilter } from "../../../../Utils/imageUtils";

const ChatReply = ({ data, handleReplyClose }) => {
  console.log("ContentType", data);
  const renderContent = () => {
    const mimeType = data?.mimeType || "";
    const fileType = mimeType.split("/")[1];

    if (mimeType.startsWith("image/")) {
      return (
        <Box
          component="img"
          src={data?.originalUrl}
          alt="reply image"
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "4px",
            objectFit: "cover",
            marginRight: "8px",
          }}
        />
      );
    } else if (
      mimeType.startsWith("application/") ||
      mimeType.startsWith("video/") ||
      mimeType.startsWith("audio/")
    ) {
      const fileIcon = ImageFilter(fileType);

      return (
        <Box
          component="img"
          src={fileIcon}
          alt="file icon"
          sx={{
            width: "40px",
            height: "40px",
            marginRight: "8px",
          }}
        />
      );
    }
    return null;
  };

  return (
    <Box
      height={"auto"}
      maxHeight={"60px"}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{
        backgroundColor: "#f0f0f0",
        borderLeft: "4px solid #25d366",
        padding: "5px 10px",
        borderRadius: "5px",
        marginBottom: "2px",
        marginLeft: "5px",
      }}
    >
      {renderContent()}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "80%",
        }}
      >
        <Box
          sx={{
            fontSize: "12px",
            color: "#25d366",
            fontWeight: "bold",
            marginBottom: "2px",
          }}
        >
          {data.messageType === "sent"
            ? "You"
            : `${data?.sender?.first_name} ${data?.sender?.last_name}`}
        </Box>
        <Box
          sx={{
            fontSize: "14px",
            color: "#333",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {data.ContentType === "text" ? data?.content : data?.fileName}
        </Box>
      </Box>

      <Box>
        <IconButton
          onClick={handleReplyClose}
          sx={{ padding: "5px", fontSize: "16px" }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ChatReply;
