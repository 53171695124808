import { Box, IconButton, Modal, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Individual from "./Individual";
import Everyone from "./Everyone";
import Group from "./Group";

const ManageUser = ({ users, setUsers, setAnchorEl }) => {
  const [activeTab, setActiveTab] = useState("Individual");
  const handleClose = () => {
    setAnchorEl(null);
    setUsers(false);
    activeTab("Individual");
  };
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Modal
        open={users}
        onClose={handleClose}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          },
        }}
      >
        <Box
          sx={{
            borderRadius: "5px",
            bgcolor: "background.paper",
            position: "absolute",
            height: "85%",
            width: "70%",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px 20px",
              backgroundColor: "#F9FAFB",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                Assign Analytics to User(s)
              </Typography>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "start" }}>
              <IconButton onClick={handleClose}>
                <CloseIcon
                  sx={{ width: "20px", height: "20px", cursor: "pointer" }}
                />
              </IconButton>
            </Box>
          </Box>
          <Tabs
            value={activeTab}
            onChange={handleChange}
            indicatorColor="primary"
            variant="scrollable"
            // scrollButtons
            sx={{
              borderBottom: "1px solid var(--light-border-color)",
            }}
          >
            <Tab
              sx={{ textTransform: "none", fontSize: "14px" }}
              label="Individual"
              value="Individual"
            />
            <Tab
              sx={{ textTransform: "none", fontSize: "14px" }}
              label="Group"
              value="Group"
            />
            <Tab
              sx={{ textTransform: "none", fontSize: "14px" }}
              label="Everyone"
              value="Everyone"
            />
          </Tabs>
          <Box
            sx={{
              padding: "15px 20px",
              overflowY: "auto",
              height: "calc(100% - 113px)",
              backgroundColor: "#F9FAFB",
            }}
          >
            {activeTab === "Individual" && (
              <Individual handleClose={handleClose} />
            )}
            {activeTab === "Group" && <Group />}
            {activeTab === "Everyone" && <Everyone />}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ManageUser;
