import React from "react";
import TargetMeterGrid from "./Components/TargetMeterGrid";

const TargetMeterLayOut = () => {
  return (
    <div>
      <TargetMeterGrid />
    </div>
  );
};

export default TargetMeterLayOut;
