import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts";
import Style from "../../../../Style/TopBar/Chart.module.css";
const data = [
  { name: "Group1", value: 1 },
  { name: "Group2", value: 3 },
  { name: "Group3", value: 4 },
  { name: "Group4", value: 5 },
  { name: "Group5", value: 2 },
];

const LineGraph = () => {
  return (
    <div className={Style.graphWrapper}>
      <ResponsiveContainer width="100%" height="70%">
        <LineChart
          width={250}
          height={100}
          data={data}
          margin={{
            top: 20,
            right: 20,

            bottom: 15,
          }}
          style={{ fontsize: "11px" }}
        >
          <CartesianGrid horizontal="true" vertical="" />
          <XAxis dataKey="name" fontSize={10} axisLine={false}>
            <Label
              value="Created by"
              fontSize={12}
              offset={0}
              position="bottom"
            />
          </XAxis>
          <YAxis
            fontSize={10}
            label={{
              value: "Record Count",
              angle: -90,
              position: "Center",
              fontSize: "12px",
            }}
            stroke="0"
          />
          <Tooltip animationDuration={1000} />

          <Line
            dot={{ fill: "rgb(117, 184, 255)", strokeWidth: 2 }}
            activeDot={{ strokeWidth: 2, r: 6 }}
            type="monotone"
            dataKey="value"
            stroke="rgb(0, 236, 206)"
            fill="rgb(129, 236, 236)"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineGraph;
