import React from "react";
import Style from "../Style/LogInPage.module.css";
import ButtonContainer from "./Component/ButtonContainer";
import LogInHeader from "./Component/LogInHeader";
const LoginPage = () => {
  return (
    <div className={Style.Login_wrapper}>
      <LogInHeader />
      <div className={Style.text_wrapper}>
        <p className={Style.LogIn_Style}>Log In</p>
        <p className={Style.LogIn_Style_}>
          Welcome back! Please enter your details.
        </p>
      </div>
      <ButtonContainer />
    </div>
  );
};

export default LoginPage;
