import React from "react";
import CustomActivityLayOut from "../Components/CustomActivityLayOut";
import { WithHoc } from "../Components/WithHoc";
import Style from "../../Style/Common.module.css";
import SupportGrid from "./Components/SupportGrid";
const SupportResponseLayOut = () => {
  const SupportResponseLayout = WithHoc(CustomActivityLayOut, SupportGrid);
  return (
    <div className={Style.commonPadding}>
      <SupportResponseLayout title={"Support Responsiveness"} />
    </div>
  );
};

export default SupportResponseLayOut;
