import { Box, Paper } from "@mui/material";
import React, { useEffect, useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";

import {
  useOnlineStatusStore,
  useOpenChatStore,
  useWorSpaceRoom,
} from "./store/teamChatStore";
import ChatWindow from "./Components/Maximized";
import ChatProfiles from "./Components/Minimized";
import useSelectedWorkSpace from "../store/selectedWorkSpaceStore";
import useUser from "../store/userStore";
import { useSocket } from "../Context/SocketStore";

const TeamChat = React.memo(() => {
  const { openChat } = useOpenChatStore();
  const queryClient = useQueryClient();
  const { Socket } = useSocket();
  const { getSelectedWorkSpace } = useSelectedWorkSpace();
  const { setworkSpaceRoomId } = useWorSpaceRoom();
  const Workspaceid = getSelectedWorkSpace();
  const { getUserId } = useUser();
  const userId = getUserId();
  const { addOnlineStatusId, removeOnlineStatusId } = useOnlineStatusStore();

  const handleOnlineStatus = useCallback(
    (response) => {
      addOnlineStatusId(response);
    },
    [addOnlineStatusId]
  );

  const handleOfflineStatus = useCallback(
    (response) => {
      removeOnlineStatusId(response);
    },
    [removeOnlineStatusId]
  );

  const handleOnlineDelivered = useCallback(
    (response) => {
      const { convoId, messageId, roomId } = response.data;
      const socketData = {
        convoId,
        roomId,
        messageIds: [messageId],
        time: new Date(),
        userId,
      };
      console.log("constructedid", socketData);

      Socket.emit("message_delivered", socketData);
    },
    [Socket, userId]
  );

  useEffect(() => {
    if (!Socket || !Workspaceid) return;

    const joinData = { workspaceId: Workspaceid };
    const deliverData = { workspaceId: Workspaceid, userId };

    const handleConnect = (response) => {
      setworkSpaceRoomId(response.roomId);
    };

    // Join the workspace and listen to events
    Socket.emit("join_workspace", joinData, () => {
      console.log("Joined workspace");
    });

    Socket.on("roomJoined", handleConnect);
    Socket.on("user_online", handleOnlineStatus);
    Socket.on("user_offline", handleOfflineStatus);
    Socket.emit("make_delivered", deliverData, () => {});
    Socket.on("message_delivered_when_online", handleOnlineDelivered);

    return () => {
      Socket.off("roomJoined", handleConnect);
      Socket.off("user_online", handleOnlineStatus);
      Socket.off("user_offline", handleOfflineStatus);
      Socket.off("message_delivered_when_online", handleOnlineDelivered);
    };
  }, [Socket, Workspaceid, userId, handleOnlineStatus, handleOfflineStatus, handleOnlineDelivered, setworkSpaceRoomId]);

  useEffect(() => {
    if (!Socket) return;

    const handleRefetch = (response) => {
      const isMember = response.data.some((data) => data === userId);
      if (isMember) {
        queryClient.invalidateQueries(["getMessageList"]);
      }
    };

    Socket.on("refetch_chat_list", handleRefetch);

    return () => {
      Socket.off("refetch_chat_list", handleRefetch);
    };
  }, [Socket, userId, queryClient]);

  return (
    <Paper
      elevation={1}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 10,
      }}
    >
      <Box width={openChat ? "22vw" : "56px"} height={"calc(100vh - 40px)"}>
        {openChat ? <ChatWindow /> : <ChatProfiles />}
      </Box>
    </Paper>
  );
});

export default TeamChat;
