import React from 'react'
import Style from "../Style/Panel.module.css"
import Carousel from '../Components/Carousel'
const Panel = () => {
  return (
    <div className={Style.outter_wrapper}>
   <Carousel/>
    </div>
  )
}

export default Panel
