import { Button, Modal, Typography } from "@mui/material";
import { borderColor, Box } from "@mui/system";
import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const style = {
  height: "30px",
  bgcolor: "var(--button-color)",
  textTransform: "none",
  color: "#fff",
  ":hover": { backgroundColor: "var(--button-color)" },
};

const styles = {
  height: "30px",
  borderColor: "var(--button-color)",
  color: "var(--button-color)",
  textTransform: "none",
  ":hover": {
    borderColor: "var(--button-color)",
    color: "var(--button-color)",
  },
};
const Confirmation = ({
  openConfirmation,
  setOpenConfirmation,
  setConnect,
}) => {
  const handleYes = () => {
    setOpenConfirmation(false);
    setConnect((prev) => !prev);
  };
  return (
    <Modal
      open={openConfirmation}
      onClose={() => setOpenConfirmation(false)}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.7)",
        },
      }}
    >
      <Box
        sx={{
          borderRadius: "4px",
          bgcolor: "background.paper",
          position: "absolute",
          height: "150px",
          width: "35%",
          minWidth: "35%",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: "20px",
        }}
      >
        <Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <InfoOutlinedIcon fontSize="medium" color="warning" />
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 600,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              Do you want to disconnect this email account?
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              marginTop: "10px",
              paddingLeft: "27px",
            }}
          >
            Once you disconnect the email account, Your all sequences using this
            email account will be paused.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            gap: "20px",
            alignItems: "center",
            padding: "10px 0",
          }}
        >
          <Button
            variant="outlined"
            sx={styles}
            onClick={() => setOpenConfirmation(false)}
          >
            Cancel
          </Button>
          <Button variant="contained" sx={style} onClick={handleYes}>
            Yes
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default Confirmation;
