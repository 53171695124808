import React from "react";
import view from "./Overview.module.scss";
import { Typography } from "@mui/material";

const PieLegend = ({ data }) => {
  return (
    <>
      {data.map((data) => (
        <div
          key={`${data.fill}-${data.name}`}
          className={view.legendcontainer}
          style={{ borderLeft: `2px solid ${data.fill}` }}
        >
          <div
            style={{
              color: `${data.fill}`,
            }}
            className={view.legendValue}
          >
            <Typography sx={{ fontSize: "25px", marginBottom: "5px" }}>
              {data.value} %
            </Typography>
            <Typography sx={{ fontSize: "14px" }}>({data.count})</Typography>
          </div>
          <Typography sx={{ fontSize: "14px", color: "#000" }}>
            {data.name}
          </Typography>
        </div>
      ))}
    </>
  );
};

export default PieLegend;
