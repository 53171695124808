import React, { useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import { Typography } from "@mui/material";
import {
  useChatUIStore,
  useReceiverId,
  useOnlineStatusStore,
} from "../../../../store/teamChatStore";
import { formatDatesTime } from "../../../../CustomHooks/formateDates";
import HoverChatDropDown from "./HoverChatDropDown";
import PushPinIcon from "@mui/icons-material/PushPin";
import { ImageFilter } from "../../../../Utils/imageUtils";

const ChatBar = ({ data, onPinChat, onArchiveChat }) => {
  const { setUIElement } = useChatUIStore();
  const { setReceiverId } = useReceiverId();
  const { onlineStatusIds } = useOnlineStatusStore();
  console.log("msglistdata", data);
  const [hoverId, setHoverId] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleBarClick = () => {
    const type = data.is_group_chat ? "#groupView" : "#chatView";
    setUIElement(type);

    setReceiverId({
      is_group_chat: data.is_group_chat,
      receiverId: data.id,
      convoId: data._id,
      userName: `${data?.first_name} ${data?.last_name}`,
      email: data?.name,
      profilePic: data?.profile_pic,
    });
  };

  const handleOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const messageOptions = [
    !data.isArchived && {
      id: 1,
      label: data.isPinned ? "Unpin" : "Pin",
      actionFunc: () => onPinChat(data._id, data.isPinned),
    },
    {
      id: 2,
      label: data.isArchived ? "Unarchive" : "Archive",
      actionFunc: () => onArchiveChat(data._id, data.isArchived),
    },
  ].filter(Boolean);

  const isOnline = onlineStatusIds.includes(data.id);

  return (
    <Box
      display="flex"
      alignItems="center"
      py={1}
      width="100%"
      sx={{ cursor: "pointer" }}
      onClick={handleBarClick}
      onMouseEnter={() => setHoverId(data._id)}
      onMouseLeave={() => setHoverId("")}
    >
      <Box width="10%">
        <Badge
          color={isOnline ? "success" : "error"}
          variant="dot"
          overlap="circular"
        >
          <Avatar
            alt={data.first_name}
            src={data.profile_pic}
            sx={{ width: 27, height: 27 }}
          />
        </Badge>
      </Box>
      <Box
        paddingLeft={0.8}
        width="90%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box width="90%" display="flex" flexDirection="column">
          <Box display={"flex"} alignItems={"center"}>
            <Box width={"95%"}>
              <Typography fontWeight={500} fontSize={12}>
                {data?.name}
              </Typography>
            </Box>
            <Box>
              <Typography fontWeight={500} fontSize={8}>
                {formatDatesTime(data?.lastMessageTime)}
              </Typography>
            </Box>
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            <Box
              sx={{
                whiteSpace: "nowrap",
                width: "95%",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {data.ContentType === "file" ? (
                <Box display={"flex"} alignItems={"center"}>
                  <img
                    alt="file icon"
                    src={ImageFilter(data.mimeType)}
                    style={{ width: 12, height: 12, marginRight: "4px" }}
                  />
                  <Typography fontSize={11} color="#959FA3" noWrap>
                    {data?.fileName}
                  </Typography>
                </Box>
              ) : (
                <Typography fontSize={11} color="#959FA3" noWrap>
                  {data?.lastMessage}
                </Typography>
              )}
            </Box>
            {data.isPinned && <PushPinIcon style={{ fontSize: "12px" }} />}
            {hoverId === data._id && (
              <HoverChatDropDown
                open={Boolean(anchorEl)}
                handleOpen={handleOpen}
                anchorEl={anchorEl}
                handleClose={handleClose}
                messageOptions={messageOptions}
                messageId={data._id}
                isArchived={data.isArchived}
                isPinned={data.isPinned}
              />
            )}
            {data?.lastMessageSender?.toLowerCase() !== "you" && (
              <Box paddingLeft={1}>
                <Badge
                  badgeContent={data?.unreadCount}
                  color="success"
                  overlap="circular"
                  max={999}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatBar;
