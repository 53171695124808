import { Box, Divider } from "@mui/material";
import React from "react";
import ChatWindowOptions from "./ChatConsole";
import ChatList from "./ChatList";
import ChatView from "./ChatList/Components/ChatView";
import { useChatUIStore } from "../../store/teamChatStore";
import TimeLine from "./TimeLine";
import Peoples from "./Peoples";
import UserProfile from "./Profile";
import ChatArchive from "./Chats";
import StarMessageList from "./ChatList/Components/StarMessageList";

const ChatWindow = React.memo(() => {
  const { uiElement } = useChatUIStore();
  console.log("myUIELE", uiElement);

  const renderContent = () => {
    switch (uiElement) {
      case "#channels":
        return <StarMessageList />;
      case "#chatView":
      case "#groupView":
        return <ChatView />;
      case "#clock":
        return <ChatList />;
      case "#userProfile":
        return <UserProfile />;
      case "#message":
        return <ChatArchive />;
      case "#person":
        return <Peoples />;

      default:
        return <div>No component selected</div>;
    }
  };

  return (
    <Box height={"100%"} width={"1    00%"}>
      <ChatWindowOptions />
      <Divider />
      {renderContent()}
    </Box>
  );
});

export default ChatWindow;
