import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import axios from "axios";

async function getAllList(page = 1, limit = 10) {
  const url = `${process.env.REACT_APP_TEAM_CHAT_BASEURL}/api/v1/group/combine/list`;
  return axios.get(url).then(({ data }) => {
    console.log("mynewdata",data)
    return data;
  });
}

export const useAllList = () => {
  return useQuery({
    queryKey: ["teamChat_getAllUserList"],
    queryFn: getAllList(),
  });
};
