import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
const schema = yup
  .object({
    users: yup.string().required("Please Choose Users"),
    role: yup.string().required("Please Select Role"),
  })
  .required();

const styleSelect = {
  width: "50%",
  height: "45px",
};

const Individual = ({ handleClose }) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  return (
    <div>
      <form>
        <FormControl fullWidth margin="normal">
          <Typography sx={{ fontSize: "15px" }}>Choose Users</Typography>
          <Select
            {...register("users")}
            error={!!errors.users}
            value={watch("users") || ""}
            onChange={(e) => setValue("users", e.target.value)}
            sx={styleSelect}
            variant="standard"
            IconComponent={AddIcon}
          >
            <MenuItem></MenuItem>
          </Select>
          <FormHelperText style={{ color: "red" }}>
            {errors.department_id?.message}
          </FormHelperText>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <Typography sx={{ fontSize: "15px" }}>Role</Typography>
          <Select
            {...register("role")}
            error={!!errors.role}
            value={watch("role") || ""}
            onChange={(e) => setValue("role", e.target.value)}
            sx={styleSelect}
            variant="standard"
          >
            <MenuItem></MenuItem>
          </Select>
          <FormHelperText style={{ color: "red" }}>
            {errors.shift_id?.message}
          </FormHelperText>
        </FormControl>

        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            marginTop: "50px",
            gap: 2,
          }}
        >
          <Button variant="contained" color="primary">
            Assign
          </Button>
          <Button variant="outlined">Cancel</Button>
        </Box>
      </form>
    </div>
  );
};

export default Individual;
