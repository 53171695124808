import { Box } from '@mui/system'
import React from 'react'
import ChipAvatar from './ChipAvatar'

const SelectedUser = ({selectedUserData,handleChipDelete}) => {
  return (
    <Box mb={2}>
          {selectedUserData.map((user) => (
            <Box key={`${user.userId}-chip`} display="inline-block" margin="4px">
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <ChipAvatar
                  src="/static/images/avatar/1.jpg"
                  onClose={() => handleChipDelete(user.userId)}
                  name={`${user.firstName} ${user.lastName}`}
                />
                <Box
                  fontSize={"8px"}
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "35px",
                  }}
                >
                  {user.firstName} {user.lastName}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
  )
}

export default SelectedUser