import axios from "axios";
import React from "react";
import App from "../../App";
import useSelectedWorkSpace from "../../store/selectedWorkSpaceStore";
import useUser from "../../store/userStore";
import { useCrmContactsStore } from "../../crm/store/CrmStore";
import { SocketProvider } from "../../Context/SocketStore";

const Interceptor = () => {
  const { getToken, logout } = useUser();
  const { getSelectedWorkSpace } = useSelectedWorkSpace();

  const resetStore = useCrmContactsStore((state) => state.resetState);
  console.log("Workspaceid", getSelectedWorkSpace());
  axios.interceptors.request.use(
    function (config) {
      config.headers.Authorization = `Bearer ${getToken()}`;
      config.headers["X-Workspace"] = getSelectedWorkSpace();

      return config;
    },
    function (error) {
      resetStore();
      logout();
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      let res = error.response;
      if (res?.status === 401 && res.config && !res.config.__isRetryRequest) {
        resetStore();
        logout();
      }

      if (error.message !== undefined && error.message === "Network Error") {
      }
      return Promise.reject(error);
    }
  );

  return (
    <>
      <SocketProvider>
        <App />
      </SocketProvider>
    </>
  );
};

export default Interceptor;
