import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import { styled } from "@mui/system";
import Style from "../Style/FundTextField.module.css";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "2px",
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px",
  },
  "& .MuiInputBase-input": {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
    },
  },
}));

const FundTextField = ({ name, value, error, helperText, onChangeFuncion }) => {
  return (
    <>
      <div className={Style.fund_text}>WHAT AMOUNT WOULD YOU LIKE TO ADD</div>
      <div className={Style.fund_align}>
        <CustomTextField
          name={name}
          type="number"
          value={value}
          error={error}
          helperText={helperText}
          onChange={onChangeFuncion}
          style={{ width: "70%" }}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">INR</InputAdornment>
            ),
          }}
        />
      </div>
    </>
  );
};

export default FundTextField;
