import React from "react";
import Avatar from "@mui/material/Avatar";
import { Box } from "@mui/material";

import { styled } from "@mui/material/styles";
import { useOnlineStatusStore } from "../../../store/teamChatStore";
const StatusIndicator = styled("div")(({ theme, online }) => ({
  position: "absolute",
  bottom: "3px",
  right: "3px",
  width: "6.25px",
  height: "6.25px",
  borderRadius: "50%",
  backgroundColor: online ? "green" : "red",
}));

const AvatarWrapper = styled(Box)({
  position: "relative",
  display: "inline-block",
  cursor: "pointer",
});



const ProfileAvatar = ({ name, profilePic,id }) => {
  const { onlineStatusIds } = useOnlineStatusStore();
  const isOnline = onlineStatusIds.includes(id);

  return (
    <Box px={"10px"} paddingTop={"12px"}>
      <AvatarWrapper>
        <Avatar alt={name} src={profilePic} />
        <StatusIndicator online={isOnline ? 1 : 0} />
      </AvatarWrapper>
    </Box>
  );
};

export default ProfileAvatar;
