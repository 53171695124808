import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/navBar.css";
import IconButton from "@mui/material/IconButton";
import vector from "./img/Vector.png";
import { LuPanelLeftOpen } from "react-icons/lu";
import { LuPanelLeftClose } from "react-icons/lu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import notifiImg from "./img/Notifications.png";
import settings from "./img/Group.png";
import profile from "./img/Group 68835.png";
import NotificationDrawer from "./NotificationDrawer";
import UserDrawer from "./Component/UserDrawer";
import SearchBar from "./NavBar/SearchBar";
import CustomCompanyDropDown from "./CompanyDropDown/CustomCompanyDropDown";
import UseSideBarActive from "../../store/sideBarStore";
import {
  useOpenChatStore,
  useTeamChatStore,
} from "../../TeamChat/store/teamChatStore";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SettingsIcon from "@mui/icons-material/Settings";

const CssTextField = styled(TextField)(({ theme }) => ({
  borderRadius: 20,
  width: 120,
  cursor: "pointer",
  color: "antiquewhite",

  "& label.Mui-focused": {
    color: "antiquewhite",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "antiquewhite",
      color: "antiquewhite",
    },
    "&:hover fieldset": {
      borderColor: "antiquewhite",
      color: "antiquewhite",
    },
    "&.Mui-focused fieldset": {
      borderColor: "antiquewhite",
      color: "antiquewhite",
    },
    "& p": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "68px",
      height: "17px",
      position: "relative",
      top: "16%",
    },
  },
}));

<CssTextField label="Your Label" variant="outlined" />;

export default function NavBar() {
  let history = useNavigate();
  const [openDrawer, setopenDrawer] = useState(false);

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setopenDrawer(openDrawer ? false : true);
  };

  const [openNotificationDrawer, setNotificationDrawer] = useState(false);

  function handleNotificationOpen() {
    setNotificationDrawer(true);
  }

  function handleNotificationClose() {
    setNotificationDrawer(false);
  }
  const { teamChat } = useTeamChatStore();
  const { openChat, setOpenChat } = useOpenChatStore();

  const [showNavbar, setShowNavbar] = useState(false);
  const setActiveStatus = UseSideBarActive((state) => state.setActiveStatus);
  return (
    <div className="navBar">
      <div className="outside-container">
        <div style={{ marginLeft: "16px" }}>
          <MenuOutlinedIcon
            fontSize="large"
            sx={{ cursor: "pointer", color: "#000334" }}
            onClick={() => {
              setActiveStatus();
            }}
          />
          {/* <IconButton
            onClick={() => {
              setActiveStatus();
            }}
          >
            <img src={vector} alt="web services" />
          </IconButton> */}
        </div>
        <div className="container1">
          <div className={`nav-elements  ${showNavbar && "active"}`}>
            <div style={{ marginRight: "24px" }}>
              <SearchBar />
            </div>
          </div>
          <div className="nav-bar-icons-wrapper">
            <div className="nav-bar-notification">
              {true ? (
                <CustomCompanyDropDown />
              ) : (
                <Tooltip title="Add Company" placement="top-end">
                  <Link
                    style={{
                      color: "#000",
                      textDecoration: "none",
                    }}
                    to="/settings/managecompany"
                  >
                    <AddCircleOutlineIcon />
                  </Link>{" "}
                </Tooltip>
              )}
            </div>
            <div className="nav-bar-settings">
              <NotificationsIcon
                onClick={handleNotificationOpen}
                sx={{ cursor: "pointer" }}
              />
              {/* <img
                style={{
                  color: "#000",
                  cursor: "pointer",
                  padding: 0,
                  fill: "#000",
                }}
                src={notifiImg}
                alt="notification"
                onClick={handleNotificationOpen}
              /> */}
            </div>
            <div className="nav-bar-settings nav-bar-settings-icon">
              <SettingsIcon
                onClick={() => history("mySettings")}
                sx={{ cursor: "pointer" }}
              />
              {/* <img
                src={settings}
                style={{ color: "#000", cursor: "pointer", }}
                onClick={() => history("mySettings")}
                alt="settings"
              /> */}
            </div>{" "}
            <div className="nav-bar-user-icon" onClick={setOpenChat}>
              {teamChat ? (
                openChat ? (
                  <LuPanelLeftClose fontSize={"23px"} sx={{ color: "#000" }} />
                ) : (
                  <LuPanelLeftOpen fontSize={"23px"} sx={{ color: "#000" }} />
                )
              ) : (
                ""
              )}
            </div>
            <div className="nav-bar-user-icon" onClick={toggleDrawer}>
              <img
                style={{ height: "36px", width: "36px", color: "#000" }}
                alt={"user's name"}
                src={profile}
              />
            </div>
            <UserDrawer open={openDrawer} toggleDrawer={toggleDrawer} />
            <NotificationDrawer
              open={openNotificationDrawer}
              handleClose={handleNotificationClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
