import React, { useState } from "react";

import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";

import CustomButtonContainer from "./Components/CustomButtonContainer";
import CustomButton from "./Components/CustomButton";
import CustomSnackBar from "../SnackBar/CustomSnackBar";

import VerifyHeader from "./VerifyHeader";

const VerifyMail = () => {
  const [snackbaropen, setSnackbaropen] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Please enter Email"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      const params = {
        email: values.email,
      };

      axios
        .post(`${process.env.REACT_APP_WEBSERVICES_PRODUCTION_URL}/client/password-reset`, params)
        .then(function (response) {
          setSnackbaropen({
            open: true,
            severity: "success",
            message:
              "Mail Sent Successfully Please Check And Verify Your Mail Id",
          });
        })

        .catch((error) => {
          setSnackbaropen({
            open: true,
            severity: "error",
            actionColor: "#edf7ed",
            message: error.response.data.error.message,
          });
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });
  return (
    <>
      <div>
        <VerifyHeader />

        <p
          style={{
            fontSize: 12,
            textAlign: "center",
            fontWeight: 600,
            margin: 10,
          }}
        >
          Enter your email address and we will send you
          <br /> instructions to reset your password.
        </p>
        <div style={{ padding: "0px 5px" }}>
          <form onSubmit={formik.handleSubmit}>
            <CustomButtonContainer
              name={"email"}
              value={formik.values.email}
              error={formik.touched.email && !!formik.errors.email}
              helperText={formik.touched.email && formik.errors.email}
              onChangeFuncion={formik.handleChange}
              title={"Email"}
              isPassword={false}
              isMargin={true}
              fieldType={"email"}
            />
            <CustomButton
              title={"Verify Mail"}
              loading={formik.isSubmitting}
              onSubmit={formik.handleSubmit}
            />
            <div style={{ padding: "0px 5px" }}></div>
          </form>
        </div>
      </div>
      <CustomSnackBar
        snackbaropen={snackbaropen}
        setSnackbaropen={setSnackbaropen}
        position="left"
      />
    </>
  );
};

export default VerifyMail;
