import React from 'react'
import Styles from "../../components/Dashboard.module.scss";
const UserWL = ({ title }) => {
    return (
        <div className={Styles.graphwrapper}>
          <div className={Styles.title}>{title}</div>
          <div className={Styles.noDataWrapper}>
          <span className={Styles.noDataText}>No Data Available</span>
          </div>
        </div>
      );
    };

export default UserWL
