import React from "react";

function EmailSetupDetails({ data }) {
  return (
    <>
      <div
        style={{
          borderRadius: ".25rem .25rem 0 0",
          border: ".0625rem solid #e5e7eb",
          background: "#fff",
          padding: ".75rem 1.5rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <div style={{ maxWidth: "46.875rem", boxSizing: "border-box" }}>
          <div style={{ display: "flex", alignItems: "center", gap: ".75rem" }}>
            <img
              src={
                data?.spf
                  ? "https://my.saleshandy.com/static/media/checks-green.622fccfa.svg"
                  : "https://my.saleshandy.com/static/media/alert-triangle.fe7de9fb.svg"
              }
              alt=""
              style={{ width: "1.125rem", height: "1.125rem" }}
            />
            <p
              style={{
                fontWeight: "500",
                fontSize: ".875rem",
                lineHeight: "1.25rem",
                color: "#1f2937",
              }}
            >
              SPF Set-up
            </p>
            {/* <img
            src="https://my.saleshandy.com/static/media/youtube.92f3ecc7.svg"
            alt=""
            style={{
              width: "1.125rem",
              height: "1.125rem",
              marginLeft: ".25rem",
            }}
          /> */}
          </div>
          <div style={{ boxSizing: "border-box" }}>
            <p
              style={{
                marginLeft: "1.875rem",
                marginTop: ".5rem",
                fontWeight: "500",
                fontSize: ".75rem",
                lineHeight: "1.25rem",
                color: "#6b7280",
                marginBottom: "1rem",
              }}
            >
              {data?.spf
                ? "Great news! Your SPF record is set up correctly."
                : "Your SPF record is not set up correctly."}
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          borderRadius: ".25rem .25rem 0 0",
          border: ".0625rem solid #e5e7eb",
          background: "#fff",
          padding: ".75rem 1.5rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <div style={{ maxWidth: "46.875rem", boxSizing: "border-box" }}>
          <div style={{ display: "flex", alignItems: "center", gap: ".75rem" }}>
            <img
              src="https://my.saleshandy.com/static/media/alert-triangle.fe7de9fb.svg"
              alt=""
              style={{ width: "1.125rem", height: "1.125rem" }}
            />
            <p
              style={{
                fontWeight: "500",
                fontSize: ".875rem",
                lineHeight: "1.25rem",
                color: "#1f2937",
              }}
            >
              Custom Domain Set-up
            </p>
            {/* <img
              src="https://my.saleshandy.com/static/media/youtube.92f3ecc7.svg"
              alt=""
              style={{
                width: "1.125rem",
                height: "1.125rem",
                marginLeft: ".25rem",
              }}
            /> */}
          </div>
          <div style={{ boxSizing: "border-box" }}>
            <p
              style={{
                marginLeft: "1.875rem",
                marginTop: ".5rem",
                fontWeight: "500",
                fontSize: ".75rem",
                lineHeight: "1.25rem",
                color: "#6b7280",
                marginBottom: "1rem",
              }}
            >
              Oops! The custom tracking domain is not set. Learn how to set up a
              custom tracking domain & set it up here.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              maxWidth: "31.25rem",
              marginLeft: "1.875rem",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                height: "28px",
                padding: ".25rem .5rem",
                transition: "all .2s ease",
              }}
            >
              <h3
                style={{
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: ".75rem",
                  lineHeight: "1.25rem",
                  color: "#1f2937",
                  display: "flex",
                  alignItems: "center",
                  gap: ".5rem",
                }}
              >
                5am5pm123@gmail.com
                <div
                  style={{
                    padding: "1px 5px",
                    width: "39px",
                    height: "18px",
                    background: "#e5e7eb",
                    borderRadius: ".25rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontSize: ".625rem",
                      lineHeight: "1rem",
                      fontWeight: "500",
                      color: "#6b7280",
                    }}
                  >
                    SMTP
                  </span>
                </div>
              </h3>
              <div style={{}}>
                <button
                  style={{
                    fontSize: ".75rem",
                    lineHeight: "1.25rem",
                    color: "#1d4ed8",
                    outline: "none",
                    border: "none",
                    background: "transparent",
                    cursor: "pointer",
                  }}
                >
                  Set Custom Domain
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          borderRadius: ".25rem .25rem 0 0",
          border: ".0625rem solid #e5e7eb",
          background: "#fff",
          padding: ".75rem 1.5rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <div style={{ maxWidth: "46.875rem", boxSizing: "border-box" }}>
          <div style={{ display: "flex", alignItems: "center", gap: ".75rem" }}>
            <img
              src={
                data?.dailyLimit > 80
                  ? "https://my.saleshandy.com/static/media/check-orange.87068622.svg"
                  : "https://my.saleshandy.com/static/media/alert-triangle.fe7de9fb.svg"
              }
              alt=""
              style={{ width: "1.125rem", height: "1.125rem" }}
            />
            <p
              style={{
                fontWeight: "500",
                fontSize: ".875rem",
                lineHeight: "1.25rem",
                color: "#1f2937",
              }}
            >
              Daily Sending Quota
            </p>
            {/* <img
              src="https://my.saleshandy.com/static/media/youtube.92f3ecc7.svg"
              alt=""
              style={{
                width: "1.125rem",
                height: "1.125rem",
                marginLeft: ".25rem",
              }}
            /> */}
          </div>
          <div style={{ boxSizing: "border-box" }}>
            <p
              style={{
                marginLeft: "1.875rem",
                marginTop: ".5rem",
                fontWeight: "500",
                fontSize: ".75rem",
                lineHeight: "1.25rem",
                color: "#6b7280",
                marginBottom: "1rem",
              }}
            >
              {data?.dailyLimit > 80
                ? " Your email account has a moderate daily sending quota. It's important to follow proper email-sending practices to avoid hitting your quota and maintain a good sender reputation. Consider segmenting your email list and sending emails in batches to stay within your sending quota."
                : "Your email account has a moderate daily sending quota. It's important to follow proper email-sending practices to avoid hitting your quota and maintain a good sender reputation. Consider segmenting your email list and sending emails in batches to stay within your sending quota."}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              maxWidth: "31.25rem",
              marginLeft: "1.875rem",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                height: "28px",
                padding: ".25rem .5rem",
                transition: "all .2s ease",
              }}
            >
              <h3
                style={{
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: ".75rem",
                  lineHeight: "1.25rem",
                  color: "#1f2937",
                  display: "flex",
                  alignItems: "center",
                  gap: ".5rem",
                }}
              >
                {data?.emailAddress ? data?.emailAddress : ""}
                <div
                  style={{
                    padding: "1px 5px",
                    width: "39px",
                    height: "18px",
                    background: "#e5e7eb",
                    borderRadius: ".25rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontSize: ".625rem",
                      lineHeight: "1rem",
                      fontWeight: "500",
                      color: "#6b7280",
                    }}
                  >
                    SMTP
                  </span>
                </div>
              </h3>
              <div style={{}}>
                <button
                  style={{
                    fontSize: ".75rem",
                    lineHeight: "1.25rem",
                    color: "#1d4ed8",
                    outline: "none",
                    border: "none",
                    background: "transparent",
                    cursor: "pointer",
                  }}
                >
                  Set Quota
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          borderRadius: ".25rem .25rem 0 0",
          border: ".0625rem solid #e5e7eb",
          background: "#fff",
          padding: ".75rem 1.5rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <div style={{ maxWidth: "46.875rem", boxSizing: "border-box" }}>
          <div style={{ display: "flex", alignItems: "center", gap: ".75rem" }}>
            <img
              src={
                data?.dmarc
                  ? "https://my.saleshandy.com/static/media/checks-green.622fccfa.svg"
                  : "https://my.saleshandy.com/static/media/alert-triangle.fe7de9fb.svg"
              }
              alt=""
              style={{ width: "1.125rem", height: "1.125rem" }}
            />
            <p
              style={{
                fontWeight: "500",
                fontSize: ".875rem",
                lineHeight: "1.25rem",
                color: "#1f2937",
              }}
            >
              DMARC Set-up
            </p>
            {/* <img
              src="https://my.saleshandy.com/static/media/youtube.92f3ecc7.svg"
              alt=""
              style={{
                width: "1.125rem",
                height: "1.125rem",
                marginLeft: ".25rem",
              }}
            /> */}
          </div>
          <div style={{ boxSizing: "border-box" }}>
            <p
              style={{
                marginLeft: "1.875rem",
                marginTop: ".5rem",
                fontWeight: "500",
                fontSize: ".75rem",
                lineHeight: "1.25rem",
                color: "#6b7280",
                marginBottom: "1rem",
              }}
            >
              {data?.dmarc
                ? "Excellent! Your DMARC record is set up correctly."
                : "Your DMARC record is not set up correctly."}
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          borderRadius: ".25rem .25rem 0 0",
          border: ".0625rem solid #e5e7eb",
          background: "#fff",
          padding: ".75rem 1.5rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <div style={{ maxWidth: "46.875rem", boxSizing: "border-box" }}>
          <div style={{ display: "flex", alignItems: "center", gap: ".75rem" }}>
            <img
              src={
                data?.emailAge
                  ? "https://my.saleshandy.com/static/media/checks-green.622fccfa.svg"
                  : "https://my.saleshandy.com/static/media/alert-triangle.fe7de9fb.svg"
              }
              alt=""
              style={{ width: "1.125rem", height: "1.125rem" }}
            />
            <p
              style={{
                fontWeight: "500",
                fontSize: ".875rem",
                lineHeight: "1.25rem",
                color: "#1f2937",
              }}
            >
              Email Age
            </p>
            {/* <img
              src="https://my.saleshandy.com/static/media/youtube.92f3ecc7.svg"
              alt=""
              style={{
                width: "1.125rem",
                height: "1.125rem",
                marginLeft: ".25rem",
              }}
            /> */}
          </div>
          <div style={{ boxSizing: "border-box" }}>
            <p
              style={{
                marginLeft: "1.875rem",
                marginTop: ".5rem",
                fontWeight: "500",
                fontSize: ".75rem",
                lineHeight: "1.25rem",
                color: "#6b7280",
                marginBottom: "1rem",
              }}
            >
              {data?.emailAge === undefined
                ? "not found"
                : data?.emailAge > 6
                ? `Great! The email account is ${data?.emailAge} months old.`
                : `The email account is ${data?.emailAge} months old.`}
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          borderRadius: ".25rem .25rem 0 0",
          border: ".0625rem solid #e5e7eb",
          background: "#fff",
          padding: ".75rem 1.5rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <div style={{ maxWidth: "46.875rem", boxSizing: "border-box" }}>
          <div style={{ display: "flex", alignItems: "center", gap: ".75rem" }}>
            <img
              src={
                data?.intervalDifference
                  ? "https://my.saleshandy.com/static/media/checks-green.622fccfa.svg"
                  : "https://my.saleshandy.com/static/media/alert-triangle.fe7de9fb.svg"
              }
              alt=""
              style={{ width: "1.125rem", height: "1.125rem" }}
            />
            <p
              style={{
                fontWeight: "500",
                fontSize: ".875rem",
                lineHeight: "1.25rem",
                color: "#1f2937",
              }}
            >
              The interval between two emails
            </p>
            {/* <img
              src="https://my.saleshandy.com/static/media/youtube.92f3ecc7.svg"
              alt=""
              style={{
                width: "1.125rem",
                height: "1.125rem",
                marginLeft: ".25rem",
              }}
            /> */}
          </div>
          <div style={{ boxSizing: "border-box" }}>
            <p
              style={{
                marginLeft: "1.875rem",
                marginTop: ".5rem",
                fontWeight: "500",
                fontSize: ".75rem",
                lineHeight: "1.25rem",
                color: "#6b7280",
                marginBottom: "1rem",
              }}
            >
              {data?.intervalDifference === undefined
                ? "not found"
                : data?.intervalDifference > 60
                ? "Excellent! The email-sending speed is reasonable, which can help prevent spam issues."
                : "The email-sending speed is not reasonable, which can help prevent spam issues."}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmailSetupDetails;
