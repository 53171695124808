import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import useBranchStore from "../../store/BranchStore";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import RedAsterisk from "../../../../components/RedAsterisk";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import toast from "react-hot-toast";

const schema = yup.object().shape({
  shift_name: yup.string().required("Shift Name is required"),
  valid_from: yup.date().required("Valid From date is required"),
  valid_to: yup.date().required("Valid To date is required"),
  working_day: yup.array().min(1, "At least one working day is required"),
  working_hours: yup.string().required("Working Hours is required"),
  early_start_time: yup
    .string()
    .matches(
      /^([01]\d|2[0-3]):([0-5]\d)$/,
      "Invalid time format. Please use HH:MM."
    )
    .required("This field is required"),
  late_start_time: yup
    .string()
    .matches(
      /^([01]\d|2[0-3]):([0-5]\d)$/,
      "Invalid time format. Please use HH:MM."
    )
    .required("This field is required"),
  early_end_time: yup
    .string()
    .matches(
      /^([01]\d|2[0-3]):([0-5]\d)$/,
      "Invalid time format. Please use HH:MM."
    )
    .required("This field is required"),
  late_end_time: yup
    .string()
    .matches(
      /^([01]\d|2[0-3]):([0-5]\d)$/,
      "Invalid time format. Please use HH:MM."
    )
    .required("This field is required"),
  branch_id: yup.string().when("isEditMode", {
    is: false,
    then: yup.string().required("Please Select Branch"),
  }),
});
const workingDay = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const AddShift = ({
  setIsEditMode,
  setIsDialogOpen,
  isEditMode,
  handleDialogClose,
  isDialogOpen,
  selectedBranch,
}) => {
  const { branch } = useBranchStore();
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const submitData = {
      ...data,
      valid_from: data.valid_from.toISOString(),
      valid_to: data.valid_to.toISOString(),
    };
    if (isEditMode) {
      axios
        .put(
          `${process.env.REACT_APP_PEOPLES_NOW_BASEURL}/shift/update-shift/${selectedBranch.branch_id}/${selectedBranch.doc_id}`,
          submitData
        )
        .then(() => {
          queryClient.invalidateQueries({ queryKey: ["shift"] });
          reset();
          toast.success("Shift Updated Successfully!");
          setIsDialogOpen(false);
          setIsEditMode(false);
        })
        .catch((error) => {
          const errorMessage =
            error.response?.data?.error?.message || "Something went wrong";
          toast.error(errorMessage);
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_PEOPLES_NOW_BASEURL}/shift/add-shift`,
          submitData
        )
        .then(() => {
          queryClient.invalidateQueries({ queryKey: ["shift"] });
          reset();
          toast.success("Shift Created Successfully!");
          setIsDialogOpen(false);
        })
        .catch((error) => {
          console.error("Error adding branch:", error);
          const errorMessage =
            error.response?.data?.error?.message || "Something went wrong";
          toast.error(errorMessage);
        });
    }
  };

  useEffect(() => {
    if (isEditMode && selectedBranch) {
      setValue("shift_name", selectedBranch.shift_name);
      setValue("valid_from", dayjs(selectedBranch.valid_from));
      setValue("valid_to", dayjs(selectedBranch.valid_to));
      setValue("early_start_time", selectedBranch.early_start_time);
      setValue("late_start_time", selectedBranch.late_start_time);
      setValue("early_end_time", selectedBranch.early_end_time);
      setValue("late_end_time", selectedBranch.late_end_time);
      setValue("working_hours", selectedBranch.working_hours);
      setValue("branch_id", selectedBranch.branch_id);
      setValue("working_day", selectedBranch.working_day || []);
    } else {
      reset();
    }
  }, [isEditMode, selectedBranch, setValue, reset]);

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="md"
    >
      <Box
        style={{
          background: "rgba(0, 10, 255, 1)",
          padding: "10px 10px 10px 25px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            color: "white",
          }}
        >
          Shift
        </Typography>
        <CloseIcon
          onClick={handleDialogClose}
          style={{
            cursor: "pointer",
            color: "white",
            fontSize: "20px",
          }}
        />
      </Box>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <Typography
                style={{
                  padding: "5px 0px 5px",
                }}
              >
                Shift Name
                <RedAsterisk />
              </Typography>
              <TextField
                {...register("shift_name")}
                variant="outlined"
                fullWidth
                size="small"
                error={Boolean(errors.shift_name)}
                helperText={errors.shift_name?.message}
                placeholder="Shift Name"
              />

              <Typography
                style={{
                  padding: "20px 0px 5px",
                }}
              >
                Early Start Time
                <RedAsterisk />
              </Typography>

              <TextField
                fullWidth
                placeholder="HH:MM"
                size="small"
                {...register("early_start_time")}
                error={!!errors.early_start_time}
                helperText={
                  errors.early_start_time ? errors.early_start_time.message : ""
                }
                inputProps={{
                  maxLength: 5,
                }}
              />
              <Typography
                style={{
                  padding: "20px 0px 5px",
                }}
              >
                Late End Time
                <RedAsterisk />
              </Typography>

              <TextField
                fullWidth
                placeholder="HH:MM"
                size="small"
                {...register("late_end_time")}
                error={!!errors.late_end_time}
                helperText={
                  errors.late_end_time ? errors.late_end_time.message : ""
                }
                inputProps={{
                  maxLength: 5,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography
                style={{
                  padding: "5px 0px 5px",
                }}
              >
                Valid From
                <RedAsterisk />
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{
                    ".css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                      padding: "9px 5px 9px 14px",
                    },
                  }}
                  size="small"
                  value={watch("valid_from")}
                  onChange={(date) => setValue("valid_from", date)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        ".css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                          { padding: "9px 5px 9px 14px" },
                      }}
                      size="small"
                      error={Boolean(errors.valid_from)}
                      helperText={errors.valid_from?.message}
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>

              <Typography
                style={{
                  padding: "20px 0px 5px",
                }}
              >
                Late Start Time
                <RedAsterisk />
              </Typography>

              <TextField
                fullWidth
                placeholder="HH:MM"
                size="small"
                {...register("late_start_time")}
                error={!!errors.late_start_time}
                helperText={
                  errors.late_start_time ? errors.late_start_time.message : ""
                }
                inputProps={{
                  maxLength: 5,
                }}
              />

              <Typography
                style={{
                  padding: "20px 0px 5px",
                }}
              >
                Branch
                <RedAsterisk />
              </Typography>

              <Select
                size="small"
                fullWidth
                {...register("branch_id")}
                error={!!errors.branch_id}
                value={watch("branch_id") || ""}
                onChange={(e) => setValue("branch_id", e.target.value)}
                MenuProps={{
                  PaperProps: {
                    sx: { maxHeight: "30%", width: "200px" },
                  },
                }}
              >
                {branch.map((item) => (
                  <MenuItem key={item.branch_id} value={item.branch_id}>
                    {item.branch_name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography
                style={{
                  padding: "5px 0px 5px",
                }}
              >
                Valid To
                <RedAsterisk />
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{
                    ".css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                      padding: "9px 5px 9px 14px",
                    },
                  }}
                  size="small"
                  value={watch("valid_to")}
                  onChange={(date) => setValue("valid_to", date)}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        ".css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                          { padding: "9px 5px 9px 14px" },
                      }}
                      {...params}
                      size="small"
                      error={Boolean(errors.valid_to)}
                      helperText={errors.valid_to?.message}
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
              <Typography
                style={{
                  padding: "20px 0px 5px",
                }}
              >
                Early End Time
                <RedAsterisk />
              </Typography>

              <TextField
                fullWidth
                size="small"
                placeholder="HH:MM"
                {...register("early_end_time")}
                error={!!errors.early_end_time}
                helperText={
                  errors.early_end_time ? errors.early_end_time.message : ""
                }
                inputProps={{
                  maxLength: 5,
                }}
              />
              <Typography
                style={{
                  padding: "20px 0px 5px",
                }}
              >
                Working Hours
                <RedAsterisk />
              </Typography>
              <TextField
                {...register("working_hours")}
                variant="outlined"
                fullWidth
                size="small"
                error={Boolean(errors.working_hours)}
                helperText={errors.working_hours?.message}
              />
            </Grid>
          </Grid>
          <Typography
            style={{
              padding: "20px 0px 5px",
            }}
          >
            Working Day
            <RedAsterisk />
          </Typography>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              width: "40%",
              border: "1px solid #ddd",
              alignItems: "center",
              padding: "0px 1px",
              flexWrap: "wrap",
              borderRadius: "8px",
              fontStyle: "Roboto",
            }}
          >
            {/* {workingDay.map((day) => (
              <Box key={day} sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox {...register("working_day")} value={day} />
                <Typography>{day}</Typography>
              </Box>
            ))} */}
            {workingDay.map((day) => (
              <Box
                key={day}
                sx={{ display: "flex", alignItems: "center", width: "80px" }}
              >
                <Checkbox
                  {...register("working_day")}
                  value={day}
                  defaultChecked={(getValues("working_day") || []).includes(
                    day
                  )}
                />
                <Typography>{day}</Typography>
              </Box>
            ))}
            {errors.working_day && (
              <Typography color="error">
                {errors.working_day.message}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              marginTop: "10px",
            }}
          >
            <Button
              style={{
                background: "rgba(0, 0, 0, 0)",
                borderRadius: "5px",
                color: "Black",
                boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25)",
                marginRight: "20px",
              }}
              onClick={handleDialogClose}
            >
              Cancel
            </Button>
            <Button
              style={{
                background: "rgba(0, 10, 255, 1)",
                borderRadius: "5px",
                color: "white",
              }}
              type="submit"
            >
              {isEditMode ? "Update" : "Add"}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddShift;
