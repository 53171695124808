import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import useBranchStore from "./BranchStore";

const fetchBranch = async (setBranch) => {
  const response = await axios.get(
    `${process.env.REACT_APP_PEOPLES_NOW_BASEURL}/branch/get-branch`
  );
  setBranch(response.data);
  return response.data;
};

const useBranch = () => {
  const { setBranch } = useBranchStore();

  return useQuery({
    queryKey: ["branch"],
    queryFn: () => fetchBranch(setBranch),
  });
};

export default useBranch;
