import React from "react";
import Style from "../../../Style/Common.module.css";
import Styles from "../../../Style/ChatNow/Segments/Segments.module.css";

import { WithHoc } from "../../Components/WithHoc";
import CustomFilterCell from "../../Components/CustomFilterCell";
import FilterBadge from "./FilterBadge";
import FilterTitle from "../../Components/FilterTitle";
const FTFilter = () => {
  const filterArray = [
    {
      id: "#Analytics",

      label: "Analytics",
    },
    {
      id: "#API Documentation",
      bgColor: "#efa74b",
      label: "API Documentation",
    },

    {
      id: "#Blog Message",

      label: "Blog Message",
    },
    {
      id: "#Campaign Message",

      label: "Campaign Message",
    },
    {
      id: "#Developer Message",

      label: "Developer Message",
    },
    {
      id: "#Developer Message",

      label: "Developer Message",
    },

    {
      id: "#bug",
      bgColor: "#efa74b",
      label: "bug",
    },
  ];
  const FilterCell = WithHoc(CustomFilterCell, FilterBadge);
  return (
    <div className={`${Style.commonBorderLeft} ${Style.commomPaddingLeft}`}>
      <FilterTitle text={"FILTER IN TRIGGERS"} />
      <div className={`${Styles.filterFieldPadding} ${Style.commonPaddingBottom}`}>
        {filterArray.map((data, index) => (
          <FilterCell data={data}  />
        ))}
      </div>
    </div>
  );
};

export default FTFilter;
