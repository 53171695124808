import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import TagIcon from "@mui/icons-material/Tag";
import { IconButton, Menu, MenuItem } from "@mui/material";
const Options = ({
  handleCloseChannel,
  handleOpenChannel,
  handleOpenCreateGroup,
  handleCloseCreateGroup,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    console.log("event.currentTarget", event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const options = [
    {
      id: "#groupchat",
      Icon: <Diversity2Icon sx={{ paddingRight: "5px" }} />,
      title: "Start a Group Chat",
    },
    {
      id: "#channelChat",
      Icon: <TagIcon sx={{ paddingRight: "5px" }} />,
      title: "Create a channel",
    },
  ];
  function handleOptionClick(id) {
    if (id === "#groupchat") {
      handleCloseChannel();
      handleOpenCreateGroup();
      handleClose();
    } else {
      handleCloseCreateGroup();
      handleOpenChannel();
      handleClose();
    }
  }
  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <AddIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options.map((data) => (
          <MenuItem onClick={() => handleOptionClick(data.id)}>
            {" "}
            {data.Icon}
            {data.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default Options;
