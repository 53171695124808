import React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { buttonStyle } from "../Style/ButtonStyle";
const Footer = ({ handleClose, handleSubmit, loading,background,SuccessTitle }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        height: "60px",
        background: background,
        alignItems: "center",
      }}
    >
      <Stack direction={"row"} spacing={2} sx={{ paddingRight: "20px" }}>
        <LoadingButton
          loading={loading}
          onClick={handleSubmit}
          type="submit"
          variant="contained"
          style={{ ...buttonStyle, color: "white", background: "#000AFF" }}
        >
       {SuccessTitle}
        </LoadingButton>
        <Button
          variant="outlined"
          style={{ ...buttonStyle, color: "black", background: "white" }}
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Stack>
    </div>
  );
};

export default Footer;
