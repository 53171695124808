import React from "react";
import { WithHoc } from "../Components/WithHoc";
import ShortCutGrid from "./Components/ShortCutGrid";
import CustomActivityLayOut from "../Components/CustomActivityLayOut";
import Style from "../../Style/Common.module.css";

const ShortCutLayout = () => {
  const ShortCutlayout = WithHoc(CustomActivityLayOut, ShortCutGrid);
  return (
    <div className={Style.commonPadding}>
      <ShortCutlayout title={"Shortcut Usage Statistics"} />
    </div>
  );
};

export default ShortCutLayout;
