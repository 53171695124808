import {create} from 'zustand';
import { persist } from 'zustand/middleware';

const SetTitleValue= create((
    (set) => ({
      title: "",
      setTitle: (newValue) => set({ title: newValue }),
    })
  ));

  const SetSubjectValue= create((
    (set) => ({
      subject: "",
      setSubject: (newValue) => set({ subject: newValue }),
    })
  ));

  const SetBodyValue= create((
    (set) => ({
      body: "",
      SetBody: (newValue) => set({ body: newValue }),
    })
  ));

  const openTemplate= create((
    (set) => ({
      open: false,
      setOpen: (newValue) => set({ open: newValue }),
    })
  ));

  export {
    SetTitleValue,
    SetSubjectValue,
    SetBodyValue,
    openTemplate
  }