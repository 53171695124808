import React, { useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import WebsiteSettings from './WebSiteSettings/WebsiteSettings';
import MessageShortcuts from './MessageShortcuts/MessageShortcuts';
import Availability from './Availability/Availability';
import Notifications from './Notifications/Notifications';

const theme = createTheme();

const CustomTabs = styled(Tabs)(({ theme }) => ({
  overflowX: 'auto',
  fontFamily: 'Roboto',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
  },
}));

const CustomTab = styled(Tab)(({ theme }) => ({
  minHeight: 20,
  padding: '16px',
  '&.Mui-selected': {
    minHeight: 25,
    color: theme.palette.primary.main,
  },
}));

const CustomCloseIcon = styled(CloseIcon)({
  width: '16px',
  height: '16px',
  marginLeft: '16px',
  marginRight: '10px',
  cursor: 'pointer',
  position: 'absolute',
  right: '5px',
  alignSelf: 'center',
});

const CustomTabPanel = styled('div')({
  height: '90%',
  overflowY: 'hidden',
});

function LiveChat({ handleClose }) {
  const [value, setValue] = useState(0);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ height: '100%' }}>
        <div
          style={{
            height: '10%',
            borderBottom: '1px solid rgba(0,0,0,0.1)',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <CustomTabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="scrollable"
            sx={{
              '.MuiTab-root .MuiButtonBase-root': {},
              '.MuiTabs-indicator': {
                height: '5px',
                backgroundColor: 'rgba(0, 10, 255, 1)',
                borderRadius: '10px',
                
              },
            }}
          >
            <CustomTab label="Website Settings" />
            <CustomTab label="Message Shortcuts" />
            <CustomTab label="Availability" />
            <CustomTab label="Notifications" />
          </CustomTabs>
          <CustomCloseIcon onClick={handleClose} />
        </div>
        <CustomTabPanel>
          {value === 0 && <WebsiteSettings />}
          {value === 1 && <MessageShortcuts />}
          {value === 2 && <Availability />}
          {value === 3 && <Notifications />}
        </CustomTabPanel>
      </div>
    </ThemeProvider>
  );
}

LiveChat.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default LiveChat;
