import React from "react";
import Style from "../../../Style/ChatNow/Segments/Segments.module.css";
const SegementFilterBadge = (props) => {
  return (
    <div
      style={{ background: `${props.data.bgColor}` }}
      className={Style.badgeWrapper}
    >
      <p style={{ margin: 0 }}>{props.data.label}</p>
    </div>
  );
};

export default SegementFilterBadge;
