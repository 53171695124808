import {
  Box,
  Button,
  InputAdornment,
  MenuItem,
  MenuList,
  Popover,
  TextField,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import Group from "../../../../component/images/Group.png";
import {
  newCampaignSubject,
  snackbarStore,
  useCsvStore,
  useEmailStore
} from "../../../../store/userStore";
import { SetBodyValue } from "../../../../store/templateStore";
import { sentTestMail } from "../../../../webservices/sequence";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import ExpandLess from "@mui/icons-material/ExpandLess";

const EmailPreview = () => {
  const { emailSubject } = newCampaignSubject();
  const { body } = SetBodyValue();
  const isBodyEmpty = !body || !body.trim() || body === "<p><br></p>";
  const { setSnackbarValue } = snackbarStore();
  const [loading, setLoading] = useState(false);
  const { selectedEmails } = useEmailStore();
  const { csvData } = useCsvStore();
  const [anchorEl, setAnchorEl] = useState(null);
  const [fromAddress, setFromAddress] = useState(selectedEmails[0]);
  let firstEmailId = "";

  if (csvData.length > 0) {
    console.log("csvData", csvData);
    firstEmailId = csvData[0].Email || csvData[0].EMAIL;
  } else {
    console.log("No data available");
  }

  const handleSendTestEmail = async () => {
    setLoading(true);
    try {
      await sentTestMail(emailSubject.subject, body, fromAddress, firstEmailId);
      setSnackbarValue({
        open: true,
        severity: "success",
        message: "Test Mail sent"
      });
    } catch (err) {
      setSnackbarValue({
        open: true,
        severity: "error",
        message: err.message
      });
    } finally {
      setLoading(false);
    }
  };

  const handleMenuClose = e => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  const handleMenuOpen = e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  return (
    <Box
      sx={{
        overflow: "hidden",
        height: "100%"
      }}
    >
      <Box
        sx={{
          padding: "20px",
          height: "calc(100% - 100px)",
          overflowY: "auto"
        }}
      >
        <TextField
          value={fromAddress}
          variant="outlined"
          size="small"
          fullWidth
          sx={{ marginBottom: "15px" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span style={{ color: "#CCCCCC", fontSize: "14px" }}>
                  From
                </span>{" "}
                <span
                  style={{
                    padding: "0 5px 4px 5px",
                    fontSize: "25px",
                    color: "#E6E6E6"
                  }}
                >
                  |
                </span>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                <ExpandMoreIcon
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                  onClick={handleMenuOpen}
                />
              </InputAdornment>
            )
          }}
        />
        <TextField
          value={firstEmailId}
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span style={{ color: "#CCCCCC", fontSize: "14px" }}>
                  To
                </span>{" "}
                <span
                  style={{
                    padding: "0 5px 4px 23px",
                    fontSize: "25px",
                    color: "#E6E6E6"
                  }}
                >
                  |
                </span>
              </InputAdornment>
            )
          }}
        />
        <Box sx={{ paddingTop: "20px" }}>
          <Box sx={{ display: "flex", paddingBottom: "15px", gap: "5px" }}>
            <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
              Subject :
            </Typography>
            <Typography sx={{ fontSize: "14px" }}>
              {emailSubject.subject}
            </Typography>
          </Box>
        </Box>
        {!isBodyEmpty
          ? <Box
              sx={{
                height: "400px",
                fontSize: "14px",
                lineHeight: "1.6",
                color: "#333"
              }}
              dangerouslySetInnerHTML={{ __html: body }}
            />
          : <Box
              sx={{
                height: "400px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: "10px",
                justifyContent: "center"
              }}
            >
              <img
                src={Group}
                alt="Group"
                style={{ height: "130px", width: "130px" }}
              />
              <Typography
                sx={{ fontSize: "14px", paddingTop: "15px", color: "#A1A3A7" }}
              >
                Write someting to generate the Email preview
              </Typography>
            </Box>}
      </Box>

      <Box
        sx={{
          borderTop: "1px solid var(--light-border-color)",
          padding: "10px 35px 10px 20px",
          justifyContent: "flex-end",
          display: "flex"
        }}
      >
        <Button
          variant="outlined"
          sx={{
            textTransform: "none",
            color: "#000AFF",
            borderColor: "#000AFF",
            "&:hover": { color: "#000AFF" }
          }}
          onClick={handleSendTestEmail}
          disabled={loading}
        >
          {loading ? "Sending..." : "Send Test Email"}
        </Button>
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        sx={{ marginTop: "8px" }}
      >
        <Box sx={{ width: "320px" }}>
          <MenuList autoFocusItem>
            {selectedEmails.map((item, index) =>
              <MenuItem
                key={index}
                sx={{
                  padding: "7px 15px",
                  width: "100%",
                  alignItems: "center"
                }}
                onClick={() => setFromAddress(item)}
              >
                <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                  {item}
                </Typography>
              </MenuItem>
            )}
          </MenuList>
        </Box>
      </Popover>
    </Box>
  );
};

export default EmailPreview;
