import * as React from "react";
import Grid from "@mui/material/Grid";
import Wrapper from "../../Components/Wrapper";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import UserWL from "./components/UserWL";
import RevenueContainer from "./components/Revenue";
import StageChart from "./components/StageChart";

export default function PipelineDashBoard() {
  const initialChartItems = [
    [
      {
        id: "openPipeline",
        component: (
          <Wrapper
            title={"Open Pipeline - This Month"}
            count={1}
            percentage={"100%"}
            rate={"0"}
          />
        ),
      },
      {
        id: "revenueWon",
        component: (
          <Wrapper
            title={"Revenue Won - This Month"}
            count={1}
            percentage={"100%"}
            rate={"0"}
            noData={true}
          />
        ),
      },
      {
        id: "pipelineWon",
        component: (
          <Wrapper
            title={"Pipeline Won - This Month"}
            count={1}
            percentage={"100%"}
            rate={"0"}
            noData={true}
          />
        ),
      },
      {
        id: "pipelineLost",
        component: (
          <Wrapper
            title={"Pipeline Lost - This Month"}
            count={1}
            rate={"0"}
            percentage={"100%"}
            noData={true}
          />
        ),
      },
    ],
    [
      {
        id: "top5UsersWon",
        component: (
          <UserWL
            title={"Top 5 Users - Pipelines Won"}
            count={1}
            percentage={"100%"}
            rate={"0"}
            noData={true}
          />
        ),
      },
      {
        id: "amountbyStage",
        component: (
          <StageChart title={"Open Pipelines Amount by Stage"} />
        ),
      },
    ],
    [
      {
        id: "top5UsersLost",
        component: (
          <UserWL
            title={"Top 5 Users - Pipelines Lost"}
            count={1}
            rate={"0"}
            percentage={"100%"}
            noData={true}
          />
        ),
      },
      {
        id: "monthlyRevenue",
        component: <RevenueContainer title={"Monthly Revenue by Users"} />,
      },
    ],
  ];

  const [chartItems, setChartItems] = React.useState(initialChartItems);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const sourceIndex = source.droppableId.split("-").slice(1).map(Number);
    const destinationIndex = destination.droppableId
      .split("-")
      .slice(1)
      .map(Number);
    const tempArray = [...chartItems];
    const [sourceColumn, sourceItemIndex] = sourceIndex;
    const [destinationColumn, destinationItemIndex] = destinationIndex;

    if (
      sourceColumn === destinationColumn &&
      sourceItemIndex === destinationItemIndex
    ) {
      return;
    }

    const sourceItem = chartItems[sourceColumn][sourceItemIndex];
    const destinationItem = chartItems[destinationColumn][destinationItemIndex];

    tempArray[sourceColumn][sourceItemIndex] = destinationItem;
    tempArray[destinationColumn][destinationItemIndex] = sourceItem;

    setChartItems(tempArray);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Grid container spacing={1}>
        {/* First row */}
        <Grid container item spacing={1} xs={12}>
          {chartItems[0].map(({ id, component }, index) => (
            <Grid item xs={3} key={id}>
              <Droppable droppableId={`column-0-${index}`} direction="vertical">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <Draggable draggableId={id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {component}
                        </div>
                      )}
                    </Draggable>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Grid>
          ))}
        </Grid>
        {/* Second row */}
        <Grid container item spacing={1} xs={12}>
          {chartItems[1].map(({ id, component }, index) => (
            <Grid item xs={index === 0 ? 3 : 9} key={id}>
              <Droppable droppableId={`column-2-${index}`} direction="vertical">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <Draggable draggableId={id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {component}
                        </div>
                      )}
                    </Draggable>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Grid>
          ))}
        </Grid>
        {/* Third row */}
        <Grid container item spacing={1} xs={12}>
          {chartItems[2].map(({ id, component }, index) => (
            <Grid item xs={index === 0 ? 3 : 9} key={id}>
              <Droppable droppableId={`column-2-${index}`} direction="vertical">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <Draggable draggableId={id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {component}
                        </div>
                      )}
                    </Draggable>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </DragDropContext>
  );
}
