import {
  Button,
  Modal,
  Typography,
  Grid,
  IconButton,
  Divider,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import {
  imapDataGlobal,
  smtpDataGlobal,
  snackbarStore,
} from "../../../../../../../MarketingStudio/store/userStore";
import { addEmailAccountGlobal } from "../../../../../../../MarketingStudio/webservices/sequence";
import { useLocation } from "react-router-dom";

import SmtpConnect from "./SmtpConnect";
import ImapConnect from "./ImapConnect";

const Connections = ({ setOpenSmtp, data, openSmtp }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { imapValues, setImapValues } = imapDataGlobal();
  const { smtpValues, setSmtpValues } = smtpDataGlobal();
  const { setSnackbarValue } = snackbarStore();
  const location = useLocation();
  const sequence_id = location.pathname.split("/")[4];
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: addEmailAccountGlobal,
    onSuccess: (data) => {
      console.log("account added successfully:", data);
      setSmtpValues("");
      setImapValues("");
      setIsLoading(false);
      setSnackbarValue({
        open: true,
        severity: "success",
        message: "Saved Successfully",
      });
      setOpenSmtp(false);
      queryClient.invalidateQueries({ queryKey: ["globalEmailAccounts"] });
    },
    onError: (error) => {
      console.error("Error adding Task:", error.response.data);
      setIsLoading(false);
      setSnackbarValue({
        open: true,
        severity: "error",
        message: error?.response?.data?.message
          ? error?.response?.data?.message
          : "internal server error",
      });
    },
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    const formattedData = {
      email_provider: smtpValues.email_provider,
      email_address: smtpValues.email_address,
      smtp_host: smtpValues.smtp_host,
      smtp_port: smtpValues.smtp_port,
      smtp_encryption: smtpValues.smtp_encryption,
      imap_host: imapValues.imap_host,
      imap_port: imapValues.imap_port,
      imap_encryption: imapValues.imap_encryption,
      user_name: smtpValues.user_name,
      smtp_password: smtpValues.smtp_password,
      imap_password: imapValues.password,
      sender_name: smtpValues.sender_name,
      first_name: smtpValues.first_name,
      last_name: smtpValues.last_name,
    };

    mutation.mutate(formattedData);
  };

  return (
    <Box>
      <Box
        sx={{
          overflow: "hidden",
          height: "100%",
        }}
      >
        <SmtpConnect prevdata={data} openSmtp={openSmtp} />
        <ImapConnect data={data} openSmtp={openSmtp} />
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "15px",
          justifyContent: "end",
          padding: "10px 24px",
          borderTop: "1px solid var(--light-border-color)",
        }}
      >
        <Button
          style={{
            backgroundColor: "transparent",
            border: "1px solid #1d4ed8",
            borderColor: "#1d4ed8",
            color: "#1d4ed8",
            fontSize: ".875rem",
            lineHeight: "1.45",
            textTransform: "capitalize",
          }}
          onClick={() => setOpenSmtp(false)}
          autoFocus
        >
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          disabled={imapValues && smtpValues ? false : true}
          onClick={onSubmit}
          style={{
            backgroundColor: "#1d4ed8",
            border: "1px solid #1d4ed8",
            borderColor: "#1d4ed8",
            color: "#fff",
            fontSize: ".875rem",
            lineHeight: "1.45",
            textTransform: "capitalize",
            marginLeft: "1rem",
          }}
          autoFocus
        >
          Connect & Save
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default Connections;
