import React from "react";
import COGraph from "./COGraph";
import COLeaderBoard from "./COLeaderBoard";
import Grid from "@mui/material/Grid";
import Style from "../../../Style/Common.module.css";
const COGrid = () => {
  return (
    <Grid container spacing={2} sx={{ padding: "2%" }}>
      <Grid item xs={12} md={4} sm={12}>
        <COLeaderBoard />
      </Grid>
      <Grid item xs={12} md={8} sm={12} className={Style.containerMinHeight}>
        <COGraph />
      </Grid>
    </Grid>
  );
};

export default COGrid;
