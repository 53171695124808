import React from "react";
import Rating from "@mui/material/Rating";

const StarRating = ({ rating = 3 }) => {
  return (
    <Rating
      name="half-rating-read"
      defaultValue={rating}
      precision={0.5}
      readOnly
    />
  );
};

export default StarRating;
