import PinImg from "../Assets/pin.png";
import ClockImg from "../Assets/clock.png";
import MessageImg from "../Assets/chatmsg.png";
import PersonImg from "../Assets/person.png";
import HashImg from "../Assets/#.png";
import DownArrow from "../Assets/DownArrow.png";

export const options = [
  {
    imgUrl: PinImg,
    alt: "pin image",
    id: "#pin",
  },
  {
    imgUrl: ClockImg,
    alt: "clock image",
    id: "#clock",
  },
  {
    imgUrl: MessageImg,
    alt: "message image",
    id: "#message",
  },
  {
    imgUrl: PersonImg,
    alt: "person image",
    id: "#person",
  },
  {
    imgUrl: HashImg,
    alt: "hash image",
    id: "#hash",
  },
  {
    imgUrl: DownArrow,
    alt: "downarrow",
    id: "#down",
  },
];
export const getBaseUrl = () => {
  const ports = [8000, 8080];
  const randomPort = ports[Math.floor(Math.random() * ports.length)];
  return `http://192.168.1.117:${randomPort}`;
};
