import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Button from "../../../../components/Button";

const ChatBotSetupDialog = ({ dialogOpen, handleSetupClose, website }) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const websiteId = website._id;
  const websiteName = website.name;
  const setupCode = `<!-- Include this script in your HTML above the </body> tag! -->
<script>
    window.websiteId = "${websiteId}";
    window.nde_chatapp_primarycolor = "red";
    window.nde_chatapp_textcolor = "black";
    window.nde_chatapp_title = "${websiteName}";
</script>
<script type="module" src="https://client.nowdigitaleasy.com/chat.js"></script>`;

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(setupCode).then(() => {
      setCopySuccess(true);

      setTimeout(() => setCopySuccess(false), 2000); // Reset the success state after 2 seconds
    });
  };

  return (
    <Dialog open={dialogOpen} onClose={handleSetupClose} maxWidth="lg">
      <DialogTitle>
        ChatBot Setup Instructions
        <Tooltip
          title={copySuccess ? "Copied!" : "Copy to Clipboard"}
          placement="top"
        >
          <IconButton
            onClick={handleCopyToClipboard}
            sx={{ ml: 1, color: "black" }}
          >
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <p>Here are the instructions to set up the ChatBot:</p>

        <SyntaxHighlighter language="html" style={a11yDark}>
          {setupCode}
        </SyntaxHighlighter>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSetupClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChatBotSetupDialog;
