import React from "react";
import CustomActivityLayOut from "../Components/CustomActivityLayOut";
import { WithHoc } from "../Components/WithHoc";
import SegementGrid from "./Components/SegementGrid";
import Style from "../../Style/Common.module.css";
const SegementLayout = () => {
  const Segementlayout = WithHoc(CustomActivityLayOut, SegementGrid);
  return (
    <div className={Style.commonPadding}>
      <Segementlayout title={"Segments Saved Over Time"} />
    </div>
  );
};

export default SegementLayout;
