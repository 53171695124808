import React from "react";
import { IconButton, MenuItem, Menu } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const HoverDropDown = ({
  open,
  handleOpen,
  anchorEl,
  messageOptions,
  handleClose,
  text,
  setHoverId,
  msgData,
}) => {
  console.log("mytextmgs", text);
  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          handleOpen(e);
        }}
        id="basic-button"
        sx={{ padding: 0 }}
      >
        <ExpandMoreIcon style={{ fontSize: "16px" }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          handleClose();
          setHoverId("");
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        {messageOptions.map((data) => {
          console.log("mydownload", data.id, text);
          if (data.id === "#download" && text) {
            return null;
          }

          if (data.id === "#copy" && !text) {
            return null;
          }

          return (
            <MenuItem
              key={data.id}
              onClick={() => {
                if (data.id === "#download") {
                  data.actionFunc(msgData.originalKey, msgData.fileName);
                } else {
                  data.actionFunc(msgData);
                }
                handleClose();
              }}
              sx={{ fontSize: "12px" }}
            >
              {data.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default HoverDropDown;
