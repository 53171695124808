import React, { useState } from "react";
import { Grid, Card, CardMedia, Typography, Button } from "@mui/material";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import { Box } from "@mui/system";

const mediaItems = [
  { id: 1, url: "https://via.placeholder.com/150", type: "image" },
  { id: 2, url: "https://via.placeholder.com/150", type: "image" },
  { id: 3, url: "https://via.placeholder.com/150", type: "image" },
  { id: 4, url: "https://via.placeholder.com/150", type: "video" },
  { id: 5, url: "https://via.placeholder.com/150", type: "image" },
  { id: 6, url: "https://via.placeholder.com/150", type: "video" },
];

const Media = () => {
  const [visibleIndex, setVisibleIndex] = useState(2);

  const handleNext = () => {
    if (visibleIndex < mediaItems.length) {
      setVisibleIndex((prev) => Math.min(prev + 2, mediaItems.length));
    }
  };

  return (
    <Box p={1}>
      <Typography
        variant="h6"
        fontSize={12}
        color="#959FA3"
        paddingBottom={1}
      
      >
        Media , Docs
      </Typography>
      <Grid container spacing={2}>
        {mediaItems.slice(0, 2).map((item) => (
          <Grid item xs={6} key={item.id}>
            <Card
              sx={{
                position: "relative",
                borderRadius: 2,
                height: 0,
                paddingTop: "100%",
              }}
            >
              <CardMedia
                component={item.type === "video" ? "video" : "img"}
                src={item.url}
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  objectFit: "cover",
                }}
                controls={item.type === "video"}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
      {visibleIndex < mediaItems.length && (
        <Button
          onClick={handleNext}
          sx={{
            position: "absolute",
            top: "50%",
            right: 16,
            transform: "translateY(-50%)",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            borderRadius: "50%",
            minWidth: 40,
            height: 40,
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.9)",
            },
          }}
        >
          <ChevronRightIcon />
        </Button>
      )}
    </Box>
  );
};

export default Media;
