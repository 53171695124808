import React from "react";
import {
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Line,
  Label,
} from "recharts";
import Style from "../../../../Style/TopBar/Chart.module.css";

const data = [
  {
    name: "Page A",
    uv: 9,
    pv: 6,
    amt: 2,
  },
  {
    name: "Page B",
    uv: 3,
    pv: 9,
    amt: 2,
  },
  {
    name: "Page C",
    uv: 2,
    pv: 8,
    amt: 2,
  },
  {
    name: "Page D",
    uv: 2,
    pv: 9,
    amt: 2,
  },
];

const AreaChart = () => {
  return (
    <div className={Style.graphWrapper}>
          <ResponsiveContainer width="100%" height="70%">
        <ComposedChart
          width={350}
          height={200}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 15,
            bottom: 10,
          }}
        >
          <CartesianGrid horizontal="true" vertical="" />
          <XAxis dataKey="name" fontSize={10}>
            <Label
              value="Record Count"
              fontSize={12}
              offset={0}
              position="bottom"
            />
          </XAxis>
          <YAxis axisLine={false} fontSize={10}>
            <Label
              value="Created by"
              fontSize={12}
              offset={0}
              position="center"
              angle="-90"
            />
          </YAxis>
          <Tooltip />
          <Line type="monotone" dataKey="uv" stroke="#3651d4" />
          <Area
            type="monotone"
            dataKey="pv"
            stroke="rgb(2, 206, 201)"
            fill="rgb(117, 184, 255)"
          />
          <Area
            type="monotone"
            dataKey="uv"
            stroke="rgb(2, 206, 201)"
            fill="rgb(129, 236, 236)"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AreaChart;
