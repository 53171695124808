import React from "react";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Label,
  ResponsiveContainer,
} from "recharts";
import Style from "../../../../Style/TopBar/Chart.module.css";

const data = [
  {
    name: "Page A",
    uv: 590,
    pv: 800,
    amt: 1400,
    cnt: 490,
    fill: "rgb(255, 127, 79)",
  },
  {
    name: "Page B",
    uv: 868,
    pv: 967,
    amt: 1506,
    cnt: 590,
    fill: "rgb(255, 165, 0)",
  },
];
const ColumnChart = () => {
  return (
    <div className={Style.graphWrapper}>
      <ResponsiveContainer width="100%" height="70%">
        <ComposedChart
          layout="vertical"
          width={350}
          height={200}
          data={data}
          margin={{
            top: 20,
            right: 20,
            left: 15,
            bottom: 15,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 0" />
          <XAxis type="number" fontSize={10}>
            {" "}
            <Label
              value="Record Count"
              fontSize={10}
              offset={0}
              position="bottom"
            />
          </XAxis>
          <YAxis
            tickMargin={5}
            className="label"
            dataKey="name"
            type="category"
            scale="band"
            fontSize={10}
            label={{
              value: "Createdby",
              angle: -90,
              position: "insideLeft",
              paddingbottom: "20px",
              verticalAnchor: "end",
              fontSize: "12px",
            }}
          />

          <Bar dataKey="pv" barSize={25} fill="#8884d8" />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ColumnChart;
