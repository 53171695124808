import React, { useEffect, useRef } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill, { Quill } from "react-quill";
import axios from "axios";
import QuillResizeImage from "quill-resize-image";
import "./style.css";
import { SetBodyValue } from "../../store/templateStore";
import { createStepStatus } from "../../store/userStore";

Quill.register("modules/resize", QuillResizeImage);

const modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, 3, 4, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ font: ["serif", "monospace", "sans-serif"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ color: [] }],
      [{ background: [] }],
      ["clean"],
      [{ mergeTags: [] }]
    ],
    handlers: {
      image: function () {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = async () => {
          const file = input.files[0];
          if (file) {
            const formData = new FormData();
            formData.append("image", file);

            try {
              const response = await axios.post(
                "https://liveserver.nowdigitaleasy.com:5002/api/v1/file/add-file",
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data"
                  }
                }
              );

              const range = this.quill.getSelection();
              this.quill.insertEmbed(range.index, "image", response.data);
            } catch (error) {
              console.error("Error uploading image:", error);
            }
          }
        };
      },
      mergeTag: function (value) {
        const range = this.quill.getSelection();
        if (range) {
          this.quill.insertText(range.index, value); // Insert selected merge tag
        }
      }
    }
  },
  resize: {
    locale: {}
  }
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "bullet",
  "indent",
  "font",
  "link",
  "image",
  "imageBlot",
  "color",
  "background",
  "list",
  "align"
];

const QuillEditor = ({ data }) => {
  const { body, SetBody } = SetBodyValue();
  const { status, setStatus } = createStepStatus();
  const quillRef = useRef(null);

  useEffect(() => {
    if (data) {
      // setStatus(true);
      SetBody(data);
    }
  }, [data]);

  const handleChange = (html) => {
    console.log("mmmm", html);
    SetBody(html);
    setStatus(true);
  };

  useEffect(() => {
    // Add merge tags dropdown manually to the toolbar
    const toolbar = document.querySelector(".ql-toolbar");
    const mergeTagSelect = document.createElement("select");

    mergeTagSelect.innerHTML = `
      <option value="">Insert Merge Tag</option>
      <option value="{{FirstName}}">First Name</option>
      <option value="{{LastName}}">Last Name</option>
      <option value="{{email}}">Email</option>
      <option value="{{phone_number}}">Phone Number</option>
      <option value="{{company_name}}">Company Name</option>
      <option value="{{website}}">Website</option>
      <option value="{{linkedin_profile}}">Linkedin Profile</option>
      <option value="{{location}}">Location</option>
      <option value="{{day_of_week}}">Day of Week</option>
    `;
    mergeTagSelect.classList.add("ql-mergeTag");

    // Add event listener to handle tag selection
    mergeTagSelect.addEventListener("change", function () {
      const quill = quillRef.current.getEditor(); // Get Quill instance from ref
      const value = mergeTagSelect.value;
      if (value) {
        // Use clipboard to paste the merge tag as HTML at the current cursor position or end
        const range = quill.getSelection(true); // Get the current selection
        if (range) {
          quill.clipboard.dangerouslyPasteHTML(range.index, value);
        }

        // Manually trigger handleChange to update the body in the Zustand store
        handleChange(quill.root.innerHTML);

        // Reset the dropdown after appending the merge tag
        mergeTagSelect.selectedIndex = 0;
      }
    });

    toolbar.appendChild(mergeTagSelect); // Append it to the toolbar
  }, []);

  return (
    <ReactQuill
      ref={quillRef}
      value={body}
      onChange={handleChange}
      modules={modules}
      formats={formats}
      theme="snow"
    />
  );
};

export default QuillEditor;
