import React from 'react'
import { LoadingButton } from "@mui/lab";
const buttonStyles = {
    backgroundColor: "#000aff",
    borderRadius: "5px",
    fontSize: "14px",
    color: "white",
    fontWeight: "600",
    marginTop: "5%",
  };
  
const CustomButton = ({loading,onSubmit,title}) => {
  return (
    <LoadingButton
              type="submit"
              variant="contained"
              loading={loading}
              onClick={onSubmit}
              style={buttonStyles}
              fullWidth
              disableElevation
            >
             {title}
            </LoadingButton>
  )
}

export default CustomButton
