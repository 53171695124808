import React from "react";
import EmailSetupDetails from "../../../../../../../MarketingStudio/MailsNow/CampInfo/element/EmailSetupDetails";
import { Box } from "@mui/system";
import { CircularProgress, Typography } from "@mui/material";
import { useQuery, useQueryClient,useMutation } from '@tanstack/react-query';
import {getEmailSetup} from '../../../../../../../MarketingStudio/webservices/sequence'

const CircularProgressWithLabel = ({ value }) => {

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        value={value}
        size={60}
        thickness={3}
        style={{ color: "rgb(245, 158, 11)" }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          style={{
            color: "rgb(245, 158, 11)",
            fontSize: "1.2rem",
            fontWeight: "600",
          }}
        >
          {`${Math.round(value)}`}
        </Typography>
      </Box>
    </Box>
  );
};

const EmailSetup = ({data}) => {

  const emailScore = useQuery({
    queryKey: ['getEmailSetup'],
    queryFn: () => getEmailSetup(data._id)
  });

  console.log("xxxxxxxxxxxxxxxx",emailScore)

  return (
    <Box sx={{ border: ".0625rem solid #e5e7eb", padding: "20px 15px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "5px",
          padding: "20px 0",
        }}
      >
        <CircularProgressWithLabel value={emailScore?.data?.data?.score || 0} />
        <Box sx={{ marginTop: "10px" }}>
          <Typography
            style={{
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "1.25rem",
              color: "#1f2937",
              textTransform: "capitalize",
            }}
          >
            Email Setup Score
          </Typography>
        </Box>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "1.25rem",
            color: "#1f2937",
          }}
        >
         Last updated at {new Date().toDateString()}  at {new Date().toLocaleTimeString()}
        </Typography>
        <Box
          sx={{
            display: "flex",
            padding: "15px",
            backgroundColor: "#EFF6FF99",
          }}
        >
          <Box sx={{ width: "80%" }}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 600, marginBottom: "5px" }}
            >
              Need help to improve your score?
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                marginBottom: "5px",
                color: "#1f2937",
              }}
            >
              Improve your email setup score by scheduling a call with our email
              deliverability expert.
            </Typography>
          </Box>
          <Box sx={{ textAlign: "center", width: "20%" }}>
            <a
              style={{
                paddingRight: "5px",
                fontSize: "12px",
                textAlign: "center",
                textDecoration: "none",
              }}
              href="https://myaccount.google.com/apppasswords"
              target="_blank"
              rel="noreferrer"
            >
              Talk to Deliverability Experts
            </a>
          </Box>
        </Box>
      </Box>
      <EmailSetupDetails data={emailScore?.data?.data}/>
    </Box>
  );
};

export default EmailSetup;
