import React from "react";
import Style from "../../../../Style/TopBar/Kpi.module.css";
import TableBadge from "./TableBadge";

const DealsCreatedTable = () => {
  const tableData = [
    {
      title: "Communications",
      count: 36,
      percentage: "14.0%",
      color: "#4cc792",
    },
    {
      title: "Advertisement",
      count: 35,
      percentage: "22%",
      color: "#f88",
    },
    {
      title: "Cold Call",
      count: 13,
      percentage: "13%",
      color: "#4cc792",
    },
    {
      title: "Employee Referreal",
      count: 4,
      percentage: "19.3%",
      color: "#f88",
    },
    {
      title: "External Referral",
      count: 2,
      percentage: "20.6%",
      color: "#4cc792",
    },
  ];
  return (
    <>
      <table className={Style.tableWidth}>
        <tbody>
        {tableData.map((data) => (
          <tr key={`${data.title}/`}>
            <td>{data.title}</td>
            <td className={Style.tablePadding}>{data.count}</td>
            <td style={{ textAlign: "center" }}>
              <TableBadge percentage={data.percentage} color={data.color} />
            </td>
          </tr>
        ))}
        </tbody>
      </table>
      <div className={Style.prevY}>*compared with last years</div>
    </>
  );
};

export default DealsCreatedTable;
