import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "../Assests/Download Button.png";
import axios from "axios";

import useInvoiceDetails from "./store/invoiceStore";
const MuiButton = styled(Button)(({ theme }) => ({
  width: "61px",
  height: "25px",
  fontSize: "12px",
  color: "black",
  fontWeight: 400,
  padding: 0,
  textTransform: "none",
  border: "1px solid #000AFF",
}));
const PayNowButton = ({ status }) => {
const{setInvoiceData}=useInvoiceDetails()
  function handleClick() {
    setInvoiceData(status)
    window.open(`/myaccount/billing/payment/${status._id}`, "_blank");
      
  }
  function handlePdf() {
    axios
      .get(`${process.env.REACT_APP_WEBSERVICES_PRODUCTION_URL}/download-invoice/${status.orderId[0].pdfKey}`)
      .then((result) => {
        window.open(result.data);
      });
  }
  return (
    <div>
      {status?.status === "paid" ? (
        <IconButton onClick={handlePdf}>
          <img src={DownloadIcon} alt="Download" />
        </IconButton>
      ) : (
        <MuiButton variant="outlined" onClick={handleClick}>
          Pay Now
        </MuiButton>
      )}
    </div>
  );
};

export default PayNowButton;
