import React from "react";
import Style from "../../Style/OverView/DashBoard.module.css";
import SourceChart from "./SourceChart";
import CustomLegend from "./CustomLegend";

const GraphContainer = ({ title }) => {
  const data = [
    { name: "Google", value: 100, fill: "#FF7009" },
    { name: "Direct", value: 300, fill: "#9D0000" },
    { name: "Whatsapp", value: 100, fill: "#473A7A" },
    { name: "Instagram", value: 150, fill: "#3A6E7A" },
    { name: "www.com", value: 150, fill: "#477A3A" },
    { name: "Google", value: 200, fill: "#7A683A" },
  ];
  return (
    <div className={Style.graphwrapper}>
      <div className={Style.title}>{title}</div>
      <div className={Style.chartContainer}>
        <SourceChart data={data} />
      </div>
      <div>
        <CustomLegend data={data} />
      </div>
    </div>
  );
};

export default GraphContainer;
