import React from 'react'
import { Alert, Snackbar } from "@mui/material";

const CustomSnackBar = ({snackbaropen,setSnackbaropen,position}) => {
  return (
    <div>
        <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: position }}
        open={snackbaropen?.open}
        onClose={() => setSnackbaropen((state) => ({ ...state, open: false }))}
        autoHideDuration={9000}
      >
        <Alert severity={snackbaropen.severity}>{snackbaropen.message}</Alert>
      </Snackbar>
    </div>
  )
}

export default CustomSnackBar
