import React from "react";
import Style from "../../../Style/ChatNow/ChatNow.module.css";
const LayOutTitle = ({ title }) => {
  return (
    <div className={`${Style.LayoutTitle} ${Style.LayoutTitle_Text}`}>
      {title}
    </div>
  );
};

export default LayOutTitle;
