import React from "react";
import LayOutTitle from "./Component/LayOutTitle";

import Paper from "@mui/material/Paper";
import ActivityGrid from "./Component/ActivityGrid";

const ActivityLayout = (props) => {
  return (
    <Paper elevation={2} sx={{ borderRadius: "2px !important" }}>
      <LayOutTitle title={props.title} />
      <ActivityGrid statusData={props.statusData} />
    </Paper>
  );
};

export default ActivityLayout;
