import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Popover,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddCampaign from "./AddCampaign/AddCampaign";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation,useQueryClient } from '@tanstack/react-query';
import {addSchedule} from '../webservices/sequence'

const ScheduleHeader = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  const [tags, setTags] = useState("");
  const [team, setTeam] = useState("");
  const [client, setClient] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: addSchedule,
    onSuccess: (data) => {
      queryClient.invalidateQueries("getSchedule");
    },
    onError: (error) => {
      console.error("Error adding schedule:", error);
      alert('Error adding schedule');
    },
  });

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (e) => {
    setStatus(e.target.value);
    setTags(e.target.value);
    setClient(e.target.value);
    setTeam(e.target.value);
  };

  const createCampaign = () =>{
    mutation.mutate();
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "56.6px",
        borderBottom: "1px solid var(--light-border-color)",
      }}
    >
      <Box
        sx={{
          alignContent: "center",
          paddingLeft: "27px",
          paddingRight: "20px",
        }}
      >
        <Typography sx={{ fontSize: "14px", fontWeight: "500",color:"var(--dark-text-color)" }}>
          Schedules
        </Typography>
      </Box>

      <Box
        sx={{
          marginLeft: "auto",
          display: "flex",
          padding: "10px 20px 10px 10px ",
          alignItems: "center",
        }}
      >
        <Box sx={{ marginLeft: "30px" }}>
        <Button
        variant="contained"
        onClick={createCampaign}
        sx={{
          height: "30px",
          fontSize: "12px",
          borderRadius: "5px",
          backgroundColor: "var(--button-color)",
          "&:hover": { backgroundColor: "var(--button-color)" },
        }}
      >
        Add new schedule
      </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ScheduleHeader;
