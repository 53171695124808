import {
  Box,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import AppData from "./AppData";
import ManageUser from "./manageUser/ManageUser";

const headCell = {
  fontSize: "13px",
  fontWeight: "600",
  backgroundColor: "var(--light-bg-img-color)",
  padding: "5px 10px",
};

const Application = ({ handleClose }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [users, setUsers] = useState(false);
  const [admin, setAdmin] = useState(false);

  const handleMenuClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  const handleMenuOpen = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const action = [
    {
      label: "Edit Admin",
      onClick: () => setAdmin(true),
    },
    {
      label: "Manage Users",
      onClick: () => setUsers(true),
    },
  ];
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "13px 15px 12px",
          height: "50px",
          borderBottom: "1px solid rgba(0,0,0,0.1)",
        }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
          Applications
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon
            sx={{ width: "20px", height: "20px", cursor: "pointer" }}
          />
        </IconButton>
      </Box>
      <Box
        sx={{
          height: "calc(100vh - 100px)",
          overflowY: "auto",
        }}
      >
        <TableContainer
          sx={{
            height: "calc(100% - 85px)",
            overflowY: "auto",
          }}
        >
          <Table
            size="small"
            sx={{ minWidth: 300 }}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead
              sx={{
                borderBottom: "1px solid #E2E3E6",
                height: "46px",
                zIndex: 3,
              }}
            >
              <TableRow>
                <TableCell sx={headCell}></TableCell>
                <TableCell sx={headCell}>APP NAME</TableCell>
                <TableCell sx={headCell}>OWNER</TableCell>
                <TableCell sx={headCell}>MEMBERS</TableCell>
                <TableCell sx={headCell}>GROUPS</TableCell>
                <TableCell sx={{ ...headCell, padding: "5px 0px" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ cursor: "pointer" }}>
              {AppData.map((data, index) => (
                <TableRow
                  sx={{
                    cursor: "pointer",
                    height: "50px",
                    "&:hover": { backgroundColor: "var(--light-bg-img-color)" },
                  }}
                  key={index}
                >
                  <TableCell sx={{ padding: "5px 10px" }} align="center">
                    {data.icon}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "5px 10px",
                      fontSize: "13px",
                      fontWeight: 600,
                    }}
                  >
                    {data.name}
                  </TableCell>
                  <TableCell sx={{ padding: "5px 10px" }}>
                    <Box>
                      <Typography sx={{ fontSize: "13px", fontWeight: 600 }}>
                        {data.owner}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "13px",
                          fontWeight: 400,
                          color: "#878787",
                        }}
                      >
                        {data.mail}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ cursor: "pointer", padding: "5px 10px" }}>
                    {data.member}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "5px 10px",
                    }}
                  >
                    {data.group}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      padding: "5px 10px 5px 0px",
                    }}
                  >
                    <IconButton onClick={handleMenuOpen}>
                      <MoreVertOutlinedIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box
        sx={{
          height: "45px",
          borderTop: "1px solid rgba(0,0,0,0.1)",
        }}
      ></Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        // sx={{ zIndex: 10000000 }}
      >
        <Paper sx={{ overflow: "auto", maxHeight: "300px" }}>
          <MenuList autoFocusItem>
            {action.map((action, index) => (
              <MenuItem
                key={index}
                sx={{
                  padding: "7px 15px",
                  width: "100%",
                  alignItems: "center",
                }}
                onClick={action.onClick}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "12px" }}
                >
                  {action.icon}
                  <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                    {action.label}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </MenuList>
        </Paper>
      </Popover>
      <ManageUser users={users} setUsers={setUsers} setAnchorEl={setAnchorEl} />
    </Box>
  );
};

export default Application;
