import React from 'react'
import Style from "../../Style/OverView/DashBoard.module.css";
import CustomLegend from "./CustomLegend";
import StatusChart from "./StatusChart";
const RenderChart = (props) => {
  return (
<div className={Style.statusGraphWrapper}>
        <div>
          <StatusChart data={props.chartData} />
        </div>
        <div className={Style.statusLegend}>
          <CustomLegend data={props.chartData} />
        </div>
      </div>
  )
}

export default RenderChart