import React  from "react";
import { useArchiveList } from "../../../CustomHooks/useGetArchive";
import ChatsList from "../ChatList/Components/ChatsList";

const ChatArchive = () => {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useArchiveList(10);

  const MessageData = data?.pages?.flatMap((page) => page.data) || [];


  return (
    <>
    <ChatsList
      MessageData={MessageData}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      isLoading={isLoading}
      title={"Archived Chats"}
    />
    </>
  );
};

export default ChatArchive;
