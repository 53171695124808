import { Box } from "@mui/system";
import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useTeamChatStore } from "../../../../../../../TeamChat/store/teamChatStore";
const GeneralSettings = () => {
  const { teamChat, setTeamChat } = useTeamChatStore();
  return (
    <Box p={1}>
      <Box display={"flex"} alignItems={"center"}>
        <Box>Show Team Chat</Box>
        <Box px={2}>
          <FormControlLabel
            control={<Switch checked={teamChat} onChange={setTeamChat} />}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default GeneralSettings;
