import { create } from "zustand";
import { persist } from "zustand/middleware";
export const useAuth = create(
  persist(
    (set, get) => ({
      account: null,
      adAccountId: "none",
      adAccountStatus: true,
      auth: { success: false },
      pageId: null,

      // set
      setAccount: (account) => set({ account }),
      setAdAccountId: (adAccountId) => set({ adAccountId }),
      setadAccountStatus: (adAccountStatus) => set({ adAccountStatus }),
      setAuth: (auth) => set({ auth }),
      setPageId: (pageId) => set({ pageId }),

      //get
      getAccount: () => get().account,
      getAdAccountId: () => get().adAccountId,
      getAdAccountStatus: () => get().adAccountStatus,
      getAuth: () => get().auth,
      getPageId: () => get().pageId,
    }),
    {
      name: "fbads-accountinfo",
    }
  )
);
