import React from "react";
import { color, styled } from "@mui/system";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

const CustomFormControl = styled(FormControl)(({ isFocused }) => ({
  "& .MuiInputBase-root": {
    border: "none",
    minWidth: "120px",
    justifyContent: "center",
    // color: isFocused ? "black" : "#ddd",
    color: "black",
  },
  "& .MuiSelect-select": {
    padding: "8px !important",
    border: "none",
    fontSize: "1rem",
    fontWeight: 400,
  },
  "& .MuiInput-underline:before, & .MuiInput-underline:after": {
    border: "none !important",
  },
  "& .MuiOutlinedInput-root": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
}));

const CustomSelect = styled(Select)({
  width: "auto",
  fontSize: "1rem",
  border: "none",
  fontWeight: 400,
  "&:focus": {
    backgroundColor: "transparent",
    border: "none",
  },
  "& .MuiSelect-icon": {
    color: "inherit",
    fontSize: "1rem",
    fontWeight: 400,
  },
});

const CustomMenuItem = styled(MenuItem)({
  fontSize: "1rem",
  fontWeight: 400,
  paddingTop: 8,
  paddingBottom: 8,
});

const CustomDropDown = ({ value, handleChange, items, handleFocusOpen }) => {
  const menuProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    getContentAnchorEl: null,
  };

  return (
    <CustomFormControl onFocus={handleFocusOpen}>
      <CustomSelect
        value={value}
        onChange={handleChange}
        disableUnderline
        IconComponent={ExpandMoreRoundedIcon}
        MenuProps={menuProps}
      >
        {items.map((item) => (
          <CustomMenuItem key={item.routeId} value={item.route}>
            {item.routeLabel}
          </CustomMenuItem>
        ))}
      </CustomSelect>
    </CustomFormControl>
  );
};

export default CustomDropDown;
