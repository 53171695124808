import React from "react";
import Style from "../../../../Style/TopBar/Kpi.module.css";

const Basics = ({ deal }) => {
  return (
    <div className={Style.basicWrapper}>
      <p className={Style.dealText}>{deal}</p>
    </div>
  );
};

export default Basics;
