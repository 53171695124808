import React from "react";
import { Box } from "@mui/material";
import { options } from "../../../../Utils/OptionsUtils";
import {
  useChatUIStore,
  usePinChatStore,
} from "../../../../store/teamChatStore";

const ChatOptions = () => {
  const { pinChat, setPinChat } = usePinChatStore();
  const { uiElement, setUIElement } = useChatUIStore();
  const handleOptionClick = (id) => {

    switch (id) {
      case "#pin":
        setPinChat();
        break;
      case "#clock":
        setUIElement("#clock");
        break;
      case "#message":
        setUIElement("#message");
        break;
      case "#person":
        setUIElement("#person");
        break;
      case "#hash":
        console.log("Hash clicked");
        break;
      case "#down":
        setUIElement("#channels");
        break;
      default:
        break;
    }
  };

  console.log("pinChat", pinChat);

  return (
    <Box display="flex" justifyContent="space-around" alignItems="center">
      {options.map((data) => (
        <Box
          key={data.id}
          display="flex"
          alignItems="center"
          height={"26px"}
          width={"32px"}
          justifyContent={"center"}
          sx={{
            bgcolor:
              (data.id === "#pin" && pinChat) || uiElement === data.id
                ? "grey.300"
                : "inherit",
            "&:hover": {
              bgcolor: "grey.300",
            },
            cursor: "pointer",
          }}
          onClick={() => handleOptionClick(data.id)}
        >
          <img alt={data.alt} src={data.imgUrl} />
        </Box>
      ))}
    </Box>
  );
};

export default ChatOptions;
