import React from "react";
import upwardImg from "../Images/OverView/Vector.png";
import Style from "../Style/OverView/DashBoard.module.css";

const WrapperData = (props) => {
  return (
    <>
      <div className={Style.dataWrapper}>
        <span className={Style.count}>{props.count}</span>
        <span>
          <img alt={upwardImg} src={upwardImg} className={Style.imageStyle} />
        </span>
        <span className={Style.percentage}>{props.percentage}</span>
      </div>
      <div className={Style.rate}>
        <span>Last Month:{props.rate}</span>
        {props.children}
      </div>
    </>
  );
};

export default WrapperData;
