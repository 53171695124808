
import { Typography } from "@mui/material";
import { useState } from "react";
import CustomSwitch from "../../../../components/Switch";
function Notifications() {
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [individualSwitches, setIndividualSwitches] = useState({
    online: false,
    offline: false,
    visitor: false,
    sounds: false,
    emailUnread: false,
    emailTranscripts: false,
    emailRatings: false,
    emailPaidInvoices: false,
  });
  const handleSwitchChange = () => {
    setNotificationsEnabled((prev) => !prev);
    // If "Enable all notifications" is turned off, turn off all individual switches
    if (!notificationsEnabled) {
      setIndividualSwitches({
        online: true,
        offline: true,
        visitor: true,
        sounds: true,
        emailUnread: true,
        emailTranscripts: true,
        emailRatings: true,
        emailPaidInvoices: true,
      });
    }
    if (notificationsEnabled) {
      setIndividualSwitches({
        online: false,
        offline: false,
        visitor: false,
        sounds: false,
        emailUnread: false,
        emailTranscripts: false,
        emailRatings: false,
        emailPaidInvoices: false,
      });
    }
  };

  const handleIndividualSwitchChange = (key) => {
    setIndividualSwitches((prev) => {
      const updatedSwitches = {
        ...prev,
        [key]: !prev[key],
      };

      // If any individual switch is turned off, turn off "Enable all notifications"
      if (!updatedSwitches[key]) {
        setNotificationsEnabled(false);
      }

      // If all individual switches are turned on, turn on "Enable all notifications"
      if (
        Object.values(updatedSwitches).every((value) => value === true) &&
        !notificationsEnabled
      ) {
        setNotificationsEnabled(true);
      }
      if (Object.values(individualSwitches).every((value) => value === false)) {
        setNotificationsEnabled(false);
      }

      return updatedSwitches;
    });
  };

  return (
    <div style={{
      // border: "1px solid red",
      height: "75.5vh"
    }}>
      <div
      style={{
        padding: "1% 10% 2% 2%",
        // border: "1px solid blue",
        display: "flex",
        height: "71vh",
      }}
    >
      <div
        style={{
          // border: "1px solid red",
          height: "71vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            // border: "1px solid blue",
          }}
        >
          <Typography
            style={{
              fontSize: "calc( 0.4vw + 10px) ",
            }}
          >
            Choose how you want to manage your notification
          </Typography>
          <Typography
            style={{
              fontSize: "calc( 0.4vw + 10px) ",
              paddingTop: "1%",
            }}
          >
            It is not recommended to disable them, as you won’t get notified
            when you receive new messages.
          </Typography>
          <div
            style={{
              // border: "1px solid grey",
              height: "5vh",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontSize: "calc( 0.4vw + 10px) ",
              }}
            >
              {notificationsEnabled
                ? "Disable all notifications"
                : "Enable all notifications"}
            </Typography>
            <CustomSwitch
              checked={notificationsEnabled}
              onChange={handleSwitchChange}
              style={
                {
                  // marginLeft: "-30%"
                }
              }
            />
          </div>
        </div>
        <div
          style={{
            // border: "1px solid yellow",
            flex: "1",
          }}
        >
          <Typography
            variant="h6"
            style={{
              color: "rgba(0, 10, 255, 1)",
              fontSize: "calc(0.7vw + 9px)",
            }}
          >
            Push notifications
          </Typography>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px", // Adjust the gap size as needed
              }}
            >
              <Typography style={{ fontSize: "calc( 0.4vw + 10px) " }}>
                Notify me of message when I am online
              </Typography>
              <CustomSwitch
                checked={individualSwitches.online}
                onChange={() => handleIndividualSwitchChange("online")}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px", // Adjust the gap size as needed
              }}
            >
              <Typography style={{ fontSize: "calc( 0.4vw + 10px) " }}>
                Notify me of message when I am offline
              </Typography>
              <CustomSwitch
                checked={individualSwitches.offline}
                onChange={() => handleIndividualSwitchChange("offline")}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px", // Adjust the gap size as needed
              }}
            >
              <Typography style={{ fontSize: "calc( 0.4vw + 10px) " }}>
                Notify me when a visitor is browsing my website{" "}
              </Typography>
              <CustomSwitch
                checked={individualSwitches.visitor}
                onChange={() => handleIndividualSwitchChange("visitor")}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px", // Adjust the gap size as needed
              }}
            >
              <Typography style={{ fontSize: "calc( 0.4vw + 10px) " }}>
                Play notification sounds Email me unread message{" "}
              </Typography>
              <CustomSwitch
                checked={individualSwitches.sounds}
                onChange={() => handleIndividualSwitchChange("sounds")}
              />
            </div>
          </div>

          <Typography
            variant="h6"
            style={{
              color: "rgba(0, 10, 255, 1)",
              fontSize: "calc(0.7vw + 10px)",
            }}
          >
            Email notifications
          </Typography>
          <div style={{}}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px", // Adjust the gap size as needed
              }}
            >
              <Typography style={{ fontSize: "calc( 0.4vw + 10px) " }}>
                {" "}
                Email me unread message{" "}
              </Typography>
              <CustomSwitch
                checked={individualSwitches.emailUnread}
                onChange={() => handleIndividualSwitchChange("emailUnread")}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px", // Adjust the gap size as needed
              }}
            >
              <Typography style={{ fontSize: "calc( 0.4vw + 10px) " }}>
                Email me transcripts of notifications{" "}
              </Typography>
              <CustomSwitch
                checked={individualSwitches.emailTranscripts}
                onChange={() =>
                  handleIndividualSwitchChange("emailTranscripts")
                }
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px", // Adjust the gap size as needed
              }}
            >
              <Typography style={{ fontSize: "calc( 0.4vw + 10px) " }}>
                Email me user ratings Email me paid invoices (only if you use a
                paid plan ){" "}
              </Typography>
              <CustomSwitch
                checked={individualSwitches.emailRatings}
                onChange={() => handleIndividualSwitchChange("emailRatings")}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px", // Adjust the gap size as needed
              }}
            >
              <Typography style={{ fontSize: "calc( 0.4vw + 10px) " }}>
                Email me paid invoices (only if you use a paid plan ){" "}
              </Typography>
              <CustomSwitch
                checked={individualSwitches.emailPaidInvoices}
                onChange={() =>
                  handleIndividualSwitchChange("emailPaidInvoices")
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    </div>
  )
}

export default Notifications

