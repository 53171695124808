import React from "react";
import NoData from "../../img/NotificationNoData.png";
const NotificationNoData = () => {
  return (
    <div
      style={{
        height: "70vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <img src={NoData} alt="nodata" />
      </div>
    </div>
  );
};

export default NotificationNoData;
