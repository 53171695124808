import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
const ForwardMessages = ({
  count,
  handleForward,
  handleModalOpen,
  handleForwardSocket
}) => {

  return (
    <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
      <Box display={"flex"} alignItems={"center"}>
        <IconButton onClick={handleForward}>
          <CloseIcon />
        </IconButton>
        <Typography color={"black"}>{count} messages</Typography>
      </Box>
      <Box>
        <IconButton onClick={handleModalOpen}>
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ForwardMessages;
