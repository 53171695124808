import React from "react";
import { WithHoc } from "../Components/WithHoc";

import CustomActivityLayOut from "../Components/CustomActivityLayOut";
import Style from "../../Style/Common.module.css";
import VisitorsGrid from "./Components/VisitorsGrid";

const VisitorsLayout = () => {
  const Visitorslayout = WithHoc(CustomActivityLayOut, VisitorsGrid);
  return (
    <div className={Style.commonPadding}>
      <Visitorslayout title={"Visitor Location Heatmap"} />
    </div>
  );
};

export default VisitorsLayout;
