import { Box, Typography } from '@mui/material'
import React from 'react'
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
// import AddIcon from "@mui/icons-material/Add";

const NoEmailFollowup = () => {
  return (
    <div >
    <div
      style={{ display: "flex", alignItems: "center", gap: "15px" }}
    >
      <div
        style={{
          width: "2.5rem",
          height: "2.5rem",
          backgroundColor: "#fff",
          borderRadius: "50px",
          border: "1px solid #d1d5db",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <MailOutlinedIcon fontSize="medium" sx={{ color: "#000AFF" }} />
      </div>
      <Typography
        sx={{ fontSize: "16px", fontWeight: 600, color: "#000" }}
      >
        Email Follow ups
      </Typography>
    </div>

    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: ".5rem .8rem 0 3.5rem",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0.7rem 1rem",
          borderRadius: "3px",
          marginBottom: "10px",
          backgroundColor: "#F7F8FE",
        }}
      >
        <div
          style={{
            display: "flex",
            marginBottom: "10px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span
            style={{
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "1.25rem",
              color: "#000",
            }}
          >
            Email
          </span>
 
        </div>
          <span
            style={{
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "1.25rem",
              color: "#000",
              width: "200px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            Subject: 
          </span>
      
      </div>

      <div style={{ alignItems: "center", display: "flex" }} >
        <Box
          sx={{
            minWidth: "10rem",
            maxWidth: "10rem",
            height: "2rem",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: ".5rem",
            marginLeft: "auto",
            fontWeight: "500",
            fontSize: ".875rem",
            lineHeight: "1.25rem",
            color: "#1d4ed8",
            padding: "10px 5px",
            borderRadius: "3px",
            ":hover": { backgroundColor: "#E6E7EF" },
          }}
        >
          <AddCircleOutlineOutlinedIcon fontSize="small" />
          <span
            style={{
              fontWeight: "500",
              fontSize: ".875rem",
              lineHeight: "1.25rem",
              color: "#1d4ed8",
            }}
          >
            Add Variants
          </span>
          <img
            src="https://my.saleshandy.com/static/media/premium-feature.615209dc.svg"
            alt=""
          />
        </Box>
      </div>

   
    </div>
    {/* <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                <div
                  style={{
                    width: "2.5rem",
                    height: "2.5rem",
                    backgroundColor: "#fff",
                    borderRadius: "50px",
                    border: "1px solid #d1d5db",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex:1
                  }}
                >
                  <AddIcon fontSize="medium" sx={{ color: "#000AFF" }} />
                </div>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#1d4ed8",
                    ":hover": { backgroundColor: "#E6E7EF" },
                    padding: "7px 10px",
                    cursor: "pointer",
                    borderRadius: "3px",
                  }}
                >
                  Add Steps
                </Typography>
              </div> */}
  </div>
  )
}

export default NoEmailFollowup
