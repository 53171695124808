import React from 'react';
import Styles from "../../components/Dashboard.module.scss";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  LabelList,
} from 'recharts';

const data = [
  {
    name: 'Qualification',
    qualification: 300, 
  },
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{background:"#fff",padding:"5px 20px 5px 20px",border:"1px solid #02CEC9"}}>  
        <p style={{fontSize:"16px",margin:"0",fontWeight:"600"}}>Qualification</p>  
        <p style={{fontSize:"14px",margin:"0"}}>{`${label} : ₹${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

const StageChart = ({ title }) => {
  return (
    <div className={Styles.chartmainwrapper}>
      <div className={Styles.title}>{title}</div>
      <div className={Styles.chartContainer}>
        <div style={{ height: 250 }}>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              layout="vertical"
              width={800}
              height={500}
              data={data}
              margin={{
                top: 10,
                left: 80,
                right: 50,
                bottom: 25,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis type="number" dataKey="qualification" label={{ value: 'Sum of Amount (Rupees)', position: 'insideBottom', offset: -15 }} />
              <YAxis dataKey="name" type="category" label={{ value: 'Stage', angle: -90, position: 'insideLeft', offset: -50, style: { textAnchor: 'middle' } }} />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="qualification" fill="#02CEC9" barSize={500} >
            <LabelList dataKey="qualification" position="insideRight" />
              </Bar>
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default StageChart;
