import React from "react";
import StarRating from "./StarRating";
import Style from "../../../Style/Common.module.css";
import Styles from "../../../Style/Common.module.css";
import MessageUsButton from "./MessageUsButton";
const TableCell = ({ data }) => {
  return (
    <tr
      className={`${Style.tableCellWrapper} ${Styles.noSideBorder}`}
      key={data.id}
    >
      <td className={Styles.flexJustify}>
        <img
          src={data.profilePic}
          alt="userProfile"
          style={{ marginTop: "10px" }}
        />
      </td>

      <td>
        <div className={Styles.displayFlex}>
          <div className={Styles.userNameText}>{data.userName}</div>
          <div className={Styles.comments}>{data.email}</div>
        </div>
      </td>

      <td>
        <StarRating rating={data.rating} />
      </td>

      <td className={`${Style.comments} ${Style.ItalicStyle}`}>
        {data.comment}
      </td>
      <td className={Styles.reportText}>{data.reportDate}</td>
      <td>
        <MessageUsButton />
      </td>
    </tr>
  );
};

export default TableCell;
