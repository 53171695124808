import React from 'react'
import Styles from "../../components/Dashboard.module.scss";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  BarChart,
} from "recharts";

const data = [
  {
    notStarted: 1,
    status: "Not Started",
  },
];
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { status, notStarted } = payload[0].payload;
    return (
      <div
        style={{
          background: "#fff",
          padding: "5px 20px 5px 20px",
          border: "1px solid #78E08F",
        }}
      >
        <p
          style={{ fontSize: "16px", margin: "0", fontWeight: "600" }}
        >{`${status}`}</p>
        <p
          style={{ fontSize: "14px", margin: "0" }}
        >{`Normal : ${notStarted}`}</p>
      </div>
    );
  }

  return null;
};
const PriorityChart = ({title}) => {
  const maxUv = Math.max(...data.map((entry) => entry.notStarted));
  const yAxisMax = maxUv === 1 ? 2 : maxUv + 1;
  return (
    <div className={Styles.chartmainwrapper} >
    <div className={Styles.title}>{title}</div>
    <div className={Styles.chartContainer} >
    <div style={{height:250}}>
    <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={800}
              height={500}
              data={data}
              margin={{
                top: 20,
                left: 80,
                right: 50,
                bottom: 25,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                type="category"
                dataKey="status"
                label={{
                  value: "Status (Tasks)",
                  position: "insideBottom",
                  offset: -20,
                }}
              />
              <YAxis
                dataKey="notStarted"
                axisLine={false}
                label={{
                  value: "Record Count",
                  angle: -90,
                  position: "insideLeft",
                  offset: -50,
                  style: { textAnchor: "middle" },
                }}
                domain={[0, yAxisMax]}
              />

              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="notStarted" barSize="100%" fill="#78E08F" />
            </BarChart>
          </ResponsiveContainer>
          <div style={{display:"flex",justifyContent:"center",marginTop:"10px"}}>
            <div style={{backgroundColor:'#78E08F',width:"10px",height:"10px",borderRadius:"50px",margin:"5px 10px"}}></div>
            <p>Normal</p>
          </div>
      </div>
      </div>
        </div>
  )
}

export default PriorityChart
