import React from "react";
import { PieChart, Pie, Tooltip, ResponsiveContainer } from "recharts";
import Style from "../../../../Style/TopBar/Chart.module.css";

const data = [
  { name: "Group A", value: 100, fill: "rgb(2, 206, 201)" },
  { name: "Group B", value: 300, fill: "rgb(117, 184, 255)" },
  { name: "Group C", value: 400, fill: " rgb(129, 236, 236)" },
];

const PieGraph = () => {
  return (
    <div className={Style.graphWrapper}>
      <ResponsiveContainer width="100%" height="75%">
        <PieChart width={150} height={150} margin={{ top: 75, left: 90 }}>
          <Pie
            dataKey="value"
            isAnimationActive={true}
            data={data}
            cx={100}
            cy={20}
            outerRadius={60}
            fill="#8884d8"
            label
          />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PieGraph;
