import React from "react";
import Grid from "@mui/material/Grid";
import GeneralRatingGraph from "./GeneralRatingGraph";

const GeneralRatingGrid = () => {
  return (
    <Grid container spacing={2} height={300}>
      <Grid item xs={12} md={12} sm={12} sx={{ paddingBottom: "10px" }}>
        <GeneralRatingGraph />
      </Grid>
    </Grid>
  );
};

export default GeneralRatingGrid;
