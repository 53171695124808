import React from "react";
import { Grid, FormControl, TextField, Typography } from "@mui/material";
import facebookAds from "./Ads.module.scss";
import StarBorderIcon from "@mui/icons-material/StarBorder";

const style = {
  margin: "5px 25px 0 10px ",
  "& .MuiInputBase-root": {
    height: "45px", // Set your desired height
  },
  "& .MuiOutlinedInput-root": {
    padding: "0", // Adjust padding if needed
  },
  "& .MuiInputLabel-root": {
    top: "-4px", // Adjust label position if needed
  },
};
const iconStyle = {
  background: "#9747FF",
  borderRadius: "50px",
  fontSize: "45px",
  color: "#fff",
  padding: "10px",
};
const OtherTraffic = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className={facebookAds.fbheader}>
          <Typography sx={{ fontSize: "20px", fontWeight: 400 }}>
            Configure source
          </Typography>
          <div className={facebookAds.buttons}>FEEDBACK</div>
        </div>
      </Grid>

      <Grid item xs={12}>
        <div className={facebookAds.container}>
          <div className={facebookAds.header}>Source Name</div>
          <FormControl fullWidth margin="normal">
            <TextField variant="outlined" sx={style} />
          </FormControl>
          <Typography
            sx={{
              margin: "0px 0 10px 10px",
              fontSize: "14px",
              color: "#878787",
            }}
          >
            As will be seen in the report. Example: Newsletters, Radio,
            Instagram post
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={facebookAds.container}>
          <div className={facebookAds.header}>UTM parameters</div>
          <div className={facebookAds.utmtextfield}>
            <div className={facebookAds.utmtext}>utm_source</div>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                placeholder="+ Add UTM parameter"
                sx={style}
              />
            </FormControl>
          </div>
          <Typography
            sx={{
              margin: "0px 0 10px 10px",
              fontSize: "14px",
              color: "#878787",
            }}
          >
            Click source. Example: email, newsletter, banner, Instagram
          </Typography>
        </div>
      </Grid>

      <Grid item xs={12}>
        <div className={facebookAds.container}>
          <div className={facebookAds.header}>Choose color</div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              margin: "15px 0 15px 10px",
            }}
          >
            <StarBorderIcon style={iconStyle} />
            <Typography
              sx={{
                textDecoration: "underline",
                textDecorationStyle: "dotted",
                cursor: "pointer",
              }}
            >
              Other color
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={facebookAds.container}>
          <div className={facebookAds.header}>Referring domains</div>
          <FormControl fullWidth margin="normal">
            <TextField
              variant="outlined"
              placeholder="+ add domain"
              sx={style}
            />
          </FormControl>
          <Typography
            sx={{
              margin: "0px 0 10px 10px",
              fontSize: "14px",
              color: "#878787",
            }}
          >
            Leads and deals created after visiting these domains will be added
            to this sources statistics.
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default OtherTraffic;
