import React from "react";
import Button from "@mui/material/Button";
import { buttonStyle } from "../../dashboard/Style/ButtonStyle";
import axios from "axios";
import { displayRazorpay } from "../../../webservices/CustomHook/RazorPay/RazorPay";


const AddFundFooter = ({amount,setSnackbarInfo,selectedOption}) => {




  const handleClick = (e) =>{

    let obj = {
      amount:amount
    }

    axios.post(`${process.env.REACT_APP_WEBSERVICES_PRODUCTION_URL}/payment/addFund`, obj).then((result)=>{

      if(result.data){

        const handlerFunction = function (response) {
          setSnackbarInfo({
            open: true,
            severity: "success",
            message: "Payment Successful,It Will Take Few Minutes To Update!",
          });
        };

        let options = {
          ...result.data.options,
          handler: handlerFunction,
        };
  
        displayRazorpay(options, setSnackbarInfo);

      }

    }).catch((err)=>{
    })

  }

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "center", height: "60px" }}
      >
        <Button
          style={{
            ...buttonStyle,
            color: "white",
            background: "#000AFF",
            height: "41px",
            width: "50%",
            fontSize: "16px",
          }}
          onClick={handleClick}
          disabled={selectedOption ? false : true}
        >
          Add Funds
        </Button>
      </div>
      <div style={{ background: "#F5F5F8", height: "50px" }}></div>
    </>
  );
};

export default AddFundFooter;
