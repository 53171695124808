import { useState } from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Dialog,
  Typography,
  TextField,
  Box,
  FormControl,
  InputLabel,
  DialogContent,
  CircularProgress,
  Tooltip,
  Modal,
  MenuItem,
  Select,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Button from "../../../../components/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Styles from "../Organization.module.scss";
import useBranchStore from "../../store/BranchStore";
import toast from "react-hot-toast";

const fetchLeave = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_PEOPLES_NOW_BASEURL}/leave/leave-by-branch`
  );
  return response.data;
};
const schema = yup
  .object({
    leave_type: yup
      .string()
      .min(2, "Please Enter Minimum 2 characters")
      .max(50, "Please Enter Maximum 50 characters")
      .required("Please Enter Leave Type"),
    days: yup.string().required("Please Enter days"),
    branch_id: yup.string().when("isEditMode", {
      is: false,
      then: yup.string().required("Please Select Branch"),
    }),
  })
  .required();

const LeaveForm = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const queryClient = useQueryClient();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const { branch } = useBranchStore();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const {
    data: departData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["leave"],
    queryFn: fetchLeave,
  });

  const onSubmit = (data) => {
    if (isEditMode) {
      axios
        .put(
          `${process.env.REACT_APP_PEOPLES_NOW_BASEURL}/leave/update-leave/${selectedBranch.branch_id}/${selectedBranch.doc_id}`,
          data
        )
        .then((response) => {
          queryClient.invalidateQueries({ queryKey: ["leave"] });
          reset();
          setIsDialogOpen(false);
          setIsEditMode(false);
          toast.success("Leave Updated Successfully!");
        })
        .catch((error) => {
          console.error("Error updating branch:", error);
          const errorMessage =
            error.response?.data?.error?.message || " Error Deleting Leave!";
          toast.error(errorMessage);
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_PEOPLES_NOW_BASEURL}/leave/add-leave`,
          data
        )
        .then((response) => {
          queryClient.invalidateQueries({ queryKey: ["leave"] });
          reset();
          setIsDialogOpen(false);
          toast.success("Leave Created Successfully!");
        })
        .catch((error) => {
          console.error("Error adding branch:", error);
          const errorMessage =
            error.response?.data?.error?.message || "Something went wrong";
          toast.error(errorMessage);
        });
    }
  };
  const handleDelete = async (id) => {
    return axios
      .delete(
        `${process.env.REACT_APP_PEOPLES_NOW_BASEURL}/leave/delete-leave/${id}`
      )
      .then((response) => {
        queryClient.invalidateQueries({ queryKey: ["leave"] });
        setIsDeleteOpen(false);
        toast.success("Leave Deleted Successfully!");
      })
      .catch((error) => {
        console.error("Error deleting branch:", error);
        setIsDeleteOpen(false);
        const errorMessage =
          error.response?.data?.error?.message || "Something went wrong";
        toast.error(errorMessage);
      });
  };

  const handleOkClick = () => {
    if (selectedBranch) {
      handleDelete(selectedBranch.doc_id);
    }
  };

  const handleEdit = (data) => {
    setSelectedBranch(data);
    setIsEditMode(true);
    setValue("leave_type", data.leave_type);
    setValue("days", data.days);
    setIsDialogOpen(true);
  };

  const handleAddLeave = () => {
    setIsEditMode(false);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setIsEditMode(false);
    reset();
  };
  if (isLoading)
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CircularProgress />
      </Box>
    );
  if (isError) return <div>Error loading data</div>;

  return (
    <div
      style={{
        height: "65vh",
      }}
    >
      <div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "2%",
            }}
          >
            <Button
              style={{
                background: "rgba(0, 10, 255, 1)",
                color: "white",
                marginRight: "2%",
              }}
              onClick={handleAddLeave}
            >
              Add Leave
            </Button>
          </div>
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: "bolder",
                    }}
                  >
                    Leave Type
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bolder",
                    }}
                  >
                    Days
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bolder",
                      width: "30px",
                    }}
                  ></TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bolder",
                      width: "30px",
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {departData.map((data) => (
                  <TableRow
                    key={data.doc_id}
                    className={Styles.hoverTable}
                    sx={{
                      "&:hover": {
                        backgroundColor: "var(--light-bg-img-color)",
                      },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell>{data.leave_type}</TableCell>
                    <TableCell>{data.days}</TableCell>
                    <TableCell className={Styles.Tablemenu}>
                      <Tooltip title="Edit" placement="bottom" arrow>
                        {" "}
                        <EditOutlinedIcon onClick={() => handleEdit(data)} />
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={Styles.Tablemenu}
                      sx={{ color: "red", paddingRight: "40px" }}
                    >
                      <Tooltip title="Delete" placement="bottom" arrow>
                        <DeleteOutlineOutlinedIcon
                          onClick={() => {
                            setSelectedBranch(data);
                            setIsDeleteOpen(true);
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <Dialog
            open={isDialogOpen}
            onClose={handleDialogClose}
            maxWidth="xs"
            fullWidth
          >
            <Box
              style={{
                background: "rgba(0, 10, 255, 1)",
                padding: "10px 10px 10px 25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ color: "white" }}>
                {isEditMode ? "Edit  Leave" : "Add  Leave"}
              </Typography>

              <CloseIcon
                onClick={handleDialogClose}
                style={{
                  cursor: "pointer",
                  color: "white",
                  fontSize: "20px",
                }}
              />
            </Box>
            <DialogContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FormControl fullWidth margin="normal">
                      <TextField
                        {...register("leave_type")}
                        error={!!errors.leave_type}
                        helperText={errors.leave_type?.message}
                        variant="outlined"
                        placeholder="Leave Type"
                        label="Leave Type *"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth margin="normal">
                      <TextField
                        {...register("days")}
                        error={!!errors.days}
                        helperText={errors.days?.message}
                        variant="outlined"
                        placeholder="Days"
                        label="Days *"
                      />
                    </FormControl>
                  </Grid>

                  {!isEditMode && (
                    <Grid item xs={12}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel>Branch * </InputLabel>
                        <Select
                          {...register("branch_id")}
                          error={!!errors.branch_id}
                          label="Branch *"
                          value={watch("branch_id") || ""}
                          onChange={(e) =>
                            setValue("branch_id", e.target.value)
                          }
                          MenuProps={{
                            PaperProps: {
                              sx: { maxHeight: "30%", width: "200px" },
                            },
                          }}
                        >
                          {branch.map((item) => (
                            <MenuItem
                              key={item.branch_id}
                              value={item.branch_id}
                            >
                              {item.branch_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    style={{
                      background: "rgba(0, 0, 0, 0)",
                      borderRadius: "5px",
                      color: "Black",
                      boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25)",
                      marginRight: "20px",
                    }}
                    onClick={() => {
                      setIsDialogOpen(false);
                      setIsEditMode(false);
                      reset();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      background: "rgba(0, 10, 255, 1)",
                      borderRadius: "5px",
                      color: "white",
                    }}
                    type="submit"
                  >
                    {isEditMode ? "Update" : "Add"}
                  </Button>
                </Box>
              </form>
            </DialogContent>
          </Dialog>
        </div>
        <Modal
          open={isDeleteOpen}
          onClose={() => setIsDeleteOpen(false)}
          sx={{ outline: "none", border: "none" }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "45vw",
              height: "150px",
              bgcolor: "background.paper",
              boxShadow: 24,
              padding: "5px 5px 20px 20px ",
              display: "flex",
              flexDirection: "column",
              borderRadius: "6px",
              outline: "none",
              border: "none",
            }}
          >
            <CloseIcon
              sx={{ fontSize: 20, cursor: "pointer", marginLeft: "auto" }}
              onClick={() => setIsDeleteOpen(false)}
            />
            <Box sx={{ height: "70px", paddingRight: "15px" }}>
              <Typography sx={{ fontSize: "16px" }}>
                {selectedBranch
                  ? `Deleting Leave "${selectedBranch.holiday_name}" will deleted permanently. Proceed?`
                  : "No item selected."}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                padding: "10px 20px",
              }}
            >
              <Button
                size="small"
                variant="outlined"
                sx={{
                  bgcolor: "#F7F8FA",
                  color: "black",
                  border: "1px solid gray",
                  marginRight: "20px",
                }}
                type="button"
                onClick={() => setIsDeleteOpen(false)}
              >
                Cancel
              </Button>
              <Button
                size="small"
                sx={{
                  bgcolor: "var(--button-color)",
                  color: "#fff",
                  ":hover": { backgroundColor: "var(--button-color)" },
                }}
                onClick={handleOkClick}
              >
                Ok
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default LeaveForm;
