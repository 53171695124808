import React from "react";
import Style from "../../../../Style/TopBar/Kpi.module.css";

const DealsOwnerTable = () => {
  const tableData = [
    {
      user: "Communications",
      ranking: 36,
    },
    {
      user: "Communications",
      ranking: 36,
    },
    {
      user: "Communications",
      ranking: 36,
    },
    {
      user: "Communications",
      ranking: 36,
    },
    {
      user: "Communications",
      ranking: 36,
    },
    {
      user: "Communications",
      ranking: 36,
    },
  ];
  return (
    <>
      <table className={Style.tableWidth}>
        <tbody>
          {tableData.map((data, index) => (
            <tr key={`${data.user}-${index}/`}>
              <td>
                {`${index+1} .`}
                {data.user}
              </td>
              <td className={Style.tablePadding}>{data.ranking}</td>
            </tr>
          ))}
        </tbody>
      </table>
  
    </>
  );
};

export default DealsOwnerTable;
