import React from "react";
import CustomCheckBox from "./CustomCheckBox";
import Style from "../Style/FundTextField.module.css";
import { Typography } from "@mui/material";

const DropDownMenu = ({ onChange, paymentOptions, selectedOption,razorpayfee }) => {

  return (
    <div className={Style.fund_checkbox_align}>
      {paymentOptions.map((option) => (
        <React.Fragment key={option.id}>
          <CustomCheckBox
            id={option.id}
            image={option.image}
            selectedOption={selectedOption}
            onChange={onChange}
          />
        </React.Fragment>
      ))}
      <div style={{height:"40px",backgroundColor:"#fbd8b4",padding:"3px"}}>
      <Typography>
        {`₹ ${razorpayfee} fees apply on razorpay in addition to the Add Money amount`}
      </Typography>
      </div>
    </div>
  );
};
export default DropDownMenu;
