import {
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
  } from "@mui/material";
  import CustomSwitch from "../../../../components/Switch";
  import React from "react";
  import Button from "../../../../components/Button";
  

function Availability() {
    const [alignment, setAlignment] = React.useState(["web"]);
  
    const handleChange = (event, newAlignment) => {
      setAlignment(newAlignment);
    };
  return (
    <div style={{
        // border: "1px solid red",
        height: "75.5vh"
    }}>
        <div
        style={{
          // border: "1px solid blue",
          padding: "1% 10% 2% 2%",
        }}
      >
        <Typography
          style={{
            padding: "0%",
          }}
        >
          Availability Settings
        </Typography>
        <div
          style={{
            border: "1px solid #efefef",
            // height: "65vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "80%",
              // border: "1px solid blue",
              padding: "0% 1%",
            }}
          >
            <Typography
              style={{
                fontWeight: "400",
                fontSize: "calc(0.4vw + 10px)",
                // lineHeight: "13.85px",
                // paragraph: "5px",
              }}
            >
              Set your self available on schedule, by configuring days and
              times(in your timezone). visitors will see as away off scheduled
              hours, but they can still send you messages.
            </Typography>
          </div>
          <div
            style={{
              width: "80%",
              // border: "1px solid blue",
  
              padding: "1% 1%",
            }}
          >
            <Typography
              style={{
                fontWeight: "600",
                fontSize: "calc(0.4vw + 10px)",
                // lineHeight: "13.85px",
                paragraph: "5px",
              }}
            >
              If you are member of a website with multiple operators, the chatbox
              will be seen as online if at least one operator is available, and
              away if all operators are unavailable.
            </Typography>
          </div>
          <div
            style={{
              width: "100%",
              // border: "1px solid #efefef",
              flex: "1",
              // paddingTop: "1%",
            }}
          >
            <div
              style={{
                height: "5vh",
                // border: "1px solid red",
                backgroundColor: "rgba(217, 217, 217, 0.23)",
                display: "flex",
                alignItems: "center",
                paddingLeft: "3%",
              }}
            >
              <Typography
                style={{
                  fontWeight: "bolder",
                  fontSize: "calc(0.4vw + 10px)",
                  color: "rgba(0, 0, 0, 1)",
                }}
              >
                You are currently seen as:
              </Typography>
              <svg
                width="9"
                height="9"
                viewBox="0 0 9 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  marginTop: "0.5%",
                  marginLeft: "1%",
                }}
              >
                <circle cx="4" cy="4" r="4" fill="#20D35D" />
              </svg>
              <Typography
                style={{
                  marginLeft: "1%",
                  fontWeight: "bolder",
                  fontSize: "calc(0.4vw + 10px)",
                  color: "rgba(0, 0, 0, 1)",
                }}
              >
                Online
              </Typography>
            </div>
            <div
              style={{
              //   border: "1px solid black",
                height: "43vh",
                overflowY: "auto",
                
                // flex: "1"
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "1%",
                  border: "1px solid #efefef",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "400",
                    fontSize: "calc(0.4vw + 10px)",
                    lineHeight: "14.06px",
                  }}
                >
                  Face Offline(inivisible mode)
                </Typography>
                <CustomSwitch />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "1%",
                  border: "1px solid #efefef",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "400",
                    fontSize: "calc(0.4vw + 10px)",
                    lineHeight: "14.06px",
                  }}
                >
                  {" "}
                  Set me available when using the app Enable availability schedule
                </Typography>
                <CustomSwitch />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "1%",
                  border: "1px solid #efefef",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "400",
                    fontSize: "calc(0.4vw + 10px)",
                    lineHeight: "14.06px",
                  }}
                >
                  {" "}
                  Enable availability schedule
                </Typography>
                <CustomSwitch />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "1%",
                  border: "1px solid #efefef",
                  overflowX: "auto",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "400",
                    fontSize: "calc(0.4vw + 10px)",
                    lineHeight: "14.06px",
                  }}
                >
                  Days
                </Typography>
                <ToggleButtonGroup
                  value={alignment}
                  onChange={handleChange}
                  aria-label="Platform"
                  size="small"
                  sx={{
                    padding: "2px",
                    color: "white",
                    justifyContent: "end",
                    ".css-1x4vipr-MuiButtonBase-root-MuiToggleButton-root.Mui-selected": {
                      color: "white",
                      backgroundColor: "rgba(0, 0, 0, 1)",
                  },
                  ".Mui-selected.MuiToggleButton-sizeSmall.MuiToggleButton-standard.MuiToggleButtonGroup-grouped.MuiToggleButtonGroup-groupedHorizontal.MuiToggleButtonGroup-middleButton.css-1x4vipr-MuiButtonBase-root-MuiToggleButton-root": {
                    border: "0.1px solid grey",
                }
                  }}
                  
                >
                  <ToggleButton disableTouchRipple value="Mon">
                    Mon
                  </ToggleButton>
                  <ToggleButton value="Tue">Tue</ToggleButton>
                  <ToggleButton value="Wed">Wed</ToggleButton>
                  <ToggleButton value="Thu">Thu</ToggleButton>
                  <ToggleButton value="Fri">Fri</ToggleButton>
                  <ToggleButton value="Sat">Sat</ToggleButton>
                  <ToggleButton disableTouchRipple value="Sun">
                    Sun
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid #efefef",
                  padding: "1%"
                }}
              >
                <Typography
                  style={{
                    fontWeight: "400",
                    fontSize: "calc(0.4vw + 10px)",
                    lineHeight: "14.06px",
                  }}
                >
                  Timezone
                </Typography>
                <TextField
                  placeholder="UTC+05:30"
                  disabled
                  style={{
                    width: "25%",
                    
                  }}
                  size="small"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "0.5%",
                  border: "1px solid #efefef",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "400",
                    fontSize: "calc(0.4vw + 10px)",
                    lineHeight: "14.06px",
                  }}
                >
                  Hours
                </Typography>
                <Button
                  style={{
                    backgroundColor: "rgba(0,0,0,0)",
                    color: "black",
                    border: "1px solid rgba(0,0,0,0.1)",
                    boxShadow: "none"
                  }}
                >
                  + Add on interval
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Availability

  