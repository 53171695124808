import { MenuItem, MenuList, Paper, Popover, Typography } from "@mui/material";
import React from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import { Box } from "@mui/system";
import {
  deleteGlobalEmailAccounts,
  setDefaultMailAccount,
} from "../../../../../../../MarketingStudio/webservices/sequence";
import { snackbarStore } from "../../../../../../../MarketingStudio/store/userStore";
import { useQueryClient } from "@tanstack/react-query";
import EmailProfile from "./EmailProfile";

const Actions = ({
  anchorEl,
  setAnchorEl,
  smtpIds,
  setSelectedIds,
  setOpenSetting,
  setActiveTab,
  data,
  activeTab,
  openSetting,
}) => {
  const { setSnackbarValue } = snackbarStore();
  const queryClient = useQueryClient();
  const smtpIdsArray = [smtpIds];

  const handleTagClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  const handleOpenEdit = () => {
    setOpenSetting(true);
    setActiveTab("Email Settings");
  };

  const action = [
    {
      label: "Edit",
      icon: (
        <EditOutlinedIcon
          fontSize="small"
          sx={{ stroke: "#ffffff", strokeWidth: 0.5 }}
        />
      ),
      onClick: () => handleOpenEdit(),
    },
    {
      label: "Delete",
      icon: (
        <DeleteOutlineIcon
          fontSize="small"
          sx={{ stroke: "#ffffff", strokeWidth: 0.5 }}
        />
      ),
      onClick: async () => {
        try {
          await deleteGlobalEmailAccounts(smtpIdsArray);
          queryClient.invalidateQueries({ queryKey: ["globalEmailAccounts"] });
          setAnchorEl(null);
          setSelectedIds([]);
          setSnackbarValue({
            open: true,
            severity: "success",
            message: "Email Account Deleted",
          });
        } catch (error) {
          console.error("Failed to Delete Email:", error);
          setSnackbarValue({
            open: true,
            severity: "success",
            message: error,
          });
        }
      },
    },
    {
      label: "Set as Default",
      icon: (
        <TaskOutlinedIcon
          fontSize="small"
          sx={{ stroke: "#ffffff", strokeWidth: 0.5 }}
        />
      ),
      onClick: async () => {
        try {
          await setDefaultMailAccount(smtpIds);
          queryClient.invalidateQueries({ queryKey: ["globalEmailAccounts"] });
          setAnchorEl(null);
          setSelectedIds([]);
          setSnackbarValue({
            open: true,
            severity: "success",
            message: "Email Account Marked as Default",
          });
        } catch (error) {
          console.error("Failed to Marked as Default:", error);
          setSnackbarValue({
            open: true,
            severity: "success",
            message: error,
          });
        }
      },
    },
  ];
  return (
    <>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleTagClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        // sx={{ zIndex: 10000000 }}
      >
        <Paper sx={{ overflow: "auto", maxHeight: "300px" }}>
          <MenuList autoFocusItem>
            {action.map((action, index) => (
              <MenuItem
                key={index}
                sx={{
                  padding: "7px 15px",
                  width: "100%",
                  alignItems: "center",
                }}
                onClick={action.onClick}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "12px" }}
                >
                  {action.icon}
                  <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                    {action.label}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </MenuList>
        </Paper>
      </Popover>
      <EmailProfile
        openSetting={openSetting}
        setOpenSetting={setOpenSetting}
        data={data}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </>
  );
};

export default Actions;
