import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "29 Jul 2019 - 4 Aug 2019",
    uv: 500,
    amt: 100,
  },
  {
    name: "5-11 Aug 2019",
    uv: 450,
    amt: 100,
  },
  {
    name: "12-18 Aug 2019",
    uv: 300,
    amt: 100,
  },
  {
    name: "19-25 Aug 2019",
    uv: 750,
    amt: 100,
  },
];

const ContactsCreatedGraph = () => {
  return (
    <ResponsiveContainer height={"100%"} width={"100%"}>
      <AreaChart
        width={800}
        height={500}
        data={data}
        margin={{
          top: 100,
          right: 30,
          left: 50,
          bottom: 5,
        }}
      >
        <XAxis dataKey="name" stroke="0"  padding={{ left: 20, right: 20 }} />
        <YAxis stroke="0"  padding={{ top: 10, bottom: 30 }}/>
        <Tooltip />
        <Area
          dataKey="uv"
          stroke="#DE8600"
          strokeWidth={2}
          fill="#C8C2B7"
          dot={{ stroke: "#DE8600", strokeWidth: 4, fill: "#DE8600" }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default ContactsCreatedGraph;
