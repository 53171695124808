import React, { useState, useRef } from "react";
import "../../styles/myAccount.css";
import { Divider, Paper, Box } from "@mui/material";
import axios from "axios";
import "../dashboard/Style/UserDrawer.css";
import { useQueryClient } from "@tanstack/react-query";

import CustomSnackbar from "../../webservices/Component/CustomSnackbar";
import ProfileUpload from "../dashboard/Component/ProfileUpload";

import useUser from "../../store/userStore";
import { useClientDetails } from "./customHooks/useClientDetials";
import Loader from "../shared/Loader";
import ViewProfileDetails from "./ViewProfileDetails";
import { Route, Routes } from "react-router-dom";
import EditProfileDetails from "./EditProfileDetails";
import toast from "react-hot-toast";
import { useProfilePic } from "./customHooks/useProfilePic";

const ProfileDetails = () => {
  const uploadref = useRef(null);
  const { data: userProfile = "" } = useProfilePic();


  const onlogochange = (e) => {
    console.log("mylogochange", e.target.files[0]);

    updateProfile(e.target.files[0]);
  };

  const { getUserId } = useUser();
  const clientid = getUserId();
  const { data, isLoading } = useClientDetails(clientid);
  const [snackbarInfo, setSnackbarInfo] = useState({
    open: "",
    severity: "",
    message: "",
  });

  const handleSnackbarClose = () => {
    setSnackbarInfo((prevInfo) => ({
      ...prevInfo,
      open: false,
    }));
  };

  // ================================================================================================================================== //

  const queryClient = useQueryClient();

  const updateProfileFun = () => {
    uploadref.current.click();
  };

  function updateProfile(file) {
    let formdata = new FormData();
    console.log("companydorm", file);

    formdata.append("profile_pic", file);

    axios
      .put(
        `${process.env.REACT_APP_WEBSERVICES_PRODUCTION_URL}/user/profile_pic`,
        formdata
      )
      .then((result) => {
        console.log("myuploadresult", result.data.message);
        toast.success(result.data.message);
        queryClient.invalidateQueries({ queryKey: ["getUserProfileData"] });
      })
      .catch((err) => {
        toast.error(err.response.data.message)
      });
  }

  if (isLoading)
    return (
      <Box width={"100vw"} height={"100vh"}>
        <Loader />
      </Box>
    );

  return (
    <Paper
      elevation="2"
      style={{
        paddingBottom: "25px",
        width: "100%",
        backgroundColor: "#ffff",
        height: "calc(100vh - 48px)",
        overflow: "auto",
      }}
    >
      <Box className="my-account-left-photo-wrapper">
        <Box className="my-account-user-photo">
          <Box>
            <Box className="userdraweravatar">
              <ProfileUpload
                onlogochange={onlogochange}
                uploadref={uploadref}
                updateProfileFun={updateProfileFun}
                path={userProfile.profile_pic_path}
                srcUrl={`${userProfile.profile_pic_path}`}
              />
            </Box>
          </Box>

          <Box className="my-account-user-info">
            <Box className="my-account-user-name">
              <h6 style={{ marginBottom: 0 }}>
                {data?.first_name}&nbsp;
                {data?.last_name}&nbsp;
                {data?.user_id}
              </h6>
            </Box>

            <Box className="my-account-user-email">
              <span>{data?.email}</span>
            </Box>

            <Box className="my-account-user-role">
              <span>{data?.profile?.name}</span>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider style={{ background: "#5450537a" }} />
      <Routes>
        <Route path="/" element={<ViewProfileDetails data={data} />} />
        <Route
          path="edit"
          element={
            <EditProfileDetails
              data={data}
              clientid={clientid}
              setSnackbarInfo={setSnackbarInfo}
            />
          }
        />
      </Routes>
      <CustomSnackbar
        snackbarInfo={snackbarInfo}
        handleSnackbarClose={handleSnackbarClose}
      />
    </Paper>
  );
};

export default ProfileDetails;
