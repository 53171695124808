import React from "react";
import Grid from "@mui/material/Grid";

import SupportHeatMap from "./SupportHeatMap";

const SupportGrid = () => {
  return (
    <Grid container spacing={2} height={"auto"}>
      <Grid item xs={12} md={12} sm={12}>
        <SupportHeatMap />
      </Grid>
    </Grid>
  );
};

export default SupportGrid;
