import React from "react";
import { Grid, FormControl, TextField, Typography } from "@mui/material";
import facebookAds from "./Ads.module.scss";
import { FaInstagram } from "react-icons/fa";

const style = {
  margin: "5px 25px 0 10px ",
  "& .MuiInputBase-root": {
    height: "45px", // Set your desired height
  },
  "& .MuiOutlinedInput-root": {
    padding: "0", // Adjust padding if needed
  },
  "& .MuiInputLabel-root": {
    top: "-4px", // Adjust label position if needed
  },
};
const iconStyle = {
  background: "linear-gradient(45deg, #F58529, #DD2A7B, #8134AF, #515BD4)",
  borderRadius: "50px",
  fontSize: "45px",
  color: "#fff",
  padding: "10px",
};
const InstaAds = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className={facebookAds.fbheader}>
          <Typography sx={{ fontSize: "20px", fontWeight: 400 }}>
            Instagram ad source
          </Typography>
          <div className={facebookAds.buttons}>FEEDBACK</div>
        </div>
      </Grid>
      <Grid item xs={12}>
        {" "}
        <div
          className={facebookAds.container}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            padding: "25px 10px",
          }}
        >
          <FaInstagram
            style={{ ...iconStyle, fontSize: "70px", borderRadius: "50%" }}
          />
          <div>
            <Typography sx={{ fontSize: "24px", fontWeight: 400 }}>
              Connect Instagram to Sales Intelligence
            </Typography>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 400, marginTop: "7px" }}
            >
              Report will show the Instagram marketing ROI and the performance
              of your sales respresentatives.
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={facebookAds.container}>
          <div className={facebookAds.header}>Log on to Instagram</div>
          <Typography
            sx={{ fontSize: "16px", fontWeight: 400, marginTop: "15px" }}
          >
            Use Instagram account to log on
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              margin: "15px 0 15px 10px",
            }}
          >
            <FaInstagram style={iconStyle} />
            <div className={facebookAds.buttons}>CONNECT</div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={facebookAds.container}>
          <div className={facebookAds.header}>Source Name</div>
          <FormControl fullWidth margin="normal">
            <TextField variant="outlined" placeholder="Instagram" sx={style} />
          </FormControl>
          <Typography
            sx={{
              margin: "0px 0 10px 10px",
              fontSize: "14px",
              color: "#878787",
            }}
          >
            As will be seen in the report. Example: Newsletters, Radio,
            Instagram post
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={facebookAds.container}>
          <div className={facebookAds.header}>UTM parameters</div>
          <div className={facebookAds.utmtextfield}>
            <div className={facebookAds.utmtext}>utm_source</div>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                placeholder="+ Add UTM parameter"
                sx={style}
              />
            </FormControl>
          </div>
          <Typography
            sx={{
              margin: "0px 0 10px 10px",
              fontSize: "14px",
              color: "#878787",
            }}
          >
            Click source. Example: email, newsletter, banner, Instagram
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default InstaAds;
