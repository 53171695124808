import React from "react";
import { PieChart, Pie, Tooltip, ResponsiveContainer } from "recharts";
import Style from "../../../../Style/TopBar/Chart.module.css";

const data = [
  { name: "Group A", value: 2400, fill: "rgb(2, 206, 201)" },
  { name: "Group B", value: 4567, fill: "rgb(117, 184, 255)" },
  { name: "Group C", value: 1398, fill: "rgb(129, 236, 236)" },
];
const Donut = () => {
  return (
    <div className={Style.graphWrapper}>
      <ResponsiveContainer width="100%" height="75%">
        <PieChart width={200} height={200} margin={{ top: 70, left: 90 }}>
          <Pie
            dataKey="value"
            data={data}
            cx={100}
            cy={20}
            innerRadius={30}
            outerRadius={60}
            fill="#82ca9d"
            label
          />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Donut;
