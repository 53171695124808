import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";

async function getMessageList(page = 1, limit = 10, convoId) {
  const url = `${process.env.REACT_APP_TEAM_CHAT_BASEURL}/api/v1/messages?convoId=${convoId}&page=${page}&limit=${limit}`;
  
  const { data } = await axios.get(url);
  return data;
}

export const useGetMessages = (limit, convoId) => {
  return useInfiniteQuery({
    queryKey: ["teamchat_messagesList", convoId],
    queryFn: ({ pageParam = 1 }) => getMessageList(pageParam, limit, convoId),
    getNextPageParam: (lastPage) => {
      return lastPage.hasNextPage ? lastPage.page + 1 : undefined;
    },
  });
};
