import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { SiGoogleads } from "react-icons/si";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { BiLeaf } from "react-icons/bi";
import { TbMailFast } from "react-icons/tb";
import { IoMdAdd } from "react-icons/io";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import OtherTraffic from "./modules/OtherTraffic";
import InstaAds from "./modules/InstaAds";
import FacebookAds from "./modules/FacebookAds";
import GoogleAds from "./modules/GoogleAds";

const TrafficSource = ({ handleClose }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  const data = [
    {
      name: "Google Ads",
      icon: (
        <SiGoogleads
          style={{
            color: "#fff",
            fontSize: "45px",
            backgroundColor: "#4285F4",
            padding: "10px",
            borderRadius: "30px",
          }}
        />
      ),
      description: <GoogleAds />,
    },
    {
      name: "Facebook",
      icon: (
        <FaFacebookF
          style={{
            color: "#fff",
            fontSize: "45px",
            backgroundColor: "#1877F2",
            padding: "10px",
            borderRadius: "30px",
          }}
        />
      ),
      description: <FacebookAds />,
    },
    {
      name: "Instagram",
      icon: (
        <FaInstagram
          style={{
            background:
              "linear-gradient(45deg, #F58529, #DD2A7B, #8134AF, #515BD4)",
            borderRadius: "50px",
            fontSize: "45px",
            color: "#fff",
            padding: "10px",
          }}
        />
      ),
      description: <InstaAds />,
    },
    {
      name: "Other Traffic",
      icon: (
        <BiLeaf
          style={{
            color: "#fff",
            fontSize: "45px",
            backgroundColor: "#34A853",
            padding: "10px",
            borderRadius: "30px",
            border: "0.5 solid #fff",
          }}
        />
      ),
      description: <OtherTraffic />,
    },
    {
      name: "Email Marketing",
      icon: (
        <TbMailFast
          style={{
            color: "#fff",
            fontSize: "45px",
            backgroundColor: "#EA4335",
            padding: "10px",
            borderRadius: "30px",
          }}
        />
      ),
      description: "This is Email Marketing data...",
    },
    {
      name: "Add",
      icon: (
        <IoMdAdd
          style={{
            color: "#fff",
            fontSize: "45px",
            backgroundColor: "#E7E7E7",
            padding: "10px",
            borderRadius: "30px",
          }}
        />
      ),
      description: "Add new traffic source...",
    },
  ];

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "50px",
          borderBottom: "1px solid rgba(0,0,0,0.1)",
        }}
      >
        {selectedItem ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginLeft: "15px",
              gap: "5px",
            }}
          >
            <KeyboardBackspaceOutlinedIcon
              fontSize="small"
              sx={{ cursor: "pointer" }}
              onClick={() => setSelectedItem(null)}
            />
            <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
              {selectedItem.name}
            </Typography>
          </Box>
        ) : (
          <Typography
            sx={{ fontSize: "16px", fontWeight: 500, marginLeft: "15px" }}
          >
            Traffic sources
          </Typography>
        )}
        <CloseIcon
          onClick={handleClose}
          sx={{
            width: "20px",
            height: "18px",
            marginLeft: "16px",
            marginRight: "10px",
            cursor: "pointer",
          }}
        />
      </Box>

      {selectedItem ? (
        // Render the selected item's data
        <Box
          sx={{
            width: "100%",
            height: "68.3vh",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#F7F7F7",
            padding: "10px",
          }}
        >
          {selectedItem.description}
        </Box>
      ) : (
        // Render the list of traffic sources
        <Box
          sx={{
            padding: "20px",
            height: "68.3vh",
            overflowY: "auto",
            backgroundColor: "#F7F7F7",
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          {data.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 20px 0",
                width: "230px",
                height: "130px",
                border: "1px solid #fff",
                borderRadius: "5px",
                backgroundColor: item.name === "Add" ? "transparent" : "#fff",
                cursor: "pointer",
                transition: "all 0.3s ease",
                ":hover": {
                  border: "1px solid #6CCB4B",
                  boxShadow:
                    "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
                },
              }}
              onClick={() => setSelectedItem(item)}
            >
              {item.icon}
              <Typography
                sx={{ fontSize: "16px", fontWeight: 500, marginTop: "15px" }}
              >
                {item.name}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default TrafficSource;
