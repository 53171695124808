import { useQuery } from "@tanstack/react-query";
import axios from "axios";
async function getUserProfilePic(id) {
  const url = `${process.env.REACT_APP_WEBSERVICES_PRODUCTION_URL}/user/me`;
  return axios.get(url).then(({ data }) => {
    console.log('====================================');
    console.log("userProfiles",data);
    console.log('====================================');
    return data;
  });
}

export const useProfilePic = () => {
  return useQuery({
    queryKey: ["getUserProfileData"],
    queryFn: () => getUserProfilePic(),
  });
};
