import AddToDriveOutlinedIcon from "@mui/icons-material/AddToDriveOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import LocalPostOfficeOutlinedIcon from "@mui/icons-material/LocalPostOfficeOutlined";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";

const AppData = [
  {
    icon: (
      <SettingsSuggestOutlinedIcon
        fontSize="large"
        sx={{ fontSize: "30px", stroke: "#ffffff", strokeWidth: 1 }}
      />
    ),
    name: "Web Services",
    owner: "admin",
    mail: "admin@cloudsloz.com",
    member: "1",
    group: "2",
  },
  {
    icon: (
      <StorefrontOutlinedIcon
        fontSize="large"
        sx={{ fontSize: "30px", stroke: "#ffffff", strokeWidth: 1 }}
      />
    ),
    name: "Marketing Studio",
    owner: "admin",
    mail: "admin@cloudsloz.com",
    member: "1",
    group: "3",
  },
  {
    icon: (
      <LocalPostOfficeOutlinedIcon
        fontSize="large"
        sx={{ fontSize: "30px", stroke: "#ffffff", strokeWidth: 1 }}
      />
    ),
    name: "NDE Mail",
    owner: "admin",
    mail: "admin@cloudsloz.com",
    member: "1",
    group: "4",
  },
  {
    icon: (
      <QuestionAnswerOutlinedIcon
        fontSize="large"
        sx={{ fontSize: "30px", stroke: "#ffffff", strokeWidth: 1 }}
      />
    ),
    name: "Chat Now",
    owner: "admin",
    mail: "admin@cloudsloz.com",
    member: "1",
    group: "5",
  },
  {
    icon: (
      <CloudDoneOutlinedIcon
        fontSize="large"
        sx={{ fontSize: "30px", stroke: "#ffffff", strokeWidth: 1 }}
      />
    ),
    name: "Vision Now",
    owner: "admin",
    mail: "admin@cloudsloz.com",
    member: "1",
    group: "6",
  },
  {
    icon: (
      <RoomOutlinedIcon
        fontSize="large"
        sx={{ fontSize: "30px", stroke: "#ffffff", strokeWidth: 1 }}
      />
    ),
    name: "Spot Now",
    owner: "admin",
    mail: "admin@cloudsloz.com",
    member: "1",
    group: "7",
  },
  {
    icon: (
      <AddToDriveOutlinedIcon
        fontSize="large"
        sx={{ fontSize: "30px", stroke: "#ffffff", strokeWidth: 1 }}
      />
    ),
    name: "NDE Drive",
    owner: "admin",
    mail: "admin@cloudsloz.com",
    member: "1",
    group: "8",
  },
  {
    icon: (
      <VideocamOutlinedIcon
        fontSize="large"
        sx={{ fontSize: "30px", stroke: "#ffffff", strokeWidth: 1 }}
      />
    ),
    name: "NDE Meet",
    owner: "admin",
    mail: "admin@cloudsloz.com",
    member: "1",
    group: "9",
  },
  {
    icon: (
      <ReceiptOutlinedIcon
        fontSize="large"
        sx={{ fontSize: "30px", stroke: "#ffffff", strokeWidth: 1 }}
      />
    ),
    name: "NDE Books",
    owner: "admin",
    mail: "admin@cloudsloz.com",
    member: "1",
    group: "10",
  },
  {
    icon: (
      <PermContactCalendarOutlinedIcon
        fontSize="large"
        sx={{ fontSize: "30px", stroke: "#ffffff", strokeWidth: 1 }}
      />
    ),
    name: "Peoples Now",
    owner: "admin",
    mail: "admin@cloudsloz.com",
    member: "1",
    group: "11",
  },
];

export default AppData;
