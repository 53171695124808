import React from "react";
import TopBar from "./TopBar";
import FullScreen from "react-fullscreen-crossbrowser";
import OverView from "./OverView";
import { Box } from "@mui/material";
import Style from "./Style/OverView/DashBoard.module.css";
import { Route, Routes } from "react-router-dom";
import ChatNowDashBoard from "./ChatNow";
import SpotNowDashBoard from "./SpotNow";
import PipelineDashBoard from "./VissonNow/Pipelines";
import EventsDashboard from "./VissonNow/Events";
import EmailAnalytics from "./VissonNow/EmailAnalytics";
import CallbyUsers from "./VissonNow/CallByUser";
import CallAnalytics from "./VissonNow/CallAnalytics";
import TaskDashboard from "./VissonNow/Tasks";
const GlobalDashBoard = () => {
  const [isFullScreen, setIsFullScreen] = React.useState(false);
  const [anchor, setAnchor] = React.useState(null);

  const openFullscreen = () => {
    setIsFullScreen(true);
    setAnchor(null);
  };

  const closeFullscreen = () => {
    setIsFullScreen(false);
  };

  const handleFullScreenChange = (isFullScreen) => {
    setIsFullScreen(isFullScreen);
    if (!isFullScreen) {
      closeFullscreen();
    }
  };

  const handleIconClose = () => {
    setAnchor(null);
  };

  return (
    <>
       <Box>
      <TopBar
        anchor={anchor}
        handleIconClose={handleIconClose}
        openFullscreen={openFullscreen}
      />
   
      <FullScreen
        enabled={isFullScreen}
        onChange={handleFullScreenChange}
        onClose={closeFullscreen}
      >
        <div
          className={`${Style.dashboard_wrapper} ${
            isFullScreen ? Style.fullScreenHeight : ""
          } `}
        >
          <Routes>
            <Route path="/" element={<OverView />} />
            <Route path="pipelines" element={<PipelineDashBoard/>} />
            <Route path="tasks" element={<TaskDashboard />} />
            <Route path="events" element={<EventsDashboard />} />
            <Route path="call-analytics" element={<CallAnalytics />} />
            <Route path="email-analytics" element={<EmailAnalytics />} />
            <Route path="call-by-users" element={<CallbyUsers />} />

            <Route path="chatnow" element={<ChatNowDashBoard />} />
            <Route path="spotnow" element={<SpotNowDashBoard />} />
          </Routes>
        </div>
      </FullScreen>
      </Box>
    
    </>
  );
};

export default GlobalDashBoard;
