import React from "react";
import { useRef, useCallback } from "react";
import { Box } from "@mui/system";
import ListBar from "./Components/ListBar";
import Loader from "../../../../components/shared/Loader";
import { useUsersList } from "../../../CustomHooks/useUsersList";

const Peoples = () => {
  const {
    data: userList = [],
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useUsersList(15);

  const usersData = userList?.pages?.flatMap((page) => page.data) || [];

  const observer = useRef();
  const lastUserRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  return (
    <Box px={2} sx={{ overflow: "hidden auto" }} height={"100%"}>
      {isLoading ? (
        <Loader />
      ) : (
        usersData.map((user, index) => (
          <div
            ref={index === usersData.length - 1 ? lastUserRef : null}
            key={user.userId}
          >
            <ListBar data={user} />
          </div>
        ))
      )}
    </Box>
  );
};

export default Peoples;
