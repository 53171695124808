import * as React from "react";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import { Box } from "@mui/system";

const StyledBadge = styled(Badge)(({ theme, isOnline }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: isOnline ? "#44b700" : "#f44336",
    color: isOnline ? "#44b700" : "#f44336",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
}));

export default function UserAvatar({ isOnline }) {
  return (
    <Box paddingRight={1}>
      <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        variant="dot"
        isOnline={isOnline} // Pass isOnline prop
      >
        <Avatar
          alt="User Avatar"
          src="/static/images/avatar/1.jpg"
          sx={{ fontSize: "15px", height: "35px", width: "35px" }}
        />
      </StyledBadge>
    </Box>
  );
}
