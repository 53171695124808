import React from "react";
import { Avatar, Box, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CloseIconStyled = styled(CloseIcon)(({ theme }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  backgroundColor: "red",
  color: "white",
  fontSize: theme.typography.teamChatMessage.fontSize,
  borderRadius: "50%",
  cursor: "pointer",
  zIndex: 1,
}));

const ChipAvatar = ({ src, onClose, name }) => {
  return (
    <Box position="relative" display="inline-block">
      <Avatar
        alt={name}
        src={src}
        sx={{ fontSize: "15px", height: "35px", width: "35px" }}
      />
      {onClose && <CloseIconStyled onClick={onClose} />}
    </Box>
  );
};

export default ChipAvatar;
