import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";

const MuiChipPaid = styled(Chip)(({ status }) => ({
  width: "62px",
  height: "21px",
  backgroundColor: getStatusColor(status),
  borderRadius: 50,

  "& .MuiChip-label": {
    color: "black",
    fontSize: "12px",
    fontWeight: 400,
    paddingLeft: "8px",
    paddingRight: "8px",
  },
}));

function getStatusColor(status) {
  return status.toLowerCase() === "paid"
    ? "#CEEDCD"
    : status.toLowerCase() === "overdue"
    ? "#F60000"
    : "#FF8A0";
}
export default function CustomChip({status}) {
  return <MuiChipPaid label={status} status={status}/>;
}

