import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import KeyboardOutlinedIcon from '@mui/icons-material/KeyboardOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import TableViewRoundedIcon from '@mui/icons-material/TableViewRounded';
import { BiFilterAlt } from "react-icons/bi";
import { SlPlane } from "react-icons/sl";
import { PiSignature } from "react-icons/pi";
import { FiEdit } from "react-icons/fi";

const mailSettingsItems = [
    { icon:<DesktopWindowsOutlinedIcon sx={{ stroke: "#ffffff", strokeWidth: 0.8 }}/>, labelText: "System" },
    {
      icon: <KeyboardOutlinedIcon sx={{ stroke: "#ffffff", strokeWidth: 0.8 }}/>,
      labelText: "Keyboard Shortcuts",
    },
    {
      icon: <TableViewRoundedIcon sx={{ stroke: "#ffffff", strokeWidth: 0.8 }}/>,
      labelText: "Mail View Options",
    },
    { icon: <FiEdit style={{ strokeWidth: 1.3,fontSize:"19px"}}/>, labelText: "Compose" },
    {
      icon: <ForumOutlinedIcon sx={{ stroke: "#ffffff", strokeWidth: 0.8 }}/>,
      labelText: "Conversation",
    },
    { icon: <FolderOutlinedIcon sx={{ stroke: "#ffffff", strokeWidth: 0.8 }}/>, labelText: "Folders" },
    {
      icon: <ArchiveOutlinedIcon sx={{ stroke: "#ffffff", strokeWidth: 0.8 }}/>,
      labelText: "Archive Policy",
    },
    { icon: <BiFilterAlt style={{ stroke: "#ffffff", strokeWidth: 0.8,fontSize:"20px"}}/>, labelText: "Filters" },
    {
      icon: <SlPlane style={{  strokeWidth: 0.5,fontSize:"18px"}}/>,
      labelText: "Out of Office",
    },
    { icon: <ReportGmailerrorredOutlinedIcon sx={{ stroke: "#ffffff", strokeWidth: 0.8 }}/>, labelText: "Anti-Spam" },
    { icon: <AlternateEmailOutlinedIcon sx={{ stroke: "#ffffff", strokeWidth: 0.8 }}/>, labelText: "Mail Accounts" },
    { icon:<PiSignature style={{ stroke: "#ffffff", strokeWidth: 0.8,fontSize:"20px"}}/>,  labelText: "Signature" },
  ];

  export default mailSettingsItems;