import React from "react";
import CustomActivityLayOut from "../Components/CustomActivityLayOut";
import { WithHoc } from "../Components/WithHoc";
import Style from "../../Style/Common.module.css";
import GeneralRatingGrid from "./Components/GeneralRatingGrid";

const GeneralRatingLayOut = () => {
  const GeneralRatingLayout = WithHoc(CustomActivityLayOut,GeneralRatingGrid );
  return (
    <div className={Style.commonPadding}>
      <GeneralRatingLayout title={"Contacts Created Over Time"} />
    </div>
  );
};

export default GeneralRatingLayOut;
