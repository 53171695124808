import { Drawer } from "@mui/material";
import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import CloseIcon from "@mui/icons-material/Close";
import CustomSnackbar from "../../../webservices/Component/CustomSnackbar";
import "../Style/UserDrawer.css";

import { styled } from "@mui/material/styles";
import CustomTabs from "./Components/CustomTabs";

const CssDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    top: 48,
    height: "100vh",
    width: "23rem",
  },
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 16,
  color: "#000000",
  padding: "15px 20px 5px",
}));

export default function NotificationDrawer({ open, handleClose }) {
  const [snackbarInfo, setSnackbarInfo] = useState({
    open: "",
    severity: "",
    message: "",
  });

  const handleSnackbarClose = (event, reason) => {
    setSnackbarInfo((prevInfo) => ({
      ...prevInfo,
      open: false,
    }));
  };

  const tabs = [
    {
      label: "Feeds",
      Component: <div>Hello, I am tab 1</div>,
    },
    {
      label: "Reminders",
      Component: <div>Hello, I am tab 2</div>,
    },
  ];

  return (
    <div>
      <>
        <CssDrawer anchor="right" open={open} onClose={handleClose}>
          <div
            onClick={handleClose}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {" "}
            <CustomTypography>Notifications</CustomTypography>
            <CloseIcon fontSize="small" sx={{ margin: "5px" }} />
          </div>

          <CustomTabs tabs={tabs} />
        </CssDrawer>
        <CustomSnackbar
          snackbarInfo={snackbarInfo}
          handleSnackbarClose={handleSnackbarClose}
        />
      </>
    </div>
  );
}
