import React from "react";
import Style from "../../../Style/Common.module.css";
import Styles from "../../../Style/ChatNow/Segments/Segments.module.css";

import { WithHoc } from "../../Components/WithHoc";
import CustomFilterCell from "../../Components/CustomFilterCell";
import FilterBadge from "./FilterBadge";
const SegmentFilter = () => {
  const filterArray = [
    {
      id: "#adword",
      bgColor: "#9747ff",
      label: "adword",
    },
    {
      id: "#adword1",
      bgColor: "#efa74b",
      label: "adword",
    },

    {
      id: "#amazing",
      bgColor: "#73767c",
      label: "amazing",
    },
    {
      id: "#andrion",
      bgColor: "#12c7b1",
      label: "andriod",
    },
    {
      id: "#app",
      bgColor: "#0000a7",
      label: "app",
    },
    {
      id: "#brazil",
      bgColor: "#9747ff",
      label: "brazil",
    },

    {
      id: "#bug",
      bgColor: "#efa74b",
      label: "bug",
    },
  ];
  const FilterCell = WithHoc(CustomFilterCell, FilterBadge);
  return (
    <div className={`${Style.commonBorderLeft} ${Style.commomPaddingLeft}`}>
      <div
        className={`${Styles.filterFieldPadding} ${Style.commonPaddingBottom}`}
      >
        {filterArray.map((data, index) => (
          <FilterCell data={data} />
        ))}
      </div>
    </div>
  );
};

export default SegmentFilter;
