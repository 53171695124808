export async function displayRazorpay(options,setSnackbarInfo) {
  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
  paymentObject.on('payment.failed',()=>{
    setSnackbarInfo({
      open:true,
      severity:"error",
      message:'Payment Failed!'
    })
  })
}       
