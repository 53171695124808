import React, { useEffect } from "react";
import { Box } from "@mui/system";
import { Avatar, Typography } from "@mui/material";
import videoCallIcon from "../../../../Assets/VideoCall.png";
import voiceCallIcon from "../../../../Assets/VoiceCall.png";
import NextIcon from "../../../../Assets/next.png";
import { styled } from "@mui/system";
import { useChatUIStore, useReceiverId } from "../../../../store/teamChatStore";

const IconWrapper = styled(Box)({
  padding: "0 8px",
  cursor: "pointer",
});

const ProfilePanel = ({ typing }) => {
  const { setUIElement } = useChatUIStore();
  const { receiverData } = useReceiverId();

  function handleProfileClick(e) {
    e.stopPropagation();
    setUIElement("#userProfile");
  }

  function handleIconClick(e, targetElement) {
    e.stopPropagation();
    setUIElement(targetElement);
  }

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      height={"8%"}
      onClick={handleProfileClick}
      sx={{ cursor: "pointer" }}
    >
      <Box paddingLeft={1} display={"flex"} alignItems={"center"}>
        <Box>
          <Avatar
            alt={receiverData.userName}
            src={receiverData.profilePic}
            sx={{ height: "27px", width: "27px" }}
          />
        </Box>
        <Box paddingLeft={1}>
          <Typography fontWeight={500} fontSize={12}>
            {receiverData?.userName}
          </Typography>
          <Typography color={"#959FA3"} fontSize={9}>
            {receiverData?.email}
          </Typography>
          <Typography color={"greeb"} fontSize={9}>
            {typing}
          </Typography>
        </Box>
      </Box>
      <Box paddingRight={1} display={"flex"} alignItems={"center"}>
        <IconWrapper onClick={(e) => handleIconClick(e, "#videoCall")}>
          <img src={videoCallIcon} alt="video call" />
        </IconWrapper>
        <IconWrapper onClick={(e) => handleIconClick(e, "#voiceCall")}>
          <img src={voiceCallIcon} alt="voice call" />
        </IconWrapper>
        <IconWrapper onClick={(e) => handleIconClick(e, "#channels")}>
          <img src={NextIcon} alt="next" />
        </IconWrapper>
      </Box>
    </Box>
  );
};

export default ProfilePanel;
