import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";
import { getBaseUrl } from "../Utils/OptionsUtils";
import { useOnlineStatusStore } from "../store/teamChatStore";

async function getChatUserList(page = 1, limit = 10) {
  const url = `${process.env.REACT_APP_TEAM_CHAT_BASEURL}/api/v1/employees?page=${page}&limit=${limit}`;
  // const url = `${getBaseUrl()}/api/v1/employees?page=${page}&limit=${limit}`;
  return axios.get(url).then(({ data }) => data);
}

export const useUsersList = (limit) => {
  const { addOnlineStatusId } = useOnlineStatusStore();

  return useInfiniteQuery({
    queryKey: ["getUsersList"],
    queryFn: async ({ pageParam = 1 }) => {
      const data = await getChatUserList(pageParam, limit);
      data.onlineUsers.forEach((userId) => {
        addOnlineStatusId(userId);
      });
      return data;
    },
    getNextPageParam: (lastPage) => {
      const { hasNextPage, page } = lastPage;
      console.log("lastPagelastPage", lastPage);

      console.log("mypagess", hasNextPage, page);

      return hasNextPage ? page + 1 : undefined;
    },
  });
};
