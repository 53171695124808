import React, { useState } from "react";
import {
  TextField,
  Checkbox,
  IconButton,
  InputAdornment,
  Box,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import CustomSnackBar from "../../../SnackBar/CustomSnackBar";
import useWorkSpaceStore from "../../../../store/WorkSpaceStore";
import useSelectedWorkSpace from "../../../../store/selectedWorkSpaceStore";
import useUser from "../../../../store/userStore";
import Style from "../../Style/LogInPage.module.css";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "2px",
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px",
  },
  "& .MuiInputBase-input": {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
    },
  },
}));

const buttonStyles = {
  backgroundColor: "#000aff",
  borderRadius: "5px",
  fontSize: "14px",
  color: "white",
  fontWeight: "600",
  marginTop: "5%",
};
function ButtonContainer() {
  const { setWorkSpace } = useWorkSpaceStore();
  const { setSelectedWorkSpace } = useSelectedWorkSpace();
  const { setLogInDetails } = useUser();
  const navigate = useNavigate();

  const [snackbaropen, setSnackbaropen] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Please enter Email"),
    password: Yup.string().required("Please enter Password"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      showPassword: false,
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const obj = {
        email: values.email,
        password: values.password,
      };

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_WEBSERVICES_PRODUCTION_URL}/client/signin`,
          obj
        );

      
        setSnackbaropen({
          open: true,
          message: "Login successful",
          severity: "success",
        });

        const { data } = response;
        console.log("logout", data);

        const selectedWorkSpace = data.workspaces.find(
          (workspace) => workspace.id === data.defaultWorkspace
        );

        setSelectedWorkSpace(selectedWorkSpace);
        setWorkSpace(data.workspaces);
        setLogInDetails(data.data);
        navigate("/");
      } catch (error) {
        console.log("first",error)
        setSnackbaropen({
          open: true,
          message: error?.response?.data?.error?.message || "An error occurred",
          severity: "error",
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleVisibility = () => {
    formik.setFieldValue("showPassword", !formik.values.showPassword);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <form onSubmit={formik.handleSubmit}>
          <div style={{ margin: "0px 3%" }}>
            <p className={Style.fieldTitle}>Email</p>
            <CustomTextField
              name="email"
              value={formik.values.email}
              error={formik.touched.email && !!formik.errors.email}
              helperText={formik.touched.email && formik.errors.email}
              onChange={formik.handleChange}
              type="email"
              fullWidth
            />
            <br />
            <p className={Style.fieldTitle} style={{ marginTop: "8%" }}>
              Password
            </p>
            <CustomTextField
              name="password"
              value={formik.values.password}
              error={formik.touched.password && !!formik.errors.password}
              helperText={formik.touched.password && formik.errors.password}
              onChange={formik.handleChange}
              type={formik.values.showPassword ? "text" : "password"}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleVisibility}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {formik.values.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div className={Style.align_container}>
              <div className={Style.align_button}>
                <Checkbox sx={{ padding: "5px 5px 5px 0px" }} />
                <p className={Style.black_text}>Remember for 30 days</p>
              </div>
              <NavLink to="/login/verifymail" className={Style.blue_text}>
                Forgot Password ?
              </NavLink>
            </div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={formik.isSubmitting}
              style={buttonStyles}
            >
              {formik.isSubmitting ? "Signing In..." : "Sign In"}
            </Button>
            <div className={Style.black_text_} style={{ marginTop: "4%" }}>
              Don't Have An Account?
              <NavLink to="/signUp" style={{ marginLeft: "4px" }}>
                <span className={Style.blue_text}>Sign Up</span>
              </NavLink>
            </div>
          </div>
        </form>
      </Box>
      <CustomSnackBar
        snackbaropen={snackbaropen}
        setSnackbaropen={setSnackbaropen}
        position="left"
      />
    </>
  );
}

export default ButtonContainer;
