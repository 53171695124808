import React, { useState } from "react";
import Dollar from "./Assets/Dollar.png";
import CurrencyStyle from "./Style/Billing.module.css";
import { PriceFormat } from "../../webservices/CustomHook/DateFormat";
import AddFundModal from "./Components/AddFundModal";
const FilterInv = ({ walletData }) => {
  const [openModule, setOpenModule] = useState(false);
  const handleClose = () => setOpenModule(false);
  const handleOpen = () => setOpenModule(true);
  return (
    <>
      <div className={CurrencyStyle.Dollar_Wrapper}>
        <div style={{ marginRight: "3px" }}>
          <img src={Dollar} alt="DollarImage" />
        </div>

        <div>
          <div className={CurrencyStyle.Currency}>{`${
            walletData?.currencyCode
          }  ${PriceFormat(walletData?.total)} `}</div>
          <div className={CurrencyStyle.AddFund} onClick={handleOpen}>
            Add Fund
          </div>
        </div>
      </div>
      <AddFundModal open={openModule} onClose={handleClose} />
    </>
  );
};

export default FilterInv;
