import React from 'react';
import { styled } from '@mui/system';
import Checkbox from '@mui/material/Checkbox';

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.1)',
  '&.Mui-checked': {
    color: 'grey',
  },
  '& .MuiSvgIcon-root': {
    width: '24px',
    height: '24px',
  },
}));

function MyCheckbox(props) {
  return (
    <CustomCheckbox
      {...props}
    />
  );
}

export default MyCheckbox;
