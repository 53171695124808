import BarGraph from "../Components/BarGraph";
import ColumnChart from "../Components/ColumnChart";
import Donut from "../Components/DonutChart";
import LineGraph from "../Components/LineGraph";
import PieGraph from "../Components/PieChart";
import TableChart from "../Components/TableChart";
import FunnelGraph from "../Components/FunnelChart";
import AreaChart from "../Components/AreaChart";
import Heatmap from "../Components/HeatMap";

export const GridData = [
  {
    id: "#columnchart",
    component: <ColumnChart />,
    type: "Column Chart",
  },
  {
    id: "#donutchart",
    component: <Donut />,
    type: "Donut Chart",
  },
  {
    id: "#piechart",
    component: <PieGraph />,
    type: "Pie Chart",
  },
  {
    id: "#barchart",
    component: <BarGraph />,
    type: "Bar Chart",
  },

  {
    id: "#linechart",
    component: <LineGraph />,
    type: "Line Chart",
  },
  {
    id: "#tableChart",
    component: <TableChart />,
    type: "Table Chart",
  },
  {
    id: "#funnelChart",
    component: <FunnelGraph />,
    type: "Funnel Chart",
  },
  {
    id: "#areaChart",
    component: <AreaChart />,
    type: "Area Chart",
  },
  {
    id: "#heatChart",
    component: <Heatmap />,
    type: "Heat Map",
  },
];
