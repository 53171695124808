import React from 'react'
import Styles from "../../components/Dashboard.module.scss";

const EventMonthChart = ({title}) => {
  return (
    <div className={Styles.chartmainwrapper} >
    <div className={Styles.title}>{title}</div>
    {/* <div className={Styles.chartContainer} ></div> */}
    <div className={Styles.noDataWrapper}>
          <span className={Styles.noDataText}>No Data Available</span>
          </div>
    </div>
  )
}

export default EventMonthChart
