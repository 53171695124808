import { create } from "zustand";
import { persist } from "zustand/middleware";

const useUserProfile = create()(
  persist(
    (set) => ({
      userProfile: {},

      setuserProfile: (data) => {
        set({ userProfile: data });
      },
     
    }),
    {
      name: "UserProfile-persit",
    }
  )
);

export default useUserProfile;
