import {
  Avatar,
  Box,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import HourglassFullTwoToneIcon from "@mui/icons-material/HourglassFullTwoTone";
import { newCampaignSubject, setNewSequenceIdStore, setNewVariantidStore, snackbarStore, stepId } from "../../../../store/userStore";
import { SetBodyValue } from "../../../../store/templateStore";
import NoEmailFollowup from "../NoEmailFollowup";
import { stepDelete, stepVariantDelete } from "../../../../webservices/sequence";
import { useQueryClient } from "@tanstack/react-query";

const FollowUp = ({ stepData,value,setValue,setVariantData,variantData }) => {
  const { stepid, setStepId } = stepId();
  const { SetBody } = SetBodyValue();
  const {setEmailSubject,resetEmailSubject} = newCampaignSubject();
  const queryClient = useQueryClient();
  const { setSnackbarValue } = snackbarStore();
  const { newSequenceId } = setNewSequenceIdStore();
  const { setNewVariantid } = setNewVariantidStore();
  
  // const handleIncrement = (step_id) => {
  //   setValue((prevState) => {
  //     const existingItem = prevState.find((v) => v.id === step_id);
  //     if (existingItem) {
  //       return prevState.map((v) =>
  //         v.id === step_id ? { ...v, day: v.day + 1 } : v
  //       );
  //     } else {
  //       return [...prevState, { id: step_id, day: 2 }]; 
  //     }
  //   });
  // };

  // const handleDecrement = (step_id) => {
  //   setValue((prevState) => {
  //     const existingItem = prevState.find((v) => v.id === step_id);
  //     if (existingItem && existingItem.day > 0) {
  //       return prevState.map((v) =>
  //         v.id === step_id ? { ...v, day: v.day - 1 } : v
  //       );
  //     } else {
  //       return prevState;
  //     }
  //   });
  // };
  const handleIncrement = (step_id) => { 
    setValue((prevState) => {
      if (prevState.id === step_id) {
        return { ...prevState, day: prevState.day + 1 }; 
      } else {
        return { id: step_id, day: 2 }; 
      }
    });
  };
  
  const handleDecrement = (step_id) => {
    setValue((prevState) => {
      if (prevState.id === step_id && prevState.day > 1) { 
        return { ...prevState, day: prevState.day - 1 }; 
      }
      return prevState; 
    });
  };

  const handleChange = (step_id, e) => {
    const newDay = parseInt(e.target.value, 10);
    if (!isNaN(newDay)) {
      setValue((prevState) => {
        const existingItem = prevState.find((v) => v.id === step_id);
        if (existingItem) {
          return prevState.map((v) =>
            v.id === step_id ? { ...v, day: newDay } : v
          );
        } else {
          return [...prevState, { id: step_id, day: newDay }];
        }
      });
    }
  };

const addVariant =(stepid) => {
  setStepId(stepid);
};

const handleDeleteStep = async (sequence_id, stepId) => {
  try {
    await stepDelete(sequence_id, stepId);
    queryClient.invalidateQueries({ queryKey: ["stepList"] });
    setSnackbarValue({
      open: true,
      severity: "success",
      message: "Step Deleted",
    });
  } catch (error) {
    console.error("Error deleting sequence:", error);
    setSnackbarValue({
      open: true,
      severity: "error",
      message: error.message || "Error deleting Step",
    });
  }
};

const handleDeleteVariant = async (stepId, variant_id) => {
  try {
    await stepVariantDelete(stepId, variant_id);
    queryClient.invalidateQueries({ queryKey: ["stepList"] });
    setSnackbarValue({
      open: true,
      severity: "success",
      message: "Variant Deleted",
    });
  } catch (error) {
  
    console.error("Error deleting sequence:", error);
    setSnackbarValue({
      open: true,
      severity: "error",
      message: error.message || "Error deleting Variant",
    });
  }
};
const handleVariantClick = (variant, stepid) => {
  setVariantData(variant);
  setStepId(stepid);
  SetBody(variant?.content);
  setEmailSubject("subject",variant?.subject);
  setNewVariantid(variant.variant_id)
};
  return (
    <Box
      sx={{
        padding: "20px",
        overflowY: "auto",
        height: "calc(100vh - 120px)",
        overflowX: "hidden",
        borderRight: "1px solid var(--light-border-color)",
        position: "relative",
      }}
    >
      <div
        style={{
          width: "1px",
          height: "calc(100vh - 180px)",
          backgroundColor: "#e5e7eb",
          position: "absolute",
          marginLeft: "20px",
        }}
      ></div>

      {stepData.isLoading  ? (
        <NoEmailFollowup/>
      ) : (
        <>
          {stepData?.data?.data?.steps?.length > 0 ? (
            <>
              {stepData.data.data.steps.map((item) => (

                <div key={item.step_id}>
                  <div
                    style={{ display: "flex", alignItems: "center", gap: "15px" }}
                  >
                    <div
                      style={{
                        width: "2.5rem",
                        height: "2.5rem",
                        backgroundColor: "#fff",
                        borderRadius: "50px",
                        border: "1px solid #d1d5db",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      <MailOutlinedIcon fontSize="medium" sx={{ color: "#000AFF" }} />
                    </div>
                    <Typography
                      sx={{ fontSize: "16px", fontWeight: 600, color: "#000" }}
                    >
                      Email Follow ups
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: ".5rem .8rem 0 3.5rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "0.7rem 1rem",
                        borderRadius: "3px",
                        marginBottom: "10px",
                        border:"1px solid #F7F8FE",
                        backgroundColor: stepid === item.step_id ? "#fff" : "#F7F8FE",
                        ":hover":{backgroundColor:"#fff",border:"1px solid #000AFF",},
                        borderColor: stepid === item.step_id && "#000AFF",
                       
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          marginBottom: "10px",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "1.25rem",
                            color: "#000",
                          }}
                        >
                          Email
                        </span>
                        {stepData?.data?.data?.steps?.length > 1 ? (
                          <DeleteOutlineOutlinedIcon
                            fontSize="small"
                            sx={{ cursor: "pointer", ":hover": { color: "red" } }}
                            onClick={() =>
                              handleDeleteStep(newSequenceId, item.step_id)
                            }
                          />
                        ) : null}
                      </div>

                      {item.variants.length === 1 ? (
                        <span
                          style={{
                            fontWeight: item?.variants[0].variant_id === variantData.variant_id ? 600 : 400,
                            fontSize: "14px",
                            lineHeight: "1.25rem",
                            color: "#000",
                            width: "200px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            cursor:"pointer"
                          }}
                          onClick={() =>{
                            setVariantData(item?.variants[0]);
                            setStepId(item?.step_id);
                            SetBody(item?.variants[0]?.content);
                            setEmailSubject("subject",item?.variants[0]?.subject);}
                          }
                        >
                          Subject: {item.variants[0].subject}
                        </span>
                      ) : (
                        item.variants.map((variant, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              padding: "10px 5px",
                              cursor:"pointer",
                              ":hover":{backgroundColor:"#F1F1F1"}
                            }}
                            onClick={() =>
                              handleVariantClick(variant, item.step_id)
                            }
                          >
                            <Avatar
                              sx={{
                                height: "30px",
                                width: "30px",
                                fontSize: "14px",
                              }}
                            >
                               {String.fromCharCode(65 + index)}
                            </Avatar>
                            <span
                              style={{
                                fontWeight: variant.variant_id === variantData.variant_id ? 600 : 400,
                                fontSize: "14px",
                                lineHeight: "1.25rem",
                                color: "#000",
                                width: "200px",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Subject: {variant.subject}
                            </span>
                            <CloseIcon fontSize="small" sx={{ fontSize: "16px", }}   onClick={() =>
                                        handleDeleteVariant(
                                          item.step_id,
                                          variant.variant_id
                                        )
                                      }/>
                          </Box>
                        ))
                      )}
                    </Box>

                    <div style={{ alignItems: "center", display: "flex" }}  onClick={() => {resetEmailSubject();SetBody("");setNewVariantid('');addVariant(item.step_id)}}>
                      <Box
                        sx={{
                          minWidth: "10rem",
                          maxWidth: "10rem",
                          height: "2rem",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          gap: ".5rem",
                          marginLeft: "auto",
                          fontWeight: "500",
                          fontSize: ".875rem",
                          lineHeight: "1.25rem",
                          color: "#1d4ed8",
                          padding: "10px 5px",
                          borderRadius: "3px",
                          ":hover": { backgroundColor: "#E6E7EF" },
                        }}
                      >
                        <AddCircleOutlineOutlinedIcon fontSize="small" />
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: ".875rem",
                            lineHeight: "1.25rem",
                            color: "#1d4ed8",
                          }}
                        >
                          Add Variants
                        </span>
                        <img
                          src="https://my.saleshandy.com/static/media/premium-feature.615209dc.svg"
                          alt=""
                        />
                      </Box>
                    </div>

                 
                  </div>
                <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                        margin: "20px 0",
                      }}
                    >
                      <div
                        style={{
                          width: "2.5rem",
                          height: "2.5rem",
                          backgroundColor: "#fff",
                          borderRadius: "50px",
                          border: "1px solid #d1d5db",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          zIndex:1
                        }}
                      >
                        <HourglassFullTwoToneIcon
                          fontSize="medium"
                          sx={{ color: "#000AFF" }}
                        />
                      </div>

                      <div
                        style={{
                          color: "#000",
                          backgroundColor: "#F7F8FE",
                          padding: "7px 10px",
                          borderRadius: "3px",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                          Wait for
                        </Typography>
             <TextField
            size="small"
            sx={{
              width: '100px',
              paddingLeft: '7px',
              '& .MuiInputBase-root': { height: '30px' },
              '& .MuiInputBase-input': {
                padding: '0 5px',
                fontSize: '12px',
              },
            }}
            // value={
            //   value.find(v => v.id === item.step_id) 
            //     ? value.find(v => v.id === item.step_id).day 
            //     : item.day
            // }
            value={value.id === item.step_id ? value.day : item.day}
            onChange={(e) => handleChange(item.step_id, e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <RemoveIcon
                    sx={{ fontSize: '14px', cursor: 'pointer' }}
                    onClick={() => handleDecrement(item.step_id ,item.day)}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <AddIcon
                    sx={{ fontSize: '14px', cursor: 'pointer' }}
                    onClick={() => handleIncrement(item.step_id ,item.day)}
                  />
                </InputAdornment>
              ),
              inputProps: { inputMode: 'numeric', pattern: '[0-9]*' },
            }}
          />

                        <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                          {" "}
                          day(s) then
                        </Typography>
                      </div>
                    </div>
                </div>
              ))}

              <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                <div
                  style={{
                    width: "2.5rem",
                    height: "2.5rem",
                    backgroundColor: "#fff",
                    borderRadius: "50px",
                    border: "1px solid #d1d5db",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex:1,
                   
                  }}
                >
                  <AddIcon fontSize="medium" sx={{ color: "#000AFF" }} />
                </div>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#1d4ed8",
                    ":hover": { backgroundColor: "#E6E7EF" },
                    padding: "7px 10px",
                    cursor: "pointer",
                    borderRadius: "3px",
                  }}
                  onClick={()=>{setNewVariantid(""); setStepId(null); resetEmailSubject();SetBody("");}}
                >
                  Add Steps
                </Typography>
              </div>
            </>
          ): (
            <NoEmailFollowup />
          )}
        </>
      ) }
    </Box>
  );
};

export default FollowUp;

