import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../../../components/Button";
import MyCheckbox from "../../../../components/CheckBox";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import useSelectedWorkSpace from "../../../../../../../store/selectedWorkSpaceStore";

const fetchAgents = async (workspaceId) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/agents/allAgents/${workspaceId}`
  );
  console.log("fetched agents:",response.data)
  return response.data; 
};

const EmployeeListDialog = ({ open, onClose }) => {
  const workspace = useSelectedWorkSpace((state) => state.getSelectedWorkSpace());
  const workspaceId = workspace.id; 
  
  const { data: agents = [], isLoading, isError } = useQuery({
    queryKey: ["agents", workspaceId],
    queryFn: () => fetchAgents(workspaceId), 
    enabled: !!workspaceId, 
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <div
        style={{
          padding: "10px",
          background: "rgba(0, 10, 255, 1)",
          top: "10px",
          right: "10px",
          cursor: "pointer",
        }}
      >
        <CloseIcon
          onClick={onClose}
          sx={{
            marginLeft: "95%",
            color: "white",
          }}
        />
      </div>
      <DialogTitle>Employee List</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <p>Loading...</p>
        ) : isError ? (
          <p>Error fetching agents.</p>
        ) : agents.length === 0 ? (
          <p>No employees found.</p>
        ) : (
          <Paper elevation={2}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Action</TableCell>
                  <TableCell>Employee Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {agents.map((agent, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <MyCheckbox />
                    </TableCell>
                    <TableCell>{agent.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          style={{ boxShadow: "0 3px 5px rgba(0, 0, 0, 0.1)" }}
        >
          Submit
        </Button>
        <Button
          onClick={onClose}
          variant="contained"
          style={{
            boxShadow: "0 3px 5px rgba(0, 0, 0, 0.1)",
            background: "rgba(0,10,255,1)",
          }}
        >
          Close
        </Button>
      </DialogActions>
      <div
        style={{
          border: "1px solid transparent",
          padding: "15px",
          borderRadius: "5px",
          background: "rgba(245, 245, 248, 1)",
        }}
      ></div>
    </Dialog>
  );
};

export default EmployeeListDialog;
