import React from "react";
import { Link } from "react-router-dom";
import BackIcon from "../../Assests/blueArrow.png";
import logo from "../../Assests/NDE_Logo_Blue.png";
import Style from "../Style/SignUpHeader.module.css";
export default function SignupHeader() {
  return (
    <>
      <div className={Style.header_wrapper}>
        <div className={Style.split_div_}>
          <Link to="/login" style={{ marginLeft: "12px" }}>
            <img src={BackIcon} alt="Navigation Arrow" />
          </Link>
        </div>
        <div className={Style.split_div}>
          <Link to="/login" style={{ marginLeft: "12px" }}>
            <img src={logo} alt="Nde logo" />
          </Link>
        </div>
      </div>
      <p className={Style.below_text}>Let's get started</p>
    </>
  );
}
