import React from "react";
import MeanScore from "./MeanScore";
import RatingTable from "./RatingTable";

const LatestRatingWrapper = () => {
  return (
    <>
      <MeanScore />
      <RatingTable />
    </>
  );
};

export default LatestRatingWrapper;
