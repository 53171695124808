import React from "react";
import { LoadingButton } from "@mui/lab";
import Style from "../Style/Footer.module.css";
const buttonStyles = {
  backgroundColor: "#000aff",
  borderRadius: "5px",
  fontSize: "14px",
  color: "white",
  fontWeight: "600",
  width: "30%",
};
const Footer = ({ handleSubmit, loading }) => {
  const handleButtonClick = (event) => {
    event.preventDefault();
    handleSubmit();
  };

  return (
    <div className={Style.footer_wrapper}>
      <LoadingButton
        style={buttonStyles}
        onClick={handleButtonClick}

        loading={loading}
      >
        Sign Up
      </LoadingButton>
    </div>
  );
};

export default Footer;
