import React from "react";
import Style from "../Style/OverView/DashBoard.module.css";

const Wrapper = (props) => {

  return (
    <div className={Style.wrapper}>
      <div className={Style.title}>{props.title}</div>
      {props.noData ? (
        <div className={Style.noDataWrapper}>
          <span className={Style.noDataText}>No Data Available</span>
        </div>
      ) : (
        <>{props.children}</>
      )}
      
    </div>
  );
};

export default Wrapper;
