export function formatDatesTime(dateTime) {
  const now = new Date();
  const inputDate = new Date(dateTime);
  const diffTime = now - inputDate;
  const oneDay = 24 * 60 * 60 * 1000;
  const oneWeek = 7 * oneDay;

  const isToday = (date) => date.toDateString() === now.toDateString();
  const isWithinWeek = (date) => diffTime <= oneWeek;

  if (isToday(inputDate)) {
    return `${String(inputDate.getHours()).padStart(2, "0")}:${String(
      inputDate.getMinutes()
    ).padStart(2, "0")}`;
  } else if (isWithinWeek(inputDate)) {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return days[inputDate.getDay()];
  } else {
    const day = String(inputDate.getDate()).padStart(2, "0");
    const month = String(inputDate.getMonth() + 1).padStart(2, "0");
    const year = inputDate.getFullYear();
    return `${day}/${month}/${year}`;
  }
}

export function formateHour(dateTime) {
  const inputDate = new Date(dateTime);
  const minutes = inputDate.getMinutes();
  const minute = minutes < 10 ? `0${minutes}` : minutes;
  const hour = inputDate.getHours();
  let time = "";

  if (hour > 12) {
    time = `${hour - 12}:${minute} PM`;
  } else if (hour == 12) {
    time = `${hour}:${minute} PM`;
  } else {
    time = `${hour}:${minute} AM`;
  }
  return time;
}
