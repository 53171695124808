import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import { PiIntersectThreeFill } from "react-icons/pi";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import AdsClickRoundedIcon from "@mui/icons-material/AdsClickRounded";
// import { HiOutlineUserGroup } from "react-icons/hi";
// import Organization from '../../Images/OrgItems/Organization.jpg'
// import Roles from '../../Images/OrgItems/Connected_People.jpg'
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";

const orgSettingsItems = [
  {
    icon: (
      <PiIntersectThreeFill
        style={{
          strokeWidth: 0.5,
          fontSize: "20px",
          border: "1px solid black",
          borderRadius: "50px",
          padding: "2px",
        }}
      />
    ),
    labelText: "Organization",
  },
  {
    icon: (
      <AdminPanelSettingsOutlinedIcon
        sx={{ stroke: "#ffffff", strokeWidth: 0.5 }}
      />
    ),
    labelText: "Roles and Permissions",
  },
  {
    icon: (
      <EventAvailableOutlinedIcon
        sx={{ stroke: "#ffffff", strokeWidth: 0.8 }}
      />
    ),
    labelText: "Web Forms",
  },
  {
    icon: <ListAltOutlinedIcon sx={{ stroke: "#ffffff", strokeWidth: 0.8 }} />,
    labelText: "Mails Now",
    iconStyle: { fontSize: 32, color: "blue" },
  },
  {
    icon: <ForumOutlinedIcon sx={{ stroke: "#ffffff", strokeWidth: 1 }} />,
    labelText: "Live Chat",
  },
  {
    icon: <GroupsOutlinedIcon sx={{ stroke: "#ffffff", strokeWidth: 0.4 }} />,
    labelText: "Team Chat",
  },
  {
    icon: <AdsClickRoundedIcon sx={{ stroke: "#ffffff", strokeWidth: 0.6 }} />,
    labelText: "Traffic sources",
  },
  {
    icon: <AppsOutlinedIcon sx={{ stroke: "#ffffff", strokeWidth: 0.6 }} />,
    labelText: "Applications",
  },
];
export default orgSettingsItems;
