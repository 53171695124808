import React from "react";
import view from "./Overview.module.scss";
import Summary from "./Summary";
import Perfomance from "./Perfomance";
import BarChart from "./Compare";
import Piechart from "./Piechart";

const Overview = ({emailAccountId}) => {
  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };
  
  const getLast7Days = () => {
    const today = new Date();
    const endDate = formatDate(today); 
    const startDate = formatDate(new Date(today.setDate(today.getDate() - 7))); 
    return { startDate, endDate };
  };

  return (
    <div>
      <div className={view.Summary}>
        <Summary emailAccountId={emailAccountId} getLast7Days={getLast7Days}/>
      </div>
      <div>
        <Perfomance emailAccountId={emailAccountId} getLast7Days={getLast7Days}/>
      </div>
      <div>
        <Piechart emailAccountId={emailAccountId} getLast7Days={getLast7Days}/>
      </div>
      <div>
        <BarChart emailAccountId={emailAccountId} getLast7Days={getLast7Days}/>
      </div>
    </div>
  );
};

export default Overview;
