import React from 'react'
import Style from "../../../Style/Common.module.css"
import upwardImg from "../../../Images/OverView/Vector.png"
const LeaderBoardStatus = (props) => {
  return (
    <div className={`${Style.tableCellColumnSplit} ${Style.flexJustify}`}>
    <img src={upwardImg} alt="profile" />
  </div>
  )
}

export default LeaderBoardStatus