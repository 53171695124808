import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export default function Loader() {

  const styles = {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <div id="common" style={styles}>
      <CircularProgress color="inherit" />
    </div>
  );
}
