import React from "react";
import Grid from "@mui/material/Grid";
import { GridData } from "../Utils/GridData";
const KPIGrid = ({ data }) => {
  return (
    <Grid container spacing={2}>
      {GridData.map((gridData) => (
        <Grid item sx={4} md={4} sm={12} key={gridData.key} spacing={2}>
          {gridData.component}
        </Grid>
      ))}
    </Grid>
  );
};

export default KPIGrid;
