import axios from "axios";
import toast from "react-hot-toast";

export const uploadFile = (
  file,
  messageId,
  updateMessageStatus,
  sendMessage
) => {
  let formdata = new FormData();
  formdata.append("file", file);

  updateMessageStatus(messageId, { uploadStatus: true });

  return axios
    .post(
      `${process.env.REACT_APP_TEAM_CHAT_BASEURL}/api/v1/messages/upload/file`,
      formdata,
      {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          updateMessageStatus(messageId, { progress });
        },
      }
    )
    .then((result) => {
      sendMessage(true, result.data, messageId);
      updateMessageStatus(messageId, {
        uploadStatus: false,
        mimeType: result.data.mimetype,
        fileName: result.data.fileName,
        size: result.data.size,
        originalKey: result.data.originalKey,
        thumbnailkey: result.data.thumbnail_key,
        thumbnailUrl: result.data.thumbnailUrl,
        originalUrl: result.data.originalUrl,
        content: "",
        ContentType: "file",
      });
    })
    .catch((err) => {
      toast.error(err.response?.data?.message || "File upload failed");
      updateMessageStatus(messageId, { uploadStatus: false });
    });
};
