
import { Button as MuiButton } from '@mui/material';
import PropTypes from "prop-types"

function Button({ children, style, ...props }) {
  const defaultStyles = {
    backgroundColor: '#000AFF',
    boxShadow: '0px 4px 4px 0px #00000040',
    color: "white"
  };

  return (
    <MuiButton style={{ ...defaultStyles, ...style }} {...props}>
      {children}
    </MuiButton>
  );
}
Button.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};


export default Button;
