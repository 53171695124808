import {
  Box,
  Typography,
  FormControl,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";

const InitiateButton = {
  fontSize: "14px",
  color: "#ffff",
  fontWeight: 500,
  borderRadius: "3px",
  textTransform: "capitalize",

  marginRight: "8px",
};

const cancelButton = {
  fontSize: "14px",
  color: "black",
  fontWeight: 500,
  borderRadius: "3px",
  textTransform: "capitalize",
  background: "#ffff",
  padding: "4px",
  border: "1px solid #ffffff00",
};

const GroupName = ({
  groupName,
  setGroupName,
  users,
  loading,
  handleGroupCreate,
}) => {
  const enable = groupName && users.length > 1;
  return (
    <Box sx={{ background: "#f4f4f4" }} padding={1}>
      <Typography component={"p"} fontWeight={500} fontSize={theme => theme.typography.teamChatTitle.fontSize}>
        Group Name
      </Typography>
      <Box width={"100%"} py={1}>
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            placeholder="Group Name...."
            onChange={(e) => {
              setGroupName(e.target.value);
            }}
            value={groupName}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {groupName.length === 0 ? (
                    <SearchIcon />
                  ) : (
                    <CloseIcon
                      onClick={() => setGroupName("")}
                      sx={{
                        cursor: "pointer",
                        background: "#acacac42",
                        borderRadius: "3px",
                      }}
                    />
                  )}
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-input": {
                padding: "8px",
                fontSize: "14px",
              },
            }}
          />
        </FormControl>
      </Box>
      <Box
        p={1}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        <LoadingButton
          style={{
            ...InitiateButton,
            background: enable ? "#0078d4" : "grey",
          }}
          disabled={!enable}
          loading={loading}
          onClick={handleGroupCreate}
        >
          Initiate Group
        </LoadingButton>
        <Button style={cancelButton}>Cancel</Button>
      </Box>
    </Box>
  );
};

export default GroupName;
