import React from "react";
import CustomActivityLayOut from "../Components/CustomActivityLayOut";
import { WithHoc } from "../Components/WithHoc";
import Style from "../../Style/Common.module.css";
import ContactCreatedGrid from "./Components/ContactCreatedGrid";
const ContactsCreatedLayOut = () => {
  const ContactsCreatedLayout = WithHoc(CustomActivityLayOut, ContactCreatedGrid);
  return (
    <div className={Style.commonPadding}>
      <ContactsCreatedLayout  title={"Contacts Created Over Time"} />
    </div>
  );
};

export default ContactsCreatedLayOut;
