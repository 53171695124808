import React from "react";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",

    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));
const ProfileUpload = ({
  uploadref,
  updateProfileFun,
  path,
  srcUrl,
  onlogochange,
}) => {
  return (
    <StyledBadge
      overlap="circular"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      variant="dot"
    >
      <div
        style={{
          borderRadius: "50%",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#D9D9D9",
          width: "100px",
          height: "100px",
        }}
        onClick={updateProfileFun}
        disableRipple
      >
        {path ? (
          <img
            src={srcUrl}
            alt="profile pic"
            style={{ borderRadius: "100px" }}
            className="image"
          />
        ) : (
          <PersonOutlineIcon style={{ fontSize: 80, color: "#000" }} />
        )}
        <input
          type="file"
          ref={uploadref}
          accept="image/*"
          style={{ display: "none" }}
          onChange={onlogochange}
          hidden
        />
      </div>
    </StyledBadge>
  );
};

export default ProfileUpload;
