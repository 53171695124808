import React from "react";
import Button from "@mui/material/Button";
import MsgIcon from "../../../Images/LatestRating/MessageIcon.png";
const buttonStyle = {
  height: "32px",
  background: "rgba(158, 225, 239, 1)",
  fontWeight: 500,
  fontSize: "13px",
  textTransform: "capitalize",
  color: "rgba(255, 255, 255, 0.8)",
  borderRadius: "2px",
};
const MessageUsButton = () => {
  return (
    <Button
      id="basic-button"
      //   onClick={handleClick}
      style={buttonStyle}
      sx={{
        "& .MuiButton-startIcon": {
          margin: "5px !important",
        },
      }}
      startIcon={<img src={MsgIcon} alt="msgIcon" />}
    >
      Message Us
    </Button>
  );
};

export default MessageUsButton;
