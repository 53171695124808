import DialMeter from "../Components/DialMeter";
import MultiBar from "../Components/MultiBar";
import SingleBar from "../Components/SinglrBar";
import TrafficMeter from "../Components/TrafficMeter";

export const GridData = [
  {
    id: "#dialGauge",
    component: <DialMeter />,
    type: "Dial Gauge",
    title: "DEALS TARGET THIS YEAR",
  },
  {
    id: "#trafficGauge",
    component: <TrafficMeter />,
    type: "Traffic Lights",
    title: "REVENUE WON THIS QUARTER",
  },
  {
    id: "#singleBar",
    component: <SingleBar />,
    type: "Single Bar",
    title: "SALES Q4- TARGET",
  },
  {
    id: "#multipleBar",
    component: <MultiBar />,
    type: "Multiple Bar",
    title: "REGION WISE TARGET",
  },
]
