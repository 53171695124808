import React from 'react'
import Style from "../../../Style/ChatNow/ChatNow.module.css";

const Description = (props) => {
  return (
    <div className={Style.descriptionStyle}>{props.desc}</div>

  )
}

export default Description