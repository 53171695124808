import React from 'react'
import Styles from "../../components/Dashboard.module.scss";
const RevenueContainer = ({ title }) => {
    return (
        <div className={Styles.chartmainwrapper}>
          <div className={Styles.title}>{title}</div>
          <div className={Styles.noDataWrapper}>
          <span className={Styles.noDataText}>No Data Found</span>
          </div>
        </div>
      );
    };

export default RevenueContainer
