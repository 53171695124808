import React from "react";
import TableCell from "./TableCell";
import Style from "../../Style/Common.module.css";
import { WithHoc } from "./WithHoc";
import LeaderBoardStatus from "../Conversation/Components/LeaderBoardStatus";

const CustomTable = (props) => {
  const CellWithStatus = WithHoc(TableCell,LeaderBoardStatus);
  return (
    <>
      {props.children}
      <table className={Style.CommonWidth}>
        <tbody>
          {props.isStatus
            ? props.tableData.map((data) => (
                <CellWithStatus
                  key={`${data.id}-${data.userName}`}
                  id={data.id}
                  userName={data.userName}
                  convo={data.convo}
                />
              ))
            : props.tableData.map((data) => (
                <TableCell
                  key={`${data.id}-${data.userName}`}
                  id={data.id}
                  userName={data.userName}
                  convo={data.convo}
                />
              ))}
        </tbody>
      </table>
    </>
  );
};

export default CustomTable;
