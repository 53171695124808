import React from "react";
import TableCell from "./TableCell";
import Style from "../../../Style/Common.module.css";
import tempImg from "../../../Images/ChatNow/TempImg.png";

const RatingTable = () => {
  const tableData = [
    {
      id: "#1",
      userName: "User1",
      email: "user1@gmail.com",
      profilePic: tempImg,
      comment: "No Comments Left",
      rating: 4,
      reportDate: "21 sep",
    },
    {
      id: "#2",
      userName: "User2",
      email: "user2@gmail.com",
      profilePic: tempImg,
      comment: "No Comments Left",
      rating: 3.5,
      reportDate: "1 sep",
    },
    {
      id: "#3",
      userName: "User3",
      email: "user3@gmail.com",
      profilePic: tempImg,
      comment: "No Comments Left",
      rating: 5,
      reportDate: "13 Apr",
    },
    {
      id: "#4",
      userName: "User4",
      email: "user4@gmail.com",
      profilePic: tempImg,
      comment: "No Comments Left",
      rating: 3.5,
      reportDate: "10 Feb",
    },
    {
      id: "#5",
      userName: "User5",
      email: "user5@gmail.com",
      profilePic: tempImg,
      comment: "No Comments Left",
      rating: 4.5,
      reportDate: "1 Dec",
    },
  ];
  return (
    <table className={`${Style.CommonWidth} ${Style.ratingTable}`}>
      <tr
        className={`${Style.commonHeight} ${Style.tableTitle} ${Style.noTopBorder} ${Style.noSideBorder}`}
      >
        <th></th>
        <th>Full Name</th>
        <th>Score</th>
        <th>Comment</th>
        <th>Reported On</th>
        <th></th>
      </tr>
      <tbody>
        {tableData.map((data) => (
          <TableCell data={data} />
        ))}
      </tbody>
    </table>
  );
};

export default RatingTable;
