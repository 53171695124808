import React, { useEffect, useState } from "react";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Antswitch from "./Antswitch";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import {
  intervalRamUp,
  updateRampUpStatus,
} from "../../../webservices/sequence";
import { ramUpData } from "../../../store/userStore";
import { Checkbox, Slider, TextField } from "@mui/material";

function SendingSettings({ id }) {
  console.log("ppppppppp", id);

  const queryClient = useQueryClient();
  const [data, setData] = useState([]);
  const { value, setValue } = ramUpData();
  const [values, setValues] = React.useState([20, 37]);

  const handleWarmupChange = (event, newValue) => {
    setValues(newValue);
  };
  const emailAccounts = useQuery({
    queryKey: ["intervalRamUp", id],
    queryFn: () => intervalRamUp(id),
  });

  useEffect(() => {
    if (emailAccounts?.data?.data) {
      setData(emailAccounts?.data?.data);
    }
  }, [emailAccounts?.data?.data]);

  console.log("bbbbuuuu", value);

  const mutation = useMutation({
    mutationFn: updateRampUpStatus,
    onSuccess: (data) => {
      alert("updated");
      queryClient.invalidateQueries("intervalRamUp");
    },
    onError: (error) => {
      console.error("Error adding sequence:", error);
    },
  });

  const handleChange = (event) => {
    const newStatus = event.target.checked ? true : false;
    const data = {
      id: id,
      status: newStatus,
    };
    mutation.mutate(data);
  };

  const valueChange = (e) => {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: value }));
  };

  useEffect(() => {
    setValue(data);
  }, [data]);

  console.log("bsrrrrrr", data);

  return (
    <div
      style={{
        border: "1px solid #e8eff5",
        borderRadius: ".25rem",
        padding: "10px 1.5rem 1.5rem",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        wordWrap: "break-word",
        backgroundColor: "#fff",
        backgroundClip: "border-box",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p
          style={{
            paddingBottom: "1.5rem",
            fontSize: "14px",
            fontWeight: 500,
          }}
        >
          Warming up an IP address involves sending low volumes of email on your
          dedicated IP and then systematically increasing your email volume over
          a period of time
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "7px",
            paddingBottom: "30px",
            borderBottom: "1px solid #e5e6ef",
          }}
        >
          <Antswitch checked={data?.ramp_up} onChange={handleChange} />
          <p
            style={{
              fontWeight: "400",
              fontSize: ".775rem",
              lineHeight: "1.0625rem",
              color: "#262626",
            }}
          >
            Email Warmup Enabled
          </p>
        </div>
      </div>
      <div
        style={{
          flex: "1 1 auto",
          paddingTop: "20px",
          paddingBottom: "30px",
          borderBottom: "1px solid #e5e6ef",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                color: "#1f2937",
                fontSize: ".875rem",
                fontWeight: "600",
                lineHeight: "1.25rem",
                alignItems: "center",
                display: "flex",
              }}
            >
              Total number of emails per day
            </span>
            <span
              style={{
                color: "#6b7280",
                fontSize: ".75rem",
                fontWeight: "400",
                lineHeight: "1.125rem",
                marginTop: ".25rem",
              }}
            >
              Maximum number of emails that could be sent via this email account
              per day.
            </span>
          </div>
          <div style={{ position: "relative" }}>
            <input
              type="number"
              name="daily_limit"
              value={data.daily_limit}
              onChange={valueChange}
              style={{
                color: "#595959",
                fontSize: "14px",
                lineHeight: "1.5715",
                backgroundColor: "#fff",
                transition: "all .3s",
                display: "inline-block",
                border: "1px solid #d9d9d9",
                borderRadius: ".25rem",
                width: "5.625rem",
              }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: "1.5625rem",
          paddingBottom: "30px",
          borderBottom: "1px solid #e5e6ef",
        }}
      >
        <div style={{ display: "flex" }}>
          <span
            style={{
              fontSize: ".875rem",
              fontWeight: "600",
              lineHeight: "22px",
              color: "#1f2937",
              marginRight: "1rem",
            }}
          >
            Time interval between two emails (in seconds)
          </span>
          <InfoOutlinedIcon fontSize="small" style={{ color: "#595959" }} />
        </div>
        <div
          style={{
            marginTop: "1.25rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ position: "relative" }}>
            <input
              type="number"
              name="interval_start"
              value={data.interval_start}
              onChange={valueChange}
              style={{
                color: "#595959",
                fontSize: "14px",
                lineHeight: "1.5715",
                backgroundColor: "#fff",
                transition: "all .3s",
                display: "inline-block",
                border: "1px solid #d9d9d9",
                borderRadius: ".25rem",
                width: "5.625rem",
              }}
            />
          </div>
          <span
            style={{
              marginLeft: ".5rem",
              marginRight: ".5rem",
              color: "#595959",
            }}
          >
            -
          </span>
          <div style={{ position: "relative" }}>
            <input
              type="number"
              name="interval_end"
              value={data?.interval_end}
              onChange={valueChange}
              style={{
                color: "#595959",
                fontSize: "14px",
                lineHeight: "1.5715",
                backgroundColor: "#fff",
                transition: "all .3s",
                display: "inline-block",
                border: "1px solid #d9d9d9",
                borderRadius: ".25rem",
                width: "5.625rem",
              }}
            />
          </div>
          <span
            style={{
              fontWeight: "500",
              fontSize: ".75rem",
              lineHeight: "20px",
              marginLeft: ".5rem",
              color: "#595959",
            }}
          >
            Seconds
          </span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "1.5rem",
          paddingBottom: "30px",
          borderBottom: "1px solid #e5e6ef",
        }}
      >
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "1.5rem",
            gap: ".5rem",
          }}
        >
          <Antswitch checked={data?.ramp_up} onChange={handleChange} />
          <h3
            style={{
              fontWeight: "600",
              fontSize: ".875rem",
              lineHeight: "1.0625rem",
              color: "#262626",
            }}
          >
            Daily Ramp-Up
          </h3>
        </div> */}
        <span
          style={{
            color: "#6b7280",
            fontSize: ".75rem",
            fontWeight: "400",
            lineHeight: "1.125rem",
            marginTop: ".25rem",
            marginBottom:".3rem"
          }}
        >
          Rampup increment value per day (suggested 5 per day)
        </span>
        <input
                type="number"
                name="ramup_increased"
                value={data?.ramup_increased}
                onChange={valueChange}
                style={{
                  color: data?.ramp_up ? "#595959" : "#bfbfbf",
                  backgroundColor: data?.ramp_up ? "#fff" : "#f5f5f5",
                  fontSize: "14px",
                  lineHeight: "1.5715",
                  border: "1px solid #d9d9d9",
                  borderRadius: ".25rem",
                  width: "4.625rem",
                }}
              />
      </div>
      <div
        style={{
          paddingTop: "20px",
          paddingBottom: "20px",
          borderBottom: "1px solid #e5e6ef",
        }}
      >
        <span
          style={{
            color: "#1f2937",
            fontSize: ".875rem",
            fontWeight: "600",
            lineHeight: "1.25rem",
            alignItems: "center",
            display: "flex",
          }}
        >
          Randomise number of warm up emails per day
        </span>

        <span
          style={{
            color: "#6b7280",
            fontSize: ".75rem",
            fontWeight: "400",
            lineHeight: "1.125rem",
            marginTop: ".25rem",
          }}
        >
          Maximum number of emails that could be sent via this email account per
          day
        </span>
        <Slider
          getAriaLabel={() => "Temperature range"}
          value={values}
          onChange={handleWarmupChange}
          valueLabelDisplay="auto"
          size="small"
          sx={{ width: "60%" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingTop: "20px",
          paddingBottom: "30px",
          borderBottom: "1px solid #e5e6ef",
        }}
      >
        <span
          style={{
            color: "#1f2937",
            fontSize: ".875rem",
            fontWeight: "600",
            lineHeight: "1.25rem",
            alignItems: "center",
            display: "flex",
          }}
        >
          Reply Rate (%)
        </span>
        <span
          style={{
            color: "#6b7280",
            fontSize: ".75rem",
            fontWeight: "400",
            lineHeight: "1.125rem",
            marginTop: ".25rem",
          }}
        >
          Suggested - 20, Maximum - 100
        </span>
        <TextField size="small" sx={{ marginTop: "5px", width: "50%" }} />
      </div>
      <div
        style={{
          flex: "1 1 auto",
          paddingTop: "20px",
          paddingBottom: "30px",
          borderBottom: "1px solid #e5e6ef",
        }}
      >
        <div style={{ display: "flex" }}>
          <div>
            <input type="checkbox" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "5px",
            }}
          >
            <span
              style={{
                color: "#1f2937",
                fontSize: ".875rem",
                fontWeight: "600",
                lineHeight: "1.25rem",
                alignItems: "center",
                display: "flex",
              }}
            >
              Enable Auto-adjust warmup/sending ratio
            </span>

            <span
              style={{
                color: "#6b7280",
                fontSize: ".75rem",
                fontWeight: "400",
                lineHeight: "1.125rem",
                marginTop: ".25rem",
              }}
            >
              Would you like us to adjust the warmups to optimize for email
              deliverability.
            </span>
          </div>
        </div>
      </div>
      <div
        style={{
          flex: "1 1 auto",
          paddingTop: "20px",
          paddingBottom: "30px",
          borderBottom: "1px solid #e5e6ef",
        }}
      >
        <div style={{ display: "flex" }}>
          <div>
            <input type="checkbox" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "5px",
            }}
          >
            <span
              style={{
                color: "#1f2937",
                fontSize: ".875rem",
                fontWeight: "600",
                lineHeight: "1.25rem",
                alignItems: "center",
                display: "flex",
              }}
            >
              Warmup the Custom Domain Tracking Link
            </span>

            <span
              style={{
                color: "#6b7280",
                fontSize: ".75rem",
                fontWeight: "400",
                lineHeight: "1.125rem",
                marginTop: ".25rem",
              }}
            >
              We will warmup your custom domain tracking link for better
              deliverability.
            </span>
          </div>
        </div>
      </div>
      <div
        style={{
          flex: "1 1 auto",
          paddingTop: "20px",
          paddingBottom: "30px",
          borderBottom: "1px solid #e5e6ef",
        }}
      >
        <div style={{ display: "flex" }}>
          <div>
            <input type="checkbox" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "5px",
            }}
          >
            <span
              style={{
                color: "#1f2937",
                fontSize: ".875rem",
                fontWeight: "600",
                lineHeight: "1.25rem",
                alignItems: "center",
                display: "flex",
              }}
            >
              Send warmup emails on weekdays
            </span>

            <span
              style={{
                color: "#6b7280",
                fontSize: ".75rem",
                fontWeight: "400",
                lineHeight: "1.125rem",
                marginTop: ".25rem",
              }}
            >
              To emulate human sending patterns, Smartlead AI will automatically
              pause sending warmup emails on weekends
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SendingSettings;
