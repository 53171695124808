import React from "react";
import Grid from "@mui/material/Grid";
import Style from "../../Style/Common.module.css";
import LeaderBoard from "./Components/LeaderBoard";
import CustomActivityLayOut from "../Components/CustomActivityLayOut";
import { WithHoc } from "../Components/WithHoc";
import ConversionGraph from "./Components/ConversionGraph";
const ConversationsLayOut = () => {
  const ConvoActivtiyLayOut = WithHoc(CustomActivityLayOut, ConversionGraph);
  return (
    <Grid container spacing={2} className={Style.commonPadding}>
      <Grid item xs={12} md={9} sm={12 }>
        <ConvoActivtiyLayOut title={"Conversation Activity"} />
      </Grid>
      <Grid item xs={12} md={3} sm={12} >
        <LeaderBoard />
      </Grid>
    </Grid>
  );
};

export default ConversationsLayOut;
