import { Box } from "@mui/system";
import React from "react";
import ProfileImage from "./Components/ProfileImage";
import { Divider } from "@mui/material";
import UserGroups from "./Components/UserGroups";
import Actions from "./Components/Actions";
import Media from "./Components/Media";

const UserProfile = () => {
  return (
    <Box height={"87%"} sx={{ overflowY: "auto" }}>
      <ProfileImage title={"Contact Info"} />
      <Divider />
      <Media />
      <Divider />
      <UserGroups />
      <Divider />
      <Actions />
    </Box>
  );
};

export default UserProfile;
   