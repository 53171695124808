import React, { useState } from "react";
import {
  autoUpdate,
  useFloating,
  useClick,
  useDismiss,
  offset,
  flip,
  size,
  useInteractions,
  FloatingPortal,
} from "@floating-ui/react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import BusinessIcon from "@mui/icons-material/Business";
import { useTheme } from "@mui/material/styles";

import Style from "../../../../Style/chatdropdown.module.css";

const ChatDropDown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  const OFFSET = 10;

  const { refs, floatingStyles, context, strategy } = useFloating({
    placement: "bottom-start",
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementMounted: autoUpdate,
    position: "center",
    middleware: [
      offset(OFFSET),
      flip(),
      size({
        apply: ({ availableHeight, elements }) => {
          Object.assign(elements.floating.style, {
            maxHeight: `${Math.max(50, availableHeight - OFFSET)}px`,
          });
        },
      }),
    ],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  const handleOptionClick = (data) => {
    setIsOpen(false);
  };

  const [hoveredItem, setHoveredItem] = useState(null);

  const handleMouseEnter = (item) => {
    setHoveredItem(item);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };
  const [openModule, setOpenModule] = useState(false);
  function handleModuleOpen() {
    setOpenModule(true);
    setIsOpen(false);
  }
  function handleModuleClose() {
    setOpenModule(false);
  }

  return (
    <>
      <div
        className={Style.dropDownWrapper}
        {...getReferenceProps({ ref: refs.setReference })}
      >
        <BusinessIcon />
        {/* <div>
          {value?.name?.length > 10
            ? `${value?.name.slice(0, 6)}...`
            : value?.name}
        </div> */}

        {isOpen ? (
          <KeyboardArrowUpIcon
            style={{
              color: "#0000007a",
              fontSize: theme.typography.teamChatIcon.fontSize,
            }}
          />
        ) : (
          <KeyboardArrowDownIcon
            style={{
              color: "#0000007a",
              fontSize: theme.typography.teamChatIcon.fontSize,
            }}
          />
        )}
      </div>

      {isOpen && (
        <FloatingPortal>
          <div
            className={Style.dropDownModal}
            {...getFloatingProps({
              ref: refs.setFloating,
              style: { ...floatingStyles, position: strategy },
            })}
          >
            {["available", "work", "busy", "invisible"].map((item, index) => (
              <div
                className={`${Style.menuItem}`}
                key={`${item?.id}-${index}`}
                onClick={() => handleOptionClick(item)}
                onMouseEnter={() => handleMouseEnter(item)}
                onMouseLeave={handleMouseLeave}
              >
                <div className={Style.avatarFlex}>
                  <span style={{ marginRight: "10px", width: "100px" }}>
                    {item}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </FloatingPortal>
      )}
    </>
  );
};

export default ChatDropDown;
