import React, { useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import toast from "react-hot-toast";
import CreateModule from "../../../../../CompanyDropDown/Components/CreateCompany";
// import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";
// import EditRoundedIcon from "@mui/icons-material/EditRounded";

const Info = () => {
  const [openModule, setOpenModule] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [image, setImage] = useState(null);
  const handleEdit = () => {
    setOpenModule(true);
    setIsEdit(true);
  };
  const handleModuleClose = () => {
    setOpenModule(false);
    setIsEdit(false);
  };
  const handleCopyPassword = () => {
    const emailId = document.getElementById("email").innerText;
    navigator.clipboard.writeText(emailId);
    toast.success(" Password Copied");
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const WorkspaceInfo = [
    { label: "Workspace Name", value: "Mounesh" },
    { label: "Email", value: "Mouni@gmail.com" },
    { label: "Gst No", value: "123456789" },
    { label: "Contact Person", value: "Contact Person" },
    { label: "TimeZone", value: "TimeZone" },
    { label: "Week Start", value: "Monday" },
    // { label: "Logo", value: "Logo" },
  ];
  const contactInfo = [
    { label: "Address", value: "Address" },
    { label: "City", value: "Namakkal" },
    { label: "State", value: "Tamil Nadu" },
    { label: "Postal Code", value: "637213" },
    { label: "Country/Region", value: "India" },
    { label: "Phone Number", value: "8549849401" },
  ];

  return (
    <Box
      sx={{
        padding: "35px 40px 10px",
        height: "68.3vh",
      }}
    >
      <Button
        sx={{
          position: "absolute",
          left: "80%",
          bgcolor: "var(--button-color)",
          ":hover": { bgcolor: "var(--button-color)" },
        }}
        variant="contained"
        onClick={handleEdit}
      >
        Edit
      </Button>

      <Box>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            marginBottom: "10px",
            padding: 0,
          }}
        >
          Workspace Information
        </Typography>

        {/* <Box
          sx={{
            height: "165px",
            width: "168px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#515151",
            cursor: "pointer",
            marginBottom: "10px",
          }}
        >
          {image ? (
            <img
              src={image}
              alt="Uploaded"
              style={{ width: "100%", height: "100%", borderRadius: "5px" }}
            />
          ) : (
            <label htmlFor="file-input">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <CloudUploadRoundedIcon
                  sx={{ fontSize: "100px", color: "#FFFFFF" }}
                />

                <Typography
                  sx={{
                    fontSize: "16px",
                    whiteSpace: "nowrap",
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  + Add Logo
                </Typography>
              </Box>
            </label>
          )}
          <input
            id="file-input"
            type="file"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </Box> */}

        <TableContainer>
          <Table>
            <TableBody>
              {WorkspaceInfo.map((info, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{ border: "none", paddingLeft: "0", width: "35%" }}
                  >
                    {info.label}
                  </TableCell>
                  <TableCell sx={{ border: "none" }}>
                    {info.label === "Email" ? (
                      <span style={{ display: "flex", alignItems: "center" }}>
                        {info.value}
                        <Tooltip title="Click to copy" arrow>
                          <ContentCopyIcon
                            onClick={handleCopyPassword}
                            sx={{
                              fontSize: "16px",
                              marginLeft: "7px",
                              color: "#2558BB",
                              cursor: "pointer",
                            }}
                          />
                        </Tooltip>
                      </span>
                    ) : (
                      info.value
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box sx={{ paddingTop: "20px" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            marginBottom: "10px",
            padding: 0,
          }}
        >
          Contact Information
        </Typography>

        <TableContainer>
          <Table>
            <TableBody>
              {contactInfo.map((info, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      border: "none",
                      paddingLeft: "0",
                      paddingRight: "0",
                      width: "35%",
                    }}
                  >
                    {info.label}
                  </TableCell>
                  <TableCell sx={{ border: "none" }}>{info.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <CreateModule
        open={openModule}
        handleClickClose={handleModuleClose}
        isEdit={isEdit}
      />
    </Box>
  );
};

export default Info;
