import React from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import downloadIcon from "../../../../img/downloadIcon.png";
const StyledTypo = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 500,
}));
const CustomCell = ({
  fieldVal,
  handleChange,
  err,
  helper,
  cellName,
  fieldName,
  isRequired,
  isMultiline,
  isDownload,
  isSelect,
  array,
  keyParam,
  valueParam,
  handleFileChange
}) => {

  return (
    <Grid
      item
      md={3}
      sm={6}
      xs={12}
      alignItems="center"
      justifyContent=" center"
    >
      <div>
        <div style={{ display: "flex" }}>
          <StyledTypo> {cellName}</StyledTypo>
          {isRequired ? (
            <StyledTypo style={{ color: "red" }}> *</StyledTypo>
          ) : (
            ""
          )}
        </div>
        <div>
          <TextField
            name={fieldName}
            value={fieldVal}
            onChange={handleChange}
            error={err}
            helperText={helper}
            size="small"
            id="standard-basic"
            fullWidth
            multiline={isMultiline}
            select={isSelect}
            SelectProps={{
              MenuProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}
            InputProps={{
              endAdornment: isDownload ? (
                <InputAdornment position="end" style={{ cursor: "pointer" }}>
                  <label htmlFor={`${fieldName}-file-input`}>
                    <img src={downloadIcon} alt="download icon" />
                  </label>
                  <input
                    type="file"
                    id={`${fieldName}-file-input`}
                    style={{ display: "none" }}
                    onChange={handleChange}
                  />
                </InputAdornment>
              ) : null,
            }}
          >
            {isSelect &&
              (array ?? []).map((item) => (
                <MenuItem key={item[keyParam] || item} value={item[valueParam] || item}>
                  {item[valueParam] || item}
                </MenuItem>
              ))}
          </TextField>
        </div>
      </div>
    </Grid>
  );
};

export default CustomCell;
