import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Divider,
} from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";

const groups = [
  {
    name: "Family",
    users: [
      "Alice",
      "Bob",
      "Charlie",
      "Alice",
      "Bob",
      "Charlie",
      "Alice",
      "Bob",
      "Charlie",
    ],
    avatar: "https://via.placeholder.com/40",
  },
  {
    name: "Work Team",
    users: ["David", "Eva", "Frank", "Grace"],
    avatar: "https://via.placeholder.com/40",
  },
  {
    name: "Friends",
    users: ["Hannah", "Ian", "Jack", "Kara", "Liam"],
    avatar: "https://via.placeholder.com/40",
  },
  {
    name: "Gym Buddies",
    users: ["Mia", "Nate", "Olivia"],
    avatar: "https://via.placeholder.com/40",
  },
];

const UserGroups = () => {
  return (
    <Box
      sx={{
        maxWidth: 600,
        margin: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h6"
        fontSize={12}
        color="#959FA3"
        paddingTop={1}
        paddingLeft={1}
      >
        Common Groups
      </Typography>
      <List sx={{ paddingTop: 0 }}>
        {" "}
        {groups.map((group, index) => (
          <React.Fragment key={group.name}>
            <ListItem>
              <ListItemAvatar>
                <Avatar src={group.avatar} sx={{ bgcolor: "primary.main" }}>
                  <GroupIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={group.name}
                secondary={
                  <Typography
                    variant="body2"
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      fontSize: "12px",
                      width: "100%",
                    }}
                  >
                    {group.users.join(", ")}
                  </Typography>
                }
              />
            </ListItem>
            {index < groups.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default UserGroups;
