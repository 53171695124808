import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Label,
} from "recharts";
import Style from "../../../../Style/TopBar/Chart.module.css";

const data = [
  {
    name: "Page A",
    fill: "rgb(255, 127, 79)",
    pv: 2,
    amt: 2400,
  },
  {
    name: "Page B",
    fill: "rgb(255, 165, 0)",
    pv: 4,
    amt: 2210,
  },
];

const BarGraph = () => {
  return (
    <div className={Style.graphWrapper}>
      <ResponsiveContainer width="100%" height="70%">
        <BarChart
          width={350}
          height={200}
          data={data}
          margin={{
            top: 20,
            right: 20,
            left: 15,
            bottom: 15,
          }}
          style={{ fontsize: "11px" }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="name" fontSize={10}>
            {" "}
            <Label
              value="Created by"
              fontSize={12}
              offset={0}
              position="bottom"
            />{" "}
          </XAxis>
          <YAxis
            fontSize={10}
            label={{
              value: "Record Count",
              angle: -90,
              position: "Center",
              fontSize: "12px",
            }}
          />

          <Bar
            isAnimationActive={true}
            animationDuration={1000}
            barSize={70}
            dataKey="pv"
            fill="#8884d8"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarGraph;
