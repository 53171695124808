import React from "react";
import { Box, Checkbox, IconButton, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoneIcon from "@mui/icons-material/Done";
import { formateHour } from "../../../../CustomHooks/formateDates";
import HoverDropDown from "./HoverDropDown";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { ImageFilter } from "../../../../Utils/imageUtils";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import CircularProgress from "@mui/material/CircularProgress";
import GradeIcon from "@mui/icons-material/Grade";
import ReplyMessage from "./ReplyMessage";

const SendMessage = ({
  data,
  hoverId,
  handleOpen,
  handleClose,
  open,
  anchorEl,
  setHoverId,
  messageOptions,
  handleDownloadFile,
  isForward,
  forwardMsgId,
  handleCheckboxChange,
}) => {
  function renderIcon(status) {
    switch (status) {
      case "sent":
        return (
          <DoneIcon
            sx={{ fontSize: "11px", color: "black", marginLeft: "2px" }}
          />
        );
      case "read":
        return (
          <DoneAllIcon
            sx={{ fontSize: "11px", color: "#87CEEB", marginLeft: "2px" }}
          />
        );
      case "delivered":
        return (
          <DoneAllIcon
            sx={{ fontSize: "11px", color: "grey", marginLeft: "2px" }}
          />
        );
      case "pending":
        return (
          <AccessTimeIcon
            sx={{ fontSize: "11px", color: "grey", marginLeft: "2px" }}
          />
        );
      default:
        return <></>;
    }
  }

  const isAudio = data?.mimeType?.startsWith("audio/");
  const isVideo = data?.mimeType?.startsWith("video/");
  const isImage = data?.mimeType?.startsWith("image/");
  const isFile = isImage || isAudio || isVideo || data.ContentType === "file";
  const isText = data.ContentType === "text";
  console.log(
    "fileupload",
    hoverId === data._id && !data.is_deleted && !data.isReplyMessage
  );

  return (
    <Box
      display={"flex"}
      justifyContent={"flex-end"}
      paddingRight={"14px"}
      paddingTop={2}
    >
      {isForward && (
        <Box>
          <Checkbox
            checked={forwardMsgId.includes(data._id)}
            onChange={(e) => handleCheckboxChange(e,data)}
            
            size="small"
            sx={{ marginRight: "8px" }}
          />
        </Box>
      )}

      <Box
        height={"auto"}
        sx={{ background: "#FFFFFF" }}
        width={"60%"}
        p={1}
        border={"1px solid rgba(0, 0, 0, 0.1)"}
        borderRadius={1}
        onMouseEnter={() => setHoverId(data._id)}
        onMouseLeave={() => setHoverId("")}
      >
        {hoverId === data._id && !data.is_deleted && data.isReplyMessage && (
          <Box display={"flex"} justifyContent={"flex-end"}>
            <HoverDropDown
              open={open}
              handleClose={handleClose}
              handleOpen={handleOpen}
              anchorEl={anchorEl}
              messageOptions={messageOptions}
              setHoverId={setHoverId}
              msgData={data}
              text={isText}
            />
          </Box>
        )}
        {data.isReplyMessage && <ReplyMessage data={data} />}

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection="column"
        >
          {isImage ? (
            <Box display={"flex"} flexDirection={"column"}>
              {hoverId === data._id &&
                !data.is_deleted &&
                !data.isReplyMessage && (
                  <Box display={"flex"} justifyContent={"flex-end"}>
                    <HoverDropDown
                      open={open}
                      handleClose={handleClose}
                      handleOpen={handleOpen}
                      anchorEl={anchorEl}
                      messageOptions={messageOptions}
                      setHoverId={setHoverId}
                      msgData={data}
                      text={isText}
                    />
                  </Box>
                )}
              <img
                alt="server data"
                src={data?.originalUrl}
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </Box>
          ) : isAudio ? (
            <Box display={"flex"} alignItems={"center"}>
              <audio controls style={{ width: "100%" }}>
                <source src={data?.originalUrl} type={data.mimeType} />
                Your browser does not support the audio element.
              </audio>
            </Box>
          ) : isVideo ? (
            <video controls style={{ width: "100%" }}>
              <source src={data?.originalUrl} type={data.mimeType} />
              Your browser does not support the video element.
            </video>
          ) : isFile ? (
            <Box display={"flex"} alignItems={"center"}>
              <img
                alt="file icon"
                src={ImageFilter(data.mimeType)}
                style={{ width: 24, height: 24, marginRight: "8px" }}
              />
              <Typography color={"#000000"} fontWeight={500} fontSize={11}>
                {data?.fileName}
              </Typography>
              {hoverId === data._id &&
                !data.is_deleted &&
                !data.isReplyMessage && (
                  <HoverDropDown
                    open={open}
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    anchorEl={anchorEl}
                    messageOptions={messageOptions}
                    msgData={data}
                    text={isText}
                  />
                )}
            </Box>
          ) : (
            <Box display={"flex"} justifyContent={"space-between"}>
              {" "}
              <Typography color={"#000000"} fontWeight={500} fontSize={14}>
                {data?.content}
              </Typography>
              {hoverId === data._id &&
                !data.is_deleted &&
                !data.isReplyMessage && (
                  <HoverDropDown
                    open={open}
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    anchorEl={anchorEl}
                    messageOptions={messageOptions}
                    type={isText}
                    textMessage={data.content}
                    msgData={data}
                    text={isText}
                  />
                )}
            </Box>
          )}
        </Box>
        <Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box>
              {" "}
              {data?.uploadStatus ? (
                <CircularProgress value={data?.progress} size={12} />
              ) : (
                ""
              )}
            </Box>

            <Box display={"flex"} alignItems={"center"}>
              {hoverId === data._id &&
                !data.is_deleted &&
                isFile &&
                !data?.uploadStatus && (
                  <IconButton
                    size="small"
                    sx={{ ml: 1 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDownloadFile(data.originalKey, data.fileName);
                    }}
                  >
                    <DownloadIcon
                      fontSize="small"
                      sx={{ color: "black", fontSize: "14px" }}
                    />
                  </IconButton>
                )}
              <Box display={"flex"} alignItems={"center"}>
                {
                  false &&   <GradeIcon sx={{ fontSize: "12px",margin:"0px 2px" }} />
                }
              
                <Typography color={"#D9D9D9"} fontWeight={500} fontSize={10}>
                  {formateHour(data?.time)}
                </Typography>
              </Box>

              {renderIcon(data?.messageStatus)}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SendMessage;
