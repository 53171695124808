import React from "react";
import Grid from "@mui/material/Grid";
import ShortCutGraph from "./ShortCutGraph";
import ShortCutFilter from "./ShortCutFilter";
import Style from "../../../Style/Common.module.css"
const ShortCutGrid = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8} sm={12} className={Style.containerMinHeight} >
        <ShortCutGraph />
      </Grid>
      <Grid item xs={12} md={4} sm={12}>
        <ShortCutFilter />
      </Grid>
    </Grid>
  );
};

export default ShortCutGrid;
