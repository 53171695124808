import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Label,
  Legend,
} from "recharts";

const BarGraph = ({ apidata,isLoading }) => {
  if (!Array.isArray(apidata) || apidata.length === 0) {
    return <div>No data available for the chart.</div>; 
  }
  const data = apidata.map(item => ({
    time: item.time, 
    sent: isLoading? 0 : item.totalSent || 0,
    replied: isLoading? 0 : item.totalReplied || 0, 
    savedFromSpam: isLoading? 0 : item.totalSavedFromSpam || 0, 
  }));

  return (
    <div style={{ height: "270px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 10,
            left: 5,
            bottom: 15,
          }}
          style={{ fontSize: "11px" }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="time" fontSize={10}>
            <Label fontSize={12} offset={0} position="bottom" />
          </XAxis>
          <YAxis
            fontSize={10}
            axisLine={false}
            label={{
              angle: -90,
              position: "Center",
              fontSize: "12px",
            }}
          />
          <Legend fontSize={10} />
          {/* Stacked Bars */}
          <Bar
            dataKey="sent"
            name="Sent"
            stackId="a"
            fill="#3792FE"
            isAnimationActive={true}
            animationDuration={1000}
            barSize={35}
          />
          <Bar
            dataKey="replied"
            name="Replied"
            stackId="a"
            fill="#FE37C6"
            isAnimationActive={true}
            animationDuration={1000}
            barSize={35}
          />
          <Bar
            dataKey="savedFromSpam"
            name="Saved from spam"
            stackId="a"
            fill="#CB4B4B"
            isAnimationActive={true}
            animationDuration={1000}
            barSize={35}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarGraph;
