import { IconButton } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import toast, { ToastBar, Toaster } from "react-hot-toast";

export const NDEToast = (props) => {

  return (
    <Toaster toastOptions={{
      // Define default options
      position: 'top-center',
      className: '',
      duration: 8000,

      // Default options for specific types
      success: {
        duration: 3000,
        style: {
          width: "auto",
          background: "green",
          color: "white"
        },
        iconTheme: {
          primary: 'green',
          secondary: 'white',
        }
      },
      error: {
        duration: 5000,
        style: {
          background: "red",
          color: "white"
        }
      }
    }}>
      {(t) => (
        <ToastBar toast={t}>
          {({ message }) => {
            return (
              <>
                {message}
                {t.closeButton && t.type !== 'loading' && (
                  <IconButton
                    sx={{
                      position: "absolute",
                      width: 18,
                      height: 18,
                      ml: 1.5,
                      color: t.type === "success" ? "green" : "red",
                      background: t.type === "success" ? "#DDECDD" : "#FBDBDE",
                      float: "right",
                      top: "-9px",
                      right: "-9px",
                      "&:hover": {
                        color: t.type === "success" ? "green" : "red",
                        background: t.type === "success" ? "#DDECDD" : "#FBDBDE"
                      }

                    }}
                    size={"small"}
                    onClick={() => toast.dismiss(t.id)}
                  >
                    <CloseIcon sx={{ fontSize: 14 }} />
                  </IconButton>
                )}
              </>
            )
          }}
        </ToastBar>
      )}
    </Toaster>
  )
}