import React, { useState } from 'react';
import { Typography } from "@mui/material";
import Button from "../../../components/Button";
import CloseIcon from '@mui/icons-material/Close';
import AddRoles from './AddRoles/AddRoles';
import Permissions from './Permissions/ViewPermissions'

function Roles({ handleClose }) {
  const [showAddRoles, setShowAddRoles] = useState(false);
  const [showPermissions, setShowPermissions] = useState(false);

  const handleAddRole = () => {
    setShowAddRoles(true);
  };
  const handleBackToRoles = () => {
    setShowAddRoles(false);
  };
  const handleViewPermissions = () => {
    setShowPermissions(true);
  };

  const handleBackToRolesFromPermissions = () => {
    setShowPermissions(false);
  };

  return (
    
    <div style={{
      height: "100%",
      overflowY: "auto"
    }}>
       {showAddRoles ? (
        <AddRoles handleBackToRoles={handleBackToRoles}  />
      ) : showPermissions ? (
        <Permissions handleBackToRoles={handleBackToRolesFromPermissions}  />
      ) : (
        <div style={{
          borderBottom: "1px solid rgba(0,0,0,0.1)",
          height: "10%"
        }}>
          <div style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            padding: "0px 8px"
          }}>
            <div style={{
              display: "flex",
              gap: "50%",
              width: "50%",
              alignSelf: "flex-end",
            }}>
              <Typography>
                Roles
              </Typography>
              <Typography>
                Campaigns
              </Typography>
            </div>
            <div style={{
              width: "50%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "15%",
              position: "relative"
            }}>
              {!showAddRoles && (
                <Button style={{ marginRight: "8%" }} onClick={handleAddRole}>
                  Add Roles
                </Button>
              )}
              <CloseIcon
                onClick={handleClose}
                style={{
                  width: "16px",
                  height: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  top: "2px",
                  right: "0",
                }}
              />
            </div>
          </div>
        </div>
      )}
      <div style={{  }}>
      {!showAddRoles && !showPermissions && (
          <div style={{ height: "90%", padding: "10px",display: "flex",gap:" 20%" }}>
            
            <Typography>
              Admin
            </Typography>
            <Typography style={{
              textDecoration: "underline",
              color: "grey"
            }}
            onClick={handleViewPermissions}>
              View Permissions
            </Typography>
          </div>
        )}
      </div>
    </div>
    
  );
}

export default Roles;
