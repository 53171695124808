import React, { useState, useImperativeHandle, forwardRef } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Typography } from "@mui/material";
import QuillEditor from "../../../component/Editor/QuillEditor";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createStep, addVariant } from "../../../webservices/sequence";
import { SetBodyValue, SetSubjectValue } from "../../../store/templateStore";
import {
  snackbarStore,
  newCampaignSubject,
  setNewVariantidStore,
  setNewSequenceIdStore,
  sequenceNameStore,
  stepId,
  createStepStatus
} from "../../../store/userStore";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { saveStepTrigger } from "../../../store/userStore";

const NewEmailForm = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { newVariantid, setNewVariantid } = setNewVariantidStore();
  const { newSequenceId, setNewSequenceId } = setNewSequenceIdStore();
  const { status, setStatus } = createStepStatus();
  const [loading, setLoading] = React.useState(false);
  const { setSnackbarValue } = snackbarStore();
  const [showHide, setShowHide] = React.useState("1");
  const { setSequenceName } = sequenceNameStore();
  const { stepid, setStepId } = stepId();
  const headerstyle = {
    p: "2px 4px",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F1F1F1",
    color: "gray",
    height: "42px",
  };

  const mutation = useMutation({
    mutationFn: createStep,
    onSuccess: (data) => {
      setNewSequenceId(data?.data?.sequence_id);
      setNewVariantid(data?.data?.variant_id);
      setSequenceName(data?.data?.sequence_name);
      setStepId(data?.data?.step_id);
      // props.setValue(data?.data?.day);
      queryClient.invalidateQueries("stepList");
      setSnackbarValue({ open: true, severity: "success", message: "Saved" });
      setLoading(false);
      setStatus(false)
    },
    onError: (error) => {
      console.log("xxxxxxx", error);
      setLoading(false);
      setSnackbarValue({
        open: true,
        severity: "error",
        message: error.response.data.message,
      });
    },
  });

  const createVariant = useMutation({
    mutationFn: addVariant,
    onSuccess: (data) => {
      queryClient.invalidateQueries("stepList");
      setNewVariantid(data?.data?.variant_id);
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      alert("Error adding sequence");
    },
  });

  const [mergetype, setMergetype] = React.useState([]);
  const { emailSubject, setEmailSubject } = newCampaignSubject();
  const [preheader, setPreheader] = React.useState([]);
  const { body, SetBody } = SetBodyValue();

  const handleChangesub = (event) => {
    setStatus(true)
    setEmailSubject("subject", event.target.value);
  };

  const handleChangepre = (event) => {
    setStatus(true)
    setPreheader([event.target.value]);
  };

  const handleChangeMerge1 = (event) => {
    setStatus(true)
    const selectedMergeTag = event.target.value;

    // Append the selected merge tag to the subject
    const newSubject = `${emailSubject.subject}${selectedMergeTag}`.trim();
    setEmailSubject("subject", newSubject);

    // Optionally, clear the mergeTag state after selection
    setEmailSubject("mergeTag", selectedMergeTag);
  };

  const handleChangeMerge2 = (event) => {
    setStatus(true)
    setPreheader((pre) => [...pre, event.target.value]);
  };

  const subjectname = emailSubject.subject;
  const preheadername = preheader.join("");
  // const createStepFun = () => {
  //   setLoading(true);
  //   const stepdata = {
  //     sequence_id: newSequenceId,
  //     variant_id: newVariantid,
  //     day: value,
  //     same_thread: "false",
  //     subject: subjectname,
  //     preheader: preheadername,
  //     content: body,
  //   };
  //   mutation.mutate(stepdata);
  // };
  const createStepFun = () => {
    setLoading(true);
    const stepdata = {
      sequence_id: newSequenceId,
      day: props.value.day,
      same_thread: "false",
      subject: subjectname,
      preheader: preheadername,
      content: body,
    };
    const varinatdata = {
      sequence_id: newSequenceId,
      step_id: stepid,
      same_thread:true,
      subject: subjectname,
      preheader: preheadername,
      content: body,
      variant_id: newVariantid || props.data?.variant_id || props.variantData?.variant_id || "",
    };

    if (stepid) {
      createVariant.mutate(varinatdata);
    } else {
      mutation.mutate(stepdata);
    }
  };
  useImperativeHandle(ref, () => ({
    createStepFun,
  }));

  return (
    <div style={{ padding: "20px 20px" }}>
      <div className="EmailHeader">
        <Paper elevation={0} component="form" sx={headerstyle}>
          <p style={{ padding: "0 10px", fontSize: "14px" }}>Subject</p>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <InputBase
            className="inputbase"
            sx={{ ml: 1, flex: 1 }}
            onChange={handleChangesub}
            name="subject"
            value={emailSubject.subject}
            placeholder="Leave this empty to send as a followup step..."
          />
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
          ></IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              name="mergeTag"
              value={emailSubject.mergeTag}
              onChange={handleChangeMerge1}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              style={{
                height: "35px",
                backgroundColor: "#e5e7eb",
                fontSize: "12px",
              }}
            >
              <MenuItem value="">Merge Type</MenuItem>
              <MenuItem value="{{FirstName}}">FirstName</MenuItem>
              <MenuItem value="{{LastName}}">LastName</MenuItem>
              <MenuItem value="{{SubjectName}}">SubjectName</MenuItem>
            </Select>
          </FormControl>
        </Paper>
      </div>
      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginTop: "2px",
          marginLeft: "8px",
        }}
      >
        <span>hh</span>
        <span
          style={{
            paddingLeft: ".375rem",
            color: "#6b7280",
            fontSize: ".75rem",
            fontWeight: "500",
            lineHeight: "1rem",
          }}
        >
          Send this email in same thread as follow-up
        </span>
      </div> */}
      {showHide === "1" ? (
        <div
          className="helper-text"
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px",
            cursor: "pointer",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              color: "var(--button-color)",
              marginLeft: "auto",
            }}
            className="helper-text-btn"
            onClick={(e) => setShowHide("0")}
          >
            Set Pre Header{" "}
          </Typography>
          <HelpOutlineIcon
            style={{
              fontSize: "20px",
              paddingLeft: "5px",
              color: "var(--button-color)",
            }}
          />
        </div>
      ) : (
        <div className="PreHeader" style={{ marginBottom: "15px" }}>
          <Paper elevation={0} component="form" sx={headerstyle}>
            <p style={{ padding: "0 10px", fontSize: "14px" }}>Pre-Header</p>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              onChange={handleChangepre}
              name="subject"
              value={preheadername}
              placeholder="Please enter pre-header...."
            />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
            ></IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={mergetype}
                onChange={handleChangeMerge2}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                style={{
                  height: "35px",
                  backgroundColor: "#e5e7eb",
                  fontSize: "12px",
                }}
              >
                <MenuItem value="">Merge Type</MenuItem>
                <MenuItem value="{{FirstName}}">FirstName</MenuItem>
                <MenuItem value="{{LastName}}">LastName</MenuItem>
                <MenuItem value="{{SubjectName}}">SubjectName</MenuItem>
              </Select>
            </FormControl>
          </Paper>
        </div>
      )}
      <div style={{ width: "100%" }}>
        <div
          style={{
            width: "100%",
            height:"calc(100vh - 360px);",
            border: "1px solid var(--light-border-color)",
          }}
        >
          <QuillEditor data={props?.data?.content || ""} />
        </div>
      </div>

    <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          border: "1px solid var(--light-border-color)",
          padding: "10px 20px 15px 30px",
        }}
      >
          {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px 20px 15px 30px",
            gap: "5px",
          }}
        >
          <Typography sx={{ fontSize: "14px" }}>Send After</Typography>
          <TextField
            size="small"
            sx={{ width: "105px", paddingLeft: "7px" }}
            value={props.value}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <RemoveIcon
                    onClick={handleDecrement}
                    sx={{ fontSize: "14px", cursor: "pointer" }}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <AddIcon
                    onClick={handleIncrement}
                    sx={{ fontSize: "14px", cursor: "pointer" }}
                  />
                </InputAdornment>
              ),
              inputProps: { inputMode: "numeric", pattern: "[0-9]*" },
            }}
          />
          <Typography sx={{ paddingLeft: "7px", fontSize: "14px" }}>
            days from step 1
          </Typography>
        </div> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "15px",
          }}
        >
          <LoadingButton
            variant="contained"
            loading={loading}
            disabled={status ? false : true}
            sx={{
              width: "80px",
              textTransform: "none",
              backgroundColor: "var(--button-color)",
              "&:hover": { backgroundColor: "var(--button-color)" },
            }}
            onClick={createStepFun}
          >
            Save
          </LoadingButton>
        </div>
      </div> 
    </div>
  );
});

export default NewEmailForm;
