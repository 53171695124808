import React from "react";
import { Route, Routes } from "react-router-dom";
import "../../styles/myAccount.css";
import "../dashboard/Style/UserDrawer.css";
import BillingDetails from "./BillingDetails";
import MyAccountSideBar from "./MyAccountSideBar";
import Style from "./Style/Profile.module.css";
import UseSideBar from "../../store/sideBarStore";
import ProfileDetails from "./ProfileDetails";

export default function Profile() {
  const { active } = UseSideBar();

  return (
    <div className={Style.wrapper}>
      {active && <MyAccountSideBar />}
      <Routes>
        <Route path="profile/*" element={<ProfileDetails />} />
        <Route path="billing" element={<BillingDetails />} />
      </Routes>
    </div>
  );
}
