import React, { memo } from 'react';
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const PdfRender = memo(({ onDocumentLoadSuccess, memoizedPdfUrl, pagesArray }) => {
  return (
    <Document
      file={memoizedPdfUrl}
      onLoadError={console.error}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      {pagesArray.map((val) => (
        <Page key={val} pageNumber={val} scale={1.5} />
      ))}
    </Document>
  );
});

export default PdfRender;
