import React, { useState } from "react";
import {
  autoUpdate,
  useFloating,
  useClick,
  useDismiss,
  offset,
  flip,
  size,
  useInteractions,
  FloatingPortal,
} from "@floating-ui/react";

import ClearIcon from "@mui/icons-material/Clear";
import InputBase from "@mui/material/InputBase";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "../../../Images/TopBar/search.png";
import DropDownImg from "../../../Images/TopBar/Polygon.png";
import { useNavigate } from "react-router-dom";

import Style from "../../../Style/TopBar/TopBar.module.css";
const SearchDropDown = () => {
  const options = [
    {
      value: "Overview",
      label: "Overview",
      route: "/dashboard",
    },
    {
      value: "Pipelines Dashboard",
      label: "Pipelines Dashboard",
      route: "/dashboard/pipelines",
    },
    {
      value: "Tasks Dashboard",
      label: "Tasks Dashboard",
      route: "/dashboard/tasks",
    },
    {
      value: "Events Dashboard",
      label: "Events Dashboard",
      route: "/dashboard/events",
    },
    {
      value: "Call Analytics",
      label: "Call Analytics",
      route: "/dashboard/call-analytics",
    },
    {
      value: "Email Analytics",
      label: "Email Analytics",
      route: "/dashboard/email-analytics",
    },
    {
      value: "Call by Users",
      label: "Call by Users",
      route: "/dashboard/call-by-users",
    },
    {
      value: "Spot Now Dashboard",
      label: "Spot Now Dashboard",
      route: "/dashboard/spotnow",
    },
    {
      value: "Chat Now Dashboard",
      label: "Chat Now Dashboard",
      route: "/dashboard/chatnow",
    },
  ];
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selection, setSelection] = useState("Overview");
  const handleSearchChange = (event) => {
    const inputText = event.target.value;
    setSearchText(inputText);
  };

  const OFFSET = 10;
  const clearSearch = () => {
    setSearchText("");
  };
  const { refs, floatingStyles, context, strategy } = useFloating({
    placement: "bottom-start",
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementMounted: autoUpdate,
    middleware: [
      offset(OFFSET),
      flip(),
      size({
        apply: ({ availableHeight, elements }) => {
          Object.assign(elements.floating.style, {
            maxHeight: `${Math.max(50, availableHeight - OFFSET)}px`,
          });
        },
      }),
    ],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);
  const navigate = useNavigate();
  const handleOptionClick = (optionLabel) => {
    setSelection(optionLabel.label);
    setIsOpen(false);
    navigate(`${optionLabel.route}`);
  };

  function renderOption(value) {
    if (selection === value) {
      return <div>{value}</div>;
    }
    return value;
  }
  const displayOptions = options
    .map((item) => {
      if (item.label.toLowerCase().includes(searchText.toLowerCase())) {
        return item;
      }
      return undefined;
    })
    .filter((item) => item !== undefined);

  return (
    <>
      <div
        className={Style.buttonWrapper}
        // onFocusVisible={false}
        {...getReferenceProps({ ref: refs.setReference })}
      >
        {selection}
        <div style={{ padding: "5px" }}>
          <img src={DropDownImg} alt="dropdown" />
        </div>
      </div>

      {isOpen && (
        <FloatingPortal>
          <div
            className={Style.dropDownContainer}
            {...getFloatingProps({
              ref: refs.setFloating,
              style: { ...floatingStyles, position: strategy },
            })}
          >
            <div className={Style.inputBaseWrapper}>
              <InputBase
                sx={{
                  border: "2px solid #0000FF",
                  width: "90%",
                  borderRadius: "4px",
                  padding: "4px",
                  height: "28px",
                }}
                placeholder="Search..."
                onChange={handleSearchChange}
                value={searchText}
                endAdornment={
                  searchText ? (
                    <ClearIcon
                      onClick={clearSearch}
                      style={{
                        height: "14px",
                        weight: "14px",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <img src={SearchIcon} style={{ padding: "5px" }} alt="searchIcon" />
                  )
                }
              />
            </div>
            <div className={Style.selectmenu}>
              {displayOptions.map((item, index) => (
                <div
                  className={`${Style.selectDropDown} ${
                    selection === item.label ? Style.selectedOptions : ""
                  }`}
                  key={`${item.value}-${index}`}
                  onClick={() => handleOptionClick(item)}
                >
                  {renderOption(item.label)}{" "}
                </div>
              ))}
            </div>
            <div className={Style.dashBoardButton}>
              <AddIcon
                style={{
                  marginRight: "5px",
                  color: "#000AFF",
                  height: "14px",
                  width: "14px",
                }}
              />{" "}
              <span> New DashBoard </span>{" "}
            </div>
          </div>
        </FloatingPortal>
      )}
    </>
  );
};

export default SearchDropDown;
