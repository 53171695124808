import React from "react";
import { Box, Avatar, Typography, IconButton, Checkbox } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { formateHour } from "../../../../CustomHooks/formateDates";
import HoverDropDown from "./HoverDropDown";
import { ImageFilter } from "../../../../Utils/imageUtils";
import ReplyMessage from "./ReplyMessage";
import GradeIcon from "@mui/icons-material/Grade";
const ReceivedMsg = ({
  data,
  hoverId,
  handleOpen,
  handleClose,
  open,
  anchorEl,
  setHoverId,
  messageOptions,
  isGroupChat,
  handleDownloadFile,
  isForward,
  handleCheckboxChange,
  forwardMsgId,
}) => {
  const isAudio = data?.mimeType?.startsWith("audio/");
  const isVideo = data?.mimeType?.startsWith("video/");
  const isImage = data?.mimeType?.startsWith("image/");
  const isFile = isImage || isAudio || isVideo || data.ContentType === "file";
  const isText = data.ContentType === "text";

  return (
    <Box display="flex" paddingTop={2} paddingLeft={2}>
      {isForward && (
        <Box>
          <Checkbox
            checked={forwardMsgId.includes(data._id)}
            onChange={(e) => handleCheckboxChange(e,data)}
            size="small"
            sx={{ marginRight: "8px" }}
          />
        </Box>
      )}

      <Box
        height="auto"
        sx={{ background: "#000AFF" }}
        width="60%"
        p={1}
        border="1px solid rgba(0, 0, 0, 0.1)"
        borderRadius={1}
        onMouseEnter={() => setHoverId(data._id)}
        onMouseLeave={() => setHoverId("")}
      >
        {hoverId === data._id && !data.is_deleted && data.isReplyMessage && (
          <Box display={"flex"} justifyContent={"flex-end"}>
            <HoverDropDown
              open={open}
              handleClose={handleClose}
              handleOpen={handleOpen}
              anchorEl={anchorEl}
              messageOptions={messageOptions}
              setHoverId={setHoverId}
              msgData={data}
              text={isText}
            />
          </Box>
        )}

        {data.isReplyMessage && <ReplyMessage data={data} />}

        <Box display={"flex"} justifyContent={"space-between"}>
          <Box width={"100%"}>
            {hoverId === data._id &&
              !data.is_deleted &&
              !data.isReplyMessage && (
                <Box display={"flex"} justifyContent={"flex-end"}>
                  <HoverDropDown
                    open={open}
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    anchorEl={anchorEl}
                    messageOptions={messageOptions}
                    setHoverId={setHoverId}
                    msgData={data}
                    text={isText}
                  />
                </Box>
              )}
            {isGroupChat && (
              <Typography
                color="#e1f0ff"
                fontWeight={500}
                fontSize={11}
                paddingBottom={1}
              >
                {data?.userName}
              </Typography>
            )}

            {isImage ? (
              <Box
                display="flex"
                justifyContent="flex-start"
                flexDirection={"column"}
              >
                <img
                  alt="Received data"
                  src={data?.originalUrl}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "4px",
                  }}
                />
              </Box>
            ) : isAudio ? (
              <Box display="flex" flexDirection="column">
                <audio controls style={{ width: "100%" }}>
                  <source src={data?.originalUrl} type={data.mimeType} />
                  Your browser does not support the audio element.
                </audio>
              </Box>
            ) : isVideo ? (
              <video controls style={{ width: "100%" }}>
                <source src={data?.originalUrl} type={data.mimeType} />
                Your browser does not support the video element.
              </video>
            ) : isFile ? (
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}
              >
                <Box display={"flex"} alignItems={"center"}>
                  <img
                    alt="file icon"
                    src={ImageFilter(data.mimeType)}
                    style={{ width: 24, height: 24, marginRight: "8px" }}
                  />
                  <Typography color="#ffff" fontWeight={500} fontSize={11}>
                    {data.fileName || data.content}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography color={"#ffff"} fontWeight={500} fontSize={14}>
                  {data?.content}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="flex-end">
          {false && <GradeIcon sx={{ fontSize: "12px", margin: "0px 2px" }} />}
          <Typography color="#D9D9D9" fontWeight={500} fontSize={10}>
            {formateHour(data?.time)}
          </Typography>

          {hoverId === data._id && !data.is_deleted && isFile && (
            <IconButton
              size="small"
              sx={{ ml: 1 }}
              onClick={(e) => {
                e.stopPropagation();
                handleDownloadFile(data.originalKey, data.fileName);
              }}
            >
              <DownloadIcon
                fontSize="small"
                sx={{ color: "#D9D9D9", fontSize: "14px" }}
              />
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ReceivedMsg;
