import React from "react";

const TableBadge = ({ percentage, color }) => {
  return (
    <span
      style={{
        padding: " 0px 5px",
        color: "white",
        background: `${color}`,
        width: "50px",
        display: "inline-block",
      }}
    >
      {percentage}
    </span>
  );
};

export default TableBadge;
