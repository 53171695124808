import React from "react";
import { styled } from "@mui/system";
import { TextField } from "@mui/material";
import Style from "../Style/LogInPage.module.css";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Styled components
const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root.MuiOutlinedInput-root": {
    borderRadius: "2px",
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px",
  },
  "& .MuiFormHelperText-root": {
    color: theme.palette.error.main,
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.error.main,
  },
  "& .MuiInputBase-input": {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
    },
  },
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
}));

// Component definition
const CustomButtonContainer = ({
  title,
  name,
  value,
  error,
  helperText,
  onChangeFuncion,
  isPassword,
  handleVisibility,
  handleMouseDownPassword,
  showPassword,
  isMargin,
  fieldType,
}) => {
  return (
    <div style={{ marginTop: isMargin ? "8%" : "" }}>
      <p className={Style.fieldTitle}>{title}</p>
      <CustomTextField
        name={name}
        value={value}
        error={error}
        type={fieldType ? fieldType : showPassword ? "text" : "password"}
        helperText={helperText}
        onChange={onChangeFuncion}
        fullWidth
        variant="outlined"
        InputProps={{
          endAdornment: isPassword ? (
            <InputAdornment position="end">
              <CustomIconButton
                aria-label="toggle password visibility"
                onClick={handleVisibility}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </CustomIconButton>
            </InputAdornment>
          ) : null,
        }}
      />
    </div>
  );
};

export default CustomButtonContainer;
