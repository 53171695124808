import React from "react";
import CustomActivityLayOut from "../Components/CustomActivityLayOut";
import { WithHoc } from "../Components/WithHoc";
import Style from "../../Style/Common.module.css";
import VisitorCalendarGrid from "./Components/VisitorCalendarGrid";
const VisitorCalendarLayout = () => {
  const VisitorCalendarLayout = WithHoc(CustomActivityLayOut,VisitorCalendarGrid );
  return (
    <div className={Style.commonPadding}>
      <VisitorCalendarLayout title={"Website Visit Calendar (Per Day And Hour)"} />
    </div>
  );
};

export default VisitorCalendarLayout;
