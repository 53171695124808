import { IoIosArrowBack } from "react-icons/io";
// import Close from "../Switches/Close";

import SelectPermissions from '../Permissions/SelectPermissions'
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import CustomSwitch from "../../../../components/Switch";
import Button from "../../../../components/Button";
import { useState } from "react";

const modules = [
  { name: "VissionNow" },
  { name: "MailsNow" },
  { name: "ChatNow" },
  { name: "SpotNow" },
];

function AddRoles({ handleBackToRoles }) {
  const [showSelectPermissions, setShowSelectPermissions] = useState(false);
  const handleBackToAddRoles=()=>{
    setShowSelectPermissions(false)
  }
  
  
  return (
    <div
    style={{
      display: "flex",
      flexDirection: "column",
      height: "100%",
      overflow: "hidden",
      // border: "1px solid red",
    }}
    >
      {showSelectPermissions && <SelectPermissions handleBackToAddRoles={handleBackToAddRoles}  />}

      <div
        style={{
          height: "5%",
          padding: "5px",
          display: "flex",
          alignItems: "center",
          backgroundColor: "rgba(217, 217, 217, 0.11)",
          // border: "1px solid black"
        }}
      >
        <span
          style={{
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={handleBackToRoles}
        >
          <IoIosArrowBack />
        </span>
        <span style={{ marginLeft: "2%", fontWeight: "bolder" }}>
          New Roles
        </span>
        <span
          style={{
            marginLeft: "auto",
            marginRight: "1%",
            cursor: "pointer",
          }}
          // onClick={handleClose}
        ></span>
      </div>
      <div
        style={{
          // border: "1px solid red",
          height: "84.5%",
          borderBottom: "1px solid rgba(0,0,0,0.1)",
        }}
      >
        <div
          style={
            {
              // height: "35%",
              // border:"1px solid red"
            }
          }
        >
          <Stack
            style={{
              marginLeft: "4%",
              overflowY: "auto",
            }}
          >
            <Typography>Name</Typography>
            <TextField size="small" style={{ width: "230px" }} />
            <Typography>Description</Typography>
            <TextField size="medium" style={{ width: "230px" }} />
            {/* <textarea/> */}
          </Stack>
        </div>
        <div
          style={{
            // border: "1px solid red",
            height: "60%",
            padding: "12px 0px",
            overflowY: "auto",
          }}
        >
          <Table>
            <TableHead
              style={{
                background: "rgba(217, 217, 217, 0.21)",
              }}
            >
              <TableRow>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    padding: "2px 16px",
                    width: "180px",
                  }}
                >
                  MODULES
                </TableCell>
                <TableCell
                  style={{
                    padding: "7px 16px",
                    fontWeight: "bolder",
                  }}
                >
                  PERMISSIONS
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {modules.map((module, index) => (
                <TableRow key={index}>
                  <TableCell
                    style={{
                      padding: "19px 16px",
                    }}
                  >
                    {module.name}
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "19px 16px",
                    }}
                  >
                    <CustomSwitch />
                    <span
                      style={{
                        marginLeft: "5%",
                        textDecoration: "underline",
                        color: "rgba(68, 68, 68, 0.64)",
                      }}
                      onClick={() => setShowSelectPermissions(true)}
                    >
                      Select Permissions
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <div
          style={{
            // borderTop: "1px solid #efefef",
            height: "10%",
            // flex: "1",
          }}
        ></div>
      </div>
      <div
        style={{
          // border: "1px solid black",
          height: "9%",
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
        }}
      >
        <div
          style={{
            display: "flex",
            //  border: '1px solid red',
          }}
        >
          <span>
            <Button
              style={{
                marginLeft: "-25%",
              }}
            >
              Save
            </Button>
          </span>
          <span>
            <Button
              style={{
                background: "white",
                color: "black",
                marginLeft: "-15%",
                border:"1px solid grey"
              }}
              onClick={handleBackToRoles}
            >
              Cancel
            </Button>
          </span>
        </div>
      </div>
    </div>
  );
}

export default AddRoles;
