import React from "react";
import CustomTable from "../../Components/CustomTable";
import { WithHoc } from "../../Components/WithHoc";
import LeaderBoardHeader from "./LeaderBoardHeader";

const LeaderBoard = () => {
  const tableData = [
    {
      id: 1,
      userName: "User 1",
      convo: 100,
    },
    {
      id: 2,
      userName: "User 2",
      convo: 60,
    },
    {
      id: 3,
      userName: "User 3",
      convo: 40,
    },
    {
      id: 4,
      userName: "User 4",
      convo: 10,
    },
    {
      id: 5,
      userName: "User 5",
      convo: 80,
    },
    {
      id: 6,
      userName: "User 6",
      convo: 134,
    },
  ];
  const TableWithHeader = WithHoc(CustomTable,LeaderBoardHeader);
  return <TableWithHeader tableData={tableData} isStatus={true} />;
};

export default LeaderBoard;
