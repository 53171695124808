import React from "react";
import { Button, Box, Grid } from "@mui/material";
import {useNavigate} from "react-router-dom"
const ViewProfileDetails = ({ data }) => {
    const navigate=useNavigate()
    function handelNavigation(){
        navigate("edit")
    }
  return (
    <>
      <Box
        className="my-account-left-info-wrapper"
        style={{ backgroundColor: "#fff", margin: 17, borderRadius: 15 }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
        >
          <Box>
            {" "}
            <h6>User Information</h6>
          </Box>
          <Box>
            {" "}
            <Button variant="contained" onClick={handelNavigation}>
              Edit
            </Button>
          </Box>
        </Box>
        <Grid container spacing={2}>
          {[
            { label: "First Name", name: "first_name" },
            { label: "Last Name", name: "last_name" },
            { label: "Phone Number", name: "phone_number" },
            { label: "Email", name: "email" },
            { label: "GST Number", name: "gstin" },
            { label: "User Role", name: "gstin" },
          ].map(({ label, name }) => (
            <Grid item md={6} xs={12} key={name}>
              <Box className="my-account-left-info">
                <Box className="my-account-left-info-key">{label} :</Box>
                <Box className="my-account-left-info-value">{data[name]}</Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box
        className="my-account-left-info-wrapper"
        style={{ backgroundColor: "#fff", margin: 20, borderRadius: 15 }}
      >
        <h6>Address</h6>
        <Grid container spacing={2}>
          {[
            { label: "Address ", name: "address" },
            { label: "City", name: "city" },
            { label: "State", name: "state" },
            { label: "Country", name: "country" },
            { label: "Postal Code", name: "pincode" },
            { label: "Status", name: "status" },
          ].map(({ label, name }) => (
            <Grid item md={6} xs={12} key={name}>
              <Box className="my-account-left-info">
                <Box className="my-account-left-info-key">{label} :</Box>
                <Box className="my-account-left-info-value">
                  {data[name] || "---"}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default ViewProfileDetails;
