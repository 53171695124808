export function PriceFormat(price) {
  const taxAsNumber = parseFloat(price); // Convert to number

  const formattedNumber = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(taxAsNumber.toFixed(2));

  return formattedNumber;
}

export function PriceFormatSymbol(price, currencyCode) {
  const taxAsNumber = parseFloat(price || 0);

  const formattedNumber = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(taxAsNumber);

  return formattedNumber;
}

export function NumberFormat(number) {
  const formatted = number >= 1 && number <= 9 ? `0${number}` : number;

  return formatted;
}
