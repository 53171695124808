import React from "react";
import Grid from "@mui/material/Grid";
import SegmentGraph from "./SegmentGraph";
import SegmentFilter from "./SegmentFilter";
import Style from "../../../Style/Common.module.css"
const SegementGrid = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8} sm={12} className={Style.containerMinHeight}>
        <SegmentGraph />
      </Grid>
      <Grid item xs={12} md={4} sm={12}>
        <SegmentFilter />
      </Grid>
    </Grid>
  );
};

export default SegementGrid;
