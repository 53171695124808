import { create } from "zustand";

const useBranchStore = create((set) => ({
  branch: [],
  setBranch: (branch) => {
    set({ branch });
  },
}));

export default useBranchStore;
