import Button from "../../../../components/Button";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Box,
  TableBody,
  CircularProgress,
  Tooltip,
  Modal,
} from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Styles from "../Organization.module.scss";
import axios from "axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import AddShift from "./AddShift";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import toast from "react-hot-toast";

const fetchShift = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_PEOPLES_NOW_BASEURL}/shift/shift-by-branch`
  );
  return response.data;
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day} / ${month} / ${year}`;
};

function Shift() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const queryClient = useQueryClient();

  const {
    data: shift,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["shift"],
    queryFn: fetchShift,
  });

  const handleDelete = async (id) => {
    return axios
      .delete(
        `${process.env.REACT_APP_PEOPLES_NOW_BASEURL}/shift/delete-shift/${id}`
      )
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["shift"] });
        setIsDeleteOpen(false);
        toast.success("Shift Deleted Successfully!");
      })
      .catch((error) => {
        console.error("Error deleting branch:", error);
        const errorMessage =
          error.response?.data?.error?.message || "Error Deleting Shift!";
        setIsDeleteOpen(false);
        toast.error(errorMessage);
      });
  };

  const handleOkClick = () => {
    if (selectedBranch) {
      handleDelete(selectedBranch.doc_id);
    }
  };

  const handleAddShiftClick = () => {
    setIsDialogOpen(true);
    setIsEditMode(false);
  };
  const handleEditClick = (data) => {
    setSelectedBranch(data);
    setIsEditMode(true);
    setIsDialogOpen(true);
  };
  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setIsEditMode(false);
  };

  if (isLoading)
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CircularProgress />
      </Box>
    );
  if (isError) return <div>Error loading data</div>;

  return (
    <div style={{ height: "65vh" }}>
      <div>
        <div style={{ marginTop: "15px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "flex-end",
            }}
          >
            <Button
              style={{ marginTop: "1%", width: "97px", marginRight: "10px" }}
              onClick={handleAddShiftClick}
            >
              Add Shift
            </Button>
          </div>
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Shift Name
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Valid From
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Valid To</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Working Hours
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bolder", width: "30px" }}
                  ></TableCell>
                  <TableCell
                    style={{ fontWeight: "bolder", width: "30px" }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shift.map((data) => (
                  <TableRow
                    key={data.doc_id}
                    className={Styles.hoverTable}
                    sx={{
                      "&:hover": {
                        backgroundColor: "var(--light-bg-img-color)",
                      },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell>{data.shift_name}</TableCell>
                    <TableCell>{formatDate(data.valid_from)}</TableCell>
                    <TableCell>{formatDate(data.valid_to)}</TableCell>
                    <TableCell>{data.working_hours}</TableCell>
                    <TableCell>
                      <Tooltip title="Edit">
                        <EditOutlinedIcon
                          onClick={() => handleEditClick(data)}
                          style={{ color: "#000" }}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Delete">
                        <DeleteOutlineOutlinedIcon
                          onClick={() => {
                            setIsDeleteOpen(true);
                            setSelectedBranch(data);
                          }}
                          style={{ color: "red" }}
                        />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
      <Modal
        open={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        sx={{ outline: "none", border: "none" }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "45vw",
            height: "150px",
            bgcolor: "background.paper",
            boxShadow: 24,
            padding: "5px 5px 20px 20px ",
            display: "flex",
            flexDirection: "column",
            borderRadius: "6px",
            outline: "none",
            border: "none",
          }}
        >
          <CloseIcon
            sx={{ fontSize: 20, cursor: "pointer", marginLeft: "auto" }}
            onClick={() => setIsDeleteOpen(false)}
          />
          <Box sx={{ height: "70px", paddingRight: "15px" }}>
            <Typography sx={{ fontSize: "16px" }}>
              {selectedBranch
                ? `Deleting Shift "${selectedBranch.expense_category}" will delete it permanently. Proceed?`
                : "No item selected."}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              padding: "10px 20px",
            }}
          >
            <Button
              size="small"
              variant="outlined"
              sx={{
                bgcolor: "#F7F8FA",
                color: "black",
                border: "1px solid gray",
                marginRight: "20px",
              }}
              type="button"
              onClick={() => setIsDeleteOpen(false)}
            >
              Cancel
            </Button>
            <Button
              size="small"
              sx={{
                bgcolor: "var(--button-color)",
                color: "#fff",
                ":hover": { backgroundColor: "var(--button-color)" },
              }}
              onClick={handleOkClick}
            >
              Ok
            </Button>
          </Box>
        </Box>
      </Modal>
      <AddShift
        // data={shift}
        setIsEditMode={setIsEditMode}
        setIsDialogOpen={setIsDialogOpen}
        isEditMode={isEditMode}
        handleDialogClose={handleDialogClose}
        isDialogOpen={isDialogOpen}
        selectedBranch={selectedBranch}
      />
    </div>
  );
}

export default Shift;
