import { Box, Tab, FormControl, Select, MenuItem, CircularProgress, Typography } from "@mui/material";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import EmailPreview from "./element/EmailPreview";
import ContentGuide from "./element/ContentGuide";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ViewQuiltOutlinedIcon from "@mui/icons-material/ViewQuiltOutlined";
import { useQuery } from "@tanstack/react-query";
import { newCampaignSubject, setNewSequenceIdStore, setNewVariantidStore, stepId, useStepDate } from "../../../store/userStore";
import { getStepList } from "../../../webservices/sequence";
import { SetBodyValue } from "../../../store/templateStore";

const style = {
  minHeight: "36px",
  padding: "6px 14px",
  height: "40px",
  textTransform: "none"
};

const styleSelect = {
  height: "45px",
  marginRight: "20px",
  "& .MuiSelect-select": {
    padding: "0px 14px "
  }
};

const EmailGuide = ({ finalPreview }) => {
  const [activeTab, setActiveTab] = useState("Content Guide");
  const [selectedStep, setSelectedStep] = useState("");
  const { newSequenceId } = setNewSequenceIdStore();
  const { setStepId } = stepId();
  const { setEmailSubject } = newCampaignSubject();
  const { SetBody } = SetBodyValue();
  const {  setNewVariantid } = setNewVariantidStore();

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const stepData = useQuery({
    queryKey: ["stepListPreview", newSequenceId],
    queryFn: () => getStepList(newSequenceId),
    enabled: !!newSequenceId
  });

  const handleStepChange = (item) => {
    setSelectedStep(item);
    SetBody(item.firstVariant.content);
    setEmailSubject("subject",item.firstVariant.subject);
    setNewVariantid(item.firstVariant._id);
    setStepId(item.stepId);

  };

  useEffect(
    () => {
      if (finalPreview === true) {
        setActiveTab("Email Preview");
      } else {
        setActiveTab("Content Guide");
      }
    },
    [finalPreview]
  );

  useEffect(() => {
    if (finalPreview === true && stepData.isSuccess && stepData.data?.data[0]?.steps?.length > 0) {
      const firstStep = stepData.data.data[0].steps[0];
      setSelectedStep(firstStep);
      handleStepChange(firstStep); 
    }
  }, [stepData.isSuccess, stepData.data]);

  return (
    <Box
      sx={{
        borderLeft: "1px solid var(--light-border-color)",
        height: "100%"
      }}
    >
      {finalPreview === true
        ? <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid var(--light-border-color)"
            }}
          >
            <Tabs
              value={activeTab}
              onChange={handleChange}
              variant="scrollable"
              aria-label="visible arrows tabs example"
              sx={{
                minHeight: "40px",
                borderBottom: "1px solid var(--light-border-color)",
                [`& .${tabsClasses.scrollButtons}`]: {
                  "&.Mui-disabled": { opacity: 0.3 }
                }
              }}
            >
              <Tab
                sx={style}
                icon={<ViewQuiltOutlinedIcon sx={{ fontSize: "20px" }} />}
                label="Email Preview"
                value="Email Preview"
                iconPosition="start"
              />
            </Tabs>
            <FormControl sx={{ minWidth: 160 }}>
        
              <Select sx={styleSelect}
              placeholder="Email"
                value={selectedStep}
                onChange={(e) => handleStepChange(e.target.value)} >
  {stepData.isLoading ? (
    <MenuItem disabled>
      <Typography sx={{ ml: 1 }}>Loading...</Typography>
    </MenuItem>
  ) : stepData.error ? (
    <MenuItem disabled>
      <Typography>No Data Found</Typography>
    </MenuItem>
  ) : (
    stepData.data?.data[0]?.steps.map((step, index) => (
      <MenuItem key={index}  value={step} sx={{backgroundColor:"#fff"}}>
        Email-{index + 1}
      </MenuItem>
    ))
  )}
</Select>
            </FormControl>
          </Box>
        : <Tabs
            value={activeTab}
            onChange={handleChange}
            variant="scrollable"
            aria-label="visible arrows tabs example"
            sx={{
              minHeight: "40px",
              borderBottom: "1px solid var(--light-border-color)",
              [`& .${tabsClasses.scrollButtons}`]: {
                "&.Mui-disabled": { opacity: 0.3 }
              }
            }}
          >
            <Tab
              sx={style}
              icon={<DescriptionOutlinedIcon sx={{ fontSize: "20px" }} />}
              label="Content Guide"
              value="Content Guide"
              iconPosition="start"
            />
          </Tabs>}

      <Box sx={{ height: "calc(100vh - 120px)" }}>
        {activeTab === "Content Guide" && <ContentGuide />}
        {activeTab === "Email Preview" && <EmailPreview />}
      </Box>
    </Box>
  );
};

export default EmailGuide;

