import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";
import { useOnlineStatusStore } from "../store/teamChatStore";

async function getMessageList(page = 1, limit = 10) {
  const url = `${process.env.REACT_APP_TEAM_CHAT_BASEURL}/api/v1/chats?page=${page}&limit=${limit}`;
  return axios.get(url).then(({ data }) => data);
}

export const useMessageList = (limit) => {
  const {addOnlineStatusId} = useOnlineStatusStore();

  return useInfiniteQuery({
    queryKey: ["teamChat_getMessageList"],
    queryFn: async ({ pageParam = 1 }) => {
      const data = await getMessageList(pageParam, limit);
      console.log("myuserdataa", data.onlineUsers);

      data.onlineUsers.forEach((userId) => {
        addOnlineStatusId(userId);
      });

      return data;
    },
    getNextPageParam: (lastPage) => {
      const { nextPage, page } = lastPage;
      return nextPage ? page + 1 : undefined;
    },
  });
};
