import React, { useState, useCallback, useEffect } from "react";
import {
  Grid,
  FormControl,
  TextField,
  Typography,
  CircularProgress,
  Box,
  InputAdornment,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import facebookAds from "./Ads.module.scss";
import { FaFacebookF } from "react-icons/fa";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../../../MarketingStudio/Ads/store/auth";
const Url = process.env.REACT_APP_FB_AD_BASE_URL;

const style = {
  margin: "5px 25px 0 10px ",
  "& .MuiInputBase-root": {
    height: "45px",
  },
  "& .MuiOutlinedInput-root": {
    padding: "0",
  },
  "& .MuiInputLabel-root": {
    top: "-4px",
  },
};

const iconStyle = {
  color: "#fff",
  fontSize: "45px",
  backgroundColor: "#1877F2",
  padding: "10px",
  borderRadius: "30px",
};

const accountinfo = async () => {
  const a = await axios.get(`${Url}/account/user`);
  return a.data;
};

const authFunc = async (setLoading, authWorking) => {
  try {
    const checkserver = await axios.get(`${Url}/health/`);

    if (!checkserver.data) {
      return { isServerWork: false };
    }

    const res = await axios.get(`${Url}/auth/`);
    return res.data.success === false ? res.data : { ...res.data };
  } catch (err) {
    console.error("Error during fetch:", err);
    return { isServerWork: false };
  } finally {
  }
};

const FacebookAds = () => {
  const [textValue, setTextValue] = useState("");
  const { setAuth, getAuth } = useAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [login, setLogin] = useState(false);
  const {
    data,
    isFetched,
    isLoading,
    isError,
    refetch: accountinfoRefetch,
  } = useQuery({
    queryKey: ["accountinfo"],
    queryFn: accountinfo,
  });

  const { data: auth, refetch } = useQuery({
    queryKey: ["auth"],
    queryFn: authFunc,
  });

  const handleLogin = useCallback(async () => {
    setLoading(true);
    const authData = await authFunc();

    if (!authData.success && authData.url) {
      // Set the window dimensions
      const width = 700;
      const height = 700;

      // Calculate center position for both left-right and top-bottom
      const left = window.screen.width / 2 - width / 2;
      const top = window.screen.height / 2 - height / 2;

      // Open the window centered both horizontally and vertically
      const newWindow = window.open(
        authData.url,
        "_blank",
        `width=${width},height=${height},top=${top},left=${left}`
      );

      const handleMessage = (event) => {
        const receivedData = event.data;
        if (receivedData.success) {
          setAuth(receivedData);
          newWindow.close();
          refetch();
          accountinfoRefetch();
          navigate("/markettingstudio/ads");
        } else {
          console.error(
            "Authentication failed or message received with no success."
          );
        }
        setLoading(false);
      };

      window.addEventListener("message", handleMessage);

      const checkWindowClosed = setInterval(() => {
        if (newWindow.closed) {
          clearInterval(checkWindowClosed);
          window.removeEventListener("message", handleMessage);
          setLoading(false);
          setLogin(false);
        }
      }, 500);
    } else {
      setAuth(authData);
      refetch();
      accountinfoRefetch();
      setLoading(false);
      navigate("/markettingstudio/ads");
    }
  }, [setAuth]);

  useQuery({
    queryKey: ["auth-fblogin"],
    queryFn: handleLogin,
    enabled: login,
  });

  const handleLogout = async () => {
    try {
      const response = await axios.get(`${Url}/account/logout`);

      if (response?.data?.success) {
        setAuth({ success: false });
        refetch();
        accountinfoRefetch();
        navigate("/markettingstudio/ads/linkaccount");
        toast.success("Logged out successfully!");
      } else {
        toast.error("Logout was unsuccessful!");
      }
    } catch (error) {
      toast.error("An error occurred while logging out. Please try again.");
      console.error("Logout error:", error);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(textValue);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          flexDirection: "column",
        }}>
        <CircularProgress
          sx={{ color: "blue", mb: 2, mt: -2 }}
          size={"4vh"}
        />
        <Typography>Connecting with Facebook</Typography>
      </Box>
    );
  }
  return (
    <Grid
      container
      spacing={2}>
      <Grid
        item
        xs={12}>
        <div className={facebookAds.fbheader}>
          <Typography sx={{ fontSize: "20px", fontWeight: 400 }}>
            Facebook ad source
          </Typography>
          <div className={facebookAds.buttons}>FEEDBACK</div>
        </div>
      </Grid>
      <Grid
        item
        xs={12}>
        {" "}
        <div
          className={facebookAds.container}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            padding: "25px 10px",
          }}>
          <FaFacebookF
            style={{ ...iconStyle, fontSize: "70px", borderRadius: "50%" }}
          />
          <div>
            <Typography sx={{ fontSize: "24px", fontWeight: 400 }}>
              Connect Facebook to Sales Intelligence
            </Typography>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 400, marginTop: "7px" }}>
              Report will show the Facebook marketing ROI and the performance of
              your sales respresentatives.
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid
        item
        xs={12}>
        <div className={facebookAds.container}>
          <div className={facebookAds.header}>Log on to Facebook</div>
          <Typography
            sx={{ fontSize: "16px", fontWeight: 400, marginTop: "15px" }}>
            {auth?.success
              ? "Facebook account connected"
              : "Use Facebook account to log on"}{" "}
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              justifyContent: "space-between",
              margin: "15px 0 15px 10px",
            }}>
            {!auth?.success ? (
              <>
                <FaFacebookF style={iconStyle} />
                <div
                  className={facebookAds.buttons}
                  onClick={() => setLogin(true)}
                  style={{ marginRight: "25px" }}>
                  CONNECT
                </div>
              </>
            ) : (
              <>
                {" "}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}>
                  <img
                    src={data?.picture?.data?.url}
                    style={{ borderRadius: "50px", ml: 2, width: "45px" }}
                  />
                  <div style={{ fontSize: "13px" }}>{data?.name}</div>
                </div>
                <div
                  className={facebookAds.buttons}
                  onClick={handleLogout}
                  style={{ marginRight: "25px" }}>
                  LOGOUT
                </div>
              </>
            )}
          </div>
        </div>
      </Grid>
      <Grid
        item
        xs={12}>
        <div className={facebookAds.container}>
          <div className={facebookAds.header}>Source Name</div>
          <FormControl
            fullWidth
            margin="normal">
            <TextField
              variant="outlined"
              placeholder="Facebook"
              sx={style}
            />
          </FormControl>
          <Typography
            sx={{
              margin: "0px 0 10px 10px",
              fontSize: "14px",
              color: "#878787",
            }}>
            As will be seen in the report. Example: Newsletters, Radio,
            Instagram post
          </Typography>
        </div>
      </Grid>
      <Grid
        item
        xs={12}>
        <div className={facebookAds.container}>
          <div className={facebookAds.header}>UTM parameters</div>
          <div className={facebookAds.utmtextfield}>
            <div className={facebookAds.utmtext}>utm_source</div>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                placeholder="+ Add UTM parameter"
                sx={style}
              />
            </FormControl>
          </div>
          <Typography
            sx={{
              margin: "0px 0 10px 10px",
              fontSize: "14px",
              color: "#878787",
            }}>
            Click source. Example: email, newsletter, banner, Instagram
          </Typography>
          <div className={facebookAds.utmtextfield}>
            <div className={facebookAds.utmtext}>utm_control</div>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                value={textValue}
                onChange={(e) => setTextValue(e.target.value)}
                sx={style}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography
                        onClick={handleCopy}
                        sx={{
                          marginRight: "15px",
                          cursor: "pointer",
                          fontSize: "16px",
                          fontWeight: 600,
                        }}>
                        Copy
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </div>
          <Typography
            sx={{
              margin: "0px 0 10px 10px",
              fontSize: "14px",
              color: "#878787",
            }}>
            You have to configure your ad account to get a correct detailed cost
            report
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default FacebookAds;
