import React, { useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import UseSelectedWorkSpace from "../../store/selectedWorkSpaceStore";

import { useParams } from "react-router-dom";
import useWorkSpaceStore from "../../store/WorkSpaceStore";
import CustomSnackBar from "../SnackBar/CustomSnackBar";
import { useQuery } from "@tanstack/react-query";
import useUser from "../../store/userStore";

const TokenAuth = () => {
  const { setWorkSpace } = useWorkSpaceStore();
  const { setSelectedWorkSpace } = UseSelectedWorkSpace();
  const {setLogInDetails}=useUser()


  const { jwt } = useParams();
  const splitted = jwt.split(" ");
  const [snackbaropen, setSnackbaropen] = useState({
    open: false,
    message: "",
    severity: "",
  });


  function checkAuth() {
    let obj = {
      token: splitted[1],
    };

    axios.post(`${process.env.REACT_APP_WEBSERVICES_PRODUCTION_URL}/client/signin`, obj).then((data) => {
      localStorage.setItem("auth", JSON.stringify(data.data.data));

      const selectedWorkSpace = data.data.workspaces.find(
        (workspace) => workspace.id === data.data.defaultWorkspace
      );
      setSnackbaropen({
        open: true,
        message: "Redirecting......",
        severity: "success",
      });
      setSelectedWorkSpace(selectedWorkSpace);
      setWorkSpace(data.data.workspaces);
      setLogInDetails(data.data.data);

      window.location.href = "/";
      return data.data.data;
    });
  }
  useQuery({
    queryKey: ["LogInViaTokenRidrect"],
    queryFn: checkAuth,
  });
  return (
    <>
      <Box
        height={"calc(100vh - 48px)"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CircularProgress />
      </Box>
      <CustomSnackBar
        snackbaropen={snackbaropen}
        setSnackbaropen={setSnackbaropen}
        position="right"
      />
    </>
  );
};

export default TokenAuth;
