import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Mon",
    uv: 110,
    amt: 100,
  },
  {
    name: "Tue",
    uv: 40,
    amt: 100,
  },
  {
    name: "Wed",
    uv: 140,
    amt: 100,
  },
  {
    name: "Thu",
    uv: 120,
    amt: 100,
  },
  {
    name: "Fri",
    uv: 100,
    amt: 100,
  },
  {
    name: "Sat",
    uv: 70,
    amt: 100,
  },
  {
    name: "Sun",
    uv: 60,
    amt: 100,
  },
];

const COGraph = () => {
  return (
    <ResponsiveContainer height={"100%"} width={"100%"}>
      <AreaChart
        width={800}
        height={500}
        data={data}
        margin={{
          top: 100,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="name" stroke="0" padding={{ left: 20, right: 20 }} />
        <YAxis stroke="0" padding={{ top: 20, bottom: 20 }} />
        <Tooltip />
        <Area
          dataKey="uv"
          stroke="#000"
          strokeWidth={2}
          fill="#E5E5E5"
          dot={{ stroke: "#000", strokeWidth: 4, fill: "#000" }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default COGraph;
