import { useQuery } from "@tanstack/react-query";
import axios from "axios";
async function getClientDetails(id) {
  const url = `${process.env.REACT_APP_WEBSERVICES_PRODUCTION_URL}/client/${id}`;  
  return axios.get(url).then(({ data }) => {


    return data.data;
  });
}

export const useClientDetails = (id) => {
  return useQuery({
    queryKey: ["getclientDetailsbyid"],
    queryFn: () => getClientDetails(id),
  });
};
