import { createContext, useEffect, useState, useContext } from "react";
import useUser from "../store/userStore";
import useSelectedWorkSpace from "../store/selectedWorkSpaceStore";
import io from "socket.io-client";

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const { getToken, getUserId } = useUser();
  const clientid = getUserId();
  const { getSelectedWorkSpace } = useSelectedWorkSpace();
  const SERVER = `${process.env.REACT_APP_TEAM_CHAT_BASEURL}`;

  const Workspaceid = getSelectedWorkSpace();
  const [Socket, setSocket] = useState(null);

  useEffect(() => {
    const token = getToken();

    if (!token || !clientid || !Workspaceid?.id) {
      console.error("Missing required data for socket connection.");
      return;
    }

    const newSocket = io(SERVER, {
      query: {
        token: `Bearer ${token}`,
        userId: clientid,
        workspaceId: Workspaceid.id,
      },
    });

    newSocket.on("connect", () => {
      console.log("Socket connected");
    });

    newSocket.on("disconnect", (reason) => {
      console.log("Socket disconnected:", reason);
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
      console.log("Socket disconnected on cleanup");
    };
  }, [getToken, clientid, Workspaceid?.id, SERVER]);

  return (
    <SocketContext.Provider value={{ Socket }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);
