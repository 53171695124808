import { Divider } from "@mui/material";
import React, { useState } from "react";
import SendingSettings from "../../../../../../../MarketingStudio/MailsNow/CampInfo/element/SendingSettings";
import LoadingButton from "@mui/lab/LoadingButton";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { ramUpData,snackbarStore } from "../../../../../../../MarketingStudio/store/userStore";
import { updateRampUpValue } from "../../../../../../../MarketingStudio/webservices/sequence";

const Sending = ({data}) => {

  const queryClient = useQueryClient();
  const { value } = ramUpData();
  const [isLoading,setIsLoading] = useState(false)
  const {setSnackbarValue} = snackbarStore()

  const mutation = useMutation({
    mutationFn: updateRampUpValue,
    onSuccess: (data) => {
      setIsLoading(false)
      setSnackbarValue({open:true,severity:"success",message:"Updated Successfully"})
      queryClient.invalidateQueries("intervalRamUp");
    },
    onError: (error) => {
      setIsLoading(false)
      setSnackbarValue({open:true,severity:"error",message:error?.response?.data?.message || "internal server error"})
      console.error("Error adding sequence:", error);
    },
  });

  const onSubmit = () => {
    setIsLoading(true)
    const updateData = {
      id:data._id,
      value:value
    }
    mutation.mutate(updateData);
  };

  return (
    <div>
      <SendingSettings id={data._id} />
      <Divider />
      <div
        style={{
          margin: ".25rem",
          display: "flex",
          justifyContent: "end",
          paddingTop: "10px",
        }}
      >
        <LoadingButton
          loading={isLoading}
          onClick={onSubmit}
          style={{
            backgroundColor: "#1d4ed8",
            border: "1px solid #1d4ed8",
            borderColor: "#1d4ed8",
            color: "#fff",
            fontSize: ".875rem",
            lineHeight: "1.45",
            textTransform: "capitalize",
            marginLeft: "1rem",
          }}
          autoFocus
        >
          Save
        </LoadingButton>
      </div>
    </div>
  );
};

export default Sending;
