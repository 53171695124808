import React from "react";
import Style from "../../Style/OverView/DashBoard.module.css";

const CustomLegend = ({ data }) => {
  return (
    <>
      {data.map((data) => (
        <div key={`${data.fill}-${data.name}`}>
          <span
            className={Style.legendColor}
            style={{ backgroundColor: `${data.fill}` }}
          ></span>
          <span className={Style.legendFont}>{data.name}</span>
        </div>
      ))}
    </>
  );
};

export default CustomLegend;
