import { Box } from "@mui/material";
import React from "react";
import ChatOptions from "./Components/ChatOptions";
import ChatSearch from "./Components/ChatSearch";

const ChatWindowOptions = () => {
  return (
    <Box maxHeight={"20%"}>
      <ChatSearch/>
      <ChatOptions />
    </Box>
  );
};

export default ChatWindowOptions;
