import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useTeamChatStore = create()(
  persist(
    (set) => ({
      teamChat: true,
      setTeamChat: () =>
        set((state) => ({
          teamChat: !state.teamChat,
        })),
    }),
    {
      name: "teamChat-persit",
    }
  )
);

export const useOpenChatStore = create()(
  persist(
    (set) => ({
      openChat: false,

      setOpenChat: () =>
        set((state) => ({
          openChat: !state.openChat,
        })),
    }),
    {
      name: "openChat-persist",
    }
  )
);

export const usePinChatStore = create()(
  persist(
    (set) => ({
      pinChat: false,

      setPinChat: () =>
        set((state) => ({
          pinChat: !state.pinChat,
        })),
      setCloseChat: () =>
        set((state) => ({
          pinChat: false,
        })),
    }),
    {
      name: "pinChat-persist",
    }
  )
);

export const useChatUIStore = create()(
  persist(
    (set) => ({
      uiElement: "#channels",

      setUIElement: (element) =>
        set(() => ({
          uiElement: element,
        })),
    }),
    {
      name: "teamChatUI-persist",
    }
  )
);

export const useReceiverId = create()(
  persist(
    (set) => ({
      receiverData: {
        receiverId: "",
        is_group_chat: "",
        convoId: "",
        userName: "",
        email: "",
        groupId:"",
        profilePic:""
      },

      setReceiverId: (newData) =>
        set(() => ({
          receiverData: { ...newData },
        })),
    }),
    {
      name: "useRecieveId-persist",
    }
  )
);

export const useWorSpaceRoom = create()(
  persist(
    (set) => ({
      workSpaceRoomId: "",
      setworkSpaceRoomId: (newData) =>
        set(() => ({
          workSpaceRoomId: newData,
        })),
    }),
    {
      name: "useworkSpaceRoomId-persist",
    }
  )
);



export const useOnlineStatusStore = create()(
  persist(
    (set) => ({
      onlineStatusIds: [],
      addOnlineStatusId: (id) => set((state) => ({
        onlineStatusIds: [...new Set([...state.onlineStatusIds, id])],
      })),
      removeOnlineStatusId: (id) => set((state) => ({
        onlineStatusIds: state.onlineStatusIds.filter((existingId) => existingId !== id),
      })),
    }),
    {
      name: "online-status-store",
    }
  )
);
