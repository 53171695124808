import React from "react";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import { IconButton } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Loader from "./Loader";
const AppTable = ({
  data,
  columns,
  setTableData,
  tableData,
  List,
  getlist,
  isLoading,
}) => {
  const [sorting, setSorting] = useState([]);
  const [filtering, setFiltering] = useState("");
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting: sorting,
      globalFilter: filtering,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
  });
  return (
    <>
      <div className="task-table-container scroll">
        <table className="task-table">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={columns.length}>
                  <Loader />
                </td>
              </tr>
            ) : (
              table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
        <div className="tanTable">
          <IconButton
            className="border rounded p-1"
            onClick={() => {
              setTableData({
                ...tableData,
                page: tableData?.page - 1,
              });
            }}
            disabled={!tableData?.hasPrevPage}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <IconButton
            className="border rounded p-1"
            onClick={() => {
              setTableData({
                ...tableData,
                page: tableData?.page + 1,
              });
            }}
            disabled={!tableData?.hasNextPage}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
          <span className="tanTableSpan">
            <div>Page</div>
            <strong>
              {tableData?.page} of {tableData?.totalPages}
            </strong>
          </span>
          <span>
            | {tableData?.page * tableData?.limit - tableData?.limit + 1}-{" "}
            {tableData?.page * tableData?.limit > tableData?.totalDocs
              ? tableData?.totalDocs
              : tableData?.page * tableData?.limit}{" "}
            of {tableData?.totalDocs}
          </span>
          <span className="tanTableSpan">
            | Go to page:
            <input
              type="number"
              max={tableData?.totalPages}
              defaultValue={tableData?.page}
              onChange={(e) => {
                table.setPageIndex(Number(e.target.value - 1));
              }}
              className="border p-1 rounded w-16"
              style={{ width: 50 }}
            />
          </span>

          <select
            value={tableData?.limit}
            onChange={(e) => {
              setTableData({
                ...tableData,
                limit: Number(e.target.value),
              });
              table.setPageSize(Number(e.target.value));
              getlist(!List);
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        <style>{` .task-table{
            width:100%;
          }.tanTable{
            display: flex;
            align-items: center;
            gap: 10px;
            font-size:14px;
            justify-content: flex-end;
          
            border-top:0px;
            padding:10px;
          }
          .tanTableSpan{
            display: flex;
            gap: 10px;
            align-items: center;
          } 
          .task-table th,
          .task-table td {
            padding: 12px;
            text-align: left;
            height: 45px;
            font-size:14px
          }

.task-table th {

  font-weight: 500;
  font-size : 12px
}

.task-table tr {
  background: #ffffff;
    margin-left: 0;
    box-shadow: -2px 5px 9px 7px rgba(0, 0, 0, 0.03);
    color: #1c1c22;
    transition: background-color 0.2s ease;
    cursor:pointer;
  }
  
  .task-table tr:hover {
    background-color: #f5f5f5;
  }

.task-row.active {
  background-color: #e1f5fe;
  cursor: pointer;
}`}</style>
      </div>
    </>
  );
};

export default AppTable;
