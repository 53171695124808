import { useState } from "react";
import Button from "../../../../components/Button";
import {
  Modal,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
// import Roles from "../Roles";

import { IoClose } from "react-icons/io5";
import MyCheckbox from "../../../../components/CheckBox";

function SelectPermissions({ handleBackToAddRoles }) {
  const [open, setOpen] = useState(true);

 
  const headers = ["Action", "Delete", "Create", "Edit", "View", "Export"];
  const actions = [
    "Contacts",
    "Mailing List",
    "Segments",
    "Tags",
    "Lead Attribution",
  ];

  return (
    <div style={{ fontFamily: "Roboto" }}>
      <Modal
        open={open}
        onClose={handleBackToAddRoles}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            background: "#fff",
            borderRadius: "5px",
            padding: "1%",
            width: "100%",
            maxWidth: "60vw",
            maxHeight: "80vh", // Adjust the maximum height here
            overflowY: "auto", // Add scroll if content overflows
          }}
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <IoClose
              style={{
                marginRight: "1%",
                marginTop: "1%",
                cursor: "pointer",
                color: "black",
              }}
              onClick={handleBackToAddRoles}
            />
          </span>
          <h2 style={{ marginBottom: "1rem" }}>
            Permission - Contacts and Lists
          </h2>
          <Table
            style={{
              border: "1px solid #efefef",
              marginLeft: "1%",
              width: "calc(100% - 2%)",
              position: "relative",
            }}
          >
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell
                    key={header}
                    style={{
                      background: "rgba(245, 245, 248, 1)",
                      position: "sticky",
                      top: -1,
                      zIndex: 1,
                      minWidth: "100px", // Adjust minimum width of cells
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {actions.map((email) => (
                <TableRow key={email}>
                  <TableCell
                    style={{
                      padding: "2px 15px",
                    }}
                  >
                    {email}
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "2px 15px",
                    }}
                  >
                    <MyCheckbox />
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "2px 15px",
                    }}
                  >
                    <MyCheckbox />
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "2px 15px",
                    }}
                  >
                    <MyCheckbox />
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "2px 15px",
                    }}
                  >
                    <MyCheckbox />
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "2px 15px",
                    }}
                  >
                    <MyCheckbox />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}
          >
            <Button
              color="primary"
              style={{
                background: "rgba(0, 10, 255, 1)",
                borderRadius: "5px",
                color: "white",
              }}
            >
              Save
            </Button>
            <Button
              onClick={handleBackToAddRoles}
              style={{
                background: "white",
                borderRadius: "5px",
                color: "black",
                marginLeft: "1rem",
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SelectPermissions;
