import React from "react";
import { Link } from "react-router-dom";
import BackIcon from "../Assests/blueArrow.png";
import logo from "../Assests/NDE_Logo_Blue.png";
import "./Style/Header.css";
const VerifyHeader = () => {
 
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div className="ArrowWidth">
        <Link to="/login" style={{ marginLeft: "12px" }}>
          <img src={BackIcon} alt="Navigation Arrow" />
        </Link>
      </div>
      <div>
        <Link to="/login" style={{ marginLeft: "12px" }}>
          <img src={logo} alt="Nde logo" />
        </Link>
      </div>
    </div>
  );
};

export default VerifyHeader;
