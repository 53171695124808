import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Divider,
  IconButton,
  InputAdornment,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Picker from "emoji-picker-react";
import SendMessage from "./SendMessage";
import ReceivedMsg from "./ReceivedMsg";
import { useReceiverId } from "../../../../store/teamChatStore";
import { useGetMessages } from "../../../../CustomHooks/useGetMessages";
import { ObjectId } from "bson";
import useSelectedWorkSpace from "../../../../../store/selectedWorkSpaceStore";
import useUser from "../../../../../store/userStore";
import PinMessage from "./PinMessage";
import axios from "axios";
import toast from "react-hot-toast";
import { uploadFile } from "./uploadFile";
import ChatReply from "./ChatReply";
import ForwardMessages from "./ForwardMessages";
import UserSelectionModule from "./UserSelectionModule";
import SystemMessage from "./SystemMessage";

const ChatBody = ({
  socket,
  roomId,
  message,
  setMessage,
  receiverId,
  senderId,
}) => {
  const { receiverData } = useReceiverId();

  const { user } = useUser();
  const { getSelectedWorkSpace } = useSelectedWorkSpace();
  const Workspaceid = getSelectedWorkSpace();
  const convoId = receiverData?.convoId;
  const myroom = roomId;
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetMessages(10, convoId);
  const [messages, setMessages] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isReplyOpen, setIsReplyOpen] = useState(false);
  const [isForward, setIsForward] = useState(false);
  const [forwardMsgId, setForwardMsgId] = useState([]);
  const [forwardDetails, setForwardDetails] = useState([]);

  const [replyData, setReplyData] = useState("");
  const handleReplyClose = () => setIsReplyOpen(false);
  const openDropDown = Boolean(anchorEl);
  const observerRef = useRef();
  const intersectionObserverRef = useRef(null);

  const fetchOlderMessages = useCallback(() => {
    if (isFetchingNextPage || !hasNextPage) return;
    fetchNextPage();
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  const lastMessageRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;

      if (intersectionObserverRef.current) {
        intersectionObserverRef.current.disconnect();
      }

      intersectionObserverRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchOlderMessages();
        }
      });

      if (node) {
        intersectionObserverRef.current.observe(node);
      }
    },
    [isFetchingNextPage, hasNextPage, fetchOlderMessages]
  );
  console.log("userdata", data, messages);

  useEffect(() => {
    if (data?.pages) {
      console.log("userdata", data.pages);

      setMessages((prevMessages) => {
        const existingMessageIds = new Set(prevMessages.map((msg) => msg._id));

        const reversedMessages = [...data.pages]
          .reverse()
          .flatMap((page) => page.data || []);

        const filteredNewMessages = reversedMessages.filter(
          (msg) => !existingMessageIds.has(msg._id)
        );

        return [...filteredNewMessages, ...prevMessages];
      });
    }
  }, [data]);

  const scrollToBottom = (offset = 50) => {
    if (observerRef.current) {
      observerRef.current.scrollTop = observerRef.current.scrollHeight + offset;
    }
  };

  const handleReceiveMsg = useCallback(
    (response) => {
      const newMessage = response.data;
      const msgData = {
        time: new Date(),
        convoId: newMessage.conversation_id,
        roomId: myroom,
        userId: senderId,
        messageIds: [newMessage.message_id],
      };

      setMessages((prevMessage) => [...prevMessage, newMessage]);
      socket.emit("message_delivered", msgData);
    },
    [roomId]
  );

  const handleDeleteMsg = useCallback((response) => {
    handleClose();
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        (msg._id || msg.message_id) === response.messageId
          ? { ...msg, content: "Message was deleted", is_deleted: true }
          : msg
      )
    );
  }, []);

  const handleReadMessage = (response) => {
    const { findMessages } = response;
    console.log("readedmessages", findMessages);

    setMessages((prevMessages) => {
      return prevMessages.map((msg) => {
        if (findMessages.includes(msg.message_id)) {
          return { ...msg, messageStatus: "read" };
        }
        return msg;
      });
    });
  };

  function handleMessageReceived(response) {
    const { messageIds } = response;
    console.log("handleMessage", response);

    setMessages((prevMessages) => {
      return prevMessages.map((msg) => {
        if (messageIds.includes(msg.message_id)) {
          return { ...msg, messageStatus: "delivered" };
        }
        return msg;
      });
    });
  }
  useEffect(() => {
    if (!roomId || !socket) return;
    const deliveryData = {
      roomId,
      convoId,
      userId: user.user_id,
      time: new Date(),
    };

    socket.on("receive_message", handleReceiveMsg);
    socket.on("message_deleted", handleDeleteMsg);
    socket.on("message_delivered", handleMessageReceived);
    socket.emit("read_message", deliveryData, () => {});
    socket.on("read_message", handleReadMessage);
    return () => {
      socket.off("receive_message", handleReceiveMsg);
      socket.off("message_deleted", handleDeleteMsg);
      socket.off("message_delivered", handleMessageReceived);
      socket.off("read_message", handleReadMessage);
    };
  }, [roomId, socket]);

  const sendMessage = (isImage, data, msgId = null) => {
    if (!roomId || !socket) return;
    const messageId = msgId || new ObjectId().toString();
    console.log("isReplyOpen", isReplyOpen, replyData);

    let sendData = {
      roomId,
      message,
      messageType: "sent",
      sender: senderId,
      receiver: receiverId,
      conversationId: convoId,
      messageId,
      workspaceId: Workspaceid,
      isGroupChat: false,
      groupId: "",
      ContentType: "text",
    };
    let appendMsg = {
      content: message,
      conversation_id: convoId,
      messageStatus: "pending",
      messageType: "sent",
      properties: [],
      time: new Date(),
      message_id: messageId,
      is_deleted: false,
      ContentType: "text",
    };
    if (isImage) {
      sendData.mimeType = data.mimetype;
      sendData.fileName = data.fileName;
      sendData.size = data.size;
      sendData.originalKey = data.originalKey;
      sendData.thumbnailkey = data.thumbnail_key;
      sendData.thumbnailUrl = data.thumbnailUrl;
      sendData.originalUrl = data.originalUrl;
      sendData.content = "";
      sendData.ContentType = "file";

      // appendMsg.mimeType = data.mimetype;
      // appendMsg.fileName = data.fileName;
      // appendMsg.size = data.size;
      // appendMsg.originalKey = data.originalKey;
      // appendMsg.thumbnailkey = data.thumbnail_key;
      // appendMsg.thumbnailUrl = data.thumbnailUrl;
      // appendMsg.originalUrl = data.originalUrl;
      // appendMsg.content = "";
      // appendMsg.ContentType = "file";
    }

    if (isReplyOpen) {
      sendData.isReplyMessage = isReplyOpen;
      sendData.reply = {
        userId: replyData.receiver._id,
        id: replyData.message_id,
        mimeType: replyData.mimeType,
        ContentType: replyData.ContentType,
        replyContent: replyData.content,
      };
      if (!isImage) {
        appendMsg.isReplyMessage = isReplyOpen;
        appendMsg.sender = replyData.sender;
        appendMsg.receiver = replyData.receiver;
        appendMsg.reply = {
          userId: replyData.receiver._id,
          id: replyData.message_id,
          mimeType: replyData.mimeType,
          ContentType: replyData.ContentType,
          replyContent: replyData.content,
          fileName: replyData.fileName,
        };
      }
      setIsReplyOpen(false);
    }
    if (receiverData.is_group_chat) {
      sendData.isGroupChat = true;
      sendData.userName = user.fullName;
      sendData.groupId = receiverId;
    }
    if (!isImage) {
      setMessages((prevMessage) => [...prevMessage, appendMsg]);
    }
    console.log("sendmsgdata", sendData);
    socket.emit("send_message", sendData, (ack) => {
      if (ack.success) {
        setIsReplyOpen(false);
        appendMsg.messageStatus = "sent";
        setMessages((prevMessages) => {
          return prevMessages.map((msg) => {
            if (msg.message_id === messageId) {
              return {
                ...msg,
                messageStatus: ack.success ? "sent" : "pending",
              };
            }
            return msg;
          });
        });
      } else {
        appendMsg.messageStatus = "pending";
      }
      scrollToBottom();
    });

    setMessage("");
  };

  const updateMessageStatus = (messageId, updates) => {
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.message_id === messageId ? { ...msg, ...updates } : msg
      )
    );
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const previewUrl = URL.createObjectURL(file);

    const messageId = new ObjectId().toString();
    let placeholderMessage = {
      content: file.name,
      conversation_id: convoId,
      messageType: "sent",
      mimeType: file.type,
      fileName: file.name,
      size: file.size,
      time: new Date(),
      message_id: messageId,
      is_deleted: false,
      ContentType: "file",
      originalUrl: previewUrl,
      uploadStatus: true,
    };

    if (isReplyOpen) {
      placeholderMessage.isReplyMessage = isReplyOpen;
      placeholderMessage.sender = replyData.sender;
      placeholderMessage.receiver = replyData.receiver;
      placeholderMessage.reply = {
        userId: replyData.receiver._id,
        id: replyData.message_id,
        mimeType: replyData.mimeType,
        contentType: replyData.ContentType,
        replyContent: replyData.content,
        fileName: replyData.fileName,
      };
      setIsReplyOpen(false);
    }

    setMessages((prevMessages) => [...prevMessages, placeholderMessage]);

    uploadFile(file, messageId, updateMessageStatus, sendMessage);
  };

  const deleteMessage = (data) => {
    if (!roomId || !socket) return;
    const deleteData = {
      roomId,
      messageId: data.messageId,
      conversationId: data.conversationId,
    };

    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        (msg._id || msg.message_id) === data.messageId
          ? { ...msg, content: "Message was deleted" }
          : msg
      )
    );
    socket.emit("delete_message", deleteData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    sendMessage(false, {});
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [hoverId, setHoverId] = useState("");
  const copyToClipboard = (data) => {
    navigator.clipboard.writeText(data.content).then(
      () => {},
      (err) => {}
    );
  };
  const messageReply = (data) => {
    console.log("msgReplyData", data);
    setIsReplyOpen(true);
    setReplyData(data);
  };
  const messageForward = (data) => {
    console.log("msgReplyData", data);
    setIsForward(true);
    setForwardMsgId((prev) => [...prev, data.message_id]);
    const forwardDetails = {
      conversationId: data.conversation_id,
      roomId: roomId,
      isGroupChat: receiverData.is_group_chat,
      userName: receiverData.userName,
      sender: senderId,
      receiver: receiverId,
      groupId: receiverData.is_group_chat ? receiverId : null,
    };
    setForwardDetails((prev) => [...prev, forwardDetails]);
  };

  const starMessage = () => {};
  const messageOptions = [
    { label: "Delete", actionFunc: deleteMessage, id: "#delete" },
    { label: "Copy Text", actionFunc: copyToClipboard, id: "#copy" },
    { label: "Reply", actionFunc: messageReply, id: "#reply" },
    { label: "Forward", actionFunc: messageForward, id: "#forward" },
    { label: "Star", actionFunc: starMessage, id: "#star" },
    { label: "UnStar", actionFunc: starMessage, id: "#unstar" },
    { label: "Download", actionFunc: handleDownloadFile, id: "#download" },
  ];
  const handleCheckboxChange = (e, msgData) => {
    if (e.target.checked) {
      setForwardMsgId((prev) => [...prev, msgData._id]);
      console.log("forwardDetails", msgData);
      const forwardDetails = {
        conversationId: msgData.conversation_id,
        roomId: roomId,
        isGroupChat: receiverData.is_group_chat,
        userName: receiverData.userName,
        sender: senderId,
        receiver: receiverId,
        groupId: receiverData.is_group_chat ? receiverId : null,
      };
      setForwardDetails((prev) => [...prev, forwardDetails]);
    } else {
      setForwardMsgId((prev) => prev.filter((id) => id !== msgData._id));
      setForwardDetails((prev) => prev.filter((id) => id !== msgData._id));
    }
  };
  const handleForward = () => {
    setIsForward(false);
    setForwardMsgId([]);
    setForwardDetails([]);
  };
  const TypingStatus = () => {
    if (!roomId || !socket) return;
    const typingData = {
      roomId,
      userName: `${user?.fullName}`,
    };

    socket.emit("typing", typingData);
  };

  function handleMessages(e) {
    setMessage(e.target.value);
    TypingStatus();
  }

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prev) => !prev);
  };

  const handleEmojiClick = (emojiData) => {
    setMessage((prevMessage) => prevMessage + emojiData.emoji);
    TypingStatus();
    setShowEmojiPicker(false);
  };

  const getDeliveredAndUnreadMessageIds = () => {
    return messages
      .filter(
        (msg) => msg.messageType === "delivered" || msg.messageType === "unread"
      )
      .map((msg) => msg.messageId);
  };
  function handleDownloadFile(key, filename) {
    const cleanedFilename = filename.replace(/\s+/g, "");

    axios
      .get(
        `${process.env.REACT_APP_TEAM_CHAT_BASEURL}/api/v1/messages/download/file?fileKey=${key}&fileName=${cleanedFilename}`,
        { responseType: "blob" }
      )
      .then((result) => {
        const blob = new Blob([result.data], {
          type: result.headers["content-type"],
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = cleanedFilename;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }
  const [openModal, setOpenModal] = useState(false);

  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => setOpenModal(false);
  function handleForwardSocket(){
    const forwardaData={
      messageIds:forwardMsgId,
      forward:forwardDetails,
      workspaceId:Workspaceid
    }
    socket.emit("forward_message", forwardaData)
  }
  return (
    <>
      <Box
        id="scrollable-chat"
        sx={{
          height: isReplyOpen ? "76%" : "84%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column-reverse",
        }}
        paddingBottom={2}
        ref={observerRef}
      >
        <div>
          {messages.map((msg, index) => (
            <div
              key={msg._id}
              style={{ marginBottom: "8px" }}
              data-id={msg._id}
              ref={index === 0 ? lastMessageRef : null}
            >
              {msg?.messageType?.toLowerCase() === "sent" ? (
                <SendMessage
                  data={msg}
                  hoverId={hoverId}
                  handleOpen={handleOpen}
                  handleClose={handleClose}
                  open={openDropDown}
                  anchorEl={anchorEl}
                  setHoverId={setHoverId}
                  messageOptions={messageOptions}
                  handleDownloadFile={handleDownloadFile}
                  isForward={isForward}
                  forwardMsgId={forwardMsgId}
                  handleCheckboxChange={handleCheckboxChange}
                />
              ) : (
                <ReceivedMsg
                  data={msg}
                  hoverId={hoverId}
                  handleOpen={handleOpen}
                  handleClose={handleClose}
                  open={openDropDown}
                  anchorEl={anchorEl}
                  setHoverId={setHoverId}
                  messageOptions={messageOptions}
                  isGroupChat={receiverData.is_group_chat}
                  handleDownloadFile={handleDownloadFile}
                  isForward={isForward}
                  handleCheckboxChange={handleCheckboxChange}
                  forwardMsgId={forwardMsgId}
                />
              )}
            </div>
          ))}
        </div>
      </Box>
      {isReplyOpen && (
        <ChatReply
          data={replyData}
          handleReplyClose={handleReplyClose}
          forwardMsgId={forwardMsgId}
        />
      )}

      <Divider />
      {isForward ? (
        <ForwardMessages
          count={forwardMsgId.length}
          handleForward={handleForward}
          handleModalOpen={handleModalOpen}
          handleForwardSocket={handleForwardSocket}
        
        />
      ) : (
        <form onSubmit={handleSubmit}>
          <Box
            height={"8%"}
            display="flex"
            alignItems="center"
            px={2}
            paddingTop={"5px"}
          >
            <TextField
              size="small"
              variant="outlined"
              sx={{ flexGrow: 1 }}
              value={message}
              onChange={handleMessages}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <label htmlFor="upload-image">
                      <IconButton component="span">
                        <AttachFileIcon />
                      </IconButton>
                    </label>
                    <IconButton onClick={toggleEmojiPicker}>
                      <EmojiEmotionsIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <input
              accept="*/*"
              style={{ display: "none" }}
              id="upload-image"
              type="file"
              onChange={handleImageUpload}
            />
            <Button
              variant="contained"
              color="primary"
              sx={{
                borderRadius: "50%",
                minWidth: 40,
                width: 40,
                height: 40,
                padding: 0,
                bgcolor: "blue",
                marginLeft: 1,
              }}
              disabled={!message?.trim()}
              type="submit"
            >
              <SendIcon sx={{ color: "white" }} />
            </Button>
          </Box>
        </form>
      )}
      {openModal && (
        <UserSelectionModule open={openModal} handleClose={handleModalClose} />
      )}

      {showEmojiPicker && (
        <Box position="absolute" bottom="60px" right="20px">
          <Picker onEmojiClick={handleEmojiClick} />
        </Box>
      )}
    </>
  );
};

export default ChatBody;
