import React from "react";
import Dotted from "../../../Images/TopBar/blueDots.png";
import Style from "../../../Style/TopBar/TopBar.module.css";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Divider } from "@mui/material";

const ViewDropDown = ({ anchor, handleIconClose, openFullscreen }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const options = [
    {
      label: "View Full Screen",
      id: "#viewfull",
      onClick: (e) => {
        openFullscreen();
      },
    },
    {
      label: "Delete",
      id: "#delete",
      onClick: () => {},
    },
  ];
  return (
    <>
      <div
        className={Style.dots}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <img src={Dotted} alt="dotted" />
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        MenuListProps={{
          style: {
            padding: 0,
            border: "1px solid #D1DAFF",
            borderRadius: "4px !important",
          },
        }}
      >
        {options.map((data, index) => (
          <div key={data.id}>
            <MenuItem
              onClick={() => {
                handleClose();
                data.onClick();
              }}
              key={data.id}
              sx={{
                height: "38px",
                width: "131px",
                padding: "12px",
                color: data.id === "#viewfull" ? "#000720" : "#646464",
              }}
              anchorEl={anchor}
              open={Boolean(anchor)}
              onClose={handleIconClose}
            >
              {data.label}
            </MenuItem>
            <Divider style={{ margin: 0, borderColor: "#00000042" }} />
          </div>
        ))}
      </Menu>
    </>
  );
};

export default ViewDropDown;
