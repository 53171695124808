import React from "react";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

const StyledBox = styled(Box)(() => ({
  height: "48px",
  background: "#000AFF",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontWeight: "600",
  fontSize: "16px",
  color: "white",
}));
const Header = ({ handleClickClose, isEdit }) => {
  return (
    <StyledBox>
      <div style={{ marginLeft: "30px" }}>
        {isEdit ? "Edit Bussiness Unit" : "Create Bussiness Unit"}
      </div>
      <IconButton
        component="span"
        onClick={handleClickClose}
        style={{ padding: 0, marginRight: 20 }}
      >
        <CloseIcon style={{ height: "16px", width: "16px", color: "white" }} />
      </IconButton>
    </StyledBox>
  );
};

export default Header;
