import React from "react";
import Style from "../../../../Style/TopBar/Kpi.module.css";

const GrowthIndex = ({ growthIndex, revenue, rate }) => {
  return (
    <div className={Style.growthIndexWrapper}>
      <div>
        <span className={Style.growthText}>{growthIndex}</span>
        <span className={Style.revenueText}>{revenue}</span>
      </div>

      <div>
        <span className={Style.rateText}>Last Month:{rate}</span>
      </div>
    </div>
  );
};

export default GrowthIndex;
