import React from "react";
import { IconButton, MenuItem, Menu, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const HoverChatDropDown = ({
  open,
  handleOpen,
  anchorEl,
  messageOptions,
  handleClose,
  messageId,
}) => {
  return (
    <>
      <IconButton onClick={handleOpen} id="basic-button" sx={{ padding: 0 }}>
        <ExpandMoreIcon style={{ fontSize: "16px" }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        {messageOptions.map((option) => (
          <MenuItem
            key={option.id}
            onClick={(event) => {
              event.stopPropagation();
              option.actionFunc(messageId);
              handleClose();
            }}
          >
            <Typography fontSize={12} fontWeight={500}>
              {option.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default HoverChatDropDown;
