import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

const ProfileImage = ({ title }) => {
  return (
    <Box p={1} >
      <Typography fontSize={12} color="#959FA3">
        {title}
      </Typography>
      <Box py={2}>
        <Box color={"blue"}></Box>
        <Box display={"flex"} justifyContent={"center"}>
          {" "}
          <div
            style={{
              borderRadius: "50%",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#D9D9D9",
              width: "100px",
              height: "100px",
            }}
            disableRipple
          >
            {false ? (
              <img
                alt="profile pic"
                style={{ borderRadius: "100px" }}
                className="image"
              />
            ) : (
              <PersonOutlineIcon style={{ fontSize: 80, color: "#000" }} />
            )}
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              hidden
            />
          </div>
        </Box>
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} py={1}>
        <Typography fontSize={14} >
        {"User 1"}
      </Typography>
        </Box>
      </Box>
    
    </Box>
  );
};

export default ProfileImage;
