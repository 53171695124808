import React from "react";
import { Box } from "@mui/system";
import UserAvatar from "./UserAvatar";
import { Typography } from "@mui/material";
import { useOnlineStatusStore } from "../../../../store/teamChatStore";

const UsersBar = ({ user, selected, onClick }) => {
  console.log("selectedUserId", selected);
  console.log("username", user);
  const { onlineStatusIds } = useOnlineStatusStore(); // Get online status IDs

  const isOnline = onlineStatusIds.includes(user.userId);
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      p={1}
      sx={{
        cursor: "pointer",
        backgroundColor: selected ? "#c8dbe8" : "transparent",
        "&:hover": {
          backgroundColor: selected ? "#c8dbe8" : "#f0f0f0",
        },
        borderRadius: "4px",
      }}
      onClick={onClick}
      tabIndex={0}
    >
      <UserAvatar isOnline={isOnline} />
      <Box display={"flex"} flexDirection={"column"} ml={1}>
        <Typography
          fontSize={(theme) => theme.typography.teamChatTitle.fontSize}
          fontWeight={500}
          variant="p"
          color={"black"}
        >
          {user?.firstName} {user?.lastName}
        </Typography>
        <Typography fontSize={"14px"} variant="p" color={"#9aa1a6"}>
          {user?.email}
        </Typography>
      </Box>
    </Box>
  );
};

export default UsersBar;
