import * as React from "react";
import Grid from "@mui/material/Grid";
import Wrapper from "../../Components/Wrapper";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import UsersAction from "./components/UsersAction";

const EmailAnalytics = () => {
  const initialChartItems = [
    [
      {
        id: "emailSend",
        component: (
          <Wrapper
            title={"Emails Send - This Month"}
            count={1}
            percentage={"100%"}
            rate={"0"}
            noData={true}
          />
        ),
      },
      {
        id: "userSend",
        component: (
          <UsersAction
            title={"Users vs Emails Sent"}
            count={1}
            percentage={"100%"}
            rate={"0"}
            noData={true}
          />
        ),
      },
    ],
    [
      {
        id: "emailOpened",
        component: (
          <Wrapper
            title={"Emails Opened - This Month"}
            count={1}
            percentage={"100%"}
            rate={"0"}
            noData={true}
          />
        ),
      },
      {
        id: "userOpened",
        component: (
          <UsersAction
            title={"Users vs Emails Opened"}
            count={1}
            rate={"0"}
            percentage={"100%"}
            noData={true}
          />
        ),
      },
    ],
    [
      {
        id: "emailClicked",
        component: (
          <Wrapper
            title={"Emails Clicked - This Month"}
            count={1}
            rate={"0"}
            percentage={"100%"}
            noData={true}
          />
        ),
      },
      {
        id: "Users vs Email Clicked",
        component: (
          <UsersAction
            title={"Top 5 Users - Pipelines Lost"}
            count={1}
            rate={"0"}
            percentage={"100%"}
            noData={true}
          />
        ),
      },
    ],
  ];

  const [chartItems, setChartItems] = React.useState(initialChartItems);
  function splitIndex(columnString) {
    const Index = columnString.split("-");
    return Index;
  }

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const sourceIndex = splitIndex(result.source.droppableId);
    const destinationIndex = splitIndex(result.destination.droppableId);
    const Source = chartItems[sourceIndex[1]][sourceIndex[2]];
    const Destination = chartItems[destinationIndex[1]][destinationIndex[2]];
    const tempArray = [...chartItems];
    if (
      chartItems[sourceIndex[1]].length === 1 ||
      chartItems[destinationIndex[1]].length === 1
    ) {
      tempArray[sourceIndex[1]] = chartItems[destinationIndex[1]];
      tempArray[destinationIndex[1]] = chartItems[sourceIndex[1]];
    } else {
      tempArray[sourceIndex[1]][sourceIndex[2]] = Destination;
      tempArray[destinationIndex[1]][destinationIndex[2]] = Source;
    }

    setChartItems(tempArray);
  };

  return (
    <DragDropContext onDragEnd={(result, index) => handleOnDragEnd(result, 0)}>
      <Grid container spacing={1}>
        {chartItems.map((column, columnIndex) => (
          <Grid container item spacing={1} xs={3}>
            {column.map(({ id, component }, index) => (
              <Grid item xs={12} key={id}>
                <Droppable
                  droppableId={`column-${columnIndex}-${index}`}
                  direction="vertical"
                >
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <Draggable key={id} draggableId={id} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {component}
                          </div>
                        )}
                      </Draggable>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    </DragDropContext>
  );
};

export default EmailAnalytics;
