import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Axios from "axios";
import ForumIcon from "@mui/icons-material/Forum";
import { Avatar, Typography } from "@mui/material";
import { Tabs, Tab } from "@mui/material";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import { create } from "zustand";
import BusinessIcon from "@mui/icons-material/Business";
import AIBOTIMAGE from "../../../../Images/aibot.png";
import { useQuery, useMutation } from "@tanstack/react-query";

const useStore = create((set) => ({
  activeTab: 0,
  setActiveTab: (newTab) => set({ activeTab: newTab }),
}));

const style = {
  position: "absolute",
  display: "flex",
  flexDirection: "row",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: 580,
  bgcolor: "background.paper",
  borderRadius: "2%",
  boxShadow: 24,
  p: 4,
  gap: "20px",
};

const ConfigModal = ({ open, onClose, website }) => {
  const [isChatOpen, setIsChatOpen] = useState(true);
  // Zustand store for managing the active tab
  const activeTab = useStore((state) => state.activeTab);
  const setActiveTab = useStore((state) => state.setActiveTab);

  const [formData, setFormData] = useState({
    websiteId: website._id,
    notifications: true,
    buttonColor: "#0000FF",
    primaryColor: "linear-gradient(to bottom, #000000, #0000FF)", // Gradient
    primaryColorStart: "#000000", // Start color
    primaryColorEnd: "#0000FF", // End color
    welcomeMessage: "Hello! How can I assist you today?",
    backgroundImage: "",
    uploadedImage: null,
  });
  const websiteName = website.name;

  // Query to fetch existing configuration
  const queryKey = ["chatBoxConfig", website._id];

  const queryFn = async () => {
    const response = await Axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/chatBox/website/${website._id}`
    );
    console.log("response data from chatbox config:", response.data);
    return response.data;
  };

  // Use object form for `useQuery`
  const {
    data: configData,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey,
    queryFn,
    enabled: true, // Set to false initially to control refetch manually
  });

  const createMutation = useMutation({
    mutationFn: (newConfig) =>
      Axios.post(`${process.env.REACT_APP_API_BASE_URL}/chatBox`, newConfig),
    onSuccess: () => {
      console.log("Configuration created successfully!");
      onClose(); // Close modal after successful creation
    },
    onError: (error) => {
      console.error("Error creating configuration:", error);
    },
  });

  // Mutation for updating the configuration
  const updateMutation = useMutation({
    mutationFn: (updatedConfig) =>
      Axios.put(`${process.env.REACT_APP_API_BASE_URL}/chatBox`, updatedConfig),
    onSuccess: () => {
      console.log("Configuration updated successfully!");
      onClose(); // Close modal after successful update
    },
    onError: (error) => {
      console.error("Error updating configuration:", error);
    },
  });

  useEffect(() => {
    if (configData) {
      const primaryColor =
        configData.data.primaryColor ||
        "linear-gradient(to bottom, #000000, #0000FF)";
      const colorMatch = primaryColor.match(
        /linear-gradient\(to bottom, (#[0-9A-Fa-f]{6}), (#[0-9A-Fa-f]{6})\)/
      );
      const primaryColorStart = colorMatch ? colorMatch[1] : "#000000";
      const primaryColorEnd = colorMatch ? colorMatch[2] : "#0000FF";

      setFormData((prevFormData) => ({
        ...prevFormData,
        websiteId: configData.data.websiteId._id || website._id,
        notifications: configData.data.notifications || true,
        buttonColor: configData.data.buttonColor || "#0000FF",
        primaryColorStart: primaryColorStart,
        primaryColorEnd: primaryColorEnd,
        primaryColor: primaryColor,
        welcomeMessage:
          configData.data.welcomeMessage ||
          "Hello! How can I assist you today?",
        backgroundImage: configData.data.backgroundImage || "",
        uploadedImage: configData.data.uploadedImage || null,
      }));
    }
  }, [configData, website._id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    let newFormData = {
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    };

    // Update primaryColor based on selected colors
    if (name === "primaryColorStart" || name === "primaryColorEnd") {
      newFormData.primaryColor = `linear-gradient(to bottom, ${newFormData.primaryColorStart}, ${newFormData.primaryColorEnd})`;
    }

    setFormData(newFormData);
  };

  const toggleChatWindow = () => {
    setIsChatOpen((prevState) => !prevState);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prev) => ({
          ...prev,
          uploadedImage: reader.result, // Set the uploaded image data
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (configData) {
      // If configuration data exists, update it
      updateMutation.mutate(formData);
    } else {
      // If configuration data does not exist, create it
      createMutation.mutate(formData);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <div>
          <h2>ChatBot Configuration</h2>
          <form onSubmit={handleSubmit}>
            <TextField
              name="websiteId"
              label="Website ID"
              value={
                formData.websiteId
                  ? "*".repeat(formData.websiteId.length - 4) +
                    formData.websiteId.slice(-4)
                  : ""
              }
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              disabled
            />
            <TextField
              name="welcomeMessage"
              label="Welcome Message"
              value={formData.welcomeMessage}
              onChange={handleChange}
              fullWidth
              margin="normal"
              multiline 
              rows={3} 
            />
            <TextField
              name="buttonColor"
              label="Button Color"
              type="color"
              value={formData.buttonColor}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "15px",
              }}
            >
              <TextField
                name="primaryColorStart"
                label="Primary Color Start"
                type="color"
                value={formData.primaryColorStart}
                onChange={handleChange}
                fullWidth
                margin="normal"
                sx={{
                  flex: "1",
                }}
              />
              <TextField
                name="primaryColorEnd"
                label="Primary Color End"
                type="color"
                value={formData.primaryColorEnd}
                onChange={handleChange}
                fullWidth
                margin="normal"
                sx={{
                  flex: "1",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <label
                style={{
                  width: "60%",
                }}
              >
                <input
                  name="notifications"
                  type="checkbox"
                  checked={formData.notifications}
                  onChange={handleChange}
                />
                Enable Notifications
              </label>
              <label
                style={{
                  flex: "1",
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ margin: "10px 0" }}
                />
              </label>
            </div>
            <br />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginTop: "15px" }}
            >
              {configData ? "Update" : "Create"} Configuration
            </Button>
          </form>
        </div>
        <div
          style={{
            width: "45vw",
            height: "auto",
            border: "1px solid rgba(0,0,0,0.2)",
            borderRadius: "5px",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              bottom: 5,
              right: 18,
              width: "45px",
              background: formData.buttonColor,
              height: "45px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={toggleChatWindow}
          >
            <ForumIcon sx={{ color: "white" }} />
          </div>
          {isChatOpen && (
            <div
              style={{
                position: "absolute",
                bottom: "40px",
                right: "55px",
                width: "350px",
                height: "450px",

                border: "1px solid rgba(0,0,0,0.2)",
                borderRadius: "15px",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div style={{ height: "93%", width: "100%" }}>
                <div
                  style={{
                    height: "84%",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",

                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",

                      background: formData.primaryColor,
                    }}
                  >
                    <div
                      style={{
                        height: "75%",
                        width: "auto",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        padding: "15px",
                      }}
                    >
                      <div
                        style={{
                          height: "45px",
                          width: "45px",
                          background: "grey",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "relative", // Allows positioning within the div
                        }}
                      >
                        {/* If an image is uploaded, show it; otherwise, show the BusinessIcon */}
                        {formData.uploadedImage ? (
                          <img
                            src={formData.uploadedImage}
                            alt="Uploaded"
                            style={{
                              height: "100%",
                              width: "100%",
                              borderRadius: "50%", // Ensures the uploaded image is circular
                              objectFit: "cover", // Ensures the image covers the circle
                            }}
                          />
                        ) : (
                          <BusinessIcon sx={{ color: "white" }} />
                        )}
                      </div>
                      <div
                        style={{
                          marginBottom: "40px",
                          padding: "5px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "white",
                            fontSize: "24px",
                            fontWeight: "bolder",
                          }}
                        >
                          {websiteName}
                        </Typography>
                        <Typography
                          sx={{
                            color: "white",
                          }}
                        >
                          {formData.welcomeMessage
                            .split("\n")
                            .map((line, index) => (
                              <div key={index}>{line}</div>
                            ))}
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: "25%",
                        borderRadius: "20px 20px 0px 0px",
                        background: "#F7F6F2",
                        display: "flex",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          width: "80%",
                          height: "80%",
                          position: "absolute",
                          background: "white",
                          bottom: "50px",
                          left: "35px",
                          borderRadius: "15px",
                          display: "flex",
                          alignItems: "center",
                          padding: "10px",
                          gap: "15px",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{
                            height: "45px",
                            width: "45px",
                            border: "3px solid #000AF0",
                            borderRadius: "50%",
                            position: "relative",
                          }}
                        >
                          <Avatar
                            src={AIBOTIMAGE}
                            sx={{ position: "absolute" }}
                          />
                        </div>
                        <Typography sx={{ fontSize: "16px" }}>
                          Start a new conversation
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    height: "16%",
                    borderTop: "1px solid rgba(0,0,0,0.2)",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tabs
                    value={activeTab}
                    onChange={(event, newValue) => setActiveTab(newValue)}
                    centered
                    indicatorColor="primary"
                    textColor="primary"
                    sx={{
                      "& .MuiTabs-indicator": {
                        backgroundColor: "#000AFF",
                        minWidth: "5px",
                        height: "2px",
                        borderRadius: "15px",
                      },
                      height: "100%",
                      width: "100%",
                      borderTop: "1px solid rgba(0,0,0,0.2)",
                    }}
                  >
                    <Tab
                      icon={<HomeRoundedIcon />}
                      label="Home"
                      sx={{
                        flex: 1,
                        "& .MuiTab-iconWrapper": {
                          transition: "transform 0.2s ease-in-out",
                        },
                        "&:hover .MuiTab-iconWrapper": {
                          transform: "scale(1.3)",
                        },
                      }}
                    />
                    <Tab
                      icon={<CommentRoundedIcon />}
                      label="Conversations"
                      sx={{
                        flex: 1,
                        "& .MuiTab-iconWrapper": {
                          transition: "transform 0.2s ease-in-out",
                        },
                        "&:hover .MuiTab-iconWrapper": {
                          transform: "scale(1.3)",
                        },
                      }}
                    />
                    <Tab
                      icon={<DescriptionRoundedIcon />}
                      label="Articles"
                      sx={{
                        flex: 1,
                        "& .MuiTab-iconWrapper": {
                          transition: "transform 0.2s ease-in-out",
                        },
                        "&:hover .MuiTab-iconWrapper": {
                          transform: "scale(1.3)",
                        },
                      }}
                    />
                  </Tabs>
                </div>
              </div>
              <div
                style={{
                  height: "7%",
                  borderTop: "1px solid rgba(0,0,0,0.2)",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                  }}
                >
                  Driven by Iaaxin
                </Typography>
              </div>
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default ConfigModal;
