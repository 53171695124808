import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Button from "../../../../components/Button";
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import RedAsterisk from "../../../../components/RedAsterisk";
import MyCheckbox from "../../../../components/CheckBox";
import WebSettings from "./WebSettings";
import People from "../../../../Images/People.png";
import Settings from "../../../../Images/Settings.png";
import threedots from "../../../../Images/threedotsmenu.png";
import integration from "../../../../Images/Integration.png";
import Edit from "../../../../Images/Edit.png";
import trash from "../../../../Images/Trashcan.png";
import useSelectedWorkSpace from "../../../../../../../store/selectedWorkSpaceStore";
import axios from "axios";
import { useQuery, useMutation } from "@tanstack/react-query";
import SettingsIcon from '@mui/icons-material/Settings';
import EmployeeListDialog from "./EmployeeListDialog";

function WebsiteSettings() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isDialogOpen2, setDialogOpen2] = useState(false);
  const [selectedWebsite, setSelectedWebsite] = useState(null);

  const selectedWorkspace = useSelectedWorkSpace((state) =>
    state.getSelectedWorkSpace()
  );
  const workspaceId = selectedWorkspace?.id || "";


  const fetchWebsites = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/websites/workspace/${workspaceId}`
    );
    return response.data;
  };


  const { data: websites = [], refetch } = useQuery({
    queryKey: ["websites", workspaceId],
    queryFn: fetchWebsites,
    enabled: !!workspaceId,
  });

  const handleEmployeeList = () => {
    setDialogOpen2(true);
  };
  const handleTableMenu = (event) => {
    setAnchorEl(event.currentTarget);
    
  };
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleCloseDialog2 = () => {
    setDialogOpen2(false);
  };
  const handleBackToWebsiteSettings = () => {
    setSelectedWebsite(null);
  };

  const handleWebsiteSettings = (website) => {
    setSelectedWebsite(website);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleDialogClose = (formikProps, isSubmitting = false) => {
    if (!isSubmitting) {
      formikProps.resetForm();
    }
    setDialogOpen(false);
    setDialogOpen(false);
  };

  const addWebsite = async (values) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/websites`,
      {
        name: values.name,
        url: values.url,
        workspace: workspaceId,
      }
    );

    return response.data;
  };


  const mutation = useMutation({
    mutationFn: addWebsite,
    onSuccess: () => {
      refetch();
      setDialogOpen(false);
    },
    onError: (error) => {
      console.error("Error adding website:", error);

    },
  });
  const deleteWebsite = async (websiteId) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/websites/${websiteId}`
    );
    return response.data;
  };
  const deleteMutation = useMutation({
    mutationFn: deleteWebsite,
    onSuccess: () => {
      refetch(); // Refetch the websites list after deletion
      handleCloseMenu(); // Close the menu after deletion
    },
    onError: (error) => {
      console.error("Error deleting website:", error);
    },
  });

  const iconStyle = {
    width: "5vw",
    height: "5vw",
    maxWidth: "25px",
    maxHeight: "25px",
    marginRight: "10px",
    cursor: "pointer",
  };
  const menuStyle = {
    width: "1vw",
    marginRight: "10%",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Website Name is required"),
    url: Yup.string().required("Website url is required"),
    
  });
  const containerValue = true;
  return (
    <div
      style={{

        height: "auto",
        overflow: "auto"
      }}
    >
      {selectedWebsite ? (
        <WebSettings
          website={selectedWebsite}
          handleBackToWebsiteSettings={handleBackToWebsiteSettings}
        />
      ) : (
        <div
          style={{
            padding: "4% 3%",

          }}
        >
          <div
            style={{

              overflowY: "auto",

              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{

                width: "100%",
                height: "9vh",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{
                  fontWeight: "bolder",
                }}
              >
                Website Settings
              </Typography>
              <span>
                <Button onClick={handleDialogOpen}>Add Website</Button>
              </span>
            </div>
            <div
              style={{
                flex: "1",
              }}
            >
              <Table
                style={{
                  border: "1px solid #efefef",
                }}
              >
                <TableHead style={{ background: "rgba(245, 245, 248, 1)" }}>
                  <TableRow>
                    <TableCell colSpan={5}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          height: "35px",
                          padding: "0px 10px",
                          gap: "10px",
                        }}
                      >
                        <span>Website Memberships</span>
                        <span>
                          You have memberships on those websites. Choose a
                          website to view its configuration.
                        </span>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {websites.map((website, index) => (
                    <TableRow key={index}>
                      <TableCell
                        style={{
                          height: "10px",
                          width: "20px",

                          padding: "5px 12px",
                        }}
                      >
                        <svg width="50" height="50">
                          <circle
                            cx="25"
                            cy="25"
                            r="20"
                            stroke="rgba(245,245,248,1)"
                            fill="rgba(245, 245, 248, 1)"
                          />
                          <text
                            x="50%"
                            y="50%"
                            dominantBaseline="middle"
                            textAnchor="middle"
                            fill="black"
                            fontSize="20px"

                          >
                            {website.name.charAt(0)}
                          </text>
                        </svg>
                      </TableCell>
                      <TableCell
                        style={{
                          padding: "3px 16px",
                        }}
                      >
                        <Typography>{website.name}</Typography>
                        <p
                          style={{
                            margin: "0",
                          }}
                        >
                          {website.domain}
                        </p>
                      </TableCell>
                      <TableCell
                        style={{
                          padding: "2px 16px",
                        }}
                      >
                        <img
                          src={People}
                          alt="Organization"
                          style={iconStyle}
                          onClick={handleEmployeeList}
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          padding: "2px 16px",
                        }}
                      >

                        <SettingsIcon style={iconStyle} onClick={() => handleWebsiteSettings(website)} />
                      </TableCell>
                      <TableCell
                        style={{
                          padding: "2px 16px",
                        }}
                      >
                        <img
                          src={threedots}
                          alt="Organization"
                          style={iconStyle}
                          onClick={handleTableMenu}
                          
                        />
                      </TableCell>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                        sx={{
                          ".css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper":
                          {
                            boxShadow: "0px 3px 3px 0px rgba(0,0,0,0.1)",
                          },
                        }}
                      >
                        <MenuItem onClick={handleCloseMenu}>
                          <img
                            src={integration}
                            alt="Organization"
                            style={menuStyle}
                          />
                          Integrations
                        </MenuItem>
                        <MenuItem onClick={handleCloseMenu}>
                          <img
                            src={Edit}
                            alt="Organization"
                            style={menuStyle}
                          />
                          Edit
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            if (website) {
                              if (window.confirm("Are you sure you want to delete this website?")) {
                                deleteMutation.mutate(website._id); // Use the selected website's ID
                              }
                            }
                            handleCloseMenu();
                          }}
                        >
                          <img src={trash} alt="Delete" style={menuStyle} />
                          Delete
                        </MenuItem>
                      </Menu>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <Dialog
                open={isDialogOpen}
                onClose={handleDialogClose}
                maxWidth="sm"
                fullWidth
              >
                <DialogTitle style={{ fontSize: "calc(0.4vw + 10px)" }}>
                  Add Website
                </DialogTitle>
                <DialogContent>
                  <Formik
                    initialValues={{
                      name: "",
                      url: "",
                      workspace: workspaceId,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      mutation.mutate(values);
                      setSubmitting(false);
                    }}
                  >
                    {({ isSubmitting, ...formikProps }) => (
                      <Form>
                        <div style={{ padding: "2%" }}>
                          <Typography style={{ padding: "1% 0%" }}>
                            Website Name
                            <RedAsterisk />
                          </Typography>
                          <Field
                            type="text"
                            name="name"
                            placeholder="Enter Website Name"
                            as={TextField}
                            fullWidth
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            style={{ color: "red" }}
                          />
                          <Typography style={{ padding: "1% 0%" }}>
                            Website URL
                            <RedAsterisk />
                          </Typography>
                          <Field
                            type="text"
                            name="url"
                            placeholder="Enter Website URL"
                            as={TextField}
                            fullWidth
                          />
                          <ErrorMessage
                            name="url"
                            component="div"
                            style={{ color: "red" }}
                          />
                          <Typography style={{ padding: "1% 0%" }}>
                            WorkspaceId
                            <RedAsterisk />
                          </Typography>
                          <TextField
                            type="text"
                            name="workspaceId"
                            placeholder="Enter Workspace Id"
                            value={workspaceId}
                            fullWidth
                            disabled
                          />
                          <ErrorMessage
                            name="workspaceId"
                            component="div"
                            style={{ color: "red" }}
                          />
                        </div>
                        <DialogActions>
                          <Button
                            onClick={() =>
                              handleDialogClose(formikProps, isSubmitting)
                            }
                            color="primary"
                          >
                            <CloseIcon />
                          </Button>
                          <Button
                            type="submit"
                            color="primary"
                            disabled={isSubmitting}
                          >
                            Add
                          </Button>
                        </DialogActions>
                      </Form>
                    )}
                  </Formik>
                </DialogContent>
              </Dialog>
              {/* <Dialog
                open={isDialogOpen2}
                onClose={handleCloseDialog2}
                maxWidth="sm"
                fullWidth
              >
                <div
                  style={{
                    padding: "10px",
                        </div>
                        <DialogActions>
                          <Button
                            onClick={() =>
                              handleDialogClose(formikProps, isSubmitting)
                            }
                            color="primary"
                          >
                            <CloseIcon />
                          </Button>
                          <Button
                            type="submit"
                            color="primary"
                            disabled={isSubmitting}
                          >
                            Add
                          </Button>
                        </DialogActions>
                      </Form>
                    )}
                  </Formik>
                </DialogContent>
              </Dialog>
              {/* <Dialog
                open={isDialogOpen2}
                onClose={handleCloseDialog2}
                maxWidth="sm"
                fullWidth
              >
                <div
                  style={{
                    padding: "10px",

                    background: "rgba(0, 10, 255, 1)",
                    top: "10px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                >
                  <CloseIcon
                    onClick={handleCloseDialog2}
                    sx={{
                      marginLeft: "95%",
                      color: "white",
                    }}
                  />
                </div>
                <DialogTitle>Employee List</DialogTitle>
                <DialogContent>
                  <Paper elevation={2}>
                    <Table container={containerValue.toString()}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Action</TableCell>
                          <TableCell>Employee Name</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Array.from({ length: 10 }, (_, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <MyCheckbox />
                            </TableCell>
                            <TableCell>Employee {index + 1}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Paper>
                </DialogContent>
                <DialogActions>
                  <Button
                    color="primary"
                    style={{ boxShadow: "0 3px 5px rgba(0, 0, 0, 0.1)" }}
                  >
                    Submit
                  </Button>
                  <Button
                    onClick={handleCloseDialog2}
                    variant="contained"
                    style={{
                      boxShadow: "0 3px 5px rgba(0, 0, 0, 0.1)",
                      background: "rgba(0,10,255,1)",
                    }}
                  >
                    Close
                  </Button>
                </DialogActions>
                <div
                  style={{
                    border: "1px solid transparent",
                    padding: "15px",
                    borderRadius: "5px",
                    background: "rgba(245, 245, 248, 1)",
                  }}
                ></div>
              </Dialog> */}
              <EmployeeListDialog open={isDialogOpen2} onClose={handleCloseDialog2} website={selectedWebsite} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default WebsiteSettings;
