// import { Grid } from "@mui/material";
// import React, { useState, forwardRef } from "react";
// import EmailForm from "./EmailForm";
// import EmailGuide from "./EmailGuide";
// // import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
// // import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
// import NewEmailForm from "./NewEmailForm";
// import FollowUp from "./element/FollowUp";
// import {  useQuery } from "@tanstack/react-query";
// import { stepList } from "../../../webservices/sequence";
// import { setNewSequenceIdStore } from "../../../store/userStore";

// const SequeLayout = forwardRef((props, ref) => {    //closeFun, data,newCampaign
//   // const [isToggled, setIsToggled] = useState(false);
//   const { newSequenceId } = setNewSequenceIdStore();
//   const [value, setValue] = useState(1);
//   const [variantData, setVariantData] = useState("");
//   // const handleIconClick = () => {
//   //   setIsToggled((prev) => !prev);
//   // };

//   const leftGridSize = props.isToggled ? 3 : 3;
//   const centerGridSize = props.isToggled ? 9 : 6;
//   const rightGridSize = props.isToggled ? 0 : 3;
//   // const icon = isToggled ? (
//   //   <ArrowBackIosNewOutlinedIcon sx={{ fontSize: "14px" }} />
//   // ) : (
//   //   <ArrowForwardIosOutlinedIcon sx={{ fontSize: "14px" }} />
//   // );
//   // const iconLeftPosition = isToggled ? "96.8%" : "69.4%";
//   const stepData = useQuery({
//     queryKey: ["stepList", newSequenceId],
//     queryFn: () => stepList(newSequenceId),
//     enabled: !!newSequenceId,
//   });

//   return (
//     <div style={{ height: "100%" }}>
//       {/* <Box
//         sx={{
//           position: "absolute",
//           left: iconLeftPosition,
//           top: "140px",
//           backgroundColor: "#F1F1F1",
//           borderRadius: "50%",
//           width: "20px",
//           alignItems: "center",
//           display: "flex",
//           justifyContent: "center",
//           height: "20px",
//           cursor: "pointer",
//           zIndex: 1,
//         }}
//         onClick={handleIconClick}
//       >
//         {icon}
//       </Box> */}
//       <Grid container>
//       <Grid item sm={leftGridSize}>
//       {<FollowUp stepData={stepData}  value={value} setValue={setValue} variantData={variantData} setVariantData={setVariantData}/>}
//         </Grid>
//         <Grid item sm={centerGridSize}>
//           {props.newCampaign ?
//           <NewEmailForm data={props.data} ref={ref}  value={value} setValue={setValue} variantData={variantData}/> :
//           <EmailForm fcloseFun={props.closeFun} data={props.data}  value={value} setValue={setValue} variantData={variantData}/> }
//         </Grid>
//         <Grid item sm={rightGridSize}>
//           {props.isToggled ? "" : <EmailGuide />}
//         </Grid>
//       </Grid>
//     </div>
//   );
// });

// export default SequeLayout;

import { Grid } from "@mui/material";
import React, { useState, forwardRef } from "react";
import EmailForm from "./EmailForm";
import EmailGuide from "./EmailGuide";
import NewEmailForm from "./NewEmailForm";
import FollowUp from "./element/FollowUp";
import { useQuery } from "@tanstack/react-query";
import { stepList } from "../../../webservices/sequence";
import { setNewSequenceIdStore } from "../../../store/userStore";

const SequeLayout = forwardRef((props, ref) => {
  const { newSequenceId } = setNewSequenceIdStore();

  const [variantData, setVariantData] = useState("");

  const stepData = useQuery({
    queryKey: ["stepList", newSequenceId],
    queryFn: () => stepList(newSequenceId),
    enabled: !!newSequenceId
  });

  const leftGridSize = !props.data ? (props.isToggled ? 3 : 3) : 0;
  const centerGridSize = !props.data
    ? props.isToggled ? 9 : 6
    : props.isToggled ? 12 : 9;
  const rightGridSize = props.isToggled ? 0 : 3;

  return (
    <div style={{ height: "100%" }}>
      <Grid container>
        {leftGridSize > 0 &&
          <Grid item sm={leftGridSize}>
            <FollowUp
              stepData={stepData}
              value={props.value}
              setValue={props.setValue}
              variantData={variantData}
              setVariantData={setVariantData}
            />
          </Grid>}
        <Grid item sm={centerGridSize}>
          {props.newCampaign
            ? <NewEmailForm
                data={props.data}
                ref={ref}
                value={props.value}
                setValue={props.setValue}
                variantData={variantData}
              />
            : <EmailForm
                fcloseFun={props.closeFun}
                data={props.data}
                value={props.value}
                setValue={props.setValue}
                variantData={variantData}
              />}
        </Grid>

        <Grid item sm={rightGridSize}>
          <EmailGuide />
        </Grid>
      </Grid>
    </div>
  );
});

export default SequeLayout;
