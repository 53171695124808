 export const WithHoc =
  (BaseComponent, ChildComponent) => (props) => {
    const baseElements = (
      <BaseComponent {...props}>
        <ChildComponent {...props}/>
      </BaseComponent>
    );
    return baseElements;
  };

