import React from "react";
import Grid from "@mui/material/Grid";
import Panel from "./Panel/Panel";
import LoginPage from "./LogIn/LoginPage";
import VerifyMail from "./VerifyMail";
import ResetPassword from "./ResetPassword";
import { useLocation } from "react-router-dom";
import Hidden from "@mui/material/Hidden";
import { Routes, Route } from "react-router-dom";
export default function Login() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const token = params.get("token");
  const id = params.get("id");

  return (
    <>
      <div style={{ height: "100vh", width: "100vw" }}>
        <Grid container>
          <Hidden smDown>
            <Grid item md={9} sm={8} xs={12}>
              <Panel />
            </Grid>
          </Hidden>
          <Grid item md={3} sm={4} xs={12}>
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route
                path="resetpassword"
                element={<ResetPassword token={token} id={id} />}
              />
              <Route path="verifymail" element={<VerifyMail />} />
            </Routes>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
