import React from "react";
import { Button, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete"; // Icon for "Delete Chat"
import BlockIcon from "@mui/icons-material/Block"; // Icon for "Block"
import ReportIcon from "@mui/icons-material/Report"; // Icon for "Report"

const Actions = () => {
  return (
    <Stack
      spacing={1}
      sx={{
        width: "100%",
      }}
    >
      <Button
        variant="text"
        startIcon={<BlockIcon sx={{ paddingLeft: "5px" }} />}
        sx={{
          width: "100%",
          color: "red",
          padding: "5px",
          justifyContent: "flex-start",
          "&:hover": {
            backgroundColor: "rgba(255, 0, 0, 0.1)",
          },
        }}
      >
        Block
      </Button>
      <Button
        variant="text"
        startIcon={<ReportIcon sx={{ paddingLeft: "5px" }} />}
        sx={{
          width: "100%",
          color: "red",
          padding: "5px",
          justifyContent: "flex-start",
          "&:hover": {
            backgroundColor: "rgba(255, 0, 0, 0.1)", // Light red background on hover
          },
        }}
      >
        Report
      </Button>
      <Button
        variant="text"
        startIcon={<DeleteIcon sx={{ paddingLeft: "5px" }} />}
        sx={{
          width: "100%",
          color: "red",
          justifyContent: "flex-start",
          padding: "5px",
          "&:hover": {
            backgroundColor: "rgba(255, 0, 0, 0.1)", // Light red background on hover
          },
        }}
      >
        Delete Chat
      </Button>
    </Stack>
  );
};

export default Actions;
