import React from "react";
import Styles from "../../components/Dashboard.module.scss";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  BarChart,
} from "recharts";

const data = [
  {
    notStarted: 2,
    date: "05/11/2024",
  },
];
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { date, notStarted } = payload[0].payload;
    return (
      <div
        style={{
          background: "#fff",
          padding: "5px 20px 5px 20px",
          border: "1px solid #60A3BC",
        }}
      >
        <p
          style={{ fontSize: "16px", margin: "0", fontWeight: "600" }}
        >{`${date}`}</p>
        <p
          style={{ fontSize: "14px", margin: "0" }}
        >{`Not Started : ${notStarted}`}</p>
      </div>
    );
  }

  return null;
};

const ClosingDateChart = ({ title }) => {
  const maxUv = Math.max(...data.map((entry) => entry.notStarted));
  const yAxisMax = maxUv === 1 ? 2 : maxUv + 1;
  return (
    <div className={Styles.chartmainwrapper}>
      <div className={Styles.title}>{title}</div>
      <div className={Styles.chartContainer}>
        <div style={{ height: 210 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={800}
              height={500}
              data={data}
              margin={{
                top: 20,
                left: 80,
                right: 50,
                bottom: 25,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                type="category"
                dataKey="date"
                label={{
                  value: "Closing Date",
                  position: "insideBottom",
                  offset: -20,
                }}
              />
              <YAxis
                dataKey="notStarted"
                axisLine={false}
                label={{
                  value: "Record Count (Activities)",
                  angle: -90,
                  position: "insideLeft",
                  offset: -50,
                  style: { textAnchor: "middle" },
                }}
                domain={[0, yAxisMax]}
              />

              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="notStarted" barSize="100%" fill="#60A3BC" />
            </BarChart>
          </ResponsiveContainer>
          <div style={{display:"flex",justifyContent:"center",marginTop:"10px"}}>
            <div style={{backgroundColor:'#60A3BC',width:"10px",height:"10px",borderRadius:"50px",margin:"5px 10px"}}></div>
            <p>Not Started</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClosingDateChart;
