import React from "react";
import styles from "../Style/CustomCheckBox.module.css";

const CustomCheckBox = ({ id, image, selectedOption, onChange }) => {
  const handleRadioChange = () => {
    onChange(id);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          width: "70%",
        }}
      >
        <div style={{margin:"10px"}}>
          <div className={styles.radio} onClick={handleRadioChange}>
            <input
              type="radio"
              value={id}
              name="paymentOption"
              checked={selectedOption === id}
            />
            <label htmlFor={id}></label>
          </div>
        </div>
        <div>
          <img src={image} alt={id} className={styles.image} />
        </div>
      </div>
    </>
  );
};

export default CustomCheckBox;
