import React from "react";
import LayOutTitle from "../Activity/Component/LayOutTitle";
import Style from "../../Style/Common.module.css";
const CustomActivityLayOut = (props) => {
  return (
    <div className={Style.commonBorder}>
      <LayOutTitle title={props.title} />
      {props.children}
    </div>
  );
};

export default CustomActivityLayOut;
