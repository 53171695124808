import React from "react";

import { useLocation, useNavigate } from "react-router-dom/dist";
import "../dashboard/Style/MyAccoundSiderBar.css";

const MyAccountSideBar = ({ ...rest }) => {
  const location = useLocation();
  const history = useNavigate();

  const isActive = (path) => location.pathname === path;

  const handleClick = (path) => {
    if (!isActive(path)) {
      history(path);
    }
  };

  return (
    <div className="MyAccountSidebar">
      <div className="MyAccountSidebar-settings">
      <h6>Settings</h6>
      </div>
 
      <div className="webServicesTab MyAccountSidebarHover" style={{ marginTop: "10px" }}>
        <div
          className={`link ${
            isActive("/myaccount/profile") ||
            isActive("/myaccount")
              ? "MyAccountSidebar-active"
              : ""
          } MyAccountSidebar-options`}
          onClick={() => handleClick("/myaccount/profile")}
        >
          <span className="MyAccountSidebar-font">Profile</span>
        </div>

        <div
          className={`link ${
            isActive("/myaccount/billing") ? "MyAccountSidebar-active" : ""
          } MyAccountSidebar-options`}
          onClick={() => handleClick("/myaccount/billing")}
        >
          <span className="MyAccountSidebar-font">Billing</span>
        </div>
      </div>
    </div>
  );
};

export default MyAccountSideBar;
