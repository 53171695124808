import React from "react";
import CustomActivityLayOut from "../Components/CustomActivityLayOut";
import Style from "../../Style/Common.module.css"
import { WithHoc } from "../Components/WithHoc";
import COGrid from "./Components/COGrid";
const COLayout = () => {
  const CoLayoUT = WithHoc(CustomActivityLayOut,COGrid)
  return (
    <div className={Style.commonPadding}>
      <CoLayoUT title={"Assigned Conversation Per Operator"} />
    </div>
  );
};

export default COLayout;
