import React from "react";
import VisitorsTableCell from "./VisitorsTableCell";
import VisitorTableHeader from "./VisitorTableHeader";
import Style from "../../../Style/Common.module.css";
const VisitorsTable = () => {
  const visitorData = [
    {
      rowid: "1",
      country: "United States",
      countrycode: "US",
      visitors: 110,
    },
    {
      rowid: "2",
      country: "Brazil",
      countrycode: "BR",
      visitors: 90,
    },
    {
      rowid: "3",
      country: "France",
      countrycode: "FR",
      visitors: 80,
    },
    {
      rowid: "4",
      country: "Canada",
      countrycode: "CA",
      visitors: 70,
    },
    {
      rowid: "5",
      country: "China",
      countrycode: "CN",
      visitors: 100,
    },
    {
      rowid: "6",
      country: "Netherlands",
      countrycode: "NL",
      visitors: 85,
    },
    {
      rowid: "7",
      country: "Germany",
      countrycode: "DE",
      visitors: 95,
    },
  ];

  return (
    <div>
      <table className={Style.CommonWidth}>
        <tr>
          <VisitorTableHeader />
        </tr>
        {visitorData.map((data) => (
          <VisitorsTableCell
            country={data.country}
            rowid={data.rowid}
            countrycode={data.countrycode}
            visitors={data.visitors}
          />
        ))}
      </table>
    </div>
  );
};

export default VisitorsTable;
