import React from "react";
import Style from "../../Style/Common.module.css";
import TempImg from "../../Images/ChatNow/TempImg.png";

const TableCell = (props) => {
  return (
    <tr
      className={`${Style.tableCellWrapper} ${Style.flex_} ${Style.commonBorder}`}
      key={`${props.convo}-${props.user}`}
    >
      <td
        className={`${Style.tableCellColumnSplit} ${Style.indexAlign} ${Style.indexText} ${Style.rightBorder}`}
      >
        {props.id}
      </td>
      <td className={`${Style.tableCellColumnSplit_main} ${Style.flex_}`}>
        <div className={Style.profilepadding}>
          <img src={TempImg} alt="profile" />
        </div>
        <div>
          <div className={Style.userNameText}>{props.userName}</div>
          <div className={Style.convoStyle}>{props.convo} conversations</div>
        </div>
      </td>
      {props.children}
    </tr>
  );
};

export default TableCell;
