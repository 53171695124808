import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function CustomSnackbar({ snackbarInfo, handleSnackbarClose }) {
  return (
    <Snackbar
      open={snackbarInfo.open}
      autoHideDuration={15000}
      onClose={handleSnackbarClose}
      // anchorOrigin={{
      //   vertical: "top",
      //   horizontal: "right",
      // }}
    >
      <Alert
        onClose={handleSnackbarClose}
        severity={snackbarInfo.severity}
        sx={{ width: "100%" }}
      >
        {snackbarInfo.message}
      </Alert>
    </Snackbar>
  );
}
