import {
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import Switch from "@mui/material/Switch";
import AntSwitch from "../../../../../../../MarketingStudio/MailsNow/CampInfo/element/Antswitch";
import LoadingButton from "@mui/lab/LoadingButton";
// import YouTubeIcon from "@mui/icons-material/YouTube";
import Smtp from "./EmailAccount.module.scss";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import {
  emailProviderGlobal,
  smtpDataGlobal,
  smtpFieldsGlobal,
} from "../../../../../../../MarketingStudio/store/userStore";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import {
  getEmailProvider,
  smtpConnectionTest,
} from "../../../../../../../MarketingStudio/webservices/sequence";

const selectOptions = [
  "Gmail/Gsuite",
  "Office 365",
  "Outlook.com / Hotmail.com / Live.com",
  "Yahoo.Mail",
  "Zoho.com",
  "Godaddy",
  "Sendgrid",
  "Yandex",
  "Other",
];
const style = {
  "& .MuiInputBase-root": {
    height: "35px", // Set your desired height
  },
  "& .MuiOutlinedInput-root": {
    padding: "0", // Adjust padding if needed
  },
  "& .MuiInputLabel-root": {
    top: "-4px", // Adjust label position if needed
  },
};
const styleSelect = {
  height: "35px", // Adjust this value as needed
  "& .MuiSelect-select": {
    padding: "4px 14px ", // Adjust padding if needed
  },
};
const schema = yup.object({
  first_name: yup
    .string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("Please Enter First Name"),
  last_name: yup
    .string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("Please enter Last Name"),
  email: yup
    .string()
    .trim()
    .lowercase()
    .email("Invalid Email")
    .required("Please Enter Email"),
  password: yup.string().trim().required("Please enter password").required(),
  smtp_host: yup
    .string()
    .max(50, "Maximum 50 characters")
    .required("Please enter SMTP Host"),
  provider: yup.string().required("Please Select Email Provider"),
  smtp_port: yup
    .number()
    .typeError("SMTP Port must be a number")
    .required("Please enter SMTP Port")
    .positive("SMTP Port must be a positive number")
    .integer("SMTP Port must be an integer"),
});

const SmtpConnect = ({ openSmtp, prevdata }) => {
  const [providerValue, setProviderValue] = useState("");
  const [smtpEncryption, setSmtpEncryption] = useState("");
  const [checked, setChecked] = useState(false);
  const [apiError, setApiError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { emailProviderValue, setEmailProviderValue } = emailProviderGlobal();
  const { setSmtpValues } = smtpDataGlobal();
  const { values, setValues } = smtpFieldsGlobal();
  const [verification, setVerification] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      provider: openSmtp ? "" : prevdata.email_provider || "",
      sender_name: openSmtp ? "" : prevdata.sender_name || "",
      email_address: openSmtp ? "" : prevdata?.email_address || "",
      smtp_host: openSmtp ? "" : prevdata?.smtp_host || "",
      smtp_port: openSmtp ? "" : prevdata?.smtp_port || "",
      smtp_password: openSmtp ? "" : prevdata?.smtp_password || "",
      smtp_encryption: openSmtp ? "" : prevdata?.smtp_encryption || "",
      first_name: openSmtp ? "" : prevdata?.first_name || "",
      last_name: openSmtp ? "" : prevdata?.last_name || "",
    },
  });

  const emailProviders = useQuery({
    queryKey: ["getEmailProvider"],
    queryFn: () => getEmailProvider(),
  });

  const providerChange = (e) => {
    if (e.target.value === 1) {
      setProviderValue(1);
      setEmailProviderValue("");
      setSmtpEncryption("");
      setValue("smtp_host", "");
      setValue("smtp_port", "");
    } else {
      setProviderValue(e.target.value);
      const result = emailProviders?.data?.data?.find(
        (item) => item._id === e.target.value
      );
      setEmailProviderValue(result);
      setSmtpEncryption(result.smtp_encryption);
      setValue("smtp_host", result.smtp_host);
      setValue("smtp_port", result.smtp_port);
      setValue("smtp_encryption", result.smtp_encryption);
    }
  };

  const toogleButtonChange = (e, value) => {
    setSmtpEncryption(value);
  };

  const mutation = useMutation({
    mutationFn: smtpConnectionTest,
    onSuccess: (data) => {
      console.log("Task added successfully:", data);
      setApiError("");
      setIsLoading(false);
      setVerification(true);
    },
    onError: (error) => {
      console.error("Error adding Task:", error.response.data);
      if (error.response.status === 535) {
        setApiError(error.response.data);
      }
      setVerification(false);
      setIsLoading(false);
    },
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    const formattedData = {
      email_provider: prevdata.email_provider || providerValue,
      smtp_host: data.smtp_host,
      smtp_port: data.smtp_port,
      email_address: data.email_address,
      smtp_password: data.smtp_password,
      sender_name: data.sender_name,
      smtp_encryption: data.smtp_encryption,
      user_name: data.user_name,
      first_name: data.first_name,
      last_name: data.last_name,
    };
    setSmtpValues(formattedData);
    mutation.mutate(formattedData);
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setValues({ ...values, [name]: value });
  };

  const checkboxChange = (event) => {
    console.log("kkkkk", values);
    setChecked(event.target.checked);
  };

  const theme = useTheme();

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  return (
    <div
      style={{
        maxHeight: "calc(90vh - 7rem)",
        overflowY: "auto",
        backgroundColor: "#f3f4f6",
        padding: ".5rem",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            borderRadius: ".5rem",
            background: "#fff",
            padding: "1rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "66%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "15.25rem",
              }}
            >
              <div style={{ display: "flex" }}>
                <span
                  style={{
                    textDecoration: "underline",
                    marginRight: ".125rem",
                    fontSize: ".875rem",
                    fontWeight: "600",
                    lineHeight: "22px",
                    color: "#1f2937",
                  }}
                >
                  SMTP Details
                </span>
                <span
                  style={{
                    fontSize: ".75rem",
                    lineHeight: "20px",
                    fontWeight: "400",
                    color: "#6c7280",
                  }}
                >
                  (to send your email)
                </span>
              </div>
              <div style={{ cursor: "pointer" }}>
                {/* <YouTubeIcon fontSize='small' style={{color:"red"}}/> */}
              </div>
            </div>
            <div style={{ marginTop: "1rem", display: "flex" }}>
              <Stack direction="row" alignItems="center">
                <AntSwitch
                  checked={checked}
                  onChange={checkboxChange}
                  inputProps={{ "aria-label": "ant design" }}
                />
              </Stack>
              <span
                style={{
                  fontSize: ".75rem",
                  color: "#1f2937",
                  fontWeight: "500",
                  paddingLeft: "4px",
                }}
              >
                Use different username
              </span>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto",
                columnGap: "1.5rem",
              }}
            >
              {checked ? (
                <div
                  style={{
                    width: "15.25rem",
                    marginTop: ".3rem",
                    position: "relative",
                  }}
                >
                  <span
                    style={{
                      fontSize: ".75rem",
                      fontWeight: "600",
                      lineHeight: "20px",
                      color: "#6b7280",
                    }}
                  >
                    User Name
                  </span>
                  <div style={{ marginTop: ".125rem" }}>
                    <Controller
                      name="user_name"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          type="text"
                          style={{
                            background: "#f7f8f9",
                            padding: "4px 11px",
                            color: "#595959",
                            fontSize: "14px",
                            lineHeight: "1.5715",
                            border: "1px solid #d9d9d9",
                            borderRadius: ".25rem",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "30px",
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div
                style={{
                  width: "15.25rem",
                  marginTop: ".3rem",
                  position: "relative",
                }}
              >
                <span
                  style={{
                    fontSize: ".75rem",
                    fontWeight: "600",
                    lineHeight: "20px",
                    color: "#6b7280",
                  }}
                >
                  Email Provider
                </span>
                <div style={{ marginTop: ".125rem" }}>
                  <FormControl fullWidth>
                    <Controller
                      name="provider"
                      control={control}
                      rules={{ required: "Provider is required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={field.value || "1"}
                          onChange={(e) => {
                            field.onChange(e); // Call the default onChange event provided by react-hook-form
                            providerChange(e);
                          }}
                          style={{
                            background: "#f7f8f9",
                            padding: "4px 11px",
                            color: "#595959",
                            fontSize: "14px",
                            lineHeight: "1.5715",
                            border: "1px solid #d9d9d9",
                            borderRadius: ".25rem",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "32px",
                            width: "200px",
                          }}
                        >
                          <MenuItem value={1}>
                            Select Email Service Provider
                          </MenuItem>
                          {emailProviders?.data?.data?.map((item) => (
                            <MenuItem key={item._id} value={item._id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </div>
              </div>
              <div
                style={{
                  width: "15.25rem",
                  marginTop: ".3rem",
                  position: "relative",
                }}
              >
                <span
                  style={{
                    fontSize: ".75rem",
                    fontWeight: "600",
                    lineHeight: "20px",
                    color: "#6b7280",
                  }}
                >
                  Sender Name
                </span>
                <div style={{ marginTop: ".125rem" }}>
                  <Controller
                    name="sender_name"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        style={{
                          background: "#f7f8f9",
                          padding: "4px 11px",
                          color: "#595959",
                          fontSize: "14px",
                          lineHeight: "1.5715",
                          border: "1px solid #d9d9d9",
                          borderRadius: ".25rem",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "30px",
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "15.25rem",
                  marginTop: ".3rem",
                  position: "relative",
                }}
              >
                <span
                  style={{
                    fontSize: ".75rem",
                    fontWeight: "600",
                    lineHeight: "20px",
                    color: "#6b7280",
                  }}
                >
                  First Name
                </span>
                <div style={{ marginTop: ".125rem" }}>
                  <Controller
                    name="first_name"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        style={{
                          background: "#f7f8f9",
                          padding: "4px 11px",
                          color: "#595959",
                          fontSize: "14px",
                          lineHeight: "1.5715",
                          border: "1px solid #d9d9d9",
                          borderRadius: ".25rem",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "30px",
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "15.25rem",
                  marginTop: ".3rem",
                  position: "relative",
                }}
              >
                <span
                  style={{
                    fontSize: ".75rem",
                    fontWeight: "600",
                    lineHeight: "20px",
                    color: "#6b7280",
                  }}
                >
                  Last Name
                </span>
                <div style={{ marginTop: ".125rem" }}>
                  <Controller
                    name="last_name"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        style={{
                          background: "#f7f8f9",
                          padding: "4px 11px",
                          color: "#595959",
                          fontSize: "14px",
                          lineHeight: "1.5715",
                          border: "1px solid #d9d9d9",
                          borderRadius: ".25rem",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "30px",
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "15.25rem",
                  marginTop: ".3rem",
                  position: "relative",
                }}
              >
                <span
                  style={{
                    fontSize: ".75rem",
                    fontWeight: "600",
                    lineHeight: "20px",
                    color: "#6b7280",
                  }}
                >
                  Email Address
                </span>
                <div style={{ marginTop: ".125rem" }}>
                  <Controller
                    name="email_address"
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <input
                        type="text"
                        value={value}
                        name={name}
                        onBlur={onBlur}
                        ref={ref}
                        onChange={(e) => {
                          handleChange(e);
                          onChange(e);
                        }}
                        id=""
                        style={{
                          background: "#f7f8f9",
                          padding: "4px 11px",
                          color: "#595959",
                          fontSize: "14px",
                          lineHeight: "1.5715",
                          border: "1px solid #d9d9d9",
                          borderRadius: ".25rem",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "30px",
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "15.25rem",
                  marginTop: ".3rem",
                  position: "relative",
                }}
              >
                <span
                  style={{
                    fontSize: ".75rem",
                    fontWeight: "600",
                    lineHeight: "20px",
                    color: "#6b7280",
                  }}
                >
                  Password
                </span>
                <div style={{ marginTop: ".125rem" }}>
                  <Controller
                    name="smtp_password"
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <input
                        type="password"
                        value={value}
                        name={name}
                        onBlur={onBlur}
                        ref={ref}
                        onChange={(e) => {
                          onChange(e);
                          handleChange(e);
                        }}
                        id=""
                        style={{
                          background: "#f7f8f9",
                          padding: "4px 11px",
                          color: "#595959",
                          fontSize: "14px",
                          lineHeight: "1.5715",
                          border: "1px solid #d9d9d9",
                          borderRadius: ".25rem",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "30px",
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "15.25rem",
                  marginTop: ".3rem",
                  position: "relative",
                }}
              >
                <span
                  style={{
                    fontSize: ".75rem",
                    fontWeight: "600",
                    lineHeight: "20px",
                    color: "#6b7280",
                  }}
                >
                  SMTP Host
                </span>
                <div style={{ marginTop: ".125rem" }}>
                  <Controller
                    name="smtp_host"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        id=""
                        style={{
                          background: "#f7f8f9",
                          padding: "4px 11px",
                          color: "#595959",
                          fontSize: "14px",
                          lineHeight: "1.5715",
                          border: "1px solid #d9d9d9",
                          borderRadius: ".25rem",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "30px",
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "15.25rem",
                  marginTop: ".3rem",
                  position: "relative",
                  display: "flex",
                }}
              >
                <div>
                  <span
                    style={{
                      fontSize: ".75rem",
                      fontWeight: "600",
                      lineHeight: "20px",
                      color: "#6b7280",
                    }}
                  >
                    SMTP Port
                  </span>
                  <div style={{ marginTop: ".125rem" }}>
                    <Controller
                      name="smtp_port"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          type="number"
                          id=""
                          style={{
                            background: "#f7f8f9",
                            padding: "4px 11px",
                            color: "#595959",
                            fontSize: "14px",
                            lineHeight: "1.5715",
                            border: "1px solid #d9d9d9",
                            borderRadius: ".25rem",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "30px",
                            width: "4.9375rem",
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div style={{ marginLeft: "10px" }}>
                  <span
                    style={{
                      fontSize: ".75rem",
                      fontWeight: "600",
                      lineHeight: "20px",
                      color: "#6b7280",
                    }}
                  >
                    Encryption
                  </span>
                  <div style={{ marginTop: ".125rem" }}>
                    <Controller
                      name="smtp_encryption"
                      control={control}
                      render={({ field }) => (
                        <ToggleButtonGroup
                          value={field.value}
                          exclusive
                          onChange={(e, value) => {
                            field.onChange(value);
                          }}
                          aria-label="text alignment"
                        >
                          <ToggleButton
                            value="SSL"
                            aria-label="left aligned"
                            style={{
                              height: "30px",
                              padding: ".4rem .5625rem",
                              width: "35px",
                            }}
                          >
                            <span style={{ fontSize: ".75rem" }}>SSL</span>
                          </ToggleButton>
                          <ToggleButton
                            value="TLS"
                            aria-label="centered"
                            style={{
                              height: "30px",
                              padding: ".4rem .5625rem",
                              width: "35px",
                            }}
                          >
                            <span style={{ fontSize: ".75rem" }}>TLS</span>
                          </ToggleButton>
                          <ToggleButton
                            value="None"
                            aria-label="right aligned"
                            style={{
                              height: "30px",
                              padding: ".4rem .5625rem",
                              width: "40px",
                            }}
                          >
                            <span
                              style={{
                                fontSize: ".75rem",
                                textTransform: "capitalize",
                              }}
                            >
                              None
                            </span>
                          </ToggleButton>
                        </ToggleButtonGroup>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {apiError ? (
              <div
                style={{
                  width: "17.75rem",
                  borderRadius: ".625rem",
                  background: "#fef2f2",
                  padding: "1rem",
                }}
              >
                <div>
                  <p
                    style={{
                      lineHeight: "1.125rem",
                      fontSize: ".75rem",
                      fontWeight: "600",
                      color: "#212529",
                    }}
                  >
                    {apiError?.message}
                  </p>
                </div>
                <div style={{ marginTop: "-.5rem" }}>
                  <div style={{ boxSizing: "border-box" }}>
                    <ol
                      style={{
                        marginLeft: "-1.25rem",
                        lineHeight: "1.125rem",
                        fontSize: ".75rem",
                        fontWeight: "400",
                      }}
                    >
                      {apiError?.data?.map((item) => (
                        <li
                          style={{ lineHeight: "1.125rem", fontSize: ".75rem" }}
                        >
                          {item}
                        </li>
                      ))}
                    </ol>
                    <div
                      style={{
                        borderTop: "1px solid #e5e7eb",
                        paddingTop: "1rem",
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          fontSize: ".75rem",
                          lineHeight: "20px",
                          fontWeight: "500",
                          textDecoration: "underline",
                          color: "#1d4ed8",
                        }}
                      >
                        Learn More
                      </span>
                      <span
                        style={{
                          fontSize: ".75rem",
                          lineHeight: "20px",
                          fontWeight: "500",
                          textDecoration: "underline",
                          color: "#1d4ed8",
                        }}
                      >
                        Show error log
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "17.75rem",
                  borderRadius: ".625rem",
                  background: "#eff6ff",
                  padding: "1rem",
                }}
              >
                <div>
                  <p
                    style={{
                      lineHeight: "1.125rem",
                      fontSize: ".75rem",
                      fontWeight: "600",
                      color: "#212529",
                    }}
                  >
                    Steps to connect SMTP
                  </p>
                </div>
                <div style={{ marginTop: "-.5rem" }}>
                  <div style={{ boxSizing: "border-box" }}>
                    <ol
                      style={{
                        marginLeft: "-1.25rem",
                        lineHeight: "1.125rem",
                        fontSize: ".75rem",
                        fontWeight: "400",
                      }}
                    >
                      <li
                        style={{ lineHeight: "1.125rem", fontSize: ".75rem" }}
                      >
                        We strongly recommend creating an{" "}
                        <a href="hh" style={{ color: "#007bff" }}>
                          app password
                        </a>{" "}
                        to connect your Gmail account through SMPT/IMAP method.
                      </li>
                      <li
                        style={{ lineHeight: "1.125rem", fontSize: ".75rem" }}
                      >
                        Enabling 2-step verification is mandatory to create an
                        app password for a Gmail account.
                      </li>
                      <li
                        style={{ lineHeight: "1.125rem", fontSize: ".75rem" }}
                      >
                        <a href="hh" style={{ color: "#007bff" }}>
                          Click here
                        </a>{" "}
                        to learn how to create & use an app password for
                        connecting your Gmail account through SMTP/IMAP method.{" "}
                        <a href="hh" style={{ color: "#007bff" }}>
                          Learn from video.
                        </a>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <LoadingButton
                loading={isLoading}
                onClick={handleSubmit(onSubmit)}
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid #1d4ed8",
                  borderColor: "#1d4ed8",
                  color: "#1d4ed8",
                  fontSize: ".875rem",
                  lineHeight: "1.45",
                  textTransform: "capitalize",
                }}
                autoFocus
              >
                Test SMTP Connection
              </LoadingButton>
              <div
                style={{
                  marginTop: "1rem",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  display: verification ? "flex" : "none",
                }}
              >
                <CheckCircleOutlineOutlinedIcon
                  fontSize="small"
                  style={{ color: "#047857" }}
                />
                <span
                  style={{
                    fontWeight: "500",
                    marginLeft: ".5rem",
                    fontSize: ".875rem",
                    lineHeight: "22px",
                    color: "#047857",
                  }}
                >
                  SMTP authenticated successfully!
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmtpConnect;
