import React from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Mon",
    uv: 40,
    pv: 20,
    pa: 50,
    pc: 10,
    pf: 33,
    amt: 100,
  },
  {
    name: "Tue",
    uv: 40,
    pv: 20,
    pa: 20,
    pc: 10,
    pf: 0,
    amt: 100,
  },
  {
    name: "Wed",
    uv: 40,
    pv: 20,
    pa: 30,
    pc: 10,
    pf: 8,
    amt: 100,
  },
  {
    name: "Thu",
    uv: 40,
    pv: 20,
    pa: 10,
    pc: 10,
    pf: 60,
    amt: 100,
  },
  {
    name: "Fri",
    uv: 40,
    pv: 20,
    pa: 15,
    pc: 10,
    pf: 50,
    amt: 100,
  },
  {
    name: "Sat",
    uv: 40,
    pv: 20,
    pa: 35,
    pc: 10,
    pf: 25,
    amt: 100,
  },
  {
    name: "Sun",
    uv: 40,
    pv: 20,
    pa: 45,
    pc: 10,
    pf: 5,
    amt: 100,
  },
];

const ShortCutGraph = () => {
  return (
    <ResponsiveContainer height={"100%"} width={"100%"}>
      <BarChart
        width={800}
        height={500}
        data={data}
        margin={{
          top: 100,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Bar
          dataKey="pv"
          fill="#9747FF"
          barSize={6}
          shape={<Rectangle fill="#9747FF" />}
        />
        <Bar
          dataKey="uv"
          fill="#9747FF"
          barSize={6}
          shape={<Rectangle fill="#82ca9d" />}
        />
        <Bar
          dataKey="pa"
          fill="#E4D342"
          barSize={6}
          shape={<Rectangle fill="#E4D342" />}
        />
        <Bar
          dataKey="pc"
          fill="#ED9001"
          barSize={6}
          shape={<Rectangle fill="#ED9001" />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ShortCutGraph;
