import React, { useState } from "react";
import {
  autoUpdate,
  useFloating,
  useClick,
  useDismiss,
  offset,
  flip,
  size,
  useInteractions,
  FloatingPortal,
} from "@floating-ui/react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import BusinessIcon from "@mui/icons-material/Business";
import Button from "@mui/material/Button";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Style from "../Style/CompanyDropDown.module.css";
import useWorkSpaceStore from "../../../store/WorkSpaceStore";
import CustomAvatar from "./Components/CustomAvatar";
import { IconButton } from "@mui/material";
import CreateModule from "./Components/CreateCompany";
import SettingsModule from "../Settings/Modal/Modal";
import { styled } from "@mui/material/styles";
import { useQueryClient } from "@tanstack/react-query";
import useSelectedWorkSpace from "../../../store/selectedWorkSpaceStore";

const MuiButton = styled(Button)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#0B1433",
    boxShadow: "none",
  },
}));
const buttonStyle = {
  width: "176px",
  height: "28px",
  fontSize: "15px",
  color: "white",
  fontWeight: 400,
  padding: 0,
  textTransform: "none",
  background: "#0B1433",
  borderRadius: "20px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const CustomCompanyDropDown = () => {
  const {workSpace} = useWorkSpaceStore();
  const { selected, setSelectedWorkSpace } = useSelectedWorkSpace();
  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();

  const OFFSET = 10;

  const { refs, floatingStyles, context, strategy } = useFloating({
    placement: "bottom-start",
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementMounted: autoUpdate,
    position: "center",
    middleware: [
      offset(OFFSET),
      flip(),
      size({
        apply: ({ availableHeight, elements }) => {
          Object.assign(elements.floating.style, {
            maxHeight: `${Math.max(50, availableHeight - OFFSET)}px`,
          });
        },
      }),
    ],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  const handleOptionClick = (data) => {
    setSelectedWorkSpace(data);
    queryClient.invalidateQueries();
    setValue(data);
    setIsOpen(false);
  };

  const [value, setValue] = useState(selected);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [openSettings, setOpenSettings] = useState(false);

  const handleOpenSettings = () => {
    setOpenSettings(true);
  };

  const handleCloseSettings = () => {
    setOpenSettings(false);
  };
  const handleMouseEnter = (item) => {
    setHoveredItem(item);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };
  const [openModule, setOpenModule] = useState(false);
  function handleModuleOpen() {
    setOpenModule(true);
    setIsOpen(false);
  }
  function handleModuleClose() {
    setOpenModule(false);
  }


  return (
    <>
      <div
        className={Style.dropDownWrapper}
        {...getReferenceProps({ ref: refs.setReference })}
      >
        <BusinessIcon />
        <div>
          {value?.name?.length > 10
            ? `${value?.name.slice(0, 6)}...`
            : value?.name}
        </div>

        {isOpen ? (
          <KeyboardArrowUpIcon style={{ color: "#0000007a", fontSize: 14 }} />
        ) : (
          <KeyboardArrowDownIcon style={{ color: "#0000007a", fontSize: 14 }} />
        )}
      </div>

      {isOpen && (
        <FloatingPortal>
          <div
            className={Style.dropDownModal}
            {...getFloatingProps({
              ref: refs.setFloating,
              style: { ...floatingStyles, position: strategy },
            })}
          >
            {workSpace.map((item, index) => (
              <div
                className={`${Style.menuItem} ${
                  value?.id === item?.id ? Style.selectedOptions : ""
                }`}
                key={`${item?.id}-${index}`}
                onClick={() => handleOptionClick(item)}
                onMouseEnter={() => handleMouseEnter(item)}
                onMouseLeave={handleMouseLeave}
              >
                <div className={Style.avatarFlex}>
                  <CustomAvatar
                    icon={item?.name[0]}
                    color={value?.id === item.id ? "#0437CD" : "#14B662"}
                  />
                  <span style={{ marginRight: "10px" }}>
                    {item.name.length > 13
                      ? `${item?.name.slice(0, 13)}...`
                      : item?.name}
                  </span>
                </div>

                <div>
                  {hoveredItem?.id === item?.id && (
                    <div onClick={handleOpenSettings}>
                      <IconButton>
                        <ModeEditIcon
                          style={{ color: "white", fontSize: "14px" }}
                        />
                      </IconButton>
                    </div>
                  )}
                </div>
              </div>
            ))}
            <div className={Style.buttonContainer}>
              <div >
                <MuiButton
                  variant="contained"
                  onClick={handleModuleOpen}
                  style={buttonStyle}
                >
                  Create Company
                </MuiButton>
              </div>
            </div>
          </div>
        </FloatingPortal>
      )}
      <CreateModule open={openModule} handleClickClose={handleModuleClose} />
      <SettingsModule open={openSettings} handleClose={handleCloseSettings} />
    </>
  );
};

export default CustomCompanyDropDown;
