import React from "react";
import { useMessageList } from "../../../CustomHooks/useMessageList";
import ChatsList from "./Components/ChatsList";

const ChatList = () => {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useMessageList(10);

  const MessageData = data?.pages?.flatMap((page) => page.data) || [];

  const sortedMessageData = MessageData.sort((a, b) => {
    return (b.isPinned ? 1 : 0) - (a.isPinned ? 1 : 0);
  });

  return (
    <>
    <ChatsList
      MessageData={sortedMessageData}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      isLoading={isLoading}
      title={"Chats"}
    />
    </>
  );
};

export default ChatList;
