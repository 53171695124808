import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import ModalHeader from "./ModalHeader";
import FundTextField from "./FundTextField";

import RazorPay from "../Assets/RazorPay.png";


import DropDownMenu from "./DropDownMenu";
import AddFundFooter from "./AddFundFooter";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  maxHeight: "calc(100vh - 85px)",
  bgcolor: "white",
  boxShadow: 24,
  overflow: "hidden",
  overflowY: "scroll",
};

const AddFundModal = ({ open, onClose }) => {

  const paymentOptions = [
    {
      image: RazorPay,
      id: "#razorPay",
    },
    // {
    //   image: Merchant,
    //   id: "#merchant",
    // },
    // {
    //   image: Stripe,
    //   id: "#stripe",
    // },
    // {
    //   image: Avenu,
    //   id: "#avenue",
    // },
  ];

  const [selectedOption, setSelectedOption] = useState("#razorPay");
  const [amount, setAmount] = useState("");
  const [razorpayfee,setRazorpayfee] = useState(0)

  const [snackbarInfo, setSnackbarInfo] = useState({
    open: "",
    severity: "",
    message: "",
  });

  const handleOptionChange = (id) => {
    setSelectedOption(id);
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  React.useEffect(()=>{

    const FinalRazorpayAmount = amount / (1 - 0.02);

    const fee = Math.round(FinalRazorpayAmount - amount);
    setRazorpayfee(fee)

  },[amount])

  return (
    <Modal open={open}>
      <Box sx={style} className="scroll">
        <ModalHeader title="Add Fund" handleClickClose={onClose} />
        <div style={{ padding: "15px" }}>
          <FundTextField
          value={amount}
          onChangeFuncion={handleAmountChange}
          />
          <DropDownMenu
            onChange={handleOptionChange}
            paymentOptions={paymentOptions}
            selectedOption={selectedOption}
            razorpayfee={razorpayfee}
          />
        </div>
        <AddFundFooter amount={amount} setSnackbarInfo={setSnackbarInfo} selectedOption={selectedOption}/>
      </Box>
    </Modal>
  );
};

export default AddFundModal;
