import { useState } from "react";
import Slide1 from "../Images/Slider1.png";
import RightArrow from "../Images/RightArrow.png";
import LeftArrow from "../Images/LeftArrow.png";
import IconButton from "@mui/material/IconButton";
import Style from "../Style/Panel.module.css";
const Carousel = () => {
  const CarouselData = [
    {
      imageSrc: Slide1,
      firstContent: "Welcome to your new dashboard",
      secondContent: "Sign in to explore changes we’ve made.",
    },
    {
      imageSrc: Slide1,
      firstContent: "Welcome to your new dashboard",
      secondContent: "Sign in to explore changes we’ve made.",
    },
    {
      imageSrc: Slide1,
      firstContent: "Welcome to your new dashboard",
      secondContent: "Sign in to explore changes we’ve made.",
    },
    {
      imageSrc: Slide1,
      firstContent: "Welcome to your new dashboard",
      secondContent: "Sign in to explore changes we’ve made.",
    },
  ];
  const [activeIndex, setActiveIndex] = useState(0);
  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= CarouselData.length) {
      newIndex = CarouselData.length - 1;
    }

    setActiveIndex(newIndex);
  };
  return (
    <div className={Style.Carousel_Wrapper}>
      <div style={{ marginTop: "10vh" }}>
        <img src={CarouselData[activeIndex].imageSrc} alt="slider" />
        <div style={{ marginTop: "10vh" }}>
          <p className={Style.carousel_first_content}>
            {CarouselData[activeIndex].firstContent}
          </p>
          <p className={Style.carousel_second_content}>
            {CarouselData[activeIndex].secondContent}
          </p>
        </div>
      </div>
      <div>
        <IconButton
          onClick={() => {
            updateIndex(activeIndex - 1);
          }}
        >
          <img src={LeftArrow} alt="Left Arrow" />
        </IconButton>
        {CarouselData.map((item, index) => {
          return (
            <>
              <IconButton
                onClick={() => {
                  updateIndex(index);
                }}
              >
                <div
                  className={
                    index === activeIndex
                      ? Style.Carousel_Dots_Active
                      : Style.Carousel_Dots
                  }
                ></div>
              </IconButton>
            </>
          );
        })}

        <IconButton
          onClick={() => {
            updateIndex(activeIndex + 1);
          }}
        >
          <img src={RightArrow} alt="Rigth Arrow" />
        </IconButton>
      </div>
    </div>
  );
};

export default Carousel;
