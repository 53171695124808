import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Interceptor from "./components/Interceptor/Interceptor";
import CssBaseline from "@mui/material/CssBaseline";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <React.Fragment>

    <CssBaseline />

    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>

        <Interceptor />

      </QueryClientProvider>

    </ThemeProvider>
  </React.Fragment>,
  document.getElementById("root")
);

reportWebVitals();
