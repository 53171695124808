import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Dialog,
  Paper,
  Typography,
  TextField,
  Box,
  DialogContent,
  InputLabel,
  FormControl,
  CircularProgress,
  Tooltip,
  MenuItem,
  Select,
  Modal,
} from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import RedAsterisk from "../../../../components/RedAsterisk";
import Button from "../../../../components/Button";
import axios from "axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Styles from "../Organization.module.scss";
import useBranchStore from "../../store/BranchStore";
import toast from "react-hot-toast";

const fetchTask = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_PEOPLES_NOW_BASEURL}/task/task-by-branch`
  );
  return response.data;
};

const schema = yup
  .object({
    task_category_name: yup
      .string()
      .min(3, "Please Enter Minimum 3 characters")
      .max(50, "Please Enter Maximum 50 characters")
      .required("Please Enter Task Name"),
    branch_id: yup.string().when("isEditMode", {
      is: false,
      then: yup.string().required("Please Select Branch"),
    }),
  })
  .required();

function TaskCategory() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const { branch } = useBranchStore();
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    data: task,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["task"],
    queryFn: fetchTask,
  });

  const onSubmit = (data) => {
    if (isEditMode) {
      axios
        .put(
          `${process.env.REACT_APP_PEOPLES_NOW_BASEURL}/task/update-task/${selectedBranch.branch_id}/${selectedBranch.doc_id}`,
          data
        )
        .then(() => {
          queryClient.invalidateQueries({ queryKey: ["task"] });
          reset();
          setIsDialogOpen(false);
          setIsEditMode(false);
          toast.success("Task Updated Successfully!");
        })
        .catch((error) => {
          console.error("Error updating branch:", error);
          const errorMessage =
            error.response?.data?.error?.message || "Something went wrong";
          toast.error(errorMessage);
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_PEOPLES_NOW_BASEURL}/task/add-task`,
          data
        )
        .then(() => {
          queryClient.invalidateQueries({ queryKey: ["task"] });
          reset();
          setIsDialogOpen(false);
          toast.success("Task Created Successfully!");
        })
        .catch((error) => {
          console.error("Error adding branch:", error);
          const errorMessage =
            error.response?.data?.error?.message || "Something went wrong";
          toast.error(errorMessage);
        });
    }
  };

  const handleDelete = async (id) => {
    return axios
      .delete(
        `${process.env.REACT_APP_PEOPLES_NOW_BASEURL}/task/delete-task/${id}`
      )
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["task"] });
        setIsDeleteOpen(false);
        toast.success("Task Deleted Successfully!");
      })
      .catch((error) => {
        console.error("Error deleting branch:", error);
        setIsDeleteOpen(false);
        const errorMessage =
          error.response?.data?.error?.message || "Error Deleting Task!";
        toast.error(errorMessage);
      });
  };

  const handleOkClick = () => {
    if (selectedBranch) {
      handleDelete(selectedBranch.doc_id);
    }
  };

  const handleEditDepart = (data) => {
    setSelectedBranch(data);
    setIsEditMode(true);
    setValue("task_category_name", data.task_category_name);
    setValue("task_description", data.task_description);
    setIsDialogOpen(true);
  };

  const handleAddTaskClick = () => {
    setIsEditMode(false);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setIsEditMode(false);
    reset();
  };

  if (isLoading)
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CircularProgress />
      </Box>
    );
  if (isError) return <div>Error loading data</div>;
  return (
    <div
      style={{
        height: "65vh",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Button
            style={{
              background: "rgba(0, 10, 255, 1)",
              color: "white",
              marginTop: "2%",
              marginRight: "2%",
            }}
            onClick={handleAddTaskClick}
          >
            Add Task
          </Button>
        </div>
        <div
          style={{
            width: "100%",
            marginTop: "2%",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                  }}
                >
                  Task Name
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                  }}
                >
                  Description
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    width: "30px",
                  }}
                ></TableCell>
                <TableCell
                  style={{
                    fontWeight: "bolder",
                    width: "30px",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {task.map((data) => (
                <TableRow
                  key={data.doc_id}
                  className={Styles.hoverTable}
                  sx={{
                    "&:hover": {
                      backgroundColor: "var(--light-bg-img-color)",
                    },
                    cursor: "pointer",
                  }}
                >
                  <TableCell>{data.task_category_name}</TableCell>
                  <TableCell>{data.task_description}</TableCell>
                  <TableCell className={Styles.Tablemenu}>
                    <Tooltip title="Edit" placement="bottom" arrow>
                      <EditOutlinedIcon
                        onClick={() => handleEditDepart(data)}
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    className={Styles.Tablemenu}
                    sx={{ color: "red", paddingRight: "40px" }}
                  >
                    <Tooltip title="Delete" placement="bottom" arrow>
                      <DeleteOutlineOutlinedIcon
                        onClick={() => {
                          setSelectedBranch(data);
                          setIsDeleteOpen(true);
                        }}
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Dialog
            open={isDialogOpen}
            onClose={handleDialogClose}
            maxWidth="xs"
            fullWidth
          >
            <Box
              style={{
                background: "rgba(0, 10, 255, 1)",
                padding: "10px 10px 10px 25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  color: "white",
                }}
              >
                Task
              </Typography>
              <CloseIcon
                onClick={handleDialogClose}
                style={{
                  cursor: "pointer",
                  color: "white",
                  fontSize: "20px",
                }}
              />
            </Box>
            <DialogContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* <InputLabel sx={{color:"black"}}>
                      Task Name <RedAsterisk />
                    </InputLabel> */}
                <FormControl fullWidth margin="normal">
                  <TextField
                    {...register("task_category_name")}
                    error={!!errors.task_category_name}
                    helperText={errors.task_category_name?.message}
                    variant="outlined"
                    placeholder="Task Name"
                    label="Task Name *"
                  />
                </FormControl>
                {/* <InputLabel sx={{color:"black"}}>Description</InputLabel> */}
                <FormControl fullWidth margin="normal">
                  <TextField
                    {...register("task_description")}
                    placeholder="Description"
                    fullWidth
                    multiline
                    rows={3}
                    variant="outlined"
                    label="Description"
                  />
                </FormControl>
                {!isEditMode && (
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Branch *</InputLabel>
                    <Select
                      {...register("branch_id")}
                      error={!!errors.branch_id}
                      value={watch("branch_id") || ""}
                      onChange={(e) => setValue("branch_id", e.target.value)}
                      label="Branch *"
                      MenuProps={{
                        PaperProps: {
                          sx: { maxHeight: "30%", width: "200px" },
                        },
                      }}
                    >
                      {branch.map((item) => (
                        <MenuItem key={item.branch_id} value={item.branch_id}>
                          {item.branch_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    style={{
                      background: "rgba(0, 0, 0, 0)",
                      borderRadius: "5px",
                      color: "Black",
                      boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25)",
                      marginRight: "20px",
                    }}
                    onClick={handleDialogClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      background: "rgba(0, 10, 255, 1)",
                      borderRadius: "5px",
                      color: "white",
                    }}
                    type="submit"
                  >
                    {isEditMode ? "Update" : "Add"}
                  </Button>
                </Box>
              </form>
            </DialogContent>
          </Dialog>
        </div>
        <Modal
          open={isDeleteOpen}
          onClose={() => setIsDeleteOpen(false)}
          sx={{ outline: "none", border: "none" }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "45vw",
              height: "150px",
              bgcolor: "background.paper",
              boxShadow: 24,
              padding: "5px 5px 20px 20px ",
              display: "flex",
              flexDirection: "column",
              borderRadius: "6px",
              outline: "none",
              border: "none",
            }}
          >
            <CloseIcon
              sx={{ fontSize: 20, cursor: "pointer", marginLeft: "auto" }}
              onClick={() => setIsDeleteOpen(false)}
            />
            <Box sx={{ height: "70px", paddingRight: "15px" }}>
              <Typography sx={{ fontSize: "16px" }}>
                {selectedBranch
                  ? `Deleting Task "${selectedBranch.task_category_name}" will delete it permanently. Proceed?`
                  : "No item selected."}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                padding: "10px 20px",
              }}
            >
              <Button
                size="small"
                variant="outlined"
                sx={{
                  bgcolor: "#F7F8FA",
                  color: "black",
                  border: "1px solid gray",
                  marginRight: "20px",
                }}
                type="button"
                onClick={() => setIsDeleteOpen(false)}
              >
                Cancel
              </Button>
              <Button
                size="small"
                sx={{
                  bgcolor: "var(--button-color)",
                  color: "#fff",
                  ":hover": { backgroundColor: "var(--button-color)" },
                }}
                onClick={handleOkClick}
              >
                Ok
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default TaskCategory;
