import { Drawer, IconButton } from "@mui/material";
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

import Style from "../Style/Profile.module.css";
import { Button } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Checkin from "../../Assests/CheckIn.png";
import "../Style/UserDrawer.css";
import axios from "axios";
import { styled } from "@mui/material/styles";
import ProfileUpload from "./ProfileUpload";
import useUser from "../../../store/userStore";
import useUserProfile from "../store/profileStore";
import { useCrmContactsStore } from "../../../crm/store/CrmStore";
import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";
import { useProfilePic } from "../../User/customHooks/useProfilePic";
const CssDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    top: 48,
    height: "100vh",
    width: "23rem",
  },
}));

const customButtonStyle = {
  width: "80px",
  height: "4vh",
  border: "1px solid #ddbdbd",
  background: "#F0483F",
  fontSize: "11px",
  fontFamily: "Inter",
  color: "#ffff",
  fontWeight: 600,
  borderRadius: "2px",
  padding: 0,
  marginLeft: "6px",
};

const customAccButtonStyle = {
  width: "90px",
  height: "4vh",
  border: "1px solid black",
  background: "#ffff",
  fontSize: "11px",
  fontFamily: "Inter",
  fontWeight: 500,
  color: "black",
  borderRadius: "2px",
  padding: 0,
};

const CircleMenuItem = ({ color, selected, onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{
        borderRadius: "50%",
        width: "15px",
        height: "15px",
        backgroundColor: color,
        marginTop: "3px",
      }}
    ></div>
  );
};
export default function UserDrawer(props) {
  let history = useNavigate();
  const uploadref = useRef(null);
  const queryClient = useQueryClient();
  const {user,logout}=useUser()

  const resetStore = useCrmContactsStore((state) => state.resetState);
  const { data: userProfile = "" } = useProfilePic();

  const onlogochange = (e) => {
    updateProfile(e.target.files[0])
  };
  const myAccOnClick = () => {
    props.toggleDrawer(true);
    history("/myaccount/profile");
  };
  const signOutOnClick = () => {
    resetStore();
    logout();
  };

  
  function updateProfile(file) {
    let formdata = new FormData();
    console.log("companydorm", file);

    formdata.append("profile_pic", file);

    axios
      .put(
        `${process.env.REACT_APP_WEBSERVICES_PRODUCTION_URL}/user/profile_pic`,
        formdata
      )
      .then((result) => {
        console.log("myuploadresult", result.data.message);
        toast.success(result.data.message);
        queryClient.invalidateQueries({ queryKey: ["getUserProfileData"] });
      })
      .catch((err) => {
        toast.error(err.response.data.message)
      });
  }

  const updateProfileFun = () => {
    uploadref.current.click();
  };



  const [age, setAge] = React.useState("Available");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [selectedCircle, setSelectedCircle] = React.useState("green");

  const handleChangee = (event) => {
    setSelectedCircle(event.target.value);
  };

  const handleAdminConsoleClick = () => {
    window.open("https://mailadmin.nowdigitaleasy.com/", "_blank");
  };
  return (
    <div>
      <>
        <CssDrawer
          anchor="right"
          open={props.open}
          onClose={props.toggleDrawer}
        >
          <div className={Style.profile_drawer_wrapper}>
            <div className={Style.profile_top}>
              <div className="drawer-close-btn" onClick={props.toggleDrawer}>
                <IconButton component="span" style={{ padding: 0 }}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <div className={Style.profile_middle_wrapper}>
              <div className={Style.profile_middle}>
                <div className={Style.profile_icon_wrapper}>
                  <div className={Style.userdraweravatar}>
                    <>
                      <ProfileUpload
                        onlogochange={onlogochange}
                        updateProfileFun={updateProfileFun}
                        uploadref={uploadref}
                        path={userProfile.profile_pic_path}
                        srcUrl={`${userProfile.profile_pic_path}`}                      />
                    </>
                  </div>
                </div>
                <div className={Style.profile_middle_text}>
                  <p className={Style.text_margin}>
                    {user?.fullName}
                  </p>
                  <p className={Style.text_margin}>{user?.email}</p>
                  <p
                    className={Style.text_margin}
                    style={{ cursor: "pointer" }}
                    onClick={handleAdminConsoleClick}
                  >
                    Admin Console
                  </p>{" "}
                  <div style={{ marginTop: "0.8rem" }}>
                    <Button style={customAccButtonStyle} onClick={myAccOnClick}>
                      My Account
                    </Button>
                    <Button style={customButtonStyle} onClick={signOutOnClick}>
                      Sign Out
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className={Style.profile_bottom_wrapper}>
              <div className={Style.profile_bottom}>
                <div className={Style.profile_bottom_text}>
                  <div>Remote Work</div>
                  <div className={Style.profile_checkin}>
                    <img src={Checkin} alt="checkin" />
                    <div style={{ marginLeft: "20px" }}>00.00.00 Hours </div>
                  </div>
                  <div className={Style.profile_dropdown}>
                    <Box sx={{ marginRight: "20px" }}>
                      <FormControl>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedCircle}
                          onChange={handleChangee}
                          sx={{
                            width: "56px",
                            borderRadius: "2px",
                            height: "26px",
                            backgroundColor: "#f0f0f4",
                          }}
                          key={"#####f0f0f4"}
                        >
                          <MenuItem value="green">
                            <CircleMenuItem
                              color="green"
                              selected={selectedCircle === "green"}
                            />
                          </MenuItem>
                          <MenuItem value="red">
                            <CircleMenuItem
                              color="red"
                              selected={selectedCircle === "red"}
                            />
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={age}
                          onChange={handleChange}
                          sx={{
                            width: "162px",
                            borderRadius: "2px",
                            height: "26px",
                            backgroundColor: "#f0f0f4",
                          }}
                        >
                          <MenuItem value={"Available"} key={"Available"}>
                            Available
                          </MenuItem>
                          <MenuItem value={"Unavailable"} key={"Unavailable"}>
                            Unavailable
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CssDrawer>
    
      </>
    </div>
  );
}
