import React from "react";
import Grid from "@mui/material/Grid";
import VisitorsTable from "./VisitorsTable";

const VisitorsGrid = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8} sm={12}>
        {/* <VisitorsGraph /> */}
      </Grid>
      <Grid item xs={12} md={4} sm={12}>
        <VisitorsTable />
      </Grid>
    </Grid>
  );
};

export default VisitorsGrid;
