import React from "react";
import Style from "../../../Style/ChatNow/ChatNow.module.css";
const VisitorTableHeader = () => {
  return (
    <div className={`${Style.LeaderBoardHeader_Wrapper} ${Style.noTopBorder}`}>
      <td className={`${Style.filterTitle} ${Style.indexAlign}`}>
        Top Countries
      </td>
    </div>
  );
};

export default VisitorTableHeader;
