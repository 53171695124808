import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Styles from "../../MailsNow/AddCampaign/Add.module.scss";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { TextField } from "@mui/material";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import SequeLayout from "./Sequence/SequeLayout";
import { Box } from "@mui/system";
// import { useMutation } from "@tanstack/react-query";
// import { createStep } from "../../webservices/sequence";
// import EmailForm from "./Sequence/EmailForm";
// import EmailGuide from "./Sequence/EmailGuide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddCampaign = ({ handleOpen, handleClose, data, name }) => {
  const [isToggled, setIsToggled] = useState(false);
  const [value, setValue] = useState({
    id: null,
    day: 1
  });

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={handleOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
          <div
            style={{
              height: "72px",
              padding: "15px 20px",
              borderBottom: "1px solid var(--light-border-color)",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <div className={Styles.AddHeader}>
              <div>
                {" "}<KeyboardBackspaceIcon
                  onClick={handleClose}
                  sx={{
                    fontSize: "30px",
                    color: "#9999A1",
                    cursor: "pointer",
                    marginTop: "6px"
                  }}
                />
              </div>
              <div>
                {" "}<CampaignOutlinedIcon
                  sx={{
                    fontSize: "30px",
                    color: "#FFF",
                    backgroundColor: "var(--button-color)",
                    borderRadius: "3px",
                    margin: "6px 20px 0 25px"
                  }}
                />
              </div>
              <div style={{ width: "100%", minWidth: "20%" }}>
                <TextField
                  variant="outlined"
                  size="small"
                  value={name || ""}
                  sx={{ width: "100%" }}
                />
              </div>
            </div>
            <div>
              <Box
                sx={{
                  padding: "5px 10px",
                  fontSize: "14px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  textDecoration: "underline",
                  ":hover": { backgroundColor: "#E6E7FF" }
                }}
                onClick={() => setIsToggled(!isToggled)}
              >
                Abc
              </Box>
            </div>
          </div>
          <div className={Styles.AddBody}>
            <SequeLayout
              closeFun={handleClose}
              data={data}
              isToggled={isToggled}
              value={value}
              setValue={setValue}
            />
          </div>
          <div className={Styles.AddFooterWrapper} />
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default AddCampaign;
