import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./router/PrivateRoute";
import Login from "./components/login/Login";
import SignUp from "./components/signUp/SignUp";
import ConsolidatePayment from "./components/User/Components/ConsolidatePayment/ConsolidatePayment";
import "./styles/app.css";
import { NDEToast } from "./crm/components/NDEToast";

function App() {
  return (
    <>
      <BrowserRouter>
        <NDEToast />
        <Routes>
          <Route
            path="myaccount/billing/payment/:invoiceId"
            element={<ConsolidatePayment />}
          />

          <Route path="/login/*" element={<Login />} />
          <Route path="/signUp" element={<SignUp />} />

          <Route path="/*" element={<PrivateRoute />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
//This comment by Kavin
export default App;
