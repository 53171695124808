import React from "react";
import { WithHoc } from "../Components/WithHoc";
import CustomActivityLayOut from "../Components/CustomActivityLayOut";
import Style from "../../Style/Common.module.css";
import FTGrid from "./Components/FTGrid";

const FiredTriggersLayOut = () => {
  const FiredTriggerslayOut = WithHoc(CustomActivityLayOut, FTGrid);
  return (
    <div className={Style.commonPadding}>
      <FiredTriggerslayOut title={"Shortcut Usage Statistics"} />
    </div>
  );
};

export default FiredTriggersLayOut;
