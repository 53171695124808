import { create } from "zustand";
import { persist } from "zustand/middleware";

const UseSideBar = create()(
  persist(
    (set, get) => ({
      active: true,
      setActiveStatus: () => set({ active: !get().active }),
    }),
    {
      name: "activeStatus-persit",
    }
  )
);

export default UseSideBar;
