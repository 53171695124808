import React from 'react'
import KPIGrid from './Component/KPIGrid'

const KPILayout = () => {
  return (
    <div>
  <KPIGrid/>
    </div>
 
  )
}

export default KPILayout