import React, { useRef, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";
import ChatBar from "./ChatBar";
import Loader from "../../../../../components/shared/Loader";
import useUser from "../../../../../store/userStore";

const ChatsList = ({
  MessageData,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  isLoading,
  title
}) => {
  const { getUserId } = useUser();
  const senderId = getUserId();

  const queryClient = useQueryClient();

  const handlePinChat = async (messageId, isPinned) => {
    const params = {
      action: !isPinned,
      convoIds: [messageId],
    };

    try {
      const result = await axios.put(
        `${process.env.REACT_APP_TEAM_CHAT_BASEURL}/api/v1/chats/pin`,
        params
      );

      toast.success(result.data);
      queryClient.invalidateQueries({ queryKey: ["teamChat_getMessageList"] });
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const handleArchiveChat = async (messageId, isArchived) => {
    const params = {
      action: !isArchived,
      convoIds: [messageId],
    };

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_TEAM_CHAT_BASEURL}/api/v1/chats/archive`,
        params
      );
      toast.success(result.data);
      queryClient.invalidateQueries({
        queryKey: ["teamChat_getMessageList"]
      });
      queryClient.invalidateQueries({
        queryKey: ["teamChat_getArchiveList"],
      });
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });

      if (node) observer.current.observe(node);
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage]
  );

  if (isLoading) return <Loader />;

  return (
    <Box
      paddingLeft={1.9}
      height="90%"
      overflow="auto"
      width="100%"
      sx={{ overflowY: "auto" }}
    >
      <Box paddingBottom={1.8} paddingTop={0.5}>
        <Typography fontWeight={500}>{title}</Typography>
      </Box>
      {MessageData.map((data, index) => (
        <div ref={lastElementRef} key={`${data._id}-${index}`}>
          <ChatBar
            data={data}
            currentId={senderId}
            onPinChat={handlePinChat}
            onArchiveChat={handleArchiveChat}
          />
        </div>
      ))}
      {isFetchingNextPage && <CircularProgress />}
    </Box>
  );
};

export default ChatsList;
