import { Typography } from "@mui/material";
import React from "react";
import view from "./Overview.module.scss";
import BarGraph from "./BarGraph";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const fetchBarChartData = async ({ queryKey }) => {
  const [_,emailAccountId, startDate, endDate] = queryKey;

  const response = await axios.get(
    `${process.env.REACT_APP_MAILS_NOW_BASEURL}/analytics/warmup-email-sent-chart/${emailAccountId}`,
    {
      params: { startDate, endDate },
    }
  );

  return response.data;
};

const BarChart = ({emailAccountId,getLast7Days}) => {

  const { startDate, endDate } = getLast7Days();
  
  const { data, isLoading } = useQuery({
    queryKey: ['warmupChart', emailAccountId,startDate,endDate],
    queryFn: fetchBarChartData,
    enabled: !!emailAccountId,
});

  return (
    <div className={view.performance}>
      <div>
        <Typography
          sx={{ fontSize: "18px", fontWeight: 600, marginBottom: "20px" }}
        >
          Warmup email sent
        </Typography>
        <BarGraph apidata={data} isLoading={isLoading}/>
      </div>
    </div>
  );
};

export default BarChart;
