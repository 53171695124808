import React from "react";
import FilterCheckbox from "./FilterCheckbox";
import Style from "../../Style/Common.module.css";
const CustomFilterCell = (props) => {
  return (
    <>
      <div
        className={`${Style.flexDisplay} ${Style.FilterBottom}`}
        key={`${props.data.id}-$`}
      >
        <FilterCheckbox /> {props.children}
      </div>
    </>
  );
};

export default CustomFilterCell;
