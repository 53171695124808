import { Box, Dialog, DialogActions, DialogContent, Divider, TextField, TextareaAutosize, Typography } from "@mui/material";
import Gif from '../../../../Images/MessageShrtcuts/Gif.svg'
import B from '../../../../Images/MessageShrtcuts/B.svg'
import I from '../../../../Images/MessageShrtcuts/I.svg'
import Play from '../../../../Images/MessageShrtcuts/Play.svg'
import A from '../../../../Images/MessageShrtcuts/A.png'
import Button from "../../../../components/Button";
import Chain from '../../../../Images/MessageShrtcuts/Chain.png'
import Img from '../../../../Images/MessageShrtcuts/Img.png'
import Imp from '../../../../Images/MessageShrtcuts/ImportFile.svg'
import Dash from '../../../../Images/MessageShrtcuts/DashBoard.svg'
import { useState } from "react";
import { IoClose } from "react-icons/io5";
import RedAsterisk from "../../../../components/RedAsterisk";


function MessageShortcuts() {
    const [isDialogOpen, setDialogOpen] = useState(false);
    const handleAddShortcut = () => {
      setDialogOpen(true);
    };
  
    const handleDialogClose = () => {
      setDialogOpen(false);
    };
    const iconStyle = {
      width: "3vw",
      height: "3vw",
      maxWidth: "15px",
      maxHeight: "15px",
      marginRight: "8px",
      // borderRight: "1px solid #efefef",
      // border: "1px solid red"
    };
  return (
    <div style={{
        // border: '1px solid red',
        height: "75.5vh"
    }}>
        <div
      style={{
        padding: "3% 5% 3% 2%",
      }}
    >
      <div
        style={{
          // border: "1px solid red",
          height: "5vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            fontWeight: "bolder",
          }}
        >
          Message Shortcuts
        </Typography>
        <Button
          style={{
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          }} onClick={handleAddShortcut}
        >
          Add Shortcut
        </Button>
        <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
       
      >
        <span
          style={{
            padding: "25px",
            background: "rgba(0, 10, 255, 1)",
            
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
          }}
        >
          <IoClose
            onClick={handleDialogClose}
            style={{
              cursor: "pointer",
              color: "white"
            }}
          />
        </span>
        <Box
          style={{
            padding: "1%",
           
          }}
        >
          <DialogContent
            style={{
              margin: 0, 
            }}
          >
            <div style={{
              padding: "2%",
            }}>
            <Typography
              variant="h6"
              style={{
               
                
                
              }}
            >
            
              Add Shortcuts
            </Typography>
            </div>
            <Typography variant="body2">
              create a new shortcut. Give it a !bang , a group tag, and write a
              message for this shortcut. Then, use it in conversation to quickly
              answer to visitors using the shortcut !bang.
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // marginTop: "5%",
                padding: "3% 2% 2% 0%"
              }}
            >
              <Typography
                variant="body2"
                style={{ display: "flex", alignItems: "center" }}
              >
                Shortcut<RedAsterisk />
              </Typography>
              <TextField placeholder="!bang" />
              <span style={{
                display: 'flex',
                flexDirection: "row",
                padding :'1%',
                // border: "1px solid red"
              }}>
              <Typography  style={{
                fontSize: "0.8rem",
                alignContent: "center"
              }}>in tag</Typography>
              </span>
              <TextField />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography style={{
                padding: "1% 0%"
              }}>Message<RedAsterisk /></Typography>
              <TextareaAutosize
                style={{
                  height: "20%",
                }}
                placeholder="Enter a message for this shortcut !bang... "
              />
              <span
                style={{
                  border: "1px solid grey",
                  padding: "0 5px",
                  width: "fit-content",
                  display: "flex",
                  alignItems: "center"
                }}
              >
               
                <img src={B} alt="B" style={iconStyle} />
               

                <img src={I} alt="I" style={iconStyle} />

                <img src={A} alt="A" style={iconStyle} />
                <img
                  src={Chain}
                  alt="Chain"
                  style={iconStyle}
                />
                <img
                  src={Gif}
                  alt="Gif"
                  style={iconStyle}
                />
                <img
                  src={Img}
                  alt="Img"
                  style={iconStyle}
                />
                <img
                  src={Play}
                  alt="Play"
                  style={iconStyle}
                />
                <img
                  src={Imp}
                  alt="Imp"
                  style={iconStyle}
                />
                <img
                  src={Dash}
                  alt="Dash"
                  style={iconStyle}
                />
              </span>
            </div>
            
          </DialogContent>
          <DialogActions></DialogActions>
        </Box>
        <div
          style={{
            padding: "2%",
            background: "rgba(245, 245, 248, 1)",

            // borderRadius: "5px",
          }}
        >
          <span
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "end",
                // border: "1px solid red"
                // marginTop: "10%"
              }}
            >
              <Button
                style={{
                  background: "rgba(0, 10, 255, 1)",
                  // width: "5%",
                  color: "white",
                }}
              >
                Submit
              </Button>
              <Button
                style={{
                  background: "rgba(0,0,0, 0)",
                  border: "black",
                  // width: "5%",
                  color: "black",
                  marginLeft: "2%"
                }}
                onClick={handleDialogClose}
              >
                Cancel
              </Button>
            </span>

        </div>
      </Dialog>
      </div>
      <div
        style={{
          border: "1px solid #efefef",
          marginTop: "1%",
          // height: "25vh",
          borderRadius: "5px",
        }}
      >
        <div
          style={{
            padding: "1%",
          }}
        >
          <Typography>Manage All shortcuts</Typography>
          <Typography>
            Manage your shortcuts. They are grouped by tag to help you organize
            them
          </Typography>
        </div>
        <Divider
          style={{
            marginTop: "2%",
          }}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            // border: "1px solid red",
            padding: "1%",
            // height:"2vh"
          }}
        >
          <span style={{ marginRight: "35%" }}>
            {" "}
            {/* Adjust the value as needed */}@
          </span>
          <Button>0 shortcuts</Button>
        </div>
      </div>
    </div>

    </div>
  )
}

export default MessageShortcuts

