import pdfImg from "../Assets/pdf.png";
import mp3Img from "../Assets/mp3.png";
import excelImg from "../Assets/excel.png";
import fileImg from "../Assets/file.png";
import imgfileImg from "../Assets/image.png";
import wordImg from "../Assets/word.png";
import videoImg from "../Assets/video.png";
import zipImg from "../Assets/zip.png";
import jsonImg from "../Assets/json.png";

const imageData = {
  pdf: pdfImg,
  mp3: mp3Img,
  excel: excelImg,
  file: fileImg,
  image: imgfileImg,
  word: wordImg,
  video: videoImg,
  zip: zipImg,
  json: jsonImg,
};

export function ImageFilter(fileType) {
  if (
    fileType === "img" ||
    fileType === "png" ||
    fileType === "jpg" ||
    fileType === "jpeg" ||
    fileType === "svg"
  ) {
    return imageData.image;
  } else if (fileType === "pdf") {
    return imageData.pdf;
  } else if (fileType === "webm" || fileType === "mp3") {
    return imageData.mp3;
  } else if (fileType === "mkv" || fileType === "mp4") {
    return imageData.video;
  } else if (fileType === "xl" || fileType === "xs") {
    return imageData.excel;
  } else if (fileType === "json") {
    return imageData.json;
  } else if (fileType === "doc") {
    return imageData.word;
  } else if (fileType === "zip") {
    return imageData.zip;
  } else {
    return imageData.file;
  }
}
