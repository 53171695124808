import React from "react";
import {
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
} from "@mui/material";

const StarMessages = ({
  starredMessages,
  onUnstar,
  onReply,
  onForward,
  onDelete,
}) => {
  return (
    <>
      <Box sx={{ padding: 1 }}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box display={"flex"} alignItems={"center"}>
            <Avatar sx={{ width: 26, height: 26, marginRight: "5px" }} />
            <Typography fontSize={"12px"}>Selva</Typography>
            <Typography fontSize={"8px"} padding={"0px 4px"}>
              {">"}
            </Typography>
            <Typography fontSize={"12px"}>You</Typography>{" "}
          </Box>
          <Box>
            <Typography fontSize={"12px"}>12/12/2022</Typography>{" "}
          </Box>
        </Box>
        <Box
          padding={"8px 5px"}
          borderRadius={2}
          sx={{ background: "grey" }}
          marginTop={"4px"}
        >
          <Box>
            <Typography fontSize={"12px"}>its an new message </Typography>
          </Box>
          <Box display={"flex"} justifyContent={"flex-end"}>
            <Typography fontSize={"8px"}>5.55pm</Typography>
          </Box>
        </Box>
  
      </Box>
      <Divider />
    </>
  );
};

export default StarMessages;
