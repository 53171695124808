import React from "react";
import { Funnel, FunnelChart, ResponsiveContainer, Tooltip } from "recharts";
import Style from "../../../../Style/TopBar/Chart.module.css";

const data = [
  {
    value: 100,
    name: "Gropup A",
    fill: "rgb(117, 184, 255)",
  },
  {
    value: 80,
    name: "Group B",
    fill: " rgb(129, 236, 236)",
  },
];

const FunnelGraph = () => {
  return (
    <div className={Style.graphWrapper}>
      <ResponsiveContainer width="100%" height="70%">
        <FunnelChart
          width={430}
          height={200}
          margin={{ bottom: 10, left: 40, top: 20 }}
        >
          <Tooltip />
          <Funnel
            dataKey="value"
            data={data}
            isAnimationActive
            labelLine
            label
          ></Funnel>
        </FunnelChart>
      </ResponsiveContainer>
    </div>
  );
};

export default FunnelGraph;
