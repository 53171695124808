import React, { useState } from "react";
import StarMessages from "./StarMessage";

const StarMessageList = () => {
  const [starredMessages, setStarredMessages] = useState([
    {
      id: 1,
      text: "Hello! This is a starred message.",
      sender: "Alice",
      time: Date.now(),
      isStarred: true,
    },
    {
      id: 2,
      text: "Another important message.",
      sender: "Bob",
      time: Date.now(),
      isStarred: true,
    },
  ]);

  const handleUnstar = (message) => {
    setStarredMessages((prev) => prev.filter((m) => m.id !== message.id));
  };

  const handleReply = (message) => {
    console.log("Reply to:", message);
  };

  const handleForward = (message) => {
    console.log("Forward:", message);
  };

  const handleDelete = (message) => {
    console.log("Delete:", message);
    setStarredMessages((prev) => prev.filter((m) => m.id !== message.id));
  };

  return (
    <div>
      <StarMessages
        starredMessages={starredMessages}
        onUnstar={handleUnstar}
        onReply={handleReply}
        onForward={handleForward}
        onDelete={handleDelete}
      />
    </div>
  );
};

export default StarMessageList;
