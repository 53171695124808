import { Typography } from "@mui/material";
import React from "react";
import view from "./Overview.module.scss";
import PieLegend from "./PieLegend";
import PieGraph from "./PieGraph";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const fetchPiechatData = async ({ queryKey }) => {
  const [_,emailAccountId, startDate, endDate] = queryKey;

  const response = await axios.get(
    `${process.env.REACT_APP_MAILS_NOW_BASEURL}/analytics/inbox-spam/${emailAccountId}`,
    {
      params: { startDate, endDate },
    }
  );

  return response.data;
};

const Piechart = ({emailAccountId,getLast7Days}) => {

  const { startDate, endDate } = getLast7Days();

  const { data, isLoading } = useQuery({
    queryKey: ['inboxVsSpam', emailAccountId,startDate,endDate],
    queryFn: fetchPiechatData,
    enabled: !!emailAccountId,
});

  const LegendData = [
    { name: "Landed from inbox", value: isLoading ? 0 : data.inboxPercentage, fill: "#6CCB4B",  count: isLoading ? 0 : data.inboxCount  },
    { name: "Saved from spam", value: isLoading ? 0 : data.savedFromSpamPercentage, fill: "#CB4B4B", count: isLoading ? 0 : data.savedFromSpamCount },
  ];
  return (
    <div className={view.performance}>
      <Typography
        sx={{ fontSize: "18px", fontWeight: 600, marginBottom: "20px" }}
      >
        Inbox vs Spam
      </Typography>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "200px",
        }}
      >
        <div>
          <PieGraph data={LegendData} />
        </div>
        <div>
          <PieLegend data={LegendData} />
        </div>
      </div>
    </div>
  );
};

export default Piechart;
