// import React from "react";
// import { Box, Button, Typography } from "@mui/material";

// const Everyone = ({ handleClose }) => {
//   return (
//     <div>
//       <form>
//         <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
//           <Box sx={{ display: "flex", alignItems: "center", gap: "7px" }}>
//             <input type="checkbox" style={{ height: "17px", width: "17px" }} />
//             <Typography sx={{ fontSize: "16px" }}>
//               Also apply condition to exiting to users
//             </Typography>
//           </Box>
//           <Box sx={{ display: "flex", alignItems: "center", gap: "7px" }}>
//             <input type="checkbox" style={{ height: "17px", width: "17px" }} />
//             <Typography sx={{ fontSize: "16px" }}>
//               Overwrite the roles assigned manually to users
//             </Typography>
//           </Box>
//         </Box>
//         <Box sx={{ marginTop: "25px" }}>
//           <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
//             CONFIGURE THE CONDITION
//           </Typography>
//         </Box>
//         <Box
//           sx={{ display: "flex", justifyContent: "start", marginTop: "50px" }}
//         >
//           <Button
//             size="small"
//             variant="contained"
//             sx={{
//               width: "15%",
//               fontSize: "14px",
//               marginRight: "20px",
//               backgroundColor: "var(--button-color)",
//               "&:hover": { backgroundColor: "var(--button-color)" },
//             }}
//             type="submit"
//           >
//             Assign
//           </Button>
//           <Button
//             size="small"
//             variant="outlined"
//             sx={{
//               marginLeft: "20px",
//               padding: "10px",
//               width: "15%",
//               fontSize: "14px",
//             }}
//             type="button"
//             onClick={handleClose}
//           >
//             Cancel
//           </Button>
//         </Box>
//       </form>
//     </div>
//   );
// };

// export default Everyone;

import React from "react";
import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  Paper,
} from "@mui/material";

const Everyone = () => {
  return (
    <div>
      <FormControlLabel
        control={<Checkbox defaultChecked />}
        label="Also apply condition to existing users"
        sx={{ display: "block" }}
      />
      <FormControlLabel
        control={<Checkbox defaultChecked />}
        label="Overwrite the roles assigned manually to users"
        sx={{ display: "block" }}
      />

      <Typography
        variant="subtitle1"
        sx={{ marginTop: "20px", marginBottom: "10px", fontWeight: 600 }}
      >
        Configure the Condition
      </Typography>

      <RadioGroup defaultValue="allMembers">
        <Box
          sx={{
            padding: "15px",
            backgroundColor: "#fef7e7",
            borderRadius: "5px",
            marginBottom: "20px",
          }}
        >
          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
            Rule setting
          </Typography>
          <FormControlLabel
            value="allMembers"
            control={<Radio />}
            label="All members"
          />
          <FormControlLabel
            value="criteria"
            control={<Radio />}
            label="Users matching the criteria"
          />
        </Box>
      </RadioGroup>

      <Box sx={{ display: "flex", justifyContent: "flex-start", gap: 2 }}>
        <Button variant="contained" color="primary">
          Assign
        </Button>
        <Button variant="outlined">Cancel</Button>
      </Box>
    </div>
  );
};

export default Everyone;
