import React from "react";
import Style from "../../../Style/Common.module.css";
import ReactCountryFlag from "react-country-flag";

const VisitorsTableCell = ({ country, rowid, countrycode, visitors }) => {
  return (
    <tr
      className={`${Style.tableCellNoHover} ${Style.flex_} ${Style.commonBorder}`}
      key={`${rowid}-${countrycode}`}
    >
      <td
        className={`${Style.tableCellColumnSplit} ${Style.indexAlign} ${Style.indexText} ${Style.rightBorder}`}
      >
        {rowid}
      </td>
      <td className={`${Style.tableCellColumnSplit_main} ${Style.flex_}`}>
        <div>
          <div className={Style.flex_}>
            <div className={Style.flagPadding}>
              <ReactCountryFlag
                countryCode={countrycode}
                svg
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                }}
              />
            </div>

            <div className={Style.userNameText}>{country}</div>
          </div>
          <div>
            <div className={`${Style.convoStyle} ${Style.visitorsPadding}`}>
              {visitors} Visitors
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default VisitorsTableCell;
