import React from "react";
import StarRating from "./StarRating";
import Style from "../../../Style/ChatNow/LatestRating/LatestRating.module.css";
import Styles from "../../../Style/Common.module.css";

const MeanScore = () => {
  return (
    <div className={Style.MeanWrapper}>
      <div className={`${Style.MeanScoreSplit} ${Styles.indexAlign} ${Styles.userNameText}`}>
        <p style={{ margin: 0 }}>MeanScore</p>
      </div>
      <div className={`${Style.MeanMargin} ${Styles.commonBorder}`}></div>
      <div className={Style.MeanScoreSplit_1}>
        <StarRating />
      </div>
    </div>
  );
};

export default MeanScore;
