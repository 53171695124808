import React from "react";
import ChartGrid from "./Components/ChartGrid";

const ChartLayOut = () => {
  return (
    <div>
      <ChartGrid />
    </div>
  );
};

export default ChartLayOut;
