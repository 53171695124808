import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Dialog,
  Typography,
  TextField,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogContent,
  CircularProgress,
  Tooltip,
  Modal,
} from "@mui/material";
import { useState } from "react";
// import RedAsterisk from "../../../../components/RedAsterisk";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "../../../../components/Button";
import CloseIcon from "@mui/icons-material/Close";
import { getAllCountries } from "countries-and-timezones";
import axios from "axios";
import { useQueryClient } from "@tanstack/react-query";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Styles from "../Organization.module.scss";
import useBranch from "../../store/BranchgetApi";
import useBranchStore from "../../store/BranchStore";
import toast from "react-hot-toast";

const schema = yup
  .object({
    branch_name: yup
      .string()
      .min(3, "Please Enter Minimum 3 characters")
      .max(50, "Please Enter Maximum 50 characters")
      .required("Please Enter First Name"),
    phone_number: yup
      .string()
      .matches(/^[0-9]{10,}$/, "Mobile number must be at least 10 digits")
      .required("Please Enter Mobile Number"),
    country: yup.string().required("Please Select Country "),
    state: yup.string().required("Please Enter State"),
    city: yup.string().required("Please Enter City"),
    street1: yup.string().required("Please Enter Street"),
  })
  .required();

function Branch() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const countries = getAllCountries();
  const queryClient = useQueryClient();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const { isLoading, isError } = useBranch();
  const { branch } = useBranchStore();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const getEditedFields = (initialValues, currentValues) => {
    return Object.keys(currentValues).reduce((acc, key) => {
      if (currentValues[key] !== initialValues[key]) {
        acc[key] = currentValues[key];
      }
      return acc;
    }, {});
  };
  const onSubmit = (data) => {
    if (isEditMode) {
      const editedFields = getEditedFields(selectedBranch, data);
      axios
        .put(
          `${process.env.REACT_APP_PEOPLES_NOW_BASEURL}/branch/update-branch/${selectedBranch.branch_id}`,
          editedFields
        )
        .then((response) => {
          queryClient.invalidateQueries({ queryKey: ["branch"] });
          reset();
          setIsDialogOpen(false);
          setIsEditMode(false);
          toast.success("Branch Updated Successfully!");
        })
        .catch((error) => {
          console.error("Error updating branch:", error);
          const errorMessage =
            error.response?.data?.error?.message || "Something went wrong";
          toast.error(errorMessage);
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_PEOPLES_NOW_BASEURL}/branch/create-branch`,
          data
        )
        .then((response) => {
          queryClient.invalidateQueries({ queryKey: ["branch"] });
          reset();
          setIsDialogOpen(false);
          toast.success("Branch Created Successfully!");
        })
        .catch((error) => {
          console.error("Error adding branch:", error);
          const errorMessage =
            error.response?.data?.error?.message || "Something went wrong";
          toast.error(errorMessage);
        });
    }
  };
  const handleDelete = async (id) => {
    return axios
      .delete(
        `${process.env.REACT_APP_PEOPLES_NOW_BASEURL}/branch/delete-branch/${id}`
      )
      .then((response) => {
        queryClient.invalidateQueries({ queryKey: ["branch"] });
        setIsDeleteOpen(false);
        toast.success("Branch Deleted Successfully!");
      })
      .catch((error) => {
        console.error("Error deleting branch:", error);
        setIsDeleteOpen(false);
        const errorMessage =
          error.response?.data?.error?.message || "Error Deleting Branch!";
        toast.error(errorMessage);
      });
  };

  const handleOkClick = () => {
    if (selectedBranch) {
      handleDelete(selectedBranch.branch_id);
    }
  };

  const handleAddBranchClick = () => {
    setIsDialogOpen(true);
  };
  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setIsEditMode(false);
    reset();
  };

  const handleEditBranchClick = (branch) => {
    setSelectedBranch(branch);
    setIsEditMode(true);
    setValue("branch_name", branch.branch_name);
    setValue("phone_number", branch.phone_number);
    setValue("country", branch.country);
    setValue("state", branch.state);
    setValue("city", branch.city);
    setValue("street1", branch.street1);
    setIsDialogOpen(true);
  };

  if (isLoading)
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CircularProgress />
      </Box>
    );
  if (isError) return <div>Error loading data</div>;
  return (
    <div>
      <div
        style={{
          height: "65vh",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <Button
              style={{
                background: "rgba(0, 10, 255, 1)",
                color: "white",
                marginTop: "2%",
                marginRight: "2%",
              }}
              onClick={handleAddBranchClick}
            >
              Add Branch
            </Button>
          </div>
          <div>
            <Box
              style={{
                width: "100%",
                marginTop: "2%",
              }}
            >
              <Table
                style={{
                  height: "50%",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                      }}
                    >
                      Branch Name
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        width: "30px",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        width: "30px",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {branch.map((branch) => (
                    <TableRow
                      className={Styles.hoverTable}
                      sx={{
                        "&:hover": {
                          backgroundColor: "var(--light-bg-img-color)",
                        },
                        cursor: "pointer",
                      }}
                      key={branch.branch_id}
                    >
                      <TableCell>{branch.branch_name}</TableCell>
                      <TableCell className={Styles.Tablemenu}>
                        <Tooltip title="Edit" placement="bottom" arrow>
                          {" "}
                          <EditOutlinedIcon
                            onClick={() => handleEditBranchClick(branch)}
                          />
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={Styles.Tablemenu}
                        sx={{ color: "red", paddingRight: "40px" }}
                      >
                        <Tooltip title="Delete" placement="bottom" arrow>
                          <DeleteOutlineOutlinedIcon
                            onClick={() => {
                              setSelectedBranch(branch);
                              setIsDeleteOpen(true);
                            }}
                          />
                        </Tooltip>
                      </TableCell>
                      {/* <TableCell>{branch.phone_number}</TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            <Dialog
              open={isDialogOpen}
              onClose={handleDialogClose}
              maxWidth="md"
              fullWidth
            >
              <Box
                style={{
                  background: "rgba(0, 10, 255, 1)",
                  padding: "10px 10px 10px 25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "white" }}>
                  {isEditMode ? "Edit Branch" : "Add Branch"}
                </Typography>
                <CloseIcon
                  onClick={() => setIsDialogOpen(false)}
                  style={{
                    cursor: "pointer",
                    color: "white",
                    fontSize: "20px",
                  }}
                />
              </Box>
              <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth margin="normal">
                        <TextField
                          {...register("branch_name")}
                          error={!!errors.branch_name}
                          helperText={errors.branch_name?.message}
                          variant="outlined"
                          label="Branch Name *"
                          placeholder="Branch Name"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth margin="normal">
                        <TextField
                          {...register("phone_number")}
                          error={!!errors.phone_number}
                          helperText={errors.phone_number?.message}
                          variant="outlined"
                          label="Mobile No *"
                          placeholder="Mobile No"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel>Country Code * </InputLabel>
                        <Select
                          {...register("country")}
                          error={!!errors.country}
                          value={watch("country") || ""}
                          onChange={(e) => setValue("country", e.target.value)}
                          label="Country Code * "
                          MenuProps={{
                            PaperProps: {
                              sx: { maxHeight: "30%", width: "200px" },
                            },
                          }}
                        >
                          {Object.values(countries).map((country) => (
                            <MenuItem key={country.id} value={country.name}>
                              {country.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth margin="normal">
                        <TextField
                          {...register("state")}
                          error={!!errors.state}
                          helperText={errors.state?.message}
                          variant="outlined"
                          label="State *"
                          placeholder="State"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth margin="normal">
                        <TextField
                          {...register("city")}
                          error={!!errors.city}
                          helperText={errors.city?.message}
                          variant="outlined"
                          label="City *"
                          placeholder="City"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth margin="normal">
                        <TextField
                          {...register("street1")}
                          error={!!errors.street1}
                          helperText={errors.street1?.message}
                          variant="outlined"
                          label="Street *"
                          placeholder="Street"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      style={{
                        background: "rgba(0, 0, 0, 0)",
                        borderRadius: "5px",
                        color: "Black",
                        boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25)",
                        marginRight: "20px",
                      }}
                      // onClose={()=> setIsDialogOpen(false)}
                      onClick={() => {
                        setIsDialogOpen(false);
                        setIsEditMode(false);
                        reset();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{
                        background: "rgba(0, 10, 255, 1)",
                        borderRadius: "5px",
                        color: "white",
                      }}
                      type="submit"
                    >
                      {isEditMode ? "Update Branch" : "Add Branch"}
                    </Button>
                  </Box>
                </form>
              </DialogContent>
            </Dialog>
          </div>
          <Modal
            open={isDeleteOpen}
            onClose={() => setIsDeleteOpen(false)}
            sx={{ outline: "none", border: "none" }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "45vw",
                height: "150px",
                bgcolor: "background.paper",
                boxShadow: 24,
                padding: "5px 5px 20px 20px ",
                display: "flex",
                flexDirection: "column",
                borderRadius: "6px",
                outline: "none",
                border: "none",
              }}
            >
              <CloseIcon
                sx={{ fontSize: 20, cursor: "pointer", marginLeft: "auto" }}
                onClick={() => setIsDeleteOpen(false)}
              />
              <Box sx={{ height: "70px", paddingRight: "15px" }}>
                <Typography sx={{ fontSize: "16px" }}>
                  {selectedBranch
                    ? `Deleting Branch "${selectedBranch.branch_name}" will deleted permanently. Proceed?`
                    : "No item selected."}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  padding: "10px 20px",
                }}
              >
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    bgcolor: "#F7F8FA",
                    color: "black",
                    border: "1px solid gray",
                    marginRight: "20px",
                  }}
                  type="button"
                  onClick={() => setIsDeleteOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  sx={{
                    bgcolor: "var(--button-color)",
                    color: "#fff",
                    ":hover": { backgroundColor: "var(--button-color)" },
                  }}
                  onClick={handleOkClick}
                >
                  Ok
                </Button>
              </Box>
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default Branch;

// sx={{".css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper" : { maxWidth: "310px",maxHeight: "35%",}}}
