import React from "react";
import Style from "../Style/TopBar/TopBar.module.css";
import SearchDropDown from "./Components/SearchDropDown";
import ComponentDropDown from "./ComponentDropDown";
import ViewDropDown from "./Components/ViewDropDown";
const TopBar = ({ anchor, handleIconClose, openFullscreen }) => {
  return (
    <div className={Style.topBarWrapper}>
      <SearchDropDown />

      <div className={Style.topBar_Right}>
        <ComponentDropDown />
        <ViewDropDown
          anchor={anchor}
          handleIconClose={handleIconClose}
          openFullscreen={openFullscreen}
        />
      </div>
    </div>
  );
};

export default TopBar;
