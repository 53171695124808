import React, { useEffect, useState } from "react";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import Smtp from "./EmailAccount.module.scss";
// import YouTubeIcon from "@mui/icons-material/YouTube";
import { Stack } from "@mui/system";
import AntSwitch from "../../../../../../../MarketingStudio/MailsNow/CampInfo/element/Antswitch";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import useMediaQuery from "@mui/material/useMediaQuery";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  emailProviderGlobal,
  smtpDataGlobal,
  smtpFieldsGlobal,
  imapDataGlobal,
} from "../../../../../../../MarketingStudio/store/userStore";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import {
  imapConnectionTest,
  imapDetails,
} from "../../../../../../../MarketingStudio/webservices/sequence";

const style = {
  "& .MuiInputBase-root": {
    height: "35px", // Set your desired height
  },
  "& .MuiOutlinedInput-root": {
    padding: "0", // Adjust padding if needed
  },
  "& .MuiInputLabel-root": {
    top: "-4px", // Adjust label position if needed
  },
};
const schema = yup.object({
  password: yup.string().trim().required("Please enter password").required(),
  email: yup
    .string()
    .trim()
    .lowercase()
    .email("Invalid Email")
    .required("Please Enter Email"),
  imap_host: yup
    .string()
    .max(50, "Maximum 50 characters")
    .required("Please enter SMTP Host"),
});

const ImapConnect = ({ data, openSmtp }) => {
  const { emailProviderValue } = emailProviderGlobal();
  const { setImapValues } = imapDataGlobal();
  const { smtpValues } = smtpDataGlobal();
  const { values } = smtpFieldsGlobal();
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [checked, setChecked] = useState(false);
  const [verification, setVerification] = useState(false);

  const imapData = useQuery({
    queryKey: ["imapDetails"],
    queryFn: () => imapDetails(data.email_address),
  });

  console.log("xooxxxx", imapData?.data?.data);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      email_address: "",
      imap_host: "",
      imap_port: "",
      imap_password: "",
      imap_encryption: "",
    },
  });

  useEffect(() => {
    if (!openSmtp) {
      setValue(
        "imap_host",
        emailProviderValue.imap_host || imapData?.data?.data?.imap_host
      );
      setValue(
        "imap_port",
        emailProviderValue.imap_port || imapData?.data?.data?.imap_port
      );
      setValue(
        "imap_encryption",
        emailProviderValue.imap_encryption ||
          imapData?.data?.data?.imap_encryption
      );
      setValue("email_address", imapData?.data?.data?.email_address || "");
      setValue("imap_password", imapData?.data?.data?.imap_password || "");
    } else {
      setValue("imap_host", "");
      setValue("imap_port", "");
      setValue("imap_encryption", "");
      setValue("email_address", "");
      setValue("imap_password", "");
    }
  }, [emailProviderValue, imapData?.data?.data, openSmtp]);

  const handleChange = (event) => {
    console.log("kkkkk", values);
    if (event.target.checked) {
      setValue("email_address", values.email_address);
      setValue("imap_password", values.smtp_password);
    } else {
      setValue("email_address", "");
      setValue("imap_password", "");
    }
    setChecked(event.target.checked);
  };

  const mutation = useMutation({
    mutationFn: imapConnectionTest,
    onSuccess: (data) => {
      console.log("Task added successfully:", data);
      setApiError("");
      setIsLoading(false);
      setVerification(true);
    },
    onError: (error) => {
      console.error("Error adding Task:", error.response.data);
      if (error.response.status === 535) {
        setApiError(error.response.data);
      }
      setVerification(false);
      setIsLoading(false);
    },
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    const formattedData = {
      imap_host: data.imap_host,
      imap_port: data.imap_port,
      email_address: data.email_address,
      password: data.imap_password,
      imap_encryption: data.imap_encryption,
    };
    setImapValues(formattedData);
    console.log("qqqqqq", formattedData);
    mutation.mutate(formattedData);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  return (
    <div
      style={{
        maxHeight: "calc(90vh - 7rem)",
        overflowY: "auto",
        backgroundColor: "#f3f4f6",
        padding: ".5rem",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            borderRadius: ".5rem",
            background: "#fff",
            padding: "1rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "66%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "15.25rem",
              }}
            >
              <div style={{ display: "flex" }}>
                <span
                  style={{
                    textDecoration: "underline",
                    marginRight: ".125rem",
                    fontSize: ".875rem",
                    fontWeight: "600",
                    lineHeight: "22px",
                    color: "#1f2937",
                  }}
                >
                  IMAP Details
                </span>
                <span
                  style={{
                    fontSize: ".75rem",
                    lineHeight: "20px",
                    fontWeight: "400",
                    color: "#6c7280",
                  }}
                >
                  (to track your email)
                </span>
              </div>
              <div style={{ cursor: "pointer" }}>
                {/* <YouTubeIcon fontSize='small' style={{color:"red"}}/> */}
              </div>
            </div>
            <div style={{ marginTop: "1rem", display: "flex" }}>
              <Stack direction="row" alignItems="center">
                <AntSwitch
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "ant design" }}
                />
              </Stack>
              <span
                style={{
                  fontSize: ".75rem",
                  color: "#1f2937",
                  fontWeight: "500",
                  paddingLeft: "4px",
                }}
              >
                Use the same username and password from SMTP
              </span>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto",
                columnGap: "1.5rem",
              }}
            >
              <div
                style={{
                  width: "15.25rem",
                  marginTop: ".3rem",
                  position: "relative",
                }}
              >
                <span
                  style={{
                    fontSize: ".75rem",
                    fontWeight: "600",
                    lineHeight: "20px",
                    color: "#6b7280",
                  }}
                >
                  Email Address
                </span>
                <div style={{ marginTop: ".125rem" }}>
                  <Controller
                    name="email_address"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        id=""
                        style={{
                          background: "#f7f8f9",
                          padding: "4px 11px",
                          color: "#595959",
                          fontSize: "14px",
                          lineHeight: "1.5715",
                          border: "1px solid #d9d9d9",
                          borderRadius: ".25rem",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "30px",
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "15.25rem",
                  marginTop: ".3rem",
                  position: "relative",
                }}
              >
                <span
                  style={{
                    fontSize: ".75rem",
                    fontWeight: "600",
                    lineHeight: "20px",
                    color: "#6b7280",
                  }}
                >
                  Password
                </span>
                <div style={{ marginTop: ".125rem" }}>
                  <Controller
                    name="imap_password"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="password"
                        id=""
                        style={{
                          background: "#f7f8f9",
                          padding: "4px 11px",
                          color: "#595959",
                          fontSize: "14px",
                          lineHeight: "1.5715",
                          border: "1px solid #d9d9d9",
                          borderRadius: ".25rem",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "30px",
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "15.25rem",
                  marginTop: ".3rem",
                  position: "relative",
                }}
              >
                <span
                  style={{
                    fontSize: ".75rem",
                    fontWeight: "600",
                    lineHeight: "20px",
                    color: "#6b7280",
                  }}
                >
                  IMAP Host
                </span>
                <div style={{ marginTop: ".125rem" }}>
                  <Controller
                    name="imap_host"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        id=""
                        style={{
                          background: "#f7f8f9",
                          padding: "4px 11px",
                          color: "#595959",
                          fontSize: "14px",
                          lineHeight: "1.5715",
                          border: "1px solid #d9d9d9",
                          borderRadius: ".25rem",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "30px",
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "15.25rem",
                  marginTop: ".3rem",
                  position: "relative",
                  display: "flex",
                }}
              >
                <div>
                  <span
                    style={{
                      fontSize: ".75rem",
                      fontWeight: "600",
                      lineHeight: "20px",
                      color: "#6b7280",
                    }}
                  >
                    IMAP Port
                  </span>
                  <div style={{ marginTop: ".125rem" }}>
                    <Controller
                      name="imap_port"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          type="number"
                          id=""
                          style={{
                            background: "#f7f8f9",
                            padding: "4px 11px",
                            color: "#595959",
                            fontSize: "14px",
                            lineHeight: "1.5715",
                            border: "1px solid #d9d9d9",
                            borderRadius: ".25rem",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "30px",
                            width: "4.9375rem",
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div style={{ marginLeft: "10px" }}>
                  <span
                    style={{
                      fontSize: ".75rem",
                      fontWeight: "600",
                      lineHeight: "20px",
                      color: "#6b7280",
                    }}
                  >
                    Encryption
                  </span>
                  <div style={{ marginTop: ".125rem" }}>
                    <Controller
                      name="imap_encryption"
                      control={control}
                      render={({ field }) => (
                        <ToggleButtonGroup
                          value={field.value}
                          exclusive
                          onChange={(e, value) => {
                            field.onChange(value);
                          }}
                          aria-label="text alignment"
                        >
                          <ToggleButton
                            value="SSL"
                            aria-label="left aligned"
                            style={{
                              height: "30px",
                              padding: ".4rem .5625rem",
                              width: "35px",
                            }}
                          >
                            <span style={{ fontSize: ".75rem" }}>SSL</span>
                          </ToggleButton>
                          <ToggleButton
                            value="TLS"
                            aria-label="centered"
                            style={{
                              height: "30px",
                              padding: ".4rem .5625rem",
                              width: "35px",
                            }}
                          >
                            <span style={{ fontSize: ".75rem" }}>TLS</span>
                          </ToggleButton>
                          <ToggleButton
                            value="None"
                            aria-label="right aligned"
                            style={{
                              height: "30px",
                              padding: ".4rem .5625rem",
                              width: "40px",
                            }}
                          >
                            <span
                              style={{
                                fontSize: ".75rem",
                                textTransform: "capitalize",
                              }}
                            >
                              None
                            </span>
                          </ToggleButton>
                        </ToggleButtonGroup>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {apiError ? (
              <div
                style={{
                  width: "17.75rem",
                  borderRadius: ".625rem",
                  background: "#fef2f2",
                  padding: "1rem",
                }}
              >
                <div>
                  <p
                    style={{
                      lineHeight: "1.125rem",
                      fontSize: ".75rem",
                      fontWeight: "600",
                      color: "#212529",
                    }}
                  >
                    {apiError.message}
                  </p>
                </div>
                <div style={{ marginTop: "-.5rem" }}>
                  <div style={{ boxSizing: "border-box" }}>
                    <ol
                      style={{
                        marginLeft: "-1.25rem",
                        lineHeight: "1.125rem",
                        fontSize: ".75rem",
                        fontWeight: "400",
                      }}
                    >
                      {apiError?.data?.map((item) => (
                        <li
                          style={{ lineHeight: "1.125rem", fontSize: ".75rem" }}
                        >
                          {item}
                        </li>
                      ))}
                    </ol>
                    <div
                      style={{
                        borderTop: "1px solid #e5e7eb",
                        paddingTop: "1rem",
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          fontSize: ".75rem",
                          lineHeight: "20px",
                          fontWeight: "500",
                          textDecoration: "underline",
                          color: "#1d4ed8",
                        }}
                      >
                        Learn More
                      </span>
                      <span
                        style={{
                          fontSize: ".75rem",
                          lineHeight: "20px",
                          fontWeight: "500",
                          textDecoration: "underline",
                          color: "#1d4ed8",
                        }}
                      >
                        Show error log
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "17.75rem",
                  borderRadius: ".625rem",
                  background: "#eff6ff",
                  padding: "1rem",
                }}
              >
                <div>
                  <p
                    style={{
                      lineHeight: "1.125rem",
                      fontSize: ".75rem",
                      fontWeight: "600",
                      color: "#212529",
                    }}
                  >
                    Steps to connect IMAP
                  </p>
                </div>
                <div style={{ marginTop: "-.5rem" }}>
                  <div style={{ boxSizing: "border-box" }}>
                    <ol
                      style={{
                        marginLeft: "-1.25rem",
                        lineHeight: "1.125rem",
                        fontSize: ".75rem",
                        fontWeight: "400",
                      }}
                    >
                      <li
                        style={{ lineHeight: "1.125rem", fontSize: ".75rem" }}
                      >
                        Make sure that IMAP is enabled for this Gmail mailbox.
                      </li>
                      <li
                        style={{ lineHeight: "1.125rem", fontSize: ".75rem" }}
                      >
                        You can use the same app password to connect the IMAP.
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <LoadingButton
                loading={isLoading}
                onClick={handleSubmit(onSubmit)}
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid #1d4ed8",
                  borderColor: "#1d4ed8",
                  color: "#1d4ed8",
                  fontSize: ".875rem",
                  lineHeight: "1.45",
                  textTransform: "capitalize",
                }}
                autoFocus
              >
                Test IMAP Connection
              </LoadingButton>
              <div
                style={{
                  marginTop: "1rem",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  display: verification ? "flex" : "none",
                }}
              >
                <CheckCircleOutlineOutlinedIcon
                  fontSize="small"
                  style={{ color: "#047857" }}
                />
                <span
                  style={{
                    fontWeight: "500",
                    marginLeft: ".5rem",
                    fontSize: ".875rem",
                    lineHeight: "22px",
                    color: "#047857",
                  }}
                >
                  IMAP authenticated successfully!
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImapConnect;
