import React from "react";
import { Box, Typography } from "@mui/material";
import { ImageFilter } from "../../../../Utils/imageUtils";

const ReplyMessage = ({ data }) => {
  const replyData = data.reply;
  if (!replyData) return null;
  const isAudio = replyData?.mimeType?.startsWith("audio/");
  const isVideo = replyData?.mimeType?.startsWith("video/");
  const isImage = replyData?.mimeType?.startsWith("image/");
  const isFile =
    isImage || isAudio || isVideo || replyData.ContentType === "file";
  console.log("myreplymsg", replyData);
  return (
    <Box
      display="flex"
      flexDirection="column"
      borderLeft="4px solid #25D366"
      bgcolor="#f0f0f0"
      padding="6px"
      borderRadius="8px"
      marginBottom="4px"
    >
      <Typography
        color="#128C7E"
        fontSize="12px"
        fontWeight="bold"
        sx={{ lineHeight: "16px", mb: 0.5 }}
      >
        {data.messageType === "sent"
          ? "You"
          : `${data?.sender?.first_name} ${data?.sender?.last_name}`}
      </Typography>

      {isImage ? (
        <Box display="flex" alignItems="center">
          <img
            alt="file icon"
            src={ImageFilter(replyData?.mimeType)}
            style={{ width: 20, height: 20, marginRight: "8px" }}
          />
          <Typography color="#333" fontSize={12} noWrap>
            {replyData?.fileName}
          </Typography>
        </Box>
      ) : isFile ? (
        <Box display="flex" alignItems="center">
          <img
            alt="file icon"
            src={ImageFilter(replyData?.mimeType)}
            style={{ width: 20, height: 20, marginRight: "8px" }}
          />
          <Typography color="#333" fontSize={12} noWrap>
            {replyData?.fileName}
          </Typography>
        </Box>
      ) : (
        <Typography
          color="#333"
          fontSize="12px"
          sx={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {replyData?.replyContent}
        </Typography>
      )}
    </Box>
  );
};

export default ReplyMessage;
