import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import NotificationNoData from "./NotificationNoData";

const StyledTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
    fontSize: "12px",
    textTransform: "capitalize",
    color: "black",
  },
  "& .MuiButtonBase-root.MuiTab-root": {
    fontSize: "12px",
    textTransform: "capitalize",
    color: "black",
    padding: 0,
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function CustomTabs({ tabs }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: "#0000FF",
            },
          }}
        >
          {tabs.map(({ label }, i) => (
            <Tab label={label} key={i} />
          ))}
        </StyledTabs>
      </Box>
      {tabs.map(({ Component }, i) => (
        <TabPanel value={value} index={i} key={i}>
          {<NotificationNoData />}
        </TabPanel>
      ))}
    </Box>
  );
}
