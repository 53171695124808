import React, { useState } from "react";
import Gads from "./Ads.module.scss";
import {
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import googleImg from "../../../../Images/google-logo.png";

const style = {
  margin: "5px 25px 0 10px ",
  "& .MuiInputBase-root": {
    height: "45px", // Set your desired height
  },
  "& .MuiOutlinedInput-root": {
    padding: "0", // Adjust padding if needed
  },
  "& .MuiInputLabel-root": {
    top: "-4px", // Adjust label position if needed
  },
};

const GoogleAds = () => {
  const [textValue, setTextValue] = useState("");
  const handleCopy = () => {
    navigator.clipboard.writeText(textValue).then(() => {
      alert("Text copied to clipboard!");
    });
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className={Gads.container}>
          <div className={Gads.header}>Log on to Google Ads</div>
          <Typography sx={{ marginTop: "15px", fontSize: "16px" }}>
            Use Google Ads Account to log on
          </Typography>
          <div className={Gads.logButton}>
            <img
              src={googleImg}
              alt="Google"
              style={{ height: "50px", width: "50px" }}
            />
            <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
              Sign in with Google
            </Typography>
          </div>
        </div>
      </Grid>

      <Grid item xs={12}>
        <div className={Gads.container}>
          <div className={Gads.header}>Source Name</div>
          <FormControl fullWidth margin="normal">
            <TextField variant="outlined" placeholder="Google Ads" sx={style} />
          </FormControl>
          <Typography
            sx={{
              margin: "0px 0 10px 10px",
              fontSize: "14px",
              color: "#878787",
            }}
          >
            As will be seen in the report. Example: Newsletters, Radio,Instagram
            post
          </Typography>
        </div>
      </Grid>

      <Grid item xs={12}>
        <div className={Gads.container}>
          <div className={Gads.header}>UTM parameters</div>
          <div className={Gads.utmtextfield}>
            <div className={Gads.utmtext}>utm_source</div>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                placeholder="+ Add UTM parameter"
                sx={style}
              />
            </FormControl>
          </div>
          <Typography
            sx={{
              margin: "0px 0 10px 10px",
              fontSize: "14px",
              color: "#878787",
            }}
          >
            Click source. Example: email, newsletter, banner, instagram
          </Typography>
          <div className={Gads.utmtextfield}>
            <div className={Gads.utmtext}>utm_control</div>

            <FormControl fullWidth>
              <TextField
                variant="outlined"
                // placeholder=""
                value={textValue}
                onChange={(e) => setTextValue(e.target.value)}
                sx={style}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography
                        onClick={handleCopy}
                        sx={{
                          marginRight: "15px",
                          cursor: "pointer",
                          fontSize: "16px",
                          fontWeight: 600,
                        }}
                      >
                        Copy
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </div>
          <Typography
            sx={{
              margin: "0px 0 10px 10px",
              fontSize: "14px",
              color: "#878787",
            }}
          >
            you have to configure your ad account to get a correct detailed cost
            report
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default GoogleAds;
