import * as React from "react";
import Grid from "@mui/material/Grid";
import Wrapper from "../Components/Wrapper";
import GraphContainer from "./Components/GraphContainer";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { WithHoc } from "../ChatNow/Components/WithHoc";
import WrapperData from "../Components/WrapperData";
import RenderChart from "./Components/RenderChart";
export default function OverView() {
  const data = [
    { name: "Google", value: 100, fill: "#FF7009" },
    { name: "Direct", value: 300, fill: "#9D0000" },
    { name: "Whatsapp", value: 100, fill: "#473A7A" },
  ];
  const WrapperComponent = WithHoc(Wrapper, WrapperData);
  const WrappeWithChart = WithHoc(Wrapper, WithHoc(WrapperData, RenderChart));

  const initialChartItems = [
    [
      {
        id: "employeeStatus",
        component: (
          <WrappeWithChart
            title={"Employee Status"}
            count={60}
            rate={"$291"}
            percentage={"100%"}
            chartData={data}
          />
        ),
      },
      {
        id: "taskCompleted",
        component: (
          <WrapperComponent
            title={"Task Completed"}
            count={3}
            percentage={"100%"}
            rate={"0"}
          />
        ),
      },
    ],
    [
      {
        id: "leadsCreated",
        component: (
          <WrapperComponent
            title={"Leads Created"}
            count={1}
            percentage={"100%"}
            rate={"0"}
          />
        ),
      },
      {
        id: "callsCompleted",
        component: (
          <WrapperComponent
            title={"Calls Completed"}
            count={1}
            percentage={"100%"}
            rate={"0"}
            noData={true}
          />
        ),
      },
    ],
    [
      {
        id: "orderClosed",
        component: (
          <WrapperComponent
            title={"Order Closed"}
            count={1}
            percentage={"100%"}
            rate={"0"}
          />
        ),
      },
      {
        id: "websiteVisitor",
        component: (
          <WrapperComponent
            title={"Website Visitor"}
            count={1}
            percentage={"100%"}
            rate={"0"}
            noData={true}
          />
        ),
      },
    ],
    [
      {
        id: "sourceOfLeads",
        component: <GraphContainer title={"Source of the Leads"} />,
      },
    ],
  ];

  const [chartItems, setChartItems] = React.useState(initialChartItems);
  function splitIndex(columnString) {
    const Index = columnString.split("-");
    return Index;
  }

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const sourceIndex = splitIndex(result.source.droppableId);
    const destinationIndex = splitIndex(result.destination.droppableId);
    const Source = chartItems[sourceIndex[1]][sourceIndex[2]];
    const Destination = chartItems[destinationIndex[1]][destinationIndex[2]];
    const tempArray = [...chartItems];
    if (
      chartItems[sourceIndex[1]].length === 1 ||
      chartItems[destinationIndex[1]].length === 1
    ) {
      tempArray[sourceIndex[1]] = chartItems[destinationIndex[1]];
      tempArray[destinationIndex[1]] = chartItems[sourceIndex[1]];
    } else {
      tempArray[sourceIndex[1]][sourceIndex[2]] = Destination;
      tempArray[destinationIndex[1]][destinationIndex[2]] = Source;
    }

    setChartItems(tempArray);
  };
  
  return (
    <DragDropContext onDragEnd={(result, index) => handleOnDragEnd(result, 0)}>
      <Grid container spacing={1} >
        {chartItems.map((column, columnIndex) => (
          <Grid container item spacing={1} xs={12} sm={6} md={3}>
            {column.map(({ id, component }, index) => (
              <Grid item xs={12} key={id}>
                <Droppable
                  droppableId={`column-${columnIndex}-${index}`}
                  direction="vertical"
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{ height: "100%" }}
                    >
                      <Draggable key={id} draggableId={id} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {component}
                          </div>
                        )}
                      </Draggable>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    </DragDropContext>
  );
}
