import React from "react";
import Grid from "@mui/material/Grid";
import FTGraph from "./FTGraph";
import FTFilter from "./FTFilter";
import Style from "../../../Style/Common.module.css"
const FTGrid = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8} sm={12} className={Style.containerMinHeight}>
        <FTGraph />
      </Grid>
      <Grid item xs={12} md={4} sm={12}>
     <FTFilter/>
      </Grid>
    </Grid>
  );
};

export default FTGrid;
