import {
  Box,
  FormControl,
  Select,
  TextField,
  Typography,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm, Controller } from "react-hook-form";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import {
  checkCustomTrackingStatus,
  getCustomTrackingDomain,
  updateSmtp,
} from "../../../../../../../MarketingStudio/webservices/sequence";
import { snackbarStore } from "../../../../../../../MarketingStudio/store/userStore";
import { SetBodyValue } from "../../../../../../../MarketingStudio/store/templateStore";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { color } from "@mui/system";
import QuillEditor from "../../../../../../../MarketingStudio/component/Editor/QuillEditor";

const style = {
  marginTop: "5px",
  "& .MuiInputBase-root": {
    height: "40px", // Set your desired height
    width: "60%",
  },
  "& .MuiOutlinedInput-root": {
    padding: "0", // Adjust padding if needed
  },
  "& .MuiInputLabel-root": {
    top: "-4px", // Adjust label position if needed
  },
};
const styleSelect = {
  height: "40px", // Adjust this value as needed
  width: "60%",
  marginTop: "5px",
  "& .MuiSelect-select": {
    padding: "4px 14px ", // Adjust padding if needed
  },
};
const typography = {
  padding: "0",
  fontSize: "14px",
  fontWeight: 400,
  color: "#6B7280",
};

const GeneralSettings = ({ data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { setSnackbarValue } = snackbarStore();
  const { body, SetBody } = SetBodyValue();
  const queryClient = useQueryClient();
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const trackingDomains = useQuery({
    queryKey: ["getCustomTrackingDomain"],
    queryFn: () => getCustomTrackingDomain(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      first_name: data?.first_name,
      last_name: data?.last_name,
      tracking_domain: data?.custom_tracking,
      bcc: data?.bcc_email,
      c_name: data?.custom_tracking,
      value: "liveserver.nowdigitaleasy.com",
    },
  });

  const mutation = useMutation({
    mutationFn: updateSmtp,
    onSuccess: (data) => {
      setIsLoading(false);
      queryClient.invalidateQueries({ queryKey: ["globalEmailAccounts"] });
      setSnackbarValue({
        open: true,
        severity: "success",
        message: "Updated Successfully",
      });
    },
    onError: (error) => {
      console.error("Error adding:", error.response.data);
      setIsLoading(false);
      setSnackbarValue({
        open: true,
        severity: "error",
        message: error?.response?.data?.message || "internal server error",
      });
    },
  });
  console.log("sskskskskks", data);
  const onSubmit = (data1) => {
    setIsLoading(true);
    const formData = {
      ...data1,
      enable: data?.enable,
      active: data?.active,
      signature: body,
      doc_id: data?._id,
    };
    mutation.mutate(formData);
  };
  const trackingDomain = watch("tracking_domain");

  useEffect(() => {
    if (trackingDomain) {
      const domainPrefix = trackingDomain.split(".")[0];
      setValue("c_name", domainPrefix);
    }
  }, [trackingDomain, setValue]);

  const handleCheckTrackingStatus = async () => {
    setLoading(true);
    setStatus(null);
    setError(null);
    try {
      const result = await checkCustomTrackingStatus(trackingDomain);
      setStatus(result);
    } catch (err) {
      setError("Failed to check status.");
    } finally {
      setLoading(false);
    }
  };
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setSnackbarValue({
          open: true,
          severity: "success",
          message: "copied",
        });
      },
      (err) => {
        setSnackbarValue({
          open: true,
          severity: "error",
          message: "error coping value",
        });
      }
    );
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        border: ".0625rem solid #e5e7eb",
        padding: "0px 15px 20px",
      }}
    >
      <FormControl margin="normal">
        <Typography sx={typography}>First Name</Typography>
        <Controller
          name="first_name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              placeholder="First Name"
              fullWidth
              sx={style}
            />
          )}
        />
      </FormControl>
      <FormControl margin="normal">
        <Typography sx={typography}>Last Name</Typography>
        <Controller
          name="last_name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              placeholder="Last Name"
              fullWidth
              sx={style}
            />
          )}
        />
      </FormControl>
      <Box sx={{ margin: "20px 0 10px 0 " }}>
        {" "}
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Typography sx={{ marginBottom: "7px" }}>
            Custom tracking domain name
          </Typography>
          <Tooltip
            title=" You'll need access to your domain hosting service to configure
            custom tracking domain. You will need to add DNS records, CNAME and
            TXT records. Copy host value for each record and add them to your
            domain manager. Refer this article to know more."
            arrow
          >
            <InfoOutlinedIcon
              fontSize="small"
              sx={{
                color: "#878787",
                fontSize: "16px",
                cursor: "pointer",
                marginBottom: "7px",
              }}
            />
          </Tooltip>
        </Box>
        <Controller
          name="tracking_domain"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              placeholder="Enter domain Name"
              fullWidth
              sx={style}
            />
          )}
        />
      </Box>
      <Box
        sx={{
          border: "1px solid #E6F7FF",
          borderRadius: "5px",
          padding: "10px 20px ",
          backgroundColor: "#F5F5F5",
          fontSize: "12px",
          alignItems: "center",
          margin: "20px 0 10px 0 ",
          display: "flex",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "35px",
            width: "20%",
          }}
        >
          <Typography>Type</Typography>
          <Typography>CNAME</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            width: "40%",
          }}
        >
          <Typography>Host</Typography>
          <Controller
            name="c_name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                size="small"
                placeholder="C Name"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <CopyAllIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => copyToClipboard(field.value)}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            width: "40%",
          }}
        >
          <Typography>Value</Typography>
          <Controller
            name="value"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                fullWidth
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="start">
                      <CopyAllIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => copyToClipboard(field.value)}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Box>
      </Box>
      {/* <FormControl margin="normal">
        <Typography sx={typography}>Custom Tracking Domain</Typography>
        <Controller
            name="tracking_domain"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                placeholder="Select Tracking Domain"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={field.value || '1'}
                onChange={(e) => {
                  field.onChange(e);
                }}
                sx={styleSelect}
              >
                <MenuItem value={1}>Select Tracking Domain</MenuItem>
                {trackingDomains?.data?.data?.map((item) => (
                  <MenuItem key={item._id} value={item._id}>{item.tracking_domain}</MenuItem>
                ))}
              </Select>
            )}
          />
      </FormControl> */}
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Box
          sx={{
            fontSize: "14px",
            color: "#4c2ef8",
            backgroundColor: "#f1f2fb",
            padding: "7px 5px",
            borderRadius: "5px",
            cursor: "pointer",
            textAlign: "center",
            width: "15%",
            ":hover": { backgroundColor: "#b9bdeb" },
          }}
          onClick={handleCheckTrackingStatus}
        >
          {loading ? "Checking..." : "Check Status"}
        </Box>
        {status && (
          <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <CheckCircleRoundedIcon fontSize="small" color="success" />
            <Typography sx={{ fontSize: "14px" }}>{status}</Typography>
          </Box>
        )}
        {error && (
          <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <CancelRoundedIcon fontSize="small" color="error" />
            <Typography sx={{ fontSize: "14px" }}> {error}</Typography>
          </Box>
        )}
      </Box>
      <FormControl margin="normal">
        <Typography sx={typography}>BCC</Typography>
        <Controller
          name="bcc"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              placeholder="BCC"
              fullWidth
              sx={style}
            />
          )}
        />
      </FormControl>

      <Box sx={{ marginTop: "15px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            marginBottom: "5px",
          }}
        >
          <Typography sx={typography}>Signature</Typography>
          <InfoOutlinedIcon
            fontSize="small"
            sx={{ cursor: "pointer", fontSize: "14px", color: "#6B7280" }}
          />
        </Box>
        <Box
          style={{
            width: "100%",
            border: "1px solid var(--light-border-color)",
          }}
        >
          <QuillEditor data={data?.signature || ""} />
        </Box>
      </Box>
      <LoadingButton
        loading={isLoading}
        style={{
          backgroundColor: "#1d4ed8",
          border: "1px solid #1d4ed8",
          borderColor: "#1d4ed8",
          color: "#fff",
          fontSize: ".875rem",
          lineHeight: "1.45",
          textTransform: "capitalize",
          width: "90px",
          margin: "25px 0 10px",
        }}
        autoFocus
        onClick={handleSubmit(onSubmit)}
      >
        Save
      </LoadingButton>
    </Box>
  );
};

export default GeneralSettings;
