import React from "react";
import ReactSpeedometer from "react-d3-speedometer";

const yearData = [
  {
    min: "0",
    max: "600",
    value: "500",
  },
];

const DialMeter = () => {
  return (
    <div style={{ display: "flex" }}>
      <div>
        {yearData.map((item, index) => (
          <div key={index}>
            <ReactSpeedometer
              width={310}
              height={180}
              ringWidth={20}
              minValue={parseInt(item.min)}
              maxValue={parseInt(item.max)}
              value={parseInt(item.value)}
              customSegmentStops={[
                parseInt(item.min),
                parseInt(item.value),
                parseInt(item.max),
              ]}
              segmentColors={["#F7C48F", "#EEEEEE"]}
              needleColor="#666666"
              needleTransitionDuration={4000}
              needleTransition="easeElastic"
              needleHeightRatio={0.5}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DialMeter;
