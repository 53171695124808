import React from "react";
import Grid from "@mui/material/Grid";
import ContactCreatedGraph from "./ContactCreatedGraph";
import Style from "../../../Style/Common.module.css";
const ContactCreatedGrid = () => {
  return (
    <Grid container spacing={2} height={"auto"}>
      <Grid item xs={12} md={12} sm={12}>
        <div className={Style.containerHeight}>
          <ContactCreatedGraph />
        </div>
      </Grid>
    </Grid>
  );
};

export default ContactCreatedGrid;
