import React from "react";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";

const CustomAvatar = ({ icon, color }) => {
  const MuiAvatar = styled(Avatar)(({ theme }) => ({
    height: "30px",
    width: "30px",
    fontSize: "15px",
    backgroundColor: color,
    marginRight: "10px",
    marginLeft: "5px",
  }));

  return (
    <>
      <MuiAvatar>{icon}</MuiAvatar>
    </>
  );
};

export default CustomAvatar;
