import React from "react";
import Grid from "@mui/material/Grid";
import { GridData } from "../Utils/GridData";
import Style from "../../../../Style/TopBar/Kpi.module.css";
import Styles from "../../../../Style/TopBar/Target.module.css";

const TargetMeterGrid = () => {
  return (
    <Grid container spacing={5}>
      {GridData.map((gridData) => (
        <Grid item sx={6} md={6} sm={12} key={gridData.key} spacing={3}>
          <>
            <div className={Styles.targetWrapper}>
              <div className={Style.titleText}>{gridData.title}</div>
              <div>{gridData.component}</div>
            </div>
            <div>
              <p className={Style.typeStyle}>{gridData.type}</p>
            </div>
          </>
        </Grid>
      ))}
    </Grid>
  );
};

export default TargetMeterGrid;
