import { create } from "zustand";
import { persist } from "zustand/middleware";

const useUser = create()(
  persist(
    (set, get) => ({
      user: null,

      setLogInDetails: (userDetails) => set({ user: userDetails }),
      logout: () => set({ user: null }),
      getToken: () => get().user?.jwtToken,
      getUserId: () => get().user?.user_id,
      getTenantToken: () => get().user?.meiliTenantToken
    }),
    {
      name: "logindetails-persit"
    }
  )
);

export default useUser;
