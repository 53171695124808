import React from "react";
import BounceStyle from "../Style/bounceStyle.module.css";
const BounceLoader = (props) => {
  return (
    <>
      <div className={BounceStyle.bouncing_loader} style={{   flex: "2 2 70%" }}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
};

export default BounceLoader;
