import React from "react";
import ReactSpeedometer from "react-d3-speedometer";


const TrafficMeter = () => {
  return (
    <div>
      <ReactSpeedometer
        width={300}
        height={180}
        needleHeightRatio={0.7}
        value={777}
        customSegmentStops={[0, 250, 750, 1000]}
        segmentColors={["#F27475", "#FFBE60", "#64D373"]}
        currentValueText="$700k"
        customSegmentLabels={[
          {
            text: "$0k",
            position: "OUTSIDE",
            color: "black",
          },
          {
            text: "",
            position: "OUTSIDE",
            color: "black",
          },
          {
            text: "$700k",
            position: "OUTSIDE",
            color: "black",
          },
        ]}
        ringWidth={10}
        needleTransitionDuration={3333}
        needleTransition="easeElastic"
        needleColor={"#666666"}
        textColor={"#d8dee9"}
      />
    </div>
  );
};

export default TrafficMeter;
