import React from "react";
import { Stack, Typography } from "@mui/material";
import ProfileAvatar from "./Components/ProfileAvatar";
import { useLatestMessages } from "../../CustomHooks/useLatestMessages";
import Loader from "../../../components/shared/Loader";
const ChatProfiles = React.memo(() => {
  const { data: latestChats = [], isLoading } = useLatestMessages(10);

  if (isLoading) {
    return <Loader />;
  }

  if (!latestChats?.data?.length) {
    return <Typography fontSize={"10px"}>No Chats.</Typography>;
  }

  return (
    <Stack>
      {latestChats?.data?.map((data, index) => (
        <ProfileAvatar
          key={data.id}
          name={data.first_name}
          profilePic={data.profile_pic}
          id={data.id}
        />
      ))}
    </Stack>
  );
});

export default ChatProfiles;
