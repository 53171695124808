import React from "react";
import Style from "../../../../Style/TopBar/Chart.module.css";

const data = [
  { name: "User1", value: 3 },
  { name: "User2", value: 6 },
];

const TableChart = () => {
  const totalValue = data.reduce((acc, item) => acc + item.value, 0);
  return (
    <div className={Style.tableWrapper}>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th className={Style.tableHead}>Created by</th>
            <th>Record Count</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className={Style.tableBorder}>
              <td className={Style.tableBody}>{item.name}</td>
              <td>{item.value}</td>
            </tr>
          ))}
          <tr className={Style.tableBorder}>
            <td className={Style.totalPadding}>Total</td>
            <td>{totalValue}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TableChart;
