// import { Box, Typography } from "@mui/material";
// import React, { useEffect } from "react";
// import ProgressBar from "../ProgressBar";
// // import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
// // import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
// import { newCampaignSubject } from "../../../../store/userStore";
// import { SetBodyValue } from "../../../../store/templateStore";

// const innerstyle = { fontSize: "12px", color: "#82858A", fontWeight: "400" };
// const spanBar = { fontSize: "12px", color: "#82858A", fontWeight: "400" };
// const fontstyle = { fontSize: "14px", fontWeight: "400" };

// const ContentGuide = () => {
//   const [subjectStrength, setSubjectStrength] = React.useState(0);
//   const [contentStrength, setContentStrength] = React.useState(0);
//   const [mergeTagCount, setMergeTagCount] = React.useState(0);

//   const { emailSubject } = newCampaignSubject();
//   const { body } = SetBodyValue();

//   useEffect(() => {
//     if (emailSubject && emailSubject.subject) {
//       setSubjectStrength(emailSubject.subject.length);
//       const mergeTagMatches = emailSubject.subject.match(/{{\w+}}/g);
//       setMergeTagCount(mergeTagMatches ? mergeTagMatches.length : 0);
//     }
//   }, [emailSubject]);

//   useEffect(() => {
//     if (body) {
//       const wordCount = body.trim().split(/\s+/).length;
//       setContentStrength(wordCount);
//     }
//   }, [body]);
// console.log("daaaaa",body)
//   return (
//     <Box
//       sx={{
//         padding: "20px",
//         overflowY: "auto",
//         height: "calc(100% - 40px)",
//         overflowX: "hidden",
//       }}
//     >
//       <Box sx={{ backgroundColor: "#E6E7FF", paddingBottom: "20px" }}>
//         <Typography
//           sx={{
//             padding: "10px 20px 7px 20px",
//             fontWeight: "600",
//             fontSize: "13px",
//           }}
//         >
//           Personalize the Subject line using merge tags
//         </Typography>
//         <Typography
//           sx={{ fontSize: "13px", paddingLeft: "20px", color: "#82858A" }}
//         >
//           An email with personalized subject lines gets 6% higher open rates.
//         </Typography>
//         {/* <Box sx={{ display: "flex", padding: "15px 20px 10px 20px" }}>
//            Code Email Tips 2/7
//           </Typography>
//           <Box sx={{ marginLeft: "auto", color: "#82858A", cursor: "pointer" }}>
//             <ArrowBackIosNewOutlinedIcon sx={{ fontSize: "14px" }} />
//             <ArrowForwardIosOutlinedIcon sx={{ fontSize: "14px" }} />
//           </Box>
//        </Box> */}
//       </Box>

//       <div className="guide-status">
//         <div className="guide-status-div" style={{ paddingTop: "20px" }}>
//           <p style={{ display: "flex", justifyContent: "space-between" }}>
//             <span className="guide-status-title" style={fontstyle}>
//               Subject Length
//             </span>
//             <span style={innerstyle}>{subjectStrength} characters</span>
//           </p>
//           <ProgressBar
//             bgcolor={
//               subjectStrength > 12 && subjectStrength <= 30
//                 ? "#d97706"
//                 : subjectStrength > 30
//                 ? "#047857"
//                 : "#b91c1c"
//             }
//             progress={
//               subjectStrength > 12 && subjectStrength <= 30
//                 ? "50"
//                 : subjectStrength > 30
//                 ? "100"
//                 : "0"
//             }
//             height={5}
//           />
//           <p style={{ display: "flex", justifyContent: "space-between" }}>
//             <span style={spanBar}> 30-60 characters suggested. </span>
//             <span
//               style={{
//                 fontSize: "12px",
//                 color:
//                   subjectStrength > 12 && subjectStrength <= 30
//                     ? "#d97706"
//                     : subjectStrength > 30
//                     ? "#047857"
//                     : "#b91c1c",
//                 fontWeight: "400",
//               }}
//             >
//               {subjectStrength > 12 && subjectStrength <= 30
//                 ? "Short"
//                 : subjectStrength > 30
//                 ? "Ideal"
//                 : "Too short"}
//             </span>
//           </p>
//         </div>
//         <div className="guide-status-div" style={{ paddingTop: "20px" }}>
//           <p style={{ display: "flex", justifyContent: "space-between" }}>
//             <span className="guide-status-title" style={fontstyle}>
//               Word Count
//             </span>{" "}
//             <span style={innerstyle}>{contentStrength} Word</span>
//           </p>
//           <ProgressBar
//             bgcolor={
//               contentStrength > 12 && contentStrength <= 30
//                 ? "#d97706"
//                 : contentStrength > 30
//                 ? "#047857"
//                 : "#b91c1c"
//             }
//             progress={
//               contentStrength > 12 && contentStrength <= 30
//                 ? "50"
//                 : contentStrength > 30
//                 ? "100"
//                 : "0"
//             }
//             height={5}
//           />
//           <p style={{ display: "flex", justifyContent: "space-between" }}>
//             <span style={spanBar}> 30-60 characters suggested. </span>{" "}
//             <span
//               style={{
//                 fontSize: "12px",
//                 color:
//                   contentStrength > 12 && contentStrength <= 30
//                     ? "#d97706"
//                     : contentStrength > 30
//                     ? "#047857"
//                     : "#b91c1c",
//                 fontWeight: "400",
//               }}
//             >
//               {contentStrength > 12 && contentStrength <= 30
//                 ? "Short"
//                 : contentStrength > 30
//                 ? "Ideal"
//                 : "Too short"}
//             </span>
//           </p>
//         </div>
//         <div className="guide-status-div" style={{ paddingTop: "20px" }}>
//           <p style={{ display: "flex", justifyContent: "space-between" }}>
//             <span className="guide-status-title" style={fontstyle}>
//               Personalization
//             </span>
//             <span style={innerstyle}>{mergeTagCount} merge tag(s)</span>
//           </p>
//           <ProgressBar
//             bgcolor={mergeTagCount >= 2 ? "#047857" : "#b91c1c"}
//             progress={mergeTagCount >= 2 ? "100" : "0"}
//             height={5}
//           />
//           <p style={{ display: "flex", justifyContent: "space-between" }}>
//             <span style={spanBar}> 2 or more merge tags suggested. </span>
//             <span
//               style={{
//                 fontSize: "12px",
//                 color: mergeTagCount >= 2 ? "#047857" : "#b91c1c",
//                 fontWeight: "400",
//               }}
//             >
//               {mergeTagCount >= 2 ? "Good" : "Poor"}
//             </span>
//           </p>
//         </div>

//         <div className="guide-status-div" style={{ paddingTop: "20px" }}>
//           <p style={{ display: "flex", justifyContent: "space-between" }}>
//             <span className="guide-status-title" style={fontstyle}>
//               Links
//             </span>
//             <span style={innerstyle}>
//               {body.match(/<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/g)?.length ||
//                 0}{" "}
//               link(s)
//             </span>
//           </p>
//           <ProgressBar
//             bgcolor={contentStrength > 100 ? "#047857" : ""}
//             progress={contentStrength > 100 ? "100" : "0"}
//             height={5}
//           />
//           <p style={{ display: "flex", justifyContent: "space-between" }}>
//             <span style={spanBar}> We suggest minimal usage. </span>
//             <span
//               style={{
//                 fontSize: "12px",
//                 color: contentStrength > 100 ? "#047857" : "",
//                 fontWeight: "400",
//               }}
//             >
//               {contentStrength > 100 ? "Excellent" : ""}
//             </span>
//           </p>
//         </div>

//         <div className="guide-status-div" style={{ paddingTop: "20px" }}>
//           <p style={{ display: "flex", justifyContent: "space-between" }}>
//             <span className="guide-status-title" style={fontstyle}>
//               Spamminess
//             </span>{" "}
//             <span style={innerstyle}>0 word</span>
//           </p>
//           <ProgressBar
//             bgcolor={contentStrength > 100 ? "#047857" : ""}
//             progress={contentStrength > 100 ? "100" : "0"}
//             height={5}
//           />
//           <p style={{ display: "flex", justifyContent: "space-between" }}>
//             <span style={spanBar}> We suggest minimal usage. </span>{" "}
//             <span
//               style={{
//                 fontSize: "12px",
//                 color: contentStrength > 100 ? "#047857" : "",
//                 fontWeight: "400",
//               }}
//             >
//               {contentStrength > 100 ? "Excellent" : ""}
//             </span>
//           </p>
//         </div>
//       </div>

//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           padding: "20px 0",
//           alignItems: "center",
//         }}
//       >
//         <Box
//           sx={{
//             backgroundColor: "#0AA607",
//             color: "white",
//             padding: "5px ",
//             height: "30px",
//             fontSize: "14px",
//           }}
//         >
//           New
//         </Box>
//         <Typography sx={{ paddingLeft: "7px", fontSize: "14px" }}>
//           Improve Deliverability With Text Only Email
//         </Typography>
//       </Box>
//     </Box>
//   );
// };

// export default ContentGuide;

import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ProgressBar from "../ProgressBar";
// import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
// import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { newCampaignSubject } from "../../../../store/userStore";
import { SetBodyValue } from "../../../../store/templateStore";

const innerstyle = { fontSize: "12px", color: "#82858A", fontWeight: "400" };
const spanBar = { fontSize: "12px", color: "#82858A", fontWeight: "400" };
const fontstyle = { fontSize: "14px", fontWeight: "400" };

const ContentGuide = () => {
  const [subjectStrength, setSubjectStrength] = React.useState(0);
  const [contentStrength, setContentStrength] = React.useState(0);
  const [mergeTagCount, setMergeTagCount] = React.useState({ subject: 0, body: 0 }); 
  const { emailSubject } = newCampaignSubject();
  const { body } = SetBodyValue();

  const TotalMergeCount = mergeTagCount.subject + mergeTagCount.body

  useEffect(() => {
    if (emailSubject && emailSubject.subject) {
      setSubjectStrength(emailSubject.subject.length);
      const subjectMergeTagMatches = emailSubject.subject.match(/{{\w+}}/g);
      const subjectMergeTagCount = subjectMergeTagMatches ? subjectMergeTagMatches.length : 0;

      setMergeTagCount((prev) => ({
        ...prev,
        subject: subjectMergeTagCount,
      }));
    }
  }, [emailSubject]);

  useEffect(() => {
    if (body) {
      const wordCount = body.trim().split(/\s+/).length;
      setContentStrength(wordCount);

      const bodyMergeTagMatches = body.match(/{{\w+}}/g);
      const bodyMergeTagCount = bodyMergeTagMatches ? bodyMergeTagMatches.length : 0;

      setMergeTagCount((prev) => ({
        ...prev,
        body: bodyMergeTagCount,
      }));
    }
  }, [body]);


  return (
    <Box
      sx={{
        padding: "20px",
        overflowY: "auto",
        height: "calc(100% - 40px)",
        overflowX: "hidden",
      }}
    >
      <Box sx={{ backgroundColor: "#E6E7FF", paddingBottom: "20px" }}>
        <Typography
          sx={{
            padding: "10px 20px 7px 20px",
            fontWeight: "600",
            fontSize: "13px",
          }}
        >
          Personalize the Subject line using merge tags
        </Typography>
        <Typography
          sx={{ fontSize: "13px", paddingLeft: "20px", color: "#82858A" }}
        >
          An email with personalized subject lines gets 6% higher open rates.
        </Typography>
      </Box>

      <div className="guide-status">
        <div className="guide-status-div" style={{ paddingTop: "20px" }}>
          <p style={{ display: "flex", justifyContent: "space-between" }}>
            <span className="guide-status-title" style={fontstyle}>
              Subject Length
            </span>
            <span style={innerstyle}>{subjectStrength} characters</span>
          </p>
          <ProgressBar
            bgcolor={
              subjectStrength > 12 && subjectStrength <= 30
                ? "#d97706"
                : subjectStrength > 30
                ? "#047857"
                : "#b91c1c"
            }
            progress={
              subjectStrength > 12 && subjectStrength <= 30
                ? "50"
                : subjectStrength > 30
                ? "100"
                : "0"
            }
            height={5}
          />
          <p style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={spanBar}> 30-60 characters suggested. </span>
            <span
              style={{
                fontSize: "12px",
                color:
                  subjectStrength > 12 && subjectStrength <= 30
                    ? "#d97706"
                    : subjectStrength > 30
                    ? "#047857"
                    : "#b91c1c",
                fontWeight: "400",
              }}
            >
              {subjectStrength > 12 && subjectStrength <= 30
                ? "Short"
                : subjectStrength > 30
                ? "Ideal"
                : "Too short"}
            </span>
          </p>
        </div>

        <div className="guide-status-div" style={{ paddingTop: "20px" }}>
          <p style={{ display: "flex", justifyContent: "space-between" }}>
            <span className="guide-status-title" style={fontstyle}>
              Word Count
            </span>{" "}
            <span style={innerstyle}>{contentStrength} Word</span>
          </p>
          <ProgressBar
            bgcolor={
              contentStrength > 12 && contentStrength <= 30
                ? "#d97706"
                : contentStrength > 30
                ? "#047857"
                : "#b91c1c"
            }
            progress={
              contentStrength > 12 && contentStrength <= 30
                ? "50"
                : contentStrength > 30
                ? "100"
                : "0"
            }
            height={5}
          />
          <p style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={spanBar}> 30-60 characters suggested. </span>{" "}
            <span
              style={{
                fontSize: "12px",
                color:
                  contentStrength > 12 && contentStrength <= 30
                    ? "#d97706"
                    : contentStrength > 30
                    ? "#047857"
                    : "#b91c1c",
                fontWeight: "400",
              }}
            >
              {contentStrength > 12 && contentStrength <= 30
                ? "Short"
                : contentStrength > 30
                ? "Ideal"
                : "Too short"}
            </span>
          </p>
        </div>

        <div className="guide-status-div" style={{ paddingTop: "20px" }}>
          <p style={{ display: "flex", justifyContent: "space-between" }}>
            <span className="guide-status-title" style={fontstyle}>
              Personalization
            </span>
            <span style={innerstyle}>
  {TotalMergeCount} merge tag(s)
</span>

          </p>
          <ProgressBar
            bgcolor={
              TotalMergeCount >= 2 
                ? "#047857"
                : "#b91c1c"
            }
            progress={
              TotalMergeCount >= 2 ? "100" : "0"
            }
            height={5}
          />
          <p style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={spanBar}> 2 or more merge tags suggested. </span>
            <span
              style={{
                fontSize: "12px",
                color:
                TotalMergeCount >= 2 
                    ? "#047857"
                    : "#b91c1c",
                fontWeight: "400",
              }}
            >
              {TotalMergeCount >= 2 
                ? "Good"
                : "Poor"}
            </span>
          </p>
        </div>

        <div className="guide-status-div" style={{ paddingTop: "20px" }}>
          <p style={{ display: "flex", justifyContent: "space-between" }}>
            <span className="guide-status-title" style={fontstyle}>
              Links
            </span>
            <span style={innerstyle}>
              {body.match(/<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/g)?.length || 0}{" "}
              link(s)
            </span>
          </p>
          <ProgressBar
            bgcolor={contentStrength > 100 ? "#047857" : ""}
            progress={contentStrength > 100 ? "100" : "0"}
            height={5}
          />
          <p style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={spanBar}> Min 1 link suggested </span>
            <span
              style={{
                fontSize: "12px",
                color: contentStrength > 100 ? "#047857" : "",
                fontWeight: "400",
              }}
            >
              {contentStrength > 100 ? "Excellent" : ""}
            </span>
          </p>
        </div>

        <div className="guide-status-div" style={{ paddingTop: "20px" }}>
          <p style={{ display: "flex", justifyContent: "space-between" }}>
            <span className="guide-status-title" style={fontstyle}>
              Spamminess
            </span>{" "}
            <span style={innerstyle}>0 word</span>
          </p>
          <ProgressBar
            bgcolor={contentStrength > 100 ? "#047857" : ""}
            progress={contentStrength > 100 ? "100" : "0"}
            height={5}
          />
          <p style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={spanBar}> We suggest minimal usage. </span>{" "}
            <span
              style={{
                fontSize: "12px",
                color: contentStrength > 100 ? "#047857" : "",
                fontWeight: "400",
              }}
            >
              {contentStrength > 100 ? "Excellent" : ""}
            </span>
          </p>
        </div>
      </div>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "20px 0",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#0AA607",
            color: "white",
            padding: "5px ",
            height: "30px",
            fontSize: "14px",
          }}
        >
          New
        </Box>
        <Typography sx={{ paddingLeft: "7px", fontSize: "14px" }}>
          Improve Deliverability With Text Only Email
        </Typography>
      </Box>
    </Box>
  );
};

export default ContentGuide;
