import React from "react";
import CustomActivityLayOut from "../Components/CustomActivityLayOut";
import Style from "../../Style/Common.module.css";
import { WithHoc } from "../Components/WithHoc";
import LatestRatingWrapper from "./Components/LatestRatingWrapper";
const LatestRatingLayout = () => {
  const LRLayout = WithHoc(CustomActivityLayOut, LatestRatingWrapper);
  return (
    <div className={`${Style.commonPadding} ${Style.commonPaddingBottom}`}>
      <LRLayout title={"Latest Ratings & Comments "} />
    </div>
  );
};

export default LatestRatingLayout;
