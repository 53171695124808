import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Mon",
    uv: 19,
    pv: 30,
    amt: 100,
  },
  {
    name: "Tue",
    uv: 40,
    pv: 25,
    amt: 100,
  },
  {
    name: "Wed",
    uv: 21,
    pv: 8,
    amt: 100,
  },
  {
    name: "Thu",
    uv: 12,
    pv: 13,
    amt: 100,
  },
  {
    name: "Fri",
    uv: 30,
    pv: 2,
    amt: 100,
  },
  {
    name: "Sat",
    uv: 10,
    pv: 5,
    amt: 100,
  },
  {
    name: "Sun",
    uv: 20,
    pv: 10,
    amt: 100,
  },
];

const ConversationGraph = () => {
  return (
    <div style={{height:330}}>
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={800}
        height={500}
        data={data}
        margin={{
          top: 100,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="pv" stackId="a" barSize={13} fill="#22B9c2" />
        <Bar dataKey="uv" stackId="a" barSize={13} fill="#026FBE" />
      </BarChart>
    </ResponsiveContainer>
    </div>
  );
};

export default ConversationGraph;
