import React, { useState } from "react";
import {
  Modal,
  Box,
  TextField,
  Typography,
  IconButton,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Avatar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAllList } from "../../../../CustomHooks/useAllList";

const users = [
  { id: 1, name: "John Doe", avatar: "https://i.pravatar.cc/300?img=1" },
  { id: 2, name: "Jane Smith", avatar: "https://i.pravatar.cc/300?img=2" },
  { id: 3, name: "Bob Johnson", avatar: "https://i.pravatar.cc/300?img=3" },
  { id: 4, name: "Alice Brown", avatar: "https://i.pravatar.cc/300?img=4" },
  { id: 5, name: "Michael Lee", avatar: "https://i.pravatar.cc/300?img=5" },
  { id: 1, name: "John Doe", avatar: "https://i.pravatar.cc/300?img=1" },
  { id: 2, name: "Jane Smith", avatar: "https://i.pravatar.cc/300?img=2" },
  { id: 3, name: "Bob Johnson", avatar: "https://i.pravatar.cc/300?img=3" },
  { id: 4, name: "Alice Brown", avatar: "https://i.pravatar.cc/300?img=4" },
  { id: 5, name: "Michael Lee", avatar: "https://i.pravatar.cc/300?img=5" },
  { id: 1, name: "John Doe", avatar: "https://i.pravatar.cc/300?img=1" },
  { id: 2, name: "Jane Smith", avatar: "https://i.pravatar.cc/300?img=2" },
  { id: 3, name: "Bob Johnson", avatar: "https://i.pravatar.cc/300?img=3" },
  { id: 4, name: "Alice Brown", avatar: "https://i.pravatar.cc/300?img=4" },
  { id: 5, name: "Michael Lee", avatar: "https://i.pravatar.cc/300?img=5" },
  { id: 1, name: "John Doe", avatar: "https://i.pravatar.cc/300?img=1" },
  { id: 2, name: "Jane Smith", avatar: "https://i.pravatar.cc/300?img=2" },
  { id: 3, name: "Bob Johnson", avatar: "https://i.pravatar.cc/300?img=3" },
  { id: 4, name: "Alice Brown", avatar: "https://i.pravatar.cc/300?img=4" },
  { id: 5, name: "Michael Lee", avatar: "https://i.pravatar.cc/300?img=5" },
];

const UserSelectionModule = ({ open, handleClose }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
const{data,isLoading}=useAllList()
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleToggle = (userId) => {
    const currentIndex = selectedUsers.indexOf(userId);
    const newChecked = [...selectedUsers];

    if (currentIndex === -1) {
      newChecked.push(userId);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedUsers(newChecked);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          p: 4,
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Select Users</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <TextField
          label="Search Users"
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchTerm}
          onChange={handleSearch}
        />

        <List
          sx={{
            maxHeight: "300px",
            overflowY: "auto"
          }}
        >
          {filteredUsers.map((user) => (
            <ListItem
              key={user.id}
              disablePadding
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.05)",
                },
              }}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedUsers.indexOf(user.id) !== -1}
                  tabIndex={-1}
                  onChange={() => handleToggle(user.id)}
                />
              </ListItemIcon>

              {/* Avatar */}
              <Avatar
                src={user.avatar}
                alt={user.name}
                sx={{ width: 24, height: 24, marginRight: "8px" }}
              />

              <ListItemText primary={user.name} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Modal>
  );
};

export default UserSelectionModule;
