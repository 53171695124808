import ChartLayOut from "../Chart";
import KPILayout from "../KPI";
import TargetMeterLayOut from "../TargetMeter";

export const DataArray = {
  KPI: {
    title: "Choose KPI Style",
    element:<KPILayout/>
  },
  BarChart: {
    title: "Choose Chart Type",
    element:<ChartLayOut/>
  },
  TargetMeter: {
    title: "Choose Target Meter Type",
    element:<TargetMeterLayOut/>
  },
};
