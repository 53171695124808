import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./Style/MainSideBar.css";
import dashboard from "./img/primaryIcons/Dashboard.png";
import service from "./img/primaryIcons/WebService.png";
import Marketing from "./img/primaryIcons/Marketing.png";
import chat from "./img/primaryIcons/ChatNow.png";
import VisionNow from "./img/primaryIcons/VisionNow.png";
import Tracking from "./img/primaryIcons/SpotNow.png";
import Peoplesnow from "./img/primaryIcons/PeoplesNow.png";
import NDEMail from "./img/primaryIcons/Mail.png";
import Drive from "./img/primaryIcons/Drive.png";
import Meet from "./img/primaryIcons/Meet.png";
import Docs from "./img/primaryIcons/Docs.png";
import Books from "./img/primaryIcons/BookKeeps.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";


const MainSideBar = () => {
  useEffect(() => {
    document.body.classList.add("shrink");
  }, []);

  useEffect(() => {
    document.body.classList.add("shrink");
  }, []);

  return (
    <div className="primaryIconsMain">
      <nav className="nav1">
        <div className="sidebar-links">
          <ul>
            <li className="tooltip-element liItems-space" data-tooltip="0">
              <NavLink to="/dashboard" activeClassName="active-link">
                <div className="icon">
                  <img src={dashboard} alt="dashboard" className="icons" />
                </div>
                <span className="truncate-text">Dashboard</span>
              </NavLink>
            </li>
            <li className="tooltip-element liItems-space" data-tooltip="2">
              <NavLink
                className="tooltip-element"
                to="/mail"
                activeClassName="active-link"
              >
                <div className="icon">
                  <img src={NDEMail} alt="mail" className="icons" />
                </div>
                <span>NDE Mail</span>
              </NavLink>
            </li>
            <li className="tooltip-element liItems-space">
              <NavLink
                className="tooltip-element"
                to="/meet"
                activeClassName="active-link"
              >
                <div className="icon">
                  <img src={Meet} alt="meet" className="icons" />
                </div>
                <span>NDE Meet</span>
              </NavLink>
            </li>
            <li className="tooltip-element liItems-space">
              <NavLink
                className="tooltip-element"
                to="/docs"
                activeClassName="active-link"
              >
                <div className="icon">
                  <img src={Docs} alt="docs" className="icons" />
                </div>
                <span>NDE Docs</span>
              </NavLink>
            </li>
            <li className="tooltip-element liItems-space">
              <NavLink
                className="tooltip-element"
                to="/drive"
                activeClassName="active-link"
              >
                <div className="icon">
                  {/* <img src={chat} alt="web services" /> */}
                  <img src={Drive} alt="drive" className="icons" />
                </div>
                <span>NDE Drive</span>
              </NavLink>
            </li>
            <li className="tooltip-element liItems-space">
              <NavLink
                className="tooltip-element"
                to="/crm"
                activeClassName="active-link"
              >
                <div className="icon">
                  <img src={VisionNow} alt="vision-now" className="icons" />
                </div>
                <span className="truncate-text">Vision Now</span>
              </NavLink>
            </li>
            <li className="tooltip-element liItems-space">
              <NavLink
                className="tooltip-element"
                to="/chatnow"
                activeClassName="active-link"
              >
                <div className="icon">
                  <img src={chat} alt="chat" className="icons" />
                </div>
                <span>Chat Now</span>
              </NavLink>
            </li>
            <li className="tooltip-element liItems-space" data-tooltip="2">
              <NavLink
                className="tooltip-element"
                to="/markettingstudio"
                activeClassName="active-link"
              >
                <div className="icon">
                  <img src={Marketing} alt="marketing" className="icons" />
                </div>
                <span>Marketing</span>
              </NavLink>
            </li>
            <li className="tooltip-element liItems-space" data-tooltip="3">
              <NavLink
                className="tooltip-element"
                to="/spotnow"
                activeClassName="active-link"
              >
                <div className="icon">
                  <img src={Tracking} alt="spotnow" className="icons" />
                </div>
                <span>Spot Now</span>
              </NavLink>
            </li>
            <li className="tooltip-element liItems-space">
              <NavLink
                className="tooltip-element"
                to="/books"
                activeClassName="active-link"
              >
                <div className="icon">
                  <img src={Books} alt="books" className="icons" />
                </div>
                <span>Book Keeps</span>
              </NavLink>
            </li>
            <li className="tooltip-element liItems-space" data-tooltip="1">
              <NavLink
                to="/webservices"
                className="tooltip-element"
                activeClassName="active-link"
              >
                <div className="icon">
                  <img src={service} alt="web services" className="icons" />
                </div>
                {/* <span className="link hide">Web Services</span> */}
                <span className="truncate-text">Web Services</span>
              </NavLink>
            </li>
            <li className="tooltip-element liItems-space">
              <NavLink
                className="tooltip-element"
                to="/peoplesnow"
                // to="/settings/manageemployee"
                activeClassName="active-link"
              >
                <div className="icon">
                  <img src={Peoplesnow} alt="peoplesnow" className="icons" />
                </div>
                <span>Peoples Now</span>
              </NavLink>
            </li>

            {/* <li className="tooltip-element" >
              <NavLink className="tooltip-element" to="/123" activeClassName="active-link" >
                <div className="icon">
                <img src={influence} alt='web services' />
                </div>
                <span className="shrink-btn">Influencer</span>
              </NavLink>
            </li> */}

            {/* More list items go here */}
          </ul>

          <h4 className="hide">Shortcuts</h4>
          {/* <span className="shrink-btn" onClick={handleShrinkClick}>
          kavi
          </span> */}
          <ul>{/* Your existing shortcut links go here */}</ul>
        </div>

        {/* <div className="tooltip">
        {tooltips.map((tooltip, index) => (
          <span key={index} className={index === activeIndex ? 'show' : ''}>
            {tooltip}
          </span>
        ))}
        </div> */}
      </nav>

      {/* <main>
        <h1>My Dashboard</h1>
        <p className="text">
        </p>
        <p className="copyright">
          &copy; 2021 - <span>Aqumex</span> All Rights Reserved.
        </p>
      </main> */}
      <div className="scrollIndicator">
        <KeyboardArrowDownIcon
          fontSize="small"
          sx={{
            color: "#fff"
          }}
        />
      </div>
    </div>
  );
};

export default MainSideBar;

