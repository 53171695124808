import Wrapper from "../../../../Components/Wrapper";
import { WithHoc } from "../../../../ChatNow/Components/WithHoc";
import WrapperData from "../../../../Components/WrapperData";
import GridWrapper from "../Component/GridWrapper";
import GrowthIndex from "../Component/GrowthIndex";
import Basics from "../Component/Basics";
import DealsCreatedTable from "../Component/DealsCreatedTable";
import DealsOwnerTable from "../Component/DealsOwnerTable";

const Standard = WithHoc(GridWrapper, WithHoc(Wrapper, WrapperData));
const Growthindex = WithHoc(GridWrapper, WithHoc(Wrapper, GrowthIndex));
const Basic = WithHoc(GridWrapper, WithHoc(Wrapper, Basics));
const DealsCreated = WithHoc(GridWrapper, WithHoc(Wrapper, DealsCreatedTable));
const DealOwner = WithHoc(GridWrapper, WithHoc(Wrapper, DealsOwnerTable));

export const GridData = [
  {
    id: "#standard",
    component: (
      <Standard
        title={"REVENUE THIS MONTH"}
        count={"$300"}
        rate={"0"}
        percentage={"3.9%"}
        type="Standard"
      />
    ),
  },
  {
    id: "#growth-Index",
    component: (
      <Growthindex
        title={"REVENUE THIS MONTH"}
        growthIndex={"13.01%"}
        rate={"0"}
        revenue={"$300"}
        type="Growth Index"
      />
    ),
  },
  {
    id: "#standard4",
    component: <Basic title={"OPEN DEALS"} deal={2000} type="Basic" />,
  },
  {
    id: "#standard2",
    component: (
      <DealsCreated
        title={"TOP INDUSTRIES - DEALS CREATED"}
        count={"$300"}
        rate={"0"}
        percentage={"3.9%"}
        type="ScoreCard"
      />
    ),
  },
  {
    id: "#standard3",
    component: <DealOwner title={"TOP 5 DEAL OWNERS"} type="Rankings" />,
  },
];
