import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/system";
import ProfilePanel from "./ProfilePanel";
import ChatBody from "./ChatBody";
import { Divider } from "@mui/material";
import { useReceiverId } from "../../../../store/teamChatStore";
import useUser from "../../../../../store/userStore";
import { useSocket } from "../../../../../Context/SocketStore";

const ChatView = () => {
  const { Socket } = useSocket()
  // const socket = useSocket();
  console.log("mytwosockets", Socket);

  const { receiverData } = useReceiverId();

  const receiverId = receiverData?.receiverId;
  const { getUserId } = useUser();
  const senderId = getUserId();
  const [roomId, setRoomId] = useState("");
  const [typing, setTyping] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (!Socket || !receiverId || !senderId) return;

    const handleConnect = (response) => {
      setRoomId(response.roomId);
    };

    Socket.on("roomJoined", handleConnect);
    const joinDataGrp = {
      groupId: receiverId,
    };
    const joinDataPvt = {
      senderId,
      receiverId,
    };
    if (receiverData.is_group_chat) {
      Socket.emit("join_group_room", joinDataGrp);
    } else {
      Socket.emit("join_private_room", joinDataPvt);
    }

    return () => {
      Socket.off("roomJoined", handleConnect);
      Socket.emit("join_private_room", joinDataPvt);
      Socket.emit("join_group_room", joinDataGrp);
    };
  }, [Socket, receiverId, senderId, receiverData.is_group_chat]);
  let timeoutEvent;

  function handleTyping(response) {
    setTyping(response.message);

    clearTimeout(timeoutEvent);
    timeoutEvent = setTimeout(() => {
      setTyping("");
    }, 3000);
  }

  useEffect(() => {
    if (!Socket) return;

    Socket.on("get_typing", handleTyping);

    return () => {
      Socket.off("get_typing", handleTyping);
      clearTimeout(timeoutEvent);
    };
  }, [Socket]);
  console.log("deliversocket", roomId);
  return (
    <Box height={"89%"} sx={{ background: "#F2FBFF" }} width={"100%"}>
      <ProfilePanel typing={typing} />
      <Divider />
      <ChatBody
        roomId={roomId}
        socket={Socket}
        message={message}
        setMessage={setMessage}
        receiverId={receiverId}
        senderId={senderId}
      />
    </Box>
  );
};

export default ChatView;
