import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useOnlineStatusStore } from "../store/teamChatStore";

async function getMessageList(page = 1, limit = 10) {
  const url = `${process.env.REACT_APP_TEAM_CHAT_BASEURL}/api/v1/chats?page=${page}&limit=${limit}`;
  return axios.get(url).then(({ data }) => data);
}

export const useLatestMessages = (limit) => {
  const { addOnlineStatusId } = useOnlineStatusStore();

  return useQuery({
    queryKey: ["teamChat_getLatestMessageList"],
    queryFn: async ({ pageParam = 1 }) => {
      const data = await getMessageList(pageParam, limit);

      data.onlineUsers.forEach((userId) => {
        addOnlineStatusId(userId);
      });

      return data;
    },
  });
};
