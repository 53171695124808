import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CallsWrapper from "./components/CallsWrapper";

const CallbyUsers = () => {
  const initialChartItems = [
    {
      id: "callsCompleted",
      component: (
        <CallsWrapper
          title={"Calls Completed - This Month"}
          count={1}
          percentage={"100%"}
          rate={"0"}
          noData={true}
        />
      ),
    },
    {
      id: "boundCalls",
      component: (
        <CallsWrapper
          title={"Inbound / Outbound Calls"}
          count={1}
          percentage={"100%"}
          rate={"0"}
          noData={true}
        />
      ),
    },
    {
      id: "upcomingCalls",
      component: (
        <CallsWrapper
          title={"Upcoming Calls - This Month"}
          count={1}
          rate={"0"}
          percentage={"100%"}
          noData={true}
        />
      ),
    },
    {
      id: "boundSeconds",
      component: (
        <CallsWrapper
          title={"Inbound / Outbound Seconds"}
          count={1}
          rate={"0"}
          percentage={"100%"}
          noData={true}
        />
      ),
    },
    {
      id: "averageCallDuration",
      component: (
        <CallsWrapper
          title={"Average Call Duration (seconds)"}
          count={1}
          percentage={"100%"}
          rate={"0"}
          noData={true}
        />
      ),
    },
  ];
  const [chartItems, setChartItems] = useState(initialChartItems);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(chartItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setChartItems(items);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="chartItems">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <Grid container spacing={2}>
              {chartItems.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {item.component}
                    </Grid>
                  )}
                </Draggable>
              ))}
            </Grid>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default CallbyUsers;
