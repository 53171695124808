import React from "react";
import Style from "../../../../Style/TopBar/Kpi.module.css";
const GridWrapper = (props) => {
  return (
    <div>
      <div className={Style.ConatinerWrapper}>{props.children}</div>
      <div>
        <p className={Style.typeStyle}>{props.type}</p>
      </div>
    </div>
  );
};

export default GridWrapper;
