import React from "react";
import { PieChart, Pie, Cell } from "recharts";
const StatusChart = ({ data }) => {

  return (
    <PieChart width={65} height={65}>
      <Pie
        data={data}
        cx={30}
        cy={30}
        innerRadius={20}
        outerRadius={30}
        fill="#8884d8"
        paddingAngle={3}
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.fill} />
        ))}
      </Pie>
    </PieChart>
  );
};

export default StatusChart;
