import React from "react";
import HeatMap from "react-heatmap-grid";
import Style from "../../../Style/Common.module.css";
export const graphData = [
  [100, 100, 40, 40, 70, 100],
  [58, 97, 34, 79, 30, 19],
  [33, 47, 6, 58, 100, 23, 100],
  [28, 74, 8, 19, 70, 98, 80],
  [60, 39, 73, 31, 100, 70, 10],
  [40, 19, 93, 71, 100, 40, 10],
  [100, 100, 100, 100, 100, 100, 100],
];

export default function SupportHeatMap() {
  const xLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const xLabelsVisibility = new Array(7).fill(true);

  const yLabels = ["4am", "6am", "8am", "12pm", "2pm", "4pm", "8pm"];
  const yLabelsVisibility = new Array(7).fill(false);

  return (
    <div style={{ display: "flex" }}>
      <div>
        {yLabels.map((data) => (
          <div
            key={data}
            className={`${Style.customLegenWrapper}  ${Style.flexCenter} ${Style.indexAlign} ${Style.reportText}`}
            style={{ borderBottom: "1px solid #e4e7e6" }}
          >
            <p style={{ margin: 0 }}>{data}</p>
          </div>
        ))}
      </div>

      <div
        className="heatmap-container"
        style={{ fontSize: "13px", width: "100%" }}
      >
        <div className="heatmap-grip-background">
          <HeatMap
            xLabels={xLabels}
            yLabels={yLabels}
            xLabelsLocation={"bottom"}
            xLabelsVisibility={xLabelsVisibility}
            yLabelsVisibility={yLabelsVisibility} // Ensure y labels are visible
            xLabelWidth={45} // Set the x label width to 45px
            yLabelWidth={0} // Set the y label width to 45px
            data={graphData}
            squares={false}
            height={68}
            cellStyle={(background, value, min, max, data, x, y) => ({
              background:
                value >= 70 ? "#35ac2d" : value >= 40 ? "#e8671a" : "#eb2c43",
              width: "45px",
              height: "45px",
              fontSize: "11.5px",
              color: "#444",
            })}
            cellRender={(value, min, max) => <div className="cell"></div>}
          />
        </div>
      </div>
    </div>
  );
}
