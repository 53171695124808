import React, { useEffect, useState } from 'react'
import {
    Box,
    Divider,
    Grid,
    IconButton,
    Typography,
    Button
  } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useQuery, useQueryClient,useMutation } from '@tanstack/react-query';
import moment from 'moment-timezone';
import {getSchedule,updateSchedule,makeScheduleDefault,deleteSchedule} from '../webservices/sequence'
import {snackbarStore} from '../store/userStore'


import ScheduleHeader from './ScheduleHeader';

function Schedules() {

  const [expanded, setExpanded] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const [default1,setDefault] = useState('')
  const [hover,setHover] = useState(null)
  const {setSnackbarValue} = snackbarStore()


  const timezones = moment.tz.names();
  const queryClient = useQueryClient();


  const sequenceData = useQuery({
    queryKey: ['getSchedule'],
    queryFn: getSchedule,
  });

  useEffect(() => {
    if (sequenceData?.isSuccess && sequenceData?.data?.data?.length > 0) {
      const defaultSchedule = sequenceData?.data?.data?.find((item) => item.default === true);
      const defaultScheduleId = defaultSchedule ? defaultSchedule._id : schedules?.data?.data[0]?._id;
      setDefault(defaultScheduleId);
    }
  }, [sequenceData?.data?.data]);

  const updateDefault = useMutation({
    mutationFn: makeScheduleDefault,
    onSuccess: (data) => {
      queryClient.invalidateQueries("getSchedule");
    },
    onError: (error) => {
      console.error("Error updating:", error);
    },
  });

  const makeDefaultFun = (index,scheduleid) =>{

    setHover(index)

    const data1 = {
      doc_id:scheduleid
    }
    updateDefault.mutate(data1);
  }

  const mutation = useMutation({
    mutationFn: updateSchedule,
    onSuccess: (data) => {
      alert("updated")
      queryClient.invalidateQueries("getSchedule");
    },
    onError: (error) => {
      console.error("Error updating:", error);
    },
  });

  const SheduleDelete = useMutation({
    mutationFn: deleteSchedule,
    onSuccess: (data) => {
      setSnackbarValue({open:true,severity:"success",message:"Deleted"})
      queryClient.invalidateQueries("getSchedule");
    },
    onError: (error) => {
      setSnackbarValue({open:true,severity:"success",message:error?.response?.data?.message ? error?.response?.data?.message : 'internal server error'})
    },
  });

  useEffect(() => {
    if (sequenceData?.data?.data) {
      setSchedules(sequenceData.data.data);
    }
  }, [sequenceData.data]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleScheduleChange = (index, field) => (event) => {
    const newSchedules = [...schedules];
    newSchedules[index][field] = event.target.value;
    setSchedules(newSchedules);
  };

  const handleDayChange = (index, day, subIndex, field, value) => {
    const newSchedules = [...schedules];
    newSchedules[index][day][subIndex][field] = value;
    setSchedules(newSchedules);
  };

  const handleAddTimeSlot = (index, day) => {
    if (schedules[index][day].length < 3) {
      const newSchedules = [...schedules];
      newSchedules[index][day].push({ start_time: "", end_time: "" });
      setSchedules(newSchedules);
    }
  };

  const handleDeleteTimeSlot = (index, day, subIndex) => {
    const newSchedules = [...schedules];
    newSchedules[index][day].splice(subIndex, 1);
    setSchedules(newSchedules);
  };

  const formatTimezone = (tz) => {
    const offset = moment.tz(tz).format('Z');
    return `${tz} (GMT ${offset})`;
  };

  const updateFunction = (id) =>{
    const docIndex = schedules.findIndex(schedule => schedule._id === id);
    if (docIndex === -1) {
      console.error("Document not found");
      return;
    }
    const updatedSchedules = [...schedules];
    updatedSchedules[docIndex] = {
      ...updatedSchedules[docIndex]
    };
    const data = {
      id:id,
      data:updatedSchedules[docIndex]
    }
    mutation.mutate(data);
  }

  const deleteScheduleFun = (id) =>{
    console.log("nnnnnnn",SheduleDelete)
    SheduleDelete.mutate({doc_id:id})
  }

  const padToTwoDigits = (num) => {
    if (num === undefined || num === null) {
      return '00'; // Return a default value or handle as needed
    }
    return num.toString().padStart(2, '0');
  };

  const renderSchedule = (index, day) => (

    <div style={{display:'flex',alignItems:'center'}}>
    {schedules[index][day].map((item, subIndex)=>{
    const [startHour, startMinute] = item.start_time.split(':').map(Number);
    const [endHour, endMinute] = item.end_time.split(':').map(Number);
    return (
    <div style={{background:"#f5f5f5",display:'flex',border:"1px solid #d9d9d9",borderRadius:".25rem",padding:"0 5px",alignItems:"center",margin:"2px .6rem 2px 0"}}>
     <input type="number" min="0" max="23" value={padToTwoDigits(startHour)} id="" style={{width:"2.2rem",textAlign:"center",fontSize:"14px",color:"#595959",outline:"none",border:"none",background:"none"}} onChange={(e) => handleDayChange(index, day, subIndex, 'start_time', `${e.target.value}:${item.start_time.split(':')[1]}`)}/>
     <small style={{height:"1.5rem",alignItems:"center",display:"flex",width:".3rem",justifyContent:"flex-start"}}>
     :
     </small>
     <input type="number" min="0" max="59" value={padToTwoDigits(startMinute)} id="" style={{width:"2.2rem",textAlign:"center",fontSize:"14px",color:"#595959",outline:"none",border:"none",background:"none"}} onChange={(e) => handleDayChange(index, day, subIndex, 'start_time', `${item.start_time.split(':')[0]}:${e.target.value}`)}/>
     <p style={{margin:"0 0 0 2px"}}>
       -
     </p>
     <input type="number" min="0" max="23"  value={padToTwoDigits(endHour)} id="" style={{width:"2.2rem",textAlign:"center",fontSize:"14px",color:"#595959",outline:"none",border:"none",background:"none"}} onChange={(e) => handleDayChange(index, day, subIndex, 'end_time', `${e.target.value}:${item.end_time.split(':')[1]}`)}/>
     <small style={{height:"1.5rem",alignItems:"center",display:"flex",width:".3rem",justifyContent:"flex-start"}}>
     :
     </small>
     <input type="number" min="0" max="59" value={padToTwoDigits(endMinute)} id="" style={{width:"2.2rem",textAlign:"center",fontSize:"14px",color:"#595959",outline:"none",border:"none",background:"none"}} onChange={(e) => handleDayChange(index, day, subIndex, 'end_time', `${item.end_time.split(':')[0]}:${e.target.value}`)}/>
     <IconButton style={{display:'flex',alignItems:'center',width:"5px",height:"5px"}} onClick={() => handleDeleteTimeSlot(index, day, subIndex)}>
     <CancelOutlinedIcon fontSize='small'/>
     </IconButton>
    </div>
)})}
    <IconButton style={{width:"1.625rem",height:"1.625rem",border:"1px solid #d9d9d9",borderRadius:".25rem",margin:"2px 0",display:"flex"}} onClick={() => handleAddTimeSlot(index, day)}>
      <AddCircleOutlineOutlinedIcon fontSize='small'/>
    </IconButton>
   </div>
    
  )

  return (
    <Box sx={{ width: "100%" }}>
        <Grid container>
        <Grid item xs={12}>
            <ScheduleHeader/>
        </Grid>
        <Grid item xs={12}>
        <div style={{padding:"1.5rem"}}>
        <div style={{paddingBottom:"2.5rem",overflowY:"auto",height:"calc(100vh - 12rem)",scrollbarWidth:"none"}}>
        {schedules.map((schedule, index)=>(
        <Accordion onMouseOut={()=>setHover(null)} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} style={{marginBottom:".875rem",borderRadius:".25rem",border:"1px solid #eaedef"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '34%', flexShrink: 0 }}>
          {schedule.name} 
          {default1 === schedule._id ?
          <span style={{color:"#52c41a",borderColor:"#b7eb8f",background:"#f6ffed",border:"1px solid #d9d9d9",borderRadius:".25rem",fontSize:"12px",lineHeight:"20px",padding:"3px 7px",marginLeft:"10px"}}>
            {default1 === schedule._id ? 'Default' : ''}
          </span> : <span onClick={()=>makeDefaultFun(index,schedule._id)} style={{color:"",borderColor:"#b7eb8f",background:"#f6ffed",border:"1px solid #d9d9d9",borderRadius:".25rem",fontSize:"12px",lineHeight:"20px",padding:"3px 7px",marginLeft:"10px"}}>
            Make it Default
          </span>}
          </Typography>
          <Typography sx={{ width: '34%', flexShrink: 0 }}>{schedule.timezone}</Typography>
          <div>
            {["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"].map((day,i)=>(
            <span key={i} style={{marginRight:"5px",color:schedule[day].length > 0 ? "#0137fc" : "#595959",background:schedule[day].length > 0 ? "#e6f7ff" : "#fafafa",borderColor:schedule[day].length ? "#9ad0ff" : "1px solid #d9d9d9",margin:"0 8px 0 0",padding:"3px 7px",fontSize:"12px",lineHeight:"20px",whiteSpace:"nowrap",border:schedule[day].length ? "1px solid #9ad0ff" : "1px solid #d9d9d9",borderRadius:".25rem"}}>
            {day.charAt(0).toUpperCase()}
            </span>
            ))}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Divider/>
          <div style={{padding:"1.5rem 2.5rem",borderTop:"1px solid #d9d9d9",background:"#fff"}}>
            <div style={{width:"35%",display:"flex",flexWrap:"wrap",marginRight:"-15px",marginLeft:"-15px"}}>
             <div style={{position:"relative",paddingRight:"15px",paddingLeft:"15px"}}>
              <label htmlFor="" style={{fontSize:".875rem",fontWeight:"600",lineHeight:"22px",color:"#212529",marginBottom:".5rem"}}>Name</label>
              <input type="text" name="name" value={schedule.name} style={{width:"19.375rem",padding:"4px 11px",color:"#595959",fontSize:"14px",lineHeight:"1.5715",backgroundColor:"#fff",border:"1px solid #d9d9d9",borderRadius:".25rem"}} onChange={handleScheduleChange(index, 'name')}/>
             </div>
             <br />
             <div style={{position:"relative",paddingRight:"15px",paddingLeft:"15px"}}>
              <label htmlFor="" style={{fontSize:".875rem",fontWeight:"600",lineHeight:"22px",color:"#212529",marginBottom:".5rem"}}>Time Zone</label>
              <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label=""
          value={schedule.timezone}
          onChange={handleScheduleChange(index, 'timezone')}
          sx={{ height: 30,width:"19.375rem" }}
        >
          {timezones.map((timezone) => (
                                <MenuItem key={timezone} value={timezone} style={{ fontSize: ".875rem" }}>
                                  {formatTimezone(timezone)}
                                </MenuItem>
                              ))}
        </Select>
      </FormControl>
             </div>
             <br />
            </div>
            <div style={{display:'flex',alignItems:'center',justifyContent:"space-between",marginLeft:"-38px"}}>
              <ul style={{marginBottom:"1rem"}}>
               <li style={{display:"flex",margin:"1rem 0"}}>
                <span style={{minWidth:"6.3125rem",fontSize:"14px",lineHeight:"22px",display:'flex',alignItems:"center",color:"#212529"}}>
                 Monday
                </span>
                {renderSchedule(index,'monday')}
               </li>
               <li style={{display:"flex",margin:"1rem 0"}}>
                <span style={{minWidth:"6.3125rem",fontSize:"14px",lineHeight:"22px",display:'flex',alignItems:"center",color:"#212529"}}>
                 Tuesday
                </span>
                {renderSchedule(index,'tuesday')}
               </li>
               <li style={{display:"flex",margin:"1rem 0"}}>
                <span style={{minWidth:"6.3125rem",fontSize:"14px",lineHeight:"22px",display:'flex',alignItems:"center",color:"#212529"}}>
                 Wednesday
                </span>
                {renderSchedule(index,'wednesday')}
               </li>
               <li style={{display:"flex",margin:"1rem 0"}}>
                <span style={{minWidth:"6.3125rem",fontSize:"14px",lineHeight:"22px",display:'flex',alignItems:"center",color:"#212529"}}>
                 Thursday
                </span>
                {renderSchedule(index,'thursday')}
               </li>
               <li style={{display:"flex",margin:"1rem 0"}}>
                <span style={{minWidth:"6.3125rem",fontSize:"14px",lineHeight:"22px",display:'flex',alignItems:"center",color:"#212529"}}>
                 Friday
                </span>
                {renderSchedule(index,'friday')}
               </li>
               <li style={{display:"flex",margin:"1rem 0"}}>
                <span style={{minWidth:"6.3125rem",fontSize:"14px",lineHeight:"22px",display:'flex',alignItems:"center",color:"#212529"}}>
                 Saturday
                </span>
                {renderSchedule(index,'saturday')}
               </li>
               <li style={{display:"flex",margin:"1rem 0"}}>
                <span style={{minWidth:"6.3125rem",fontSize:"14px",lineHeight:"22px",display:'flex',alignItems:"center",color:"#212529"}}>
                 Sunday
                </span>
                {renderSchedule(index,'sunday')}
               </li>
              </ul>
              </div> 
              <div style={{display:'flex',alignItems:'center',justifyContent:"space-between",marginTop:"1rem"}}>
              <Button style={{backgroundColor:"#1d4ed8",color:"#fff",height:"2rem",fontWeight:"500",fontSize:".875rem",textTransform:"capitalize"}} onClick={()=>updateFunction(schedule._id)}>
              Save
              </Button>
              <Button style={{marginLeft:"2rem",color:"#ff4d4f",display:"flex",alignItems:'center'}} onClick={()=>deleteScheduleFun(schedule._id)}>
                Delete schedule
              </Button>
              </div>
          </div>
        </AccordionDetails>
      </Accordion>
      ))}
        </div>
        </div>
        </Grid>
        </Grid>
    </Box>
  )
}

export default Schedules