import React from "react";
import Style from "../../../Style/Common.module.css";
const FilterBadge = (props) => {
  return (
    <div
      className={Style.userNameText}
      style={{ marginTop: "3px" }}
      key={`${props.data.label}-${props.data.label}`}
    >
      {props.data.label}
    </div>
  );
};

export default FilterBadge;
