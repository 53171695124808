import { Box } from "@mui/system";
import { Typography, Avatar } from "@mui/material";
import { useChatUIStore, useReceiverId } from "../../../../store/teamChatStore";

const ListBar = ({ data }) => {
  const { setUIElement } = useChatUIStore();
  const { setReceiverId } = useReceiverId();
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      py={1}
      width={"100%"}
      sx={{ cursor: "pointer" }}
      onClick={() => {
        setUIElement("#chatView");
        console.log("myid", data);
        setReceiverId({
          receiverId: data.userId,
          is_group_chat: false,
          convoId: data.conversationId,
          userName: `${data?.firstName} ${data?.lastName}`,
          email: data?.email,
        });
      }}
    >
      <Box>
        <Avatar
          alt="Travis Howard"
          src="/static/images/avatar/7.jpg"
          sx={{ width: "27px", height: "27px" }}
        />
      </Box>
      <Box paddingLeft={0.8}>
        <Typography fontWeight={500} fontSize={12}>
          {data?.firstName} {data?.lastName}
        </Typography>
        <Typography fontSize={11} color={"#959FA3"}>
          {data?.email}
        </Typography>
      </Box>
    </Box>
  );
};

export default ListBar;
