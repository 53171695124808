import React from "react";
import Drawer from "@mui/material/Drawer";
import DrawerHeader from "./DrawerHeader";
import Style from "../../../Style/TopBar/CustomDrawer.module.css";
import { DataArray } from "../Utils/RenderData";

const CustomDrawer = ({ open, onClose, selected }) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      PaperProps={{
        sx: {
          minWidth: "82vw",
          maxWidth: "82vw",
          overflow: "hidden",
        },
      }}
    >
      <DrawerHeader onClose={onClose} title={DataArray[selected].title} />
      <div className={Style.drawerBody}>{DataArray[selected].element}</div>
    </Drawer>
  );
};

export default CustomDrawer;
