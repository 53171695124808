import React from "react";
import Grid from "@mui/material/Grid";
import Style from "../../../Style/ChatNow/ChatNow.module.css";
import Wrapper from "../../../Components/Wrapper";
import Paper from "@mui/material/Paper";
import { WithHoc } from "../../Components/WithHoc";
import Description from "./Description";

const ActivityGrid = ({ statusData }) => {
  const WithDesc = WithHoc(Wrapper, Description);

  return (
    <div className={Style.LayoutInnerPadding}>
      <Grid container spacing={2}>
        {statusData.map((data, index) => (
          <Grid
            item
            key={`${data.title}-${data.percentage}-${index}`}
            xs={12}
            md={4}
            sm={12}
          >
            <>
              <Paper elevation={3} style={{ borderRadius: "4px !important" }}>
                <WithDesc
                  title={data.title}
                  count={data.count}
                  percentage={data.percentage}
                  desc={data.desc}
                />
              </Paper>
            </>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ActivityGrid;
