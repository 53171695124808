import React from "react";
import Hidden from "@mui/material/Hidden";
import Grid from "@mui/material/Grid";

import "../../styles/signUp.css";
import Panel from "../login/Panel/Panel";
import SignupHeader from "./Components/SignupHeader";
import SingUpForm from "./Components/SingUpForm";
export default function SignUp() {
  return (
    <>
      <div style={{ height: "100vh", width: "100vw" }}>
        <Grid container md={12} xs={12}>
          <Grid
            item
            md={6}
            xs={12}
            sm={12}
            sx={{ height: "100vh", overflowY: "auto" }}
          >
            <SignupHeader />
            <SingUpForm />
          </Grid>
          <Hidden mdDown>
            <Grid item md={6} xs={6} sm={6}>
              <Panel />
            </Grid>
          </Hidden>
        </Grid>
      </div>
    </>
  );
}
