import React, { useState, useRef, useEffect } from "react";
import { Tab, Tabs } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled } from "@mui/system";
import Info from "./info/Info";
import Shift from "./Shift/Shift";
import Department from "./Department/Department";
import Expense from "./Expense/Expense";
// import Leave from "./Leave/Leave";
import Holidays from "./Holidays/Holidays";
import Designation from "./Designation/Designation";
import Branch from "./Branch/Branch";
import TaskCategory from "./TaskCategory/TaskCategory";
import LeaveForm from "./Leave/LeaveForm";

const theme = createTheme();

const CustomTabs = styled(Tabs)(({ theme }) => ({
  overflowX: "auto",
  fontFamily: "Roboto",
  [theme.breakpoints.down("md")]: {
    flexDirection: "row",
  },
}));

const CustomTab = styled(Tab)(({ theme }) => ({
  minHeight: 20,
  padding: "16px 0px",
  "&.Mui-selected": {
    minHeight: 25,
    color: theme.palette.primary.main,
  },
}));

const CustomCloseIcon = styled(CloseIcon)({
  width: "16px",
  height: "16px",
  marginLeft: "16px",
  marginRight: "10px",
  cursor: "pointer",
  position: "relative",
  top: "1px",
  right: "5px",
});

const CustomTabPanel = styled("div")({
  height: "81%",
  borderBottom: "1px solid rgba(0,0,0,0.1)",
  overflowY: "auto",
});

// Component definition
function Organization({ handleClose }) {
  const tabsRef = useRef(null); // Define tabsRef here

  const [value, setValue] = useState(0);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const handleWheel = (e) => {
      e.preventDefault();
      const delta = e.deltaY || e.detail || -e.wheelDelta;
      const tabList = tabsRef.current; // Access tabsRef here
      if (tabList) {
        const targetScrollLeft = tabList.scrollLeft + delta;
        // Ensure smooth scrolling within bounds
        const maxScrollLeft = tabList.scrollWidth - tabList.clientWidth;
        const targetScrollLeftClamped = Math.min(
          maxScrollLeft,
          Math.max(0, targetScrollLeft)
        );
        smoothScroll(tabList, tabList.scrollLeft, targetScrollLeftClamped, 50);
      }
    };

    if (tabsRef.current) {
      tabsRef.current.addEventListener("wheel", handleWheel);
    }

    return () => {
      if (tabsRef.current) {
        tabsRef.current.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);

  function smoothScroll(element, start, end, duration) {
    let startTime = null;

    function animation(currentTime) {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const scrollAmount = easeInOutQuad(
        timeElapsed,
        start,
        end - start,
        duration
      );
      element.scrollLeft = scrollAmount;
      if (timeElapsed < duration) {
        requestAnimationFrame(animation);
      }
    }

    function easeInOutQuad(t, b, c, d) {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    }

    requestAnimationFrame(animation);
  }

  return (
    <ThemeProvider theme={theme}>
      <div style={{ height: "100%", width: "100%" }}>
        <div
          style={{
            width: "100%",
            height: "50px",
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid rgba(0,0,0,0.1)",
          }}
        >
          <CustomTabs
            ref={tabsRef} // Assign ref to CustomTabs component
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="scrollable"
            sx={{
              ".MuiTab-root .MuiButtonBase-root": {},
              ".MuiTabs-indicator": {
                height: "5px",
                backgroundColor: "rgba(0, 10, 255, 1)",
                borderRadius: "10px",
              },
            }}
          >
            <CustomTab label="Info" />
            <CustomTab label="Branch" />
            <CustomTab label="Department" />
            <CustomTab label="Designation" />
            <CustomTab label="Task Category" />
            <CustomTab label="Expense" />
            <CustomTab label="Shift" />
            <CustomTab label="Leave" />
            <CustomTab label="Holidays" />
          </CustomTabs>
          <CustomCloseIcon onClick={handleClose} />
        </div>
        <CustomTabPanel>
          {value === 0 && <Info />}
          {value === 1 && <Branch />}
          {value === 2 && <Department />}
          {value === 3 && <Designation />}
          {value === 4 && <TaskCategory />}
          {value === 5 && <Expense />}
          {value === 6 && <Shift />}
          {value === 7 && <LeaveForm />}
          {value === 8 && <Holidays />}
        </CustomTabPanel>
      </div>
    </ThemeProvider>
  );
}

Organization.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default Organization;
