import React from "react";
import Style from "../../Style/CheckBox.module.css";
const FilterCheckbox = () => {
  return (
    <>
      <label className={Style.container}>
        <input type="checkbox" />
        <span className={Style.checkmark}></span>
      </label>
    </>
  );
};

export default FilterCheckbox;
