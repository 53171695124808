import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Schedules from "../../../../../../MarketingStudio/MailsNow/Schedules";
import EmailAccounts from "./emailAcconts/EmailAccounts";
import CloseIcon from "@mui/icons-material/Close";

function MailsNowTab({ handleClose }) {
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleChange}
          aria-label="MailsNow Tabs"
        >
          <Tab label="Email Accounts" />
          <Tab label="Schedules" />
        </Tabs>
        <CloseIcon
          onClick={handleClose}
          sx={{
            width: "20px",
            height: "18px",
            marginLeft: "16px",
            marginRight: "10px",
            cursor: "pointer",
          }}
        />
      </Box>
      <Box sx={{ height: "calc(100% - 48px)" }}>
        {activeTab === 0 && <EmailAccounts />}
        {activeTab === 1 && <Schedules />}
      </Box>
    </Box>
  );
}

export default MailsNowTab;
