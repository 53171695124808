import { Box } from "@mui/material";
import React, { useState } from "react";
import MessageIcon from "../../../../Assets/message.png";
import SearchIcon from "../../../../Assets/searchIcon.png";
import ChatDropDown from "./ChatDropDown";
import Options from "./Options";
import CreateGroup from "./CreateGroup";
const ChatSearch = () => {
  const [createGroup, setCreateGroup] = useState(false);
  const handleOpenCreateGroup = () => setCreateGroup(true);
  const handleCloseCreateGroup = () => setCreateGroup(false);
  const [channel, setChannel] = useState(false);
  const handleOpenChannel = () => setChannel(true);
  const handleCloseChannel = () => setChannel(false);
  return (
    <>
      {" "}
      <Box
        display={"flex"}
        justifyContent={"space-around"}
        py={1}
        alignItems={"center"}
      >
        <Box>
          <img src={MessageIcon} alt="Message Icon" />
        </Box>
        <Box>
          <ChatDropDown />
        </Box>
        <Box>
          <Options
            handleCloseChannel={handleCloseChannel}
            handleOpenChannel={handleOpenChannel}
            handleOpenCreateGroup={handleOpenCreateGroup}
            handleCloseCreateGroup={handleCloseCreateGroup}
          />
        </Box>
      </Box>
      <CreateGroup
        open={createGroup}
        handleClickClose={handleCloseCreateGroup}
      />
    </>
  );
};

export default ChatSearch;
