import React from "react";
import ConversationsLayOut from "./Conversation";

import ActivityLayout from "./Activity";
import COLayout from "./ConversationOperator";
import LatestRatingLayout from "./LatestRating";
import SegementLayout from "./Segments";
import ShortCutLayout from "./ShortCutUsage";
import FiredTriggersLayOut from "./FiredTriggers";
import VisitorsLayout from "./VisitorsLocation";
import SupportResponseLayOut from "./SupportResponse";
import GeneralRatingLayOut from "./GeneralRating";
import VisitorCalendarLayout from "./VisitorCalendar";
import ContactsCreatedLayOut from "./ContactsCreated";

const ChatNowDashBoard = () => {
  const statusData = [
    {
      title: "Conversations",
      count: 871,
      percentage: "100%",
      desc: "1157 the week before",
    },
    {
      title: "Conversations",
      count: "10m",
      percentage: "60%",
      desc: "8m the week before",
    },
    {
      title: "Website Visitors",
      count: 20286,
      percentage: "70%",
      desc: "20,877 the week before",
    },
  ];
  return (
    <>
      <ActivityLayout
        title={"Activity Last Week Compared to The Week Before  "}
        statusData={statusData}
      />
      <ConversationsLayOut />
      <SupportResponseLayOut />
      <SegementLayout />
      <ShortCutLayout />
      <COLayout />
      <FiredTriggersLayOut />
      <VisitorsLayout />
      <GeneralRatingLayOut />
      <ContactsCreatedLayOut />
      <VisitorCalendarLayout />
      <LatestRatingLayout />
    </>
  );
};

export default ChatNowDashBoard;
